import React from "react";
import { SimplifiedFormProvider } from "./useSimplifiedForm";
import SimplifiedFormContent from "./SimplifiedFormContent";
import { Provider } from "jotai";

const SimplifiedFormWrapper = ({
  data = null,
  itemKey = "new",
  onAfterSubmitParameters = () => {},
  onFormUpdate = () => {},
  myProducers,
  myRole,
}) => {
  return (
    <Provider>
      <SimplifiedFormProvider
        value={{
          data,
          key: itemKey,
          myProducers,
          myRole,
          onAfterSubmitParameters,
          onFormUpdate,
        }}
      >
        <SimplifiedFormContent />
      </SimplifiedFormProvider>
    </Provider>
  );
};

export default SimplifiedFormWrapper;
