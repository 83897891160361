import React, { useContext, useEffect } from "react";
import ParametersForm from "./ParametersForm";
import SimplifiedForm from "./SimplifiedForm";
import useSimplifiedForm, { SimplifiedFormContext } from "./useSimplifiedForm";
import CustomTabs from "../../common/CustomTabs";

const SimplifiedFormContent = () => {
  const formContext = useContext(SimplifiedFormContext);
  const { simplifiedFormActions, parameterState } = useSimplifiedForm();

  useEffect(() => {
    if (formContext.data) {
      simplifiedFormActions.initializeFormState();
    } else {
      simplifiedFormActions.resetParameterState();
    }
  }, []);

  return (
    <CustomTabs
      items={[
        {
          id: "parameters",
          title: "Paramètres",
          content: (setCurrentTab) => (
            <ParametersForm goToForm={() => setCurrentTab(1)} />
          ),
        },
        {
          id: "simpform",
          title: "Formulaire",
          content: () => <SimplifiedForm />,
          disabled: !parameterState.id,
          disabledMessage: "Veuillez d'abord enregistrer les paramètres",
        },
      ]}
    />
  );
};

export default SimplifiedFormContent;
