import React from "react";
import Pagination from "../../../../common/Pagination";
import useClientList from "../useClientList";

const ClientListPagination = ({ clientCount }) => {
  const { clientFilters, actions } = useClientList();

  return (
    <Pagination
      page={clientFilters.page}
      nbPages={Math.ceil(clientCount / 25)}
      changePage={actions.updatePage}
    />
  );
};

export default ClientListPagination;
