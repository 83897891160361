import React, { useState } from 'react';
import Page from '../../common/layout/Page';
import DataLine from './DataLine';

const ImportData = () => {
    const [errors, setErrors] = useState({});
    const [imports, setImports] = useState([
        {
            path: 'historyitems',
            name: 'Historique : indices, taxes ...',
        },
        {
            path: 'customlists',
            name: 'Listes personnalisées',
        },
        {
            path: 'customlistitems',
            name: 'Items des listes personnalisées',
        },
        {
            path: 'firms',
            name: 'Sociétés',
        },
        {
            path: 'offices',
            name: 'Agences',
        },
        {
            path: 'bankaccounts',
            name: 'Compte bancaires',
        },
        {
            path: 'companies',
            name: "Compagnies d'assurance",
        },
        {
            path: 'producers',
            name: 'Gestionnaires / apporteurs',
        },
        {
            path: 'guarantees',
            name: 'Garanties',
        },
        {
            path: 'producttypes',
            name: 'Types de contrat',
        },
        {
            path: 'products',
            name: 'Produits',
        },
        {
            path: 'clients',
            name: 'Clients',
        },
        {
            path: 'policies',
            name: 'Contrats',
        },
        {
            path: 'amendments',
            name: 'Avenants',
        },
        {
            path: 'receipts',
            name: 'Quittances',
        },
        {
            path: 'complaints',
            name: 'Réclamations',
        },
    ]);

    return (
        <Page container={'container-fluid'} title="Import des données Modulr" errors={errors}>
            <ul className="list-group">
                {imports.map((im, imk) => (
                    <DataLine line={im} key={imk} setErrors={setErrors} />
                ))}
            </ul>
        </Page>
    );
};

export default ImportData;
