import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";
import useCommissionsList from "../useCommissionsList";

const filtersAtom = atom({
  beginAt: "",
  endAt: "",
  producer: "",
});
const pageAtom = atom(1);

const useCommissionListFilters = () => {
  const { actions: commissionsActions } = useCommissionsList();
  const [filters, setfilters] = useAtom(filtersAtom);
  const [page, setPage] = useAtom(pageAtom);

  const actions = {
    onChangeFilter: (e) => {
      const { name, value } = e.target;
      setfilters({ ...filters, [name]: value });
    },
    onSubmitSearch: (e) => {
      e.preventDefault();
      commissionsActions.onSearchCommissions(filters, page);
    },
    onResetFilters: () => {
      setfilters({ beginAt: "", endAt: "" });
    },
    onChangePage: (newPage) => {
      setPage(newPage);
      commissionsActions.onSearchCommissions(filters, newPage);
    },
    onClickLastMonth: () =>
      setfilters({
        ...filters,
        beginAt: DateTime.local().minus({ months: 1 }).plus({ days: 1 }).toISO(),
        endAt: DateTime.local().toISO(),
      }),
    onClick3LastMonth: () =>
      setfilters({
        ...filters,
        beginAt: DateTime.local().minus({ months: 3 }).plus({ days: 1 }).toISO(),
        endAt: DateTime.local().toISO(),
      }),
    onClick6LastMonth: () =>
      setfilters({
        ...filters,
        beginAt: DateTime.local().minus({ months: 6 }).plus({ days: 1 }).toISO(),
        endAt: DateTime.local().toISO(),
      }),
  };

  return {
    filters,
    page,
    actions,
  };
};

export default useCommissionListFilters;
