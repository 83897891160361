import { useEffect, useState } from "react";
import dataService from "../../../../../../helpers/dataService";

const useCalculatedValues = ({ company }) => {
  const [isCalcLoading, setIsCalcLoading] = useState(false);
  const [calculatedValues, setCalculatedValues] = useState({
    franchiseTOCValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    isRCPro: false,
    naturalDisasterValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    floodDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    earthQuakeDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    rainDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    waterDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    stealDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    stealInCheckoutDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    stealInChestDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    aggressionDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    iceBreakDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    signBreakDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    transportedGoodsDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    hardwareDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    freezeRoomGoodsDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    operatingLossDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    goodwillMarketValueDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    electricalDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
    machineDamageValue: {
      min: 0,
      max: 0,
      value: 0,
    },
  });

  useEffect(() => {
    getCalculatedValues();
  }, [
    company.state.stockageInputs.classicContentValue,
    company.state.stockageInputs.itContentValue,
    company.state.stockageInputs.electricalContentValue,
    company.state.stockageInputs.amenitiesValue,
    company.state.stockageInputs.amenitiesAndEmbelishmentsValue,
  ]);

  const makeCall = async () => {
    return await getCalculatedValue({
      ape: company.ape, // A MODIFIER IMPERATIVEMENT
      mrpData: {
        ...company.state.constructionInputs,
        ...company.state.stockageInputs,
        ...company.state.fundsInputs,
        ...company.state.buildingInputs,
        ...company.state.risksInputs,
        ...company.state.accident1Inputs,
        ...company.state.accident2Inputs,
      },
    });
  };

  const getCalculatedValues = async () => {
    setIsCalcLoading(true);
    let values = await makeCall();

    setCalculatedValues({
      ...calculatedValues,
      ...values,
    });
    setIsCalcLoading(false);
  };

  const getCalculatedValue = async ({ ape, mrpData }) => {
    return new Promise((res, rej) => {
      dataService.post(
        `tarificators/products/MRP`,
        {
          ape,
          mrpData,
        },
        res,
        rej
      );
    });
  };

  return { calculatedValues, isCalcLoading };
};

export default useCalculatedValues;
