import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";
import { useEffect } from "react";

const productsAtom = atom([]);

const useProducts = (fetchOnLoad) => {
  const [products, setProducts] = useAtom(productsAtom);

  useEffect(() => {
    if (fetchOnLoad && products.length === 0) onGetProducts();
  }, []);

  const onGetProducts = () => {
    dataService.get(`products`, setProducts);
  };

  return {
    products,
    onGetProducts,
  };
};

export default useProducts;
