import { simulatorAdapters } from "../../adapters/simulatorAdapters";
import { GRAAL_API_URL, GRAAL_API_KEY } from "../../config";
import { graalApiAxiosInstance } from "../../middlewares/useApiAuthMiddleware";

const useGraalApi = () => {
  const graalApiActions = {
    getAuthToken: async () => {
      return await graalApiAxiosInstance.post(
        `${GRAAL_API_URL}login`,
        {},
        {
          headers: {
            "X-API-KEY": GRAAL_API_KEY,
          },
        }
      );
    },
    getTarification: async (data) => {
      return await graalApiAxiosInstance.post(
        `${GRAAL_API_URL}tarificateurs/simuler-tarifs`,
        simulatorAdapters.transformForAPI(data)
      );
    },
  };

  return {
    graalApiActions,
  };
};

export default useGraalApi;
