import React, { useEffect } from "react";

var positionXMulti = 1;
var positionYMulti = 1;
// var coordWidthMulti = 2;
// var coordHeightMulti = 2;
var spreadDistance = 100;
var spreadRadius = 4;

const CanvasParticle = ({ image64, id, radius, particleRadius = 0.5 }) => {
  var canvas = "";
  var ctx = null;
  let particleArray = [];
  let mouse = {
    x: null,
    y: null,
    radius: radius,
  };

  useEffect(() => {
    canvas = document.querySelector(`#${id}`);
    ctx = canvas.getContext("2d");
    canvas.width = image64.width;
    canvas.height = image64.height;

    window.addEventListener("mousemove", (event) => {
      //   mouse.x = e.x + canvas.clientLeft / 2;
      //     mouse.y = e.y + canvas.clientTop / 2;
      let rect = canvas.getBoundingClientRect();
      let x = event.clientX - rect.left;
      let y = event.clientY - rect.top;
      mouse.x = x;
      mouse.y = y;
    });
    setTimeout(() => {
      ctx.drawImage(image64, 0, 0);
      drawImage();
    }, 1500);
  }, []);

  function drawImage() {
    let imageWidth = image64.width;
    let imageHeight = image64.height;
    const data = ctx.getImageData(0, 0, imageWidth, imageHeight);

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    class Particle {
      constructor(x, y, baseX, baseY, color, size) {
        this.density = Math.random() * spreadRadius;
        this.color = color;
        this.size = 2;
        this.x = x;
        this.y = y;
        this.baseX = baseX;
        this.baseY = baseY;
      }
      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * particleRadius);
        ctx.closePath();
        ctx.fill();
      }
      update() {
        var mouseRadius = !mouse.x || !mouse.y ? 0 : mouse.radius;
        ctx.fillStyle = this.color;
        let dx = mouse.x - this.x;
        let dy = mouse.y - this.y;

        let distance = Math.sqrt(dx * dx + dy * dy);
        let forceDirectionX = -dx / distance;
        let forceDirectionY = -dy / distance;

        const maxDistance = spreadDistance;
        let force = (maxDistance - distance) / maxDistance;
        if (force < 0) force = 0;

        let directionX = forceDirectionX * force * this.density * 0.6;
        let directionY = forceDirectionY * force * this.density * 0.6;

        if (distance < mouseRadius + this.size) {
          this.x -= directionX;
          this.y -= directionY;
        } else {
          let randomSpeed = Math.random() * 3 + 1;
          if (this.x !== this.baseX) {
            let dx = this.x - this.baseX;
            this.x -= dx / randomSpeed;
          }
          if (this.y !== this.baseY) {
            let dy = this.y - this.baseY;
            this.y -= dy / randomSpeed;
          }
        }
        this.draw();
      }
    }
    function init() {
      particleArray = [];
      for (let y = 0, y2 = data.height; y < y2; y++) {
        for (let x = 0, x2 = data.width; x < x2; x++) {
          if (data.data[y * 4 * data.width + x * 4 + 3] > 128) {
            let positionX = x;
            let positionY = y;
            let color =
              "rgb(" +
              data.data[y * 4 * data.width + x * 4] +
              "," +
              data.data[y * 4 * data.width + x * 4 + 1] +
              "," +
              data.data[y * 4 * data.width + x * 4 + 2] +
              ")";

            particleArray.push(
              new Particle(
                image64.width / 2,
                image64.height / 2,
                positionX * positionXMulti,
                positionY * positionYMulti,
                color
              )
            );
          }
        }
      }
    }
    function animate() {
      requestAnimationFrame(animate);
      //   ctx.fillStyle = "rgba(0,0,0,.5)";
      //     ctx.fillRect(0, 0, image64.width, image64.height);
      ctx.clearRect(0, 0, image64.width, image64.height);

      for (let i = 0; i < particleArray.length; i++) {
        particleArray[i].update();
      }
    }

    init();
    animate();

    window.addEventListener("resize", () => {
      canvas.width = image64.width;
      canvas.height = image64.height;
      init();
    });
  }

  return <canvas id={id} style={{}}></canvas>;
};

export default CanvasParticle;
