import React, { useRef } from "react";
import LabelContainer from "../LabelContainer";
import EmptyButton from "./EmptyButton";

const TextInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  ...props
}) => {
  const inputRef = useRef(null);
  const valueChange = (e) => {
    let filteredValue = e.target.value;

    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };
  return (
    <LabelContainer {...props} value={value}>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        style={inputStyle}
        className={"form-control " + inputClassName}
        type="text"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={value}
        onChange={valueChange}
        placeholder={placeholder}
        onBlur={props.onBlur || null}
      />
      {!disabled && !props.noEmptyBtn && (
        <EmptyButton change={change} name={props.name} targetInput={inputRef} value={value} />
      )}
    </LabelContainer>
  );
};

export default TextInput;
