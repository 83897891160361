import { useContext } from "react";
import styled from "styled-components";
import { Tooltip, TooltipButton } from "../../Tooltip/Tooltip";
import TodoCreatedBy from "../common/TodoCreatedBy/TodoCreatedBy";
import TodoNameEdit from "../common/TodoNameEdit/TodoNameEdit";
import TodoNameView from "../common/TodoNameView/TodoNameView";
import TodoStatus from "../common/TodoStatus/TodoStatus";
import TodoTextEdit from "../common/TodoTextEdit/TodoTextEdit";
import TodoUpdatedBy from "../common/TodoUpdatedBy/TodoUpdatedBy";
import { TodoContext } from "../context/TodoContext";
import TodoActions from "./TodoActions/TodoActions";
import "./todoItem.scss";

const TodoItem = () => {
  const { todo, actions } = useContext(TodoContext);

  return (
    <StyledTodoItem className="row shadow-sm rounded mx-0 position-relative task">
      <StyledStatusCol className="col-1 pr-0 d-flex align-items-center">
        <TodoStatus todo={todo} updateTodo={actions.updateTodo} />
      </StyledStatusCol>
      <div className="col-10  mt-lg-0">
        <TodoNameEdit todo={todo} updateTodo={actions.updateTodo} />
      </div>

      <div className="col-md-1 col-12 d-center text-center">
        <TooltipButton
          tooltipContent={<TodoTextEdit todo={todo} updateTodo={actions.updateTodo} />}
        >
          <i className={`fa text-primary ${todo.text ? "fa-comment-dots" : "fa-comment-o"}`} />
        </TooltipButton>
      </div>
      <StyledActionPanel className="">
        <TodoActions />
      </StyledActionPanel>
      <div className="todoOwnership border-top border-left border-right">
        <TodoCreatedBy todo={todo} />
        <TodoUpdatedBy todo={todo} />
      </div>
    </StyledTodoItem>
  );
};

const StyledTodoItem = styled.div`
  background: white;
  padding: 5px;
`;
const StyledActionPanel = styled.div`
  position: absolute;
  right: -37px;
  background: white;
  width: 37px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: inset 3px 0px 6px -6px black;
  & button {
    padding: 0px;
    width: 100%;
    height: 37px;
  }
`;
const StyledOwnership = styled.div`
  position: absolute;
  top: -22px;
  right: 5px;
  z-index: 9;
  background: white;
  padding: 0 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const StyledStatusCol = styled.div`
  max-width: 46px;
`;

export default TodoItem;
