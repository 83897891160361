import React from "react";
import styled from "styled-components";
import useProductInfos from "../useProductInfos";

const ProductDataList = () => {
    const { productData } = useProductInfos();

    return productData.map((inp) => {
        return (
            <StyledRow className="row border-bottom flex-column flex-md-row" key={`pdl${inp.id}`}>
                <div className="col-12 col-md-6">{inp.label || inp.id} :</div>
                <div className="col-12 col-md-6">
                    <strong>{inp.realValue}</strong>
                </div>
            </StyledRow>
        );
    });
};

const StyledRow = styled.div`
    &:hover {
        background: var(--color-default-light);
    }
`;

export default ProductDataList;
