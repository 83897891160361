import React, { useRef } from "react";
import LabelContainer from "../LabelContainer";
import { DateTime } from "luxon";
import EmptyButton from "./EmptyButton";

const DateTimeInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  min = "",
  max = "",
  ...props
}) => {
  const inputRef = useRef(null);
  const valueChange = (e) => {
    var filteredValue = DateTime.fromISO(e.target.value).toISO();
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  return (
    <LabelContainer {...props} value={value}>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        style={inputStyle}
        min={min}
        max={max}
        className={"form-control " + inputClassName}
        type="datetime-local"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={DateTime.fromISO(value).toFormat("yyyy-MM-dd'T'HH:mm")}
        onChange={valueChange}
        placeholder={placeholder}
        onBlur={props.onBlur || null}
      />
      <EmptyButton
        change={change}
        name={props.name}
        targetInput={inputRef}
        value={value}
        right={-20}
      />
    </LabelContainer>
  );
};

export default DateTimeInput;
