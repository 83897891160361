import { atom, useAtom } from "jotai";
import dataService from "../../../helpers/dataService";

const blackListAtom = atom([]);

const blackListFiltersAtom = atom({
  filters: {
    terms: "",
  },
  page: 1,
});

const isLoadingBlackListAtom = atom(false);
const nbResultsAtom = atom(0);

const useBlackList = () => {
  const [blackList, setBlackList] = useAtom(blackListAtom);
  const [blackListFilters, setBlackListFilters] = useAtom(blackListFiltersAtom);
  const [isLoadingBlackList, setIsLoadingBlackList] = useAtom(isLoadingBlackListAtom);
  const [nbResults, setNbResults] = useAtom(nbResultsAtom);

  const getBlackList = () => {
    setIsLoadingBlackList(true);
    dataService.post(
      "blacklists/search",
      blackListFilters,
      (datas) => {
        console.log("dt", datas);
        setBlackList(datas.data);
        setNbResults(datas.count);
      },
      (err) => {},
      () => setIsLoadingBlackList(false)
    );
  };

  const handleFilterChange = (e) => {
    let { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      value = checked;
    }
    setBlackListFilters({
      ...blackListFilters,
      filters: {
        ...blackListFilters.filters,
        [name]: value,
      },
    });
  };

  const changePage = (page) => {
    const newFilters = {
      ...blackListFilters,
      page,
    };
    getBlackList({ overrideFilters: newFilters });
    setBlackListFilters(newFilters);
  };

  return {
    blackList,
    blackListFilters,
    isLoadingBlackList,
    nbResults,
    actions: {
      handleFilterChange,
      getBlackList,
      changePage,
    },
  };
};

export default useBlackList;
