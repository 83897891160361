import { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../helpers/dataService";
import tools, { PAGES } from "../../../../helpers/tools";

const usePartnerInfos = () => {
  const [isLoadingPartnerInfos, setIsLoadingPartnerInfos] = useState(false);
  const [partnerInfos, setPartnerInfos] = useState({});
  const [selectedUrl, setSelectedUrl] = useState(
    tools.findIn(PAGES, "FORM", "id").link
  );
  const [isSendingLink, setIsSendingLink] = useState(false);
  const affiliateLink = tools.getAffiliateLink(
    partnerInfos.affiliateCode,
    selectedUrl
  );
  const [errors, setErrors] = useState({});

  const getPartnerInfos = () => {
    setIsLoadingPartnerInfos(true);
    dataService.get(
      "managers/me",
      setPartnerInfos,
      (err) => {},
      () => setIsLoadingPartnerInfos(false)
    );
  };

  const sendAffiliateLink = (emails, link, message, onLinkSent) => {
    let emailErrors = [];
    emails.forEach((email) => {
      var isValid = tools.validateEmail(email);
      if (!isValid) {
        emailErrors.push(`${email} n'est pas une adresse email valide. `);
      }
    });
    if (emailErrors.length > 0) {
      setErrors({ emails: emailErrors });
      return false;
    }
    setIsSendingLink(true);
    dataService.post(
      `producers/send-my-affiliate-code`,
      { emails, comment: message, url: link },
      (data) => {
        onLinkSent(data);
        toast.success(
          "Votre lien d'affiliation a bien été envoyé à vos destinataires"
        );
      },
      setErrors,
      () => setIsSendingLink(false)
    );
  };

  const copyAffiliateLink = () => {
    tools.copyTextToClipboard(affiliateLink);
    toast.success("Lien d'affiliation copié dans le presse-papier");
  };

  return {
    isLoadingPartnerInfos,
    partnerInfos,
    affiliateLink,
    isSendingLink,
    errors,
    actions: {
      getPartnerInfos,
      copyAffiliateLink,
      setSelectedUrl,
      sendAffiliateLink,
    },
  };
};

export default usePartnerInfos;
