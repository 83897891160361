import React from "react";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import colors from "../../../helpers/colors";

const CustomModal = () => {
  const [state, dispatch] = useStore();
  const actions = modalActions(state, dispatch);
  const modalProps = state.modal;

  return (
    <div
      className="position-fixed w-100 h-100 align-items-center justify-content-center"
      style={{
        display: modalProps.isOpen ? "flex" : "none",
        top: 0,
        left: 0,
        background: "rgb(0 0 0 / 54%)",
        zIndex: "99999999",
        overflowY: "auto",
        ...modalProps.style,
      }}
    >
      <div
        className={
          " position-relative p-5 " +
          (modalProps.isOpen ? "animated zoomIn faster" : "")
        }
        style={{
          width: "96%",
          height: "98%",
          maxWidth: "400",
          borderRadius: 5,
          overflowY: "scroll",
          background: "white",
        }}
      >
        {modalProps.content}

        <div
          className="d-flex align-items-center justify-content-end "
          style={{ position: "fixed", top: "2%", right: "3%", zIndex: "99999" }}
        >
          {modalProps.actions}
          {!modalProps.noClose && (
            <button
              onClick={() =>
                actions.updateModal({ isOpen: false, content: null })
              }
              className="btn btn-default bg-white"
            >
              Fermer
              <i className="fa fa-times ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
