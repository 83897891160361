import { useState } from "react";
import dataService from "../../../../../../../../../helpers/dataService";

const useBills = () => {
  const [isLoadingBills, setIsLoadingBills] = useState(false);
  const [bills, setBills] = useState(null);

  const getClientBills = (clientId) => {
    dataService.get(
      `billings/clients/${clientId}`,
      setBills,
      (err) => {},
      () => setIsLoadingBills(false)
    );
  };

  return {
    isLoadingBills,
    bills,
    getClientBills,
  };
};

export default useBills;
