import React from "react";
import styled from "styled-components";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";

const MailHistory = ({ emailData = [] }) => {
  const [state] = useStore();

  const { EVENT_ACTIONS } = state.constants.items;

  return (
    <div>
      <StyledMailHistoryItem>
        {EVENT_ACTIONS.filter((event) => event.type == "email").map((event) => {
          var emailRef = emailData.find((mail) => mail.id == event.id);
          var isDone = emailRef?.isDone || false;

          return (
            <div>
              <i className={`fa ${isDone ? "fa-check text-info " : "fa-times text-black-50"}`}></i>{" "}
              {event.name}{" "}
              {emailRef?.doneAt && (
                <small className="text-black-50 ml-1">
                  {tools.formatDateHours(emailRef.doneAt)}
                </small>
              )}
            </div>
          );
        })}
      </StyledMailHistoryItem>
    </div>
  );
};

const StyledMailHistoryItem = styled.div`
  margin-top: 10px;
`;

export default MailHistory;

/* 
EVENT_ACTIONS: [
    {
    id: "send",
    name: "Envoyé",
    type: "email",
  },
  {
    id: "received",
    name: "Reçu",
    type: "email",
  },
  {
    id: "uniqueOpen",
    name: "Première ouverture",
    type: "email",
  },
  {
    id: "open",
    name: "Ouvert",
    type: "email",
  },
  {
    id: "click",
    name: "Cliqué",
    type: "email",
  },
  {
    id: "error",
    name: "Erreur d'envoi",
    type: "email",
  }
]
 */
