import { useEffect } from "react";
import styled from "styled-components";
import AddCommentForm from "../AddCommentForm/AddCommentForm";
import { CommentContext } from "../context/CommentContext";
import useCommentList from "../useCommentList";
import ActiveComment from "./ActiveComment/ActiveComment";

const CommentListView = ({
  initComments,
  onUpdateComments,
  onUpdateComment,
  onAddComment,
  users = [],
}) => {
  const { setupComments, actions } = useCommentList(
    onUpdateComments,
    onUpdateComment,
    onAddComment
  );

  useEffect(() => {
    setupComments(initComments, users);
  }, [initComments]);

  return (
    <StyledCommentList className="d-flex flex-column justify-content-between h-100 pb-4">
      <CommentContext.Provider value={{ actions }}>
        {initComments.length == 0 ? (
          <StyledNoComment>aucun commentaire</StyledNoComment>
        ) : (
          <ActiveComment />
        )}
        <AddCommentForm addComment={actions.addComment} users={users} />
      </CommentContext.Provider>
    </StyledCommentList>
  );
};

const StyledCommentList = styled.div`
  padding: 0px;
  list-style: none;
  padding-top: 7px;
`;

const StyledNoComment = styled.div`
  color: var(--color-default);
  font-style: italic;
`;

export default CommentListView;
