import React from "react";
import { Link } from "react-router-dom";

const Page = ({
  container = "container",
  containerClassname = "",
  title,
  subTitle,
  children,
  errors = null,
  back = null,
  action = null,
  style = {},
  margin = "my-5",
  titleMargin = "mb-lg-5 mb-md-4 mb-3",
  notAnimated = false,
  announcement = null,
}) => {
  return (
    <div
      className={
        container +
        "  px-lg-5 px-3 " +
        margin +
        " " +
        containerClassname +
        (notAnimated ? "" : "  ")
      }
      style={style}
    >
      <div className={" d-flex align-items-center flex-wrap " + titleMargin}>
        {back && (
          <Link
            to={back}
            className="btn mr-3 shadow-none"
            style={{ padding: "15px 18px" }}
          >
            <i className="fa fa-arrow-left"></i>
          </Link>
        )}

        <h1
          className="align-items-center d-flex justify-content-between mb-0 mx-0 text-center w-100"
          style={{ fontSize: "1.5rem" }}
        >
          {title}
          {action && (
            <Link
              to={action.to}
              data-priv={action.dataPriv}
              className="btn btn-primary float-right ml-3"
            >
              <i className="fa fa-plus mr-2"></i>
              {action.text}
            </Link>
          )}
        </h1>
        <br />
        {subTitle && (
          <h5 className="w-100 text-primary mt-1">
            <i>{subTitle}</i>
          </h5>
        )}
      </div>
      {errors && errors.other && (
        <div className="alert alert-danger">{errors.other}</div>
      )}
      {children}
    </div>
  );
};

export default Page;
