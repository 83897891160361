import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import dataService from "../../helpers/dataService";
import useRole from "../useRole/useRole";
import useStore from "../../context/useStore";

const producersAtom = atom([]);
const orderedProducersAtom = atom((get) => {
  const producers = get(producersAtom);
  return producers.sort((a, b) => (a.company > b.company ? 1 : -1));
});
const isLoadingProducersAtom = atom(false);

const useProducers = (fetchOnLoad = false) => {
  const [state] = useStore();
  const [producers, setProducers] = useAtom(producersAtom);
  const [orderedProducers] = useAtom(orderedProducersAtom);
  const [isLoadingProducers, setIsLoadingProducers] = useAtom(
    isLoadingProducersAtom
  );
  const { myRole } = useRole();

  useEffect(() => {
    if (fetchOnLoad && producers.length == 0) actions.getProducers();
  }, []);

  const actions = {
    getProducers: () => {
      setIsLoadingProducers(true);
      dataService.get(
        "producers",
        setProducers,
        (err) => {},
        () => setIsLoadingProducers(false)
      );
    },
  };

  const myProducers =
    myRole === "PARTNER"
      ? producers.filter((p) => p.company === state.auth.user.producer?.company)
      : producers;

  return {
    isLoadingProducers,
    producers,
    myProducers,
    orderedProducers,
    actions,
  };
};

export default useProducers;
