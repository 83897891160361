import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { API_URL } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import CharCounter from "../../common/CharCounter";

const HintDetail = (props) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [hint, setHint] = useState({
    isVisible: true,
    category: "PJ",
    text: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (props.match.params.id != "create") {
      setIsLoading(true);
      Axios.get(API_URL + "hints/" + props.match.params.id)
        .then((res) => {
          setHint(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          toast.error(
            "Une erreur est survenue lors de la récupération des astuces"
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, []);

  function updateData(e) {
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }
    if (name == "comment") {
      if (value.length >= 300) {
        value = value.slice(0, 300);
      }
    }
    setHint({ ...hint, [name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      Axios.post(API_URL + "hints/", hint)
        .then((res) => {
          setHint(res.data);
          setIsSaved(true);
          toast.success("L'astuce a bien été créée");
          window.location.href = "/hints/" + res.data.id;
        })
        .catch((err) => {
          toast.error(
            "Une erreur est survenue lors de la création de l'astuce"
          );
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    } else {
      //update
      Axios.patch(API_URL + "hints/" + props.match.params.id, hint)
        .then((res) => {
          setHint(res.data);
          setIsSaved(true);
          toast.success("L'astuce a bien été modifiée");
        })
        .catch((err) => {
          toast.error(
            "Une erreur est survenue lors de la modification de l'astuce"
          );
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    }
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader withContainer={true} />
  ) : (
    <Page
      container={"container-fluid px-5"}
      title={
        (props.match.params.id == "create" ? "Création" : "Modification") +
        " d'une astuce"
      }
      errors={errors}
      back={"/hints"}
    >
      <Control
        label="Catégorie"
        name="category"
        type="btnList"
        datas={constants.HINT_CATEGORIES}
        value={hint.category}
        change={updateData}
        error={errors}
      />
      <Control
        label="Est visible ?"
        name="isVisible"
        type="checkbox"
        checked={hint.isVisible}
        value={hint.isVisible}
        change={updateData}
        error={errors}
      />

      <Control
        margin={"my-0"}
        label="Texte"
        type="textarea"
        name="text"
        value={hint.text}
        change={updateData}
        error={errors}
      />
      <CharCounter str={hint.text} max={300} />
      <SaveBtn
        className="mt-4"
        save={save}
        isSaving={isSaving}
        isSaved={isSaved}
      />
    </Page>
  );
};

export default HintDetail;
