import React, { useState } from "react";
import useStore from "../../../../context/useStore";
import Control from "../../../common/Controls/Control";

const LeadCancelSelector = ({ onValidate }) => {
  const [state] = useStore();
  const { LEAD_CANCEL_REASONS } = state.constants.items;
  const [reason, setReason] = useState({
    cancelReason: "",
    cancelText: "",
  });

  const handleChangeReason = (e) => {
    const { name, value } = e.target;
    setReason({
      ...reason,
      [name]: value,
    });
  };

  const submitData = () => {
    onValidate(reason);
  };

  return (
    <div>
      <Control
        label="Motif de l'abandon"
        type="btnList"
        name="cancelReason"
        datas={LEAD_CANCEL_REASONS}
        dataIndex="id"
        dataLabel="name"
        value={reason.cancelReason}
        change={handleChangeReason}
      />
      <Control
        label="Commentaire optionnel"
        type="textarea"
        name="cancelText"
        value={reason.cancelText}
        change={handleChangeReason}
        labelClassname="w-100"
      />
      <button className="btn btn-primary" onClick={submitData}>
        Valider
      </button>
    </div>
  );
};

export default LeadCancelSelector;
