import React from "react";

const CompanyRecapWrapper = ({
  bgClassName = "bg-white",
  borderClassName = "border",
  withShadow = true,
  ...props
}) => {
  return (
    <div
      className={`${bgClassName} ${borderClassName} ${
        withShadow ? "shadow-small" : ""
      } mb-3 rounded overflow-hidden `}
      style={{
        maxWidth: "min(450px,100%)",
        width: 450,
      }}
    >
      {props.children}
    </div>
  );
};

export default CompanyRecapWrapper;
