import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import frLocale from "moment/locale/fr";
import { DateTime } from "luxon";

const DateRange = ({ setSearch, start, end }) => {
  moment.locale("fr", frLocale);
  const [startDate, setStartDate] = useState(
    moment(DateTime.fromISO(start).toISO())
  );
  const [endDate, setEndDate] = useState(moment(DateTime.fromISO(end).toISO()));
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (startDate && endDate) {
      console.log(startDate.toISOString());
      console.log(endDate.toISOString());
      setSearch((s) => ({
        ...s,
        beginAt: startDate.toISOString(),
        endAt: endDate.toISOString(),
      }));
    }
  }, [startDate, endDate]);

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="tata-start-date"
      endDate={endDate}
      endDateId="tata-end-date"
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      isOutsideRange={() => false}
      startDatePlaceholderText="Entre le"
      endDatePlaceholderText="et le"
    />
  );
};

export default DateRange;
