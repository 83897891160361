import { DateTime } from "luxon";

export const simulatorAdapters = {
  transformForAPI: (params) => {
    return {
      produit_type: params.productType,
      chiffre_affaires: params.turnover,
      debut_le: DateTime.fromISO(params.beginAt).toFormat("yyyy-MM-dd"),
      fin_le: DateTime.fromISO(params.endAt).toFormat("yyyy-MM-dd"),
      code_postal: params.postalCode + "00",
      rc: undefined,
      fs: undefined,
      ape: params.ape,
      data: {
        option_fs: params.optFs,
        option_rc: params.optRc,
      },
    };
  },
};
