import { atom, useAtom } from "jotai";
import { searchClients } from "../../../services/clientService";
import React from "react";

const clientsAtom = atom([]);
const isLoadingClientsAtom = atom(false);
const clientFiltersAtom = atom({
  user: {
    email: "",
    nomPrenomClient: "",
    nomSociete: "",
    sirenOuSiret: "",
    numeroContrat: "",
    statutContrat: 10,
    statutCompte: "",
    compagniesAssurances: [],
    typesProduits: [],
    produits: [],
    partenaire: "",
    auMoins1Contrat: false,
  },
  page: 1,
});

const ClientSelectorContext = React.createContext();
export const ClientSelectorProvider = ({ value, children }) => {
  return (
    <ClientSelectorContext.Provider value={value}>
      {children}
    </ClientSelectorContext.Provider>
  );
};

const useClientSelector = () => {
  const selectorContext = React.useContext(ClientSelectorContext);
  console.log("contenxt", selectorContext);
  const [clientFilters, setClientFilters] = useAtom(clientFiltersAtom);
  const [clients, setClients] = useAtom(clientsAtom);

  const [isLoadingClients, setIsLoadingClients] = useAtom(isLoadingClientsAtom);

  const actions = {
    onSubmitSearch: (e) => {
      e.preventDefault();
      actions.onGetClients(clientFilters);
    },
    onGetClients: ({ overrideFilters = null } = {}) => {
      setIsLoadingClients(true);
      searchClients(overrideFilters || clientFilters)
        .then((data) => {
          //order policies by product.name
          setClients(
            data.users.map((user) => ({
              ...user,
              clients: user.clients.map((client) => ({
                ...client,
                policies: client.policies.sort((a, b) =>
                  a.productType < b.productType ? 1 : -1
                ),
              })),
            }))
          );
        })
        .finally(() => setIsLoadingClients(false));
    },
    handleChangeFilters: (e) => {
      let { name, value, type, checked } = e.target;
      value = type == "checkbox" ? (checked ? true : false) : value;
      const newFilters = {
        ...clientFilters,
        user: {
          ...clientFilters.user,
          [name]: value,
        },
      };
      setClientFilters(newFilters);
    },
    onSelectClient: selectorContext.onSelectClient,
  };
  return {
    clients,
    isLoadingClients,
    clientFilters,
    actions,
  };
};

export default useClientSelector;
