import React from "react";
import Page from "../../common/layout/Page";
import LeadList from "./LeadList/LeadList";
import { LeadProvider } from "./useLeads";

const LeadListPage = () => {
  return (
    <Page container="container-fluid" title="Liste des leads" subTitle="Demandes en cours">
      <LeadProvider value={{ productCategory: "PRO" }}>
        <LeadList />
      </LeadProvider>
    </Page>
  );
};

export default LeadListPage;
