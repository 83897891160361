import React, { useEffect } from "react";
import styled from "styled-components";
import CompanyList from "./CompanyList/CompanyList";
import UserInfos from "./UserInfos/UserInfos";
import useClientItem from "./useClientItem";

const ClientItem = ({ clientData }) => {
  const { client, actions } = useClientItem();

  useEffect(() => {
    actions.initClient(clientData);
  }, [clientData]);

  return !client ? null : (
    <StyledClientItem
      className="border rounded mb-3 position-relative  bg-white"
      id={`leaditem${clientData.id}`}
    >
      <div
        className="p-3"
        style={{
          borderLeft: "3px solid #3e75ff",
        }}
      >
        {/* <PanelSectionTitle>Compte</PanelSectionTitle> */}
        <UserInfos />
      </div>
      <CompanyList />
    </StyledClientItem>
  );
};

const StyledClientItem = styled.div`
  margin-top: 30px;
`;

export default ClientItem;
