import React from "react";

const DataResult = ({ data, text, color, className, style = {} }) => {
  return (
    <div
      className={
        "d-flex flex-column justify-content-center align-items-center " +
        className
      }
      style={style}
    >
      <button style={{ minWidth: 30 }} className={"btn btn-sm btn-" + color}>
        {data}
      </button>
      <span>{text}</span>
    </div>
  );
};

export default DataResult;
