import { atom, useAtom } from "jotai";
import React, { useContext } from "react";
import { simplifiedFormAdapter } from "../../../adapters/simplifiedFormAdapter";
import {
  submitParameters,
  updateParameters,
} from "../../../services/graal-api/simplifiedFormService";
import useGlobalError from "../../../hooks/useGlobalError/useGlobalError";
import { toast } from "react-toastify";

const parameterStateEmpty = {
  id: "",
  product: "",
  partenaire_lie: "",
  client_lie: "",
  est_public: true,
  est_modification_formulaire_produit: true,
  est_rattachement_compte_utilisateur: true,
  est_finaliser_demande: true,
};

const parameterStateAtom = atom({
  ...parameterStateEmpty,
});
const parameterStateErrorsAtom = atom({});
const formStateAtom = atom({});
const isSavingAtom = atom(false);

export const SimplifiedFormContext = React.createContext();
export const SimplifiedFormProvider = ({ value, children }) => {
  return (
    <SimplifiedFormContext.Provider value={value}>
      {children}
    </SimplifiedFormContext.Provider>
  );
};

const useSimplifiedForm = () => {
  const [parameterState, setParameterState] = useAtom(parameterStateAtom);
  const [formState, setFormState] = useAtom(formStateAtom);
  const [isSaving, setIsSaving] = useAtom(isSavingAtom);
  const [parameterStateErrors, setParameterStateErrors] = useAtom(
    parameterStateErrorsAtom
  );
  const formContext = useContext(SimplifiedFormContext);
  const commonControlProps = {
    noPaddingOnFormGroup: true,
    noMarginOnFormGroup: true,
    noBg: true,
    k: formContext.key,
    error: parameterStateErrors.errors || {},
  };
  const { globalErrorActions } = useGlobalError();

  const simplifiedFormActions = {
    initializeFormState: () => {
      const { data } = formContext;
      setParameterState((old) =>
        simplifiedFormAdapter.getItemParametersForm(data)
      );
      setFormState((old) => ({ ...data }));
    },
    resetParameterState: () => {
      const { myProducers } = formContext;
      setParameterState((old) => ({
        ...old,
        ...parameterStateEmpty,
        partenaire_lie: myProducers.length === 1 ? myProducers[0] : "",
        product: "PJ",
        initialData: {},
      }));
    },
    onUpdateParameterState: (newFormState) => {
      setParameterState((old) => newFormState);
    },
    onSubmitForm: async (e) => {
      e.preventDefault();

      if (parameterState.id) {
        simplifiedFormActions.onUpdateParameter(e);
      } else {
        simplifiedFormActions.onCreateParameter(e);
      }
    },
    onCreateParameter: async () => {
      setIsSaving(true);

      try {
        const response = await submitParameters(
          simplifiedFormAdapter.transformNewItemForAPI(parameterState)
        );
        simplifiedFormActions.onUpdateParameterState(response.data?.parameters);
        if (formContext.onAfterSubmitParameters) {
          formContext.onAfterSubmitParameters(response.data);
        }
      } catch (err) {
        globalErrorActions.handleGlobalError(err.response.status, {
          callback: () => {
            simplifiedFormActions.onCreateParameter();
          },
        });
        setParameterStateErrors(err.response.data);
      }

      setIsSaving(false);
    },
    onUpdateParameter: async () => {
      setIsSaving(true);

      try {
        const response = await updateParameters(
          simplifiedFormAdapter.transformExistingItemForAPI(parameterState)
        );
        toast.success("Paramètres enregistrés avec succès");
      } catch (err) {
        globalErrorActions.handleGlobalError(err.response.status, {
          callback: () => {
            simplifiedFormActions.onUpdateParameter();
          },
        });
        setParameterStateErrors(err.response.data);
      }

      setIsSaving(false);
    },
    onUpdateParameterInput: (e) => {
      let { name, value, checked, type } = e.target;
      if (type == "checkbox") {
        value = checked ? true : false;
      }
      setParameterState((old) => {
        return {
          ...old,
          [name]: value,
        };
      });
    },
    onFormUpdate: (data) => {
      formContext.onFormUpdate(data);
    },
  };

  return {
    parameterState,
    formState,
    setFormState,
    simplifiedFormActions,
    isSaving,
    commonControlProps,
    formContext,
    parameterStateErrors,
  };
};

export default useSimplifiedForm;
