import React from "react";

const CharCounter = ({ str, max }) => {
  return (
    <div
      style={{
        background: "white",
        height: 5,
        borderRadius: 5,
        border: "1px solid #d8d8d8",
      }}
    >
      <div
        style={{
          background: "#36767e",
          width: (str.length / max) * 100 + "%",
          height: 5,
        }}
      ></div>
      <div className="text-muted font-italic float-right">
        {str.length}/{max}
      </div>
    </div>
  );
};

export default CharCounter;
