import useClientList from "./useClientList";

const ClientCount = () => {
  const { isLoadingClients, detailedCount } = useClientList();

  return (
    <div className="text-center">
      {isLoadingClients ? (
        "Recherche en cours..."
      ) : (
        <>
          <div>{detailedCount.users} client(s)</div>
          <div>{detailedCount.clients} société(s)</div>
          <div>{detailedCount.policies} contrat(s)</div>
        </>
      )}
    </div>
  );
};

export default ClientCount;
