import React from "react";
import { PanelSectionTitle } from "../../layout/StyledComponents/StyledComponents";
import ProductDataList from "../ProductDataList/ProductDataList";
const ACInfos = () => {
  return (
    <div>
      <PanelSectionTitle>Assurance Construction</PanelSectionTitle>
      <ProductDataList />
    </div>
  );
};

export default ACInfos;
