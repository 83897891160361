import React from "react";
import styled from "styled-components";
import TodoCreatedBy from "../../common/TodoCreatedBy/TodoCreatedBy";
import TodoNameView from "../../common/TodoNameView/TodoNameView";
import TodoUpdatedBy from "../../common/TodoUpdatedBy/TodoUpdatedBy";
import useTodoList from "../../useTodoList";

const ActiveTodo = () => {
  const { activeTodoId, todos } = useTodoList();

  const activeTodo = todos.find((t) => t._id == activeTodoId);

  return activeTodo ? (
    <StyledActiveTodo className="row">
      <div className="col-12">
        <TodoNameView todo={activeTodo} />
        <TodoCreatedBy todo={activeTodo} />
        <br />
        <TodoUpdatedBy todo={activeTodo} />
      </div>
    </StyledActiveTodo>
  ) : null;
};

const StyledActiveTodo = styled.div``;

export default ActiveTodo;
