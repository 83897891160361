import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn";
import useRbe from "../useRbe";
import {
  commonControlProps,
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../../common/Controls/filterContainer";

const RBEFilters = () => {
  const { rbeFilters, actions, isLoadingRbeList } = useRbe();
  const { filters } = rbeFilters;

  function RBEHandle(e) {
    e.preventDefault();
    actions.getRbeList();
  }

  return (
    <form onSubmit={RBEHandle}>
      <div className={filterContainerClassName}>
        <div className="col-12 col-md-6">
          <Control
            label="Société"
            placeholder="Nom, SIRET ou SIREN"
            type="text"
            name="clientTerms"
            value={filters.clientTerms}
            change={actions.handleFilterChange}
            {...commonControlProps}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Nom du bénéficiaire"
            type="text"
            name="beneficiaryTerms"
            value={filters.beneficiaryTerms}
            change={actions.handleFilterChange}
            {...commonControlProps}
          />
        </div>

        <div className="col-12">
          <Control
            label="Société ayant un bénéficiaire black listé"
            type="checkbox"
            name="hasBlacklistedBeneficiary"
            checked={filters.hasBlacklistedBeneficiary}
            change={actions.handleFilterChange}
            {...commonControlProps}
          />
        </div>
        <div className={searchButtonContainerClassName}>
          <SaveBtn
            className={searchButtonClassName}
            text="Rechercher"
            typeBtn="submit"
            type={searchButtonType}
            isSaving={isLoadingRbeList}
            loaderColor={searchButtonLoaderColor}
          />
        </div>
      </div>
    </form>
  );
};

export default RBEFilters;
