import React, { useState, useEffect } from "react";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import { commonControlProps } from "../../common/Controls/filterContainer";

import {
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../common/Controls/filterContainer";

const FileCategoryFilter = ({
  search,
  categories,
  updateSearch,
  startSearch,
  isLoading,
  nbResults,
}) => {
  const [searchText, setSearchText] = useState("Rechercher");

  useEffect(() => {
    setSearchText("Rechercher");
  }, [search]);

  useEffect(() => {
    if (nbResults > 0) setSearchText(nbResults + " résultats trouvés");
  }, [nbResults]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        startSearch();
      }}
      className={filterContainerClassName}
    >
      <div className="col-12">
        <Control
          label="Recherche"
          name="searchString"
          type="text"
          value={search.fileCategory.searchString}
          change={updateSearch}
          {...commonControlProps}
        />
      </div>
      <div className={searchButtonContainerClassName}>
        <SaveBtn
          className={searchButtonClassName}
          typeBtn="submit"
          text={searchText}
          isSaving={isLoading}
          type={searchButtonType}
          loaderColor={searchButtonLoaderColor}
        />
      </div>
    </form>
  );
};

export default FileCategoryFilter;
