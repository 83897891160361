import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../../../../../../../../../context/actions/modalActions";
import useStore from "../../../../../../../../../../../context/useStore";
import dataService from "../../../../../../../../../../../helpers/dataService";
import Control from "../../../../../../../../../../common/Control";
import SaveBtn from "../../../../../../../../../../common/SaveBtn";
import Loader from "../../../../../../../../../../common/Loader";
import useUserCustomers from "../../../../../../../useUserCustomers";

const MigrateClient = ({ companyId }) => {
  const [selectedUserMigration, setSelectedUserMigration] = useState("");
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const { userCustomers, actions } = useUserCustomers();

  const [isMigrating, setIsMigrating] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (userCustomers.length == 0) {
      actions.getUserCustomers();
    }
  }, []);

  const migrateClient = () => {
    setIsMigrating(true);

    dataService.patch(
      `clients/${companyId}/migration`,
      { user: selectedUserMigration },
      (data) => {
        toast.success("La société a bien été migrée");
        mActions.updateModal({
          isOpen: false,
          content: null,
        });
      },
      setErrors,
      () => setIsMigrating(false)
    );
  };

  if (userCustomers.length == 0) return <Loader />;

  return (
    <div>
      <h4>Migrer la société vers un autre compte utilisateur</h4>
      <Control
        containerStyle={{ animationDelay: "100ms" }}
        label="Sélectionnez le compte utilisateur vers lequel vous souhaitez migrer cette société"
        name="userCustomers"
        value={selectedUserMigration}
        type="autocomplete"
        datas={userCustomers}
        dataIndex={"id"}
        dataLabel={"firstname"}
        dataLabel2={"lastname"}
        dataLabel3={"email"}
        autoCompleteDisplayIndex={"email"}
        error={errors}
        change={(e) => setSelectedUserMigration(e.target.value)}
        placeholder={"Nom ou prénom ou email"}
        isValid={selectedUserMigration}
      />
      {errors.other && (
        <span className="text-danger animated flash">{errors.other}</span>
      )}
      {selectedUserMigration.length > 0 ? (
        <SaveBtn
          className="btn btn-primary"
          isSaving={isMigrating}
          isSaved={false}
          save={migrateClient}
          text={
            <>
              <i className="fa fa-cloud-download-alt mr-2" />
              Migrer la société
            </>
          }
        />
      ) : null}
    </div>
  );
};

export default MigrateClient;
