import { useAtom } from "jotai";
import { myPrivilegesAtom, myRoleAtom } from "../../atoms/atoms";

const useRole = () => {
  const [myRole, setMyRole] = useAtom(myRoleAtom);
  const [myPrivileges, setMyPrivileges] = useAtom(myPrivilegesAtom);

  return {
    myRole,
    setMyRole,
    myPrivileges,
    setMyPrivileges,
  };
};

export default useRole;
