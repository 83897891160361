import React from "react";
import Loader from "../../common/Loader";
import UserInfo from "./UserInfo";
import UserPrivilege from "./UserPrivilege";
import SaveBtn from "../../common/SaveBtn";
import Page from "../../common/layout/Page";
import TimelineFiltered from "../../common/Timeline/TimelineFiltered";
import { DateTime } from "luxon";
import useUserDetail from "./useUserDetail";
import AvatarForm from "./AvatarForm/AvatarForm";

const UserDetail = (props) => {
  const urlId = props.match?.params?.id;

  const {
    actions,
    PRIVILEGES,
    ROLES,
    PRIVILEGE_CATEGORIES,
    roleConfig,
    accountId,
    account,
    errors,
    accountLoading,
    privilegesLoading,
    modifyAvatar,
    pwdIdentical,
    isInfoSaved,
    isInfoSaving,
    userRoles,
    isUnlocked,
    isUnlocking,
    isPrivilegesSaved,
    isPrivilegesSaving,
  } = useUserDetail(urlId);

  const canViewRoles =
    userRoles.length &&
    Object.keys(ROLES).length &&
    Object.keys(PRIVILEGES).length &&
    Object.keys(roleConfig).length;

  const isPartner = canViewRoles ? userRoles.find((r) => r.roleKey == "PARTNER") : false;

  return (
    <Page
      title={
        accountId == "create"
          ? "Créer un utilisateur"
          : "Modifier l'utilisateur " + account.firstname + " " + account.lastname
      }
      container="container-fluid px-5"
      back={"/users"}
      errors={errors}
    >
      {accountLoading || privilegesLoading ? (
        <Loader />
      ) : (
        <div className="col-12">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className={"nav-link active"}
                id={"informations-tab"}
                data-toggle="tab"
                href={"#informations"}
                role="tab"
                aria-controls={"informations"}
              >
                Informations
              </a>
            </li>
            <li className="nav-item" data-priv="bo_modifyuserprivileges">
              <a
                className={"nav-link "}
                id={"privileges-tab"}
                data-toggle="tab"
                href={"#privileges"}
                role="tab"
                aria-controls={"privileges"}
              >
                Privilèges
              </a>
            </li>
            <li className="nav-item" data-priv="bo_viewusertimeline">
              <a
                className={"nav-link "}
                id={"timeline-tab"}
                data-toggle="tab"
                href={"#timeline"}
                role="tab"
                aria-controls={"timeline"}
              >
                Timeline
              </a>
            </li>
          </ul>

          <div className="tab-content mt-4">
            <div className="tab-pane fade show active" id="informations" role="tabpanel">
              {accountId !== "create" && (
                <AvatarForm
                  isModifyingAvatar={modifyAvatar && urlId !== "create"}
                  setModifyAvatar={actions.setModifyAvatar}
                  userId={urlId}
                />
              )}
              {!modifyAvatar && (
                <>
                  <UserInfo
                    isCreate={accountId == "create"}
                    account={account}
                    updateData={actions.updateData}
                    pwdIdentical={pwdIdentical}
                    isPartner={isPartner}
                    errors={errors}
                  />
                  <div className="d-flex align-items-center flex-wrap">
                    <SaveBtn
                      className="mt-5 mx-2"
                      save={actions.saveInfo}
                      isSaving={isInfoSaving}
                      isSaved={isInfoSaved}
                    />
                    {userRoles[0].id && (
                      <button
                        style={{ minWidth: 0 }}
                        className="btn btn-danger mt-5 mx-2 mx-lg-2 mx-md-2"
                        data-priv="bo_deleteuser"
                        onClick={actions.removeUser}
                      >
                        <i className="fa fa-times mr-2" /> Supprimer l'utilisateur
                      </button>
                    )}
                    <SaveBtn
                      type="dark"
                      className="mt-5 mx-2"
                      save={actions.unlockAccount}
                      isSaving={isUnlocking}
                      isSaved={isUnlocked}
                      text={
                        <>
                          <i className="fa fa-lock-open mr-2" /> Déverouiller le compte
                        </>
                      }
                    />
                  </div>
                </>
              )}
            </div>

            {canViewRoles && (
              <div
                className="tab-pane fade"
                id="privileges"
                role="tabpanel"
                data-priv="bo_modifyuserprivileges"
              >
                {accountId == "create" ? (
                  <p>Enregistrez d'abord les informations</p>
                ) : (
                  <>
                    <UserPrivilege
                      userRoles={userRoles}
                      changeRole={actions.changeRole}
                      changePrivilege={actions.changePrivilege}
                      ROLES={ROLES}
                      PRIVILEGES={PRIVILEGES}
                      PRIVILEGE_CATEGORIES={PRIVILEGE_CATEGORIES}
                      roleConfig={roleConfig}
                      errors={actions.setErrors}
                    />

                    <SaveBtn
                      save={actions.savePrivileges}
                      isSaving={isPrivilegesSaving}
                      isSaved={isPrivilegesSaved}
                    />
                  </>
                )}
              </div>
            )}
            <div className="tab-pane fade" id="timeline" role="tabpanel">
              {accountId !== "create" && (
                <TimelineFiltered
                  className="mt-4"
                  showFilters={true}
                  initialSearch={{
                    model: "users",
                    id: accountId,
                    searchString: "",
                    beginAt: DateTime.local().minus({ months: 1 }).startOf("day"),
                    endAt: DateTime.local().endOf("day"),
                    type: "",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default UserDetail;
