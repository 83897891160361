import React from "react";
import styled from "styled-components";
import useTodoList from "../../useTodoList";

const TodoNameView = ({ todo }) => {
  const { availableTasks } = useTodoList();

  const taskRef = availableTasks?.find((t) => t.id == todo.taskId) || {};

  return (
    <>
      <StyledTodoName>{taskRef?.name}</StyledTodoName>
      {todo.text && (
        <StyledTodoComment>
          <i className="fa fa-comment-dots mr-1" />
          {todo.text}
        </StyledTodoComment>
      )}
    </>
  );
};

const StyledTodoName = styled.div``;

const StyledTodoComment = styled.div`
  color: var(--color-default-dark);
`;

export default TodoNameView;
