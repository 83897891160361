import { atom, useAtom } from "jotai";
import { toast } from "react-toastify";
import tools from "../../../../helpers/tools";
import useTaskService from "../../../../services/graal-api/useTaskService";
import useCommentService from "../../../../services/graal-api/useCommentService";
import { useContext } from "react";
import { ItemContext } from "./ItemContext";
import useGlobalError from "../../../../hooks/useGlobalError/useGlobalError";
import { updateStatus } from "../../../../services/graal-api/simplifiedFormService";

const simplifiedFormItemAtom = atom({});
export const bottomPanelContentAtom = atom(null);
export const isItemLoadingAtom = atom(false);

const useSimplifiedFormItem = () => {
  const { commentsServiceActions } = useCommentService();
  const { postComment, patchComment, deleteComment } = commentsServiceActions;
  const { taskServiceActions } = useTaskService();
  const { deleteTask, patchTask, patchTasksOrder, postTask } =
    taskServiceActions;
  const [simplifiedFormItem, setSimplifiedFormItem] = useAtom(
    simplifiedFormItemAtom
  );
  const [bottomPanelContent, setBottomPanelContent] = useAtom(
    bottomPanelContentAtom
  );
  const [isItemLoading, setIsItemLoading] = useAtom(isItemLoadingAtom);
  const { managers } = useContext(ItemContext);
  const { globalErrorActions } = useGlobalError();

  const actions = {
    initItem: (data) => {
      setSimplifiedFormItem(data);
    },
    onFormUpdate: (data) => {
      setSimplifiedFormItem({ ...simplifiedFormItem, formData: data });
    },
    openTasks: () => {
      const isAlreadyOpen = bottomPanelContent === "TASKS";
      setBottomPanelContent(isAlreadyOpen ? null : "TASKS");
      actions.scrollItemToTop();
    },
    openComments: () => {
      const isAlreadyOpen = bottomPanelContent === "COMMENTS";
      setBottomPanelContent(isAlreadyOpen ? null : "COMMENTS");
      actions.scrollItemToTop();
    },
    openInfos: async () => {
      const isAlreadyOpen = bottomPanelContent === "INFOS";
      setBottomPanelContent(isAlreadyOpen ? null : "INFOS");
      actions.scrollItemToTop();
    },
    closeBottomPanel: () => {
      setBottomPanelContent(false);
    },
    onUpdateTasks: (tasks) => {
      console.log("UPDATE TASKS");
      const oldLead = { ...simplifiedFormItem };
      setSimplifiedFormItem({ ...simplifiedFormItem, tasks });
      setIsItemLoading(true);
      patchTasksOrder(simplifiedFormItem.uuid, { tasks })
        .then((data) => {
          toast.success("Tâches réordonnées");
          setSimplifiedFormItem(
            actions.getFormattedItemTasks({
              ...simplifiedFormItem,
              tasks: data.tasks,
            })
          );
        })
        .catch((err) => {
          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => actions.onUpdateTasks(tasks),
          });
          setSimplifiedFormItem(oldLead);
        })
        .finally(() => setIsItemLoading(false));
    },
    onUpdateTask: (task) => {
      console.log("UPDATE TASK", task);

      // PATCH /tasks/:id
      // body {
      //   "taskId": VOIR CONSTANTS LEAD_TASKS,
      //   "text": "blabla" || ""
      // }
      const oldLead = { ...simplifiedFormItem };
      setSimplifiedFormItem({
        ...simplifiedFormItem,
        tasks: [
          ...simplifiedFormItem.tasks.map((t) => (t.id === task.id ? task : t)),
        ],
      });
      setIsItemLoading(true);
      patchTask(task.id, task)
        .then((data) => {
          console.log("data", data);
          toast.success("Tâche mise à jour");
          setSimplifiedFormItem(
            actions.getFormattedItemTasks({
              ...simplifiedFormItem,
              tasks: data.tasks,
            })
          );
        })
        .catch((err) => {
          console.log("err patch", err.response);
          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => actions.onUpdateTask(task),
          });
          setSimplifiedFormItem(oldLead);
        })
        .finally(() => setIsItemLoading(false));
    },
    onAddTask: (task) => {
      console.log("ADD TASK", task);

      // POST /tasks
      // body {
      //     "entity": "62274fa25202ee0198b206fc",
      //     "entityRef": "Lead",
      //     "taskId": VOIR CONSTANTS LEAD_TASKS,
      //     "text": "blabla" || ""
      // }
      const oldLead = { ...simplifiedFormItem };

      setIsItemLoading(true);
      postTask({
        entity: simplifiedFormItem.id,
        entityRef: "Lead",
        ...task,
      })
        .then((data) => {
          toast.success("Tâche ajoutée");
          //const addedTask = data.tasks[data.tasks.length - 1];
          setSimplifiedFormItem(
            actions.getFormattedItemTasks({
              ...simplifiedFormItem,
              tasks: data.tasks,
            })
          );
        })
        .catch((err) => {
          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => actions.onAddTask(task),
          });
          setSimplifiedFormItem(oldLead);
        })
        .finally(() => setIsItemLoading(false));
    },
    onDeleteTask: (taskId) => {
      console.log("DELETE TASK");
      const oldLead = { ...simplifiedFormItem };
      setIsItemLoading(true);
      deleteTask(taskId)
        .then((data) => {
          setSimplifiedFormItem({
            ...simplifiedFormItem,
            tasks: [...simplifiedFormItem.tasks.filter((t) => t.id != taskId)],
          });
          toast.success("Tâche supprimée");
        })
        .catch((err) => {
          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => actions.onDeleteTask(taskId),
          });
          setSimplifiedFormItem(oldLead);
        })
        .finally(() => setIsItemLoading(false));
    },

    onUpdateComments: (comments) => {
      console.log("UPDATE COMMENTS");
      setSimplifiedFormItem({ ...simplifiedFormItem, comments });
    },
    onUpdateComment: (comment) => {
      console.log("UPDATE COMMENT");
      const oldLead = { ...simplifiedFormItem };
      setSimplifiedFormItem({
        ...simplifiedFormItem,
        comments: [
          ...simplifiedFormItem.comments.map((c) =>
            c.id == comment.id ? comment : c
          ),
        ],
      });
      setIsItemLoading(true);
      patchComment(comment.id, comment)
        .then((data) => {
          toast.success("Commentaire mis à jour");
          setSimplifiedFormItem(
            actions.getFormattedItemComments({
              ...simplifiedFormItem,
              comments: data.comments,
            })
          );
        })
        .catch((err) => {
          if (err.response?.status) {
            globalErrorActions.handleGlobalError(err.response.status, {
              callback: () => actions.onUpdateComment(comment),
            });
          }
          setSimplifiedFormItem(oldLead);
        })
        .finally(() => setIsItemLoading(false));
    },
    onAddComment: (comment) => {
      console.log("ADD COMMENT", comment);

      const oldLead = { ...simplifiedFormItem };
      setIsItemLoading(true);
      postComment({
        entity: simplifiedFormItem.id,
        entityRef: "PRICE",
        ...comment,
      })
        .then((data) => {
          toast.success("Commentaire ajouté");
          setSimplifiedFormItem((simplifiedFormItem) =>
            actions.getFormattedItemComments({
              ...simplifiedFormItem,
              comments: data.comments,
            })
          );
        })
        .catch((err) => {
          if (err.response?.status) {
            globalErrorActions.handleGlobalError(err.response.status, {
              callback: () => actions.onAddComment(comment),
            });
          }
          setSimplifiedFormItem(oldLead);
        })
        .finally(() => {
          setIsItemLoading(false);
        });
    },
    onDeleteComment: (commentId) => {
      console.log("DELETE COMMENT");
      const oldLead = { ...simplifiedFormItem };
      setSimplifiedFormItem({
        ...simplifiedFormItem,
        comments: [
          ...simplifiedFormItem.comments.filter((c) => c.id != commentId),
        ],
      });
      setIsItemLoading(true);
      deleteComment(commentId)
        .then((data) => {
          toast.success("Commentaire supprimé");
        })
        .catch((err) => {
          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => actions.onDeleteComment(commentId),
          });
          setSimplifiedFormItem(oldLead);
        })
        .finally(() => setIsItemLoading(false));
    },
    getFormattedItemTasks: (rawItem) => {
      const newItem = {
        ...rawItem,
        tasks: tools.populateNamesFromId(managers, rawItem.tasks),
      };
      return newItem;
    },
    getFormattedItemComments: (rawItem) => {
      const newItem = {
        ...rawItem,
        comments: tools.populateNamesFromId(managers, rawItem.comments),
      };
      return newItem;
    },
    scrollItemToTop: () => {
      const leadElem = document.querySelector(
        `#formitem${simplifiedFormItem.id}`
      );
      leadElem.scrollIntoView({ behavior: "smooth" });
    },
    onUpdateStatus: async (statut, reason = null) => {
      console.log("UPDATE STATUS");
      setIsItemLoading(true);
      const oldLead = { ...simplifiedFormItem };
      setSimplifiedFormItem({ ...simplifiedFormItem, statut, ...reason });

      try {
        const res = await updateStatus(simplifiedFormItem.id, statut, reason);
      } catch (err) {
        globalErrorActions.handleGlobalError(err.response.status, {
          callback: () => actions.onUpdateStatus(statut, reason),
        });
        setSimplifiedFormItem(oldLead);
      }
    },
  };

  return {
    simplifiedFormItem,
    actions,
    isItemLoading,
    bottomPanelContent,
  };
};

export default useSimplifiedFormItem;
