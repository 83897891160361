import React from "react";
import { PanelSectionTitle } from "../../layout/StyledComponents/StyledComponents";
import ProductDataList from "../ProductDataList/ProductDataList";
const MRPInfos = () => {
  return (
    <div>
      <PanelSectionTitle>Multirisque Professionnelle</PanelSectionTitle>
      <ProductDataList />
    </div>
  );
};

export default MRPInfos;
