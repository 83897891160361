import { useEffect } from "react";
import styled from "styled-components";
import AddTodoForm from "./AddTodoForm/AddTodoForm";
import { TodoContext } from "./context/TodoContext";
import TodoItem from "./TodoItem/TodoItem";
import useTodoList from "./useTodoList";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const ObjectiveHandle = ({ dragHandleProps }) => {
	return (
		<StyledDragHandle className="d-center text-white" {...dragHandleProps}>
			<i className="fa fa-grip-vertical" />
		</StyledDragHandle>
	);
};

const TodoList = ({
	initTodos,
	tasksByProduct,
	onUpdateTodos,
	onUpdateTodo,
	onAddTodo,
	onDeleteTodo,
	users,
}) => {
	const { todos, setupTodos, actions } = useTodoList(
		onUpdateTodos,
		onUpdateTodo,
		onAddTodo,
		onDeleteTodo
	);

	useEffect(() => {
		setupTodos(initTodos, tasksByProduct, users);
	}, [initTodos]);

	const handleOnDragEnd = (result) => {
		if (!result.destination) {
			return false;
		}
		const items = Array.from(todos);
		const [reorderedItems] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItems);
		actions.updateTodos(items);
	};

	return (
		<>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<Droppable droppableId="tasks" type="TASKS">
					{(provided) => (
						<StyledTodoList {...provided.droppableProps} ref={provided.innerRef}>
							{todos.map((todo, todok) => (
								<Draggable
									key={`todo${todo.id}${todo.name}`}
									draggableId={`todo${todo.id}${todo.name}`}
									index={todok}
								>
									{(provided) => (
										<StyledTodoItem
											className="border rounded"
											ref={provided.innerRef}
											{...provided.draggableProps}
										>
											<ObjectiveHandle dragHandleProps={provided.dragHandleProps} />
											<TodoContext.Provider value={{ todo, actions }}>
												<TodoItem />
											</TodoContext.Provider>
										</StyledTodoItem>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</StyledTodoList>
					)}
				</Droppable>
			</DragDropContext>
			<AddTodoForm addTodo={actions.addTodo} />
		</>
	);
};

const StyledTodoList = styled.ul`
	margin-bottom: 10px;
	padding: 0px;
	list-style: none;
`;
const StyledTodoItem = styled.li`
	margin: 15px 0px;
	margin-bottom: 28px;
	margin-left: 10px;
	position: relative;
	max-width: 97%;
`;

const StyledDragHandle = styled.div`
	background: var(--color-default-dark);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	width: 30px;
	height: 100%;
	position: absolute;
	left: -30px;
`;

export default TodoList;
