import { useEffect } from "react";
import styled from "styled-components";
import { TodoContext } from "../context/TodoContext";
import useTodoList from "../useTodoList";
import ActiveTodo from "./ActiveTodo/ActiveTodo";
import TodoBlockList from "./TodoBlockList/TodoBlockList";

const TodoListView = ({
  initTodos,
  tasksByProduct,
  onUpdateTodos = () => {},
  onUpdateTodo = () => {},
  users = [],
}) => {
  const { setupTodos, actions } = useTodoList(onUpdateTodos, onUpdateTodo);

  useEffect(() => {
    setupTodos(initTodos, tasksByProduct, users);
  }, [initTodos]);

  return (
    <StyledTodoList>
      {initTodos.length == 0 ? (
        <StyledNoTask>aucune tâche</StyledNoTask>
      ) : (
        <>
          <TodoContext.Provider value={{ actions }}>
            <TodoBlockList />
            <ActiveTodo />
          </TodoContext.Provider>
        </>
      )}
    </StyledTodoList>
  );
};

const StyledTodoList = styled.div`
  margin-bottom: 10px;
  padding: 0px;
  list-style: none;
  padding-top: 7px;
`;
const StyledNoTask = styled.div`
  color: var(--color-default);
  font-style: italic;
`;

export default TodoListView;
