import React from "react";
import styled from "styled-components";
import Loader from "../../Loader";
import useDOMIInfos from "./useDOMIInfos";

const DOMIInfos = ({ productData }) => {
  const { formConfig, formatData } = useDOMIInfos();

  if (!formConfig) return <Loader />;

  const formattedData = formatData(productData);

  return formattedData.map((inp) => {
    return (
      <StyledRow className="row border-bottom flex-column flex-md-row" key={`pdl${inp.id}`}>
        <div className="col-12 col-md-6">{inp.label}</div>
        <div className="col-12 col-md-6">
          <strong>{inp.value}</strong>
        </div>
      </StyledRow>
    );
  });
};

export default DOMIInfos;

const StyledRow = styled.div`
  &:hover {
    background: var(--color-default-light);
  }
`;
