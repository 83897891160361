import React, { useMemo } from "react";
import styled from "styled-components";
import tools from "../../../../helpers/tools";
import Amount from "./CommissionItem/CommissionDetail/Amount";
import useCommissionsList from "./useCommissionsList";

const CommissionSummary = () => {
  const { commissions } = useCommissionsList();

  const totals =
    commissions.length == 0
      ? { total: 0, paid: 0, unpaid: 0 }
      : {
          total: commissions.reduce((a, b) => a + b.receipt.commission, 0),
          unpaid: commissions
            .filter((com) => com.receipt.payments?.status !== "DONE")
            .reduce((a, b) => a + b.receipt.commission, 0),
          paid: commissions
            .filter((com) => com.receipt.payments?.status == "DONE")
            .reduce((a, b) => a + b.receipt.commission, 0),
        };

  return (
    <StyledCommissionSummary className=" p-3  d-flex align-items-center justify-content-around ">
      <Amount amount={tools.round(totals.unpaid)} label="EN ATTENTE" className="text-warning" />
      <Amount amount={tools.round(totals.paid)} label="PAYÉ" className="text-success" />
      <Amount amount={tools.round(totals.total)} label="TOTAL" className="text-primary" />
    </StyledCommissionSummary>
  );
};

const StyledCommissionSummary = styled.div`
  & .amount-component {
    text-align: center;
    & .amount-label {
      font-size: 24px;
    }
    & .text-big-1 {
      font-size: 30px;
      margin-left: 0px;
    }
  }
`;

export default CommissionSummary;
