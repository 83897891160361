import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import "./sidenav.scss";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import Avatar from "../Avatar";
import { groups, sidenavItems } from "../../../sidenavItems";

const Header = withRouter((props) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  const [openedDetails, setOpenedDetails] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (state.auth.isAuthenticated) {
      window.$(document).ready(function () {
        window.$("#sidebarOpen").on("click", function () {
          window.$("#sidebar").removeClass("active");
        });
      });
    }
  }, [state.auth.isAuthenticated]);

  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);
      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);

  useEffect(() => {
    let subMenus = document.getElementsByClassName("submenu");
    if (subMenus) {
      for (var y = 0; y < subMenus.length; y++) {
        let parentElem = subMenus[y];
        let childs = parentElem.childNodes;
        let disabledCount = 0;
        for (var i = 0; i < childs.length; i++) {
          if (window.getComputedStyle(childs[i]).display === "none")
            disabledCount++;
        }
        if (disabledCount == childs.length) {
          let elementToHideId = parentElem.getAttribute("id");
          let linkToHide = document.querySelector(`#${elementToHideId}-link`);
          linkToHide.classList.remove("d-flex");
          linkToHide.classList.add("d-none");
        }
      }
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
  }, []);

  const collapseSidebar = () => {
    window.$("#sidebar").toggleClass("active");
  };

  // useEffect(() => {
  //   window.$("#sidebar").addClass("active");
  // }, [props.location.pathname]);

  var authData = state.auth;
  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <>
      <nav id="sidebar" className="shadow-sm border-right">
        <button type="button" id="sidebarOpen" className="navbar-btn menu-widg">
          <i className="fa fa-chevron-left fa-2x text-success" />
        </button>
        {isLoaded && ""}
        <div className="sidebar-header text-center">
          <NavLink
            to="/me"
            className="sidebar-avatar d-flex align-items-center justify-content-center flex-column"
            style={{ color: "#0a107d" }}
            onClick={collapseSidebar}
          >
            {avatarVisible && (
              <Avatar width={60} height={60} id={authData.user.id} />
            )}
            <span className="mt-2">
              {authData.user.firstname + " " + authData.user.lastname}
            </span>
            {authData.user.producer?.company && (
              <div>{authData.user.producer?.company}</div>
            )}
          </NavLink>
        </div>

        <ul className="list-unstyled components position-relative sidebar-items-container mb-0">
          {sidenavItems
            .filter((m) => !m.group)
            .map((m, mk) => (
              <li
                data-priv={m.dataPriv}
                key={"menuitemside" + mk}
                className="sidebar-item"
              >
                <NavLink
                  onClick={collapseSidebar}
                  className="nav-link px-4 "
                  to={m.path}
                >
                  <div className="d-center d-inline-flex">
                    <i
                      className={"fa fa-" + m.icon + " mr-3"}
                      style={{ width: 22 }}
                    />
                  </div>
                  <span>{m.name}</span>
                </NavLink>
              </li>
            ))}
        </ul>
        <ul className="list-unstyled components position-relative sidebar-items-container ">
          {Object.keys(groups).map((gkey, gk) => {
            const group = groups[gkey];
            const itemId = `submenu-${gk}-link`;
            const isOpened = openedDetails.includes(itemId);
            return (
              <li key={"menuitemside" + gkey} className="sidebar-item ">
                <details>
                  <summary
                    id={itemId}
                    onClick={() => {
                      if (isOpened) {
                        setOpenedDetails((det) =>
                          det.filter((d) => d != itemId)
                        );
                      } else {
                        setOpenedDetails((det) => [...det, itemId]);
                      }
                    }}
                    className="sidebar-item nav-link px-4 border-top"
                  >
                    <div className="d-center d-inline-flex">
                      <i
                        className={`fa fa-${
                          isOpened ? "chevron-down" : "chevron-right"
                        } mr-3`}
                        style={{ width: 22 }}
                      />
                    </div>
                    <span>{group.name}</span>
                  </summary>
                  <div className="submenu" id={`submenu-${gk}`}>
                    {sidenavItems
                      .filter((m) => m.group == group.id)
                      .map((m, mk) => {
                        return (
                          <div
                            data-priv={m.dataPriv}
                            key={`menuitemside` + m.path}
                          >
                            <NavLink
                              onClick={collapseSidebar}
                              className="nav-link px-4 "
                              to={m.path}
                            >
                              <div className="d-center d-inline-flex">
                                <i
                                  className={"fa fa-" + m.icon + " mr-3"}
                                  style={{ width: 22 }}
                                />
                              </div>
                              <span>{m.name}</span>
                            </NavLink>
                          </div>
                        );
                      })}
                  </div>
                </details>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  ) : (
    <></>
  );
});

export default withRouter(Header);
