import React from "react";
import styled from "styled-components";
import useStore from "../../../../../../context/useStore";

const ViewCancelReason = ({ cancelReason, cancelText }) => {
  const [state, dispatch] = useStore();
  const { LEAD_CANCEL_REASONS } = state.constants.items;

  if (!cancelReason) return null;

  const reasonRef = LEAD_CANCEL_REASONS.find((l) => l.id == cancelReason);

  return (
    <StyledCancelReason>
      {reasonRef?.name}
      {cancelText && (
        <i
          title={cancelText}
          className="fa fa-exclamation-circle cursor-pointer"
          onClick={() => alert(cancelText)}
        />
      )}
    </StyledCancelReason>
  );
};

const StyledCancelReason = styled.div`
  font-size: 9px;
`;

export default ViewCancelReason;
