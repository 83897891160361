import { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../../helpers/dataService";

const useUserForm = (client, updateClient) => {
  const [isEmailDisabled, setIsEmailDisabled] = useState(true);
  const [isPhoneDisabled, setIsPhoneDisabled] = useState(true);
  const [isSavingEmail, setIsSavingEmail] = useState(false);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isSavingPhone, setIsSavingPhone] = useState(false);
  const [isSavingPassword, setIsSavingPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const [userData, setUserData] = useState({
    gender: client.gender,
    firstname: client.firstname,
    lastname: client.lastname,
    phone: client.phone,
    email: client.email,
    isAllClients: false,
    password: "",
  });

  const handleChangeUser = (e) => {
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      value = checked ? true : false;
    }
    setUserData({ ...userData, [name]: value });
  };

  const onSavePhone = () => {
    setIsSavingPhone(true);
    const newClient = {
      ...client,
      clients: [],
      phone: userData.phone,
      isAllClients: userData.isAllClients,
    };

    dataService.patch(
      `customers/${client.id}/phone`,
      newClient,
      (data) => {
        toast.success("Le téléphone de l'utilisateur a bien été modifié");
        updateClient(newClient);
      },
      setErrors,
      () => setIsSavingPhone(false)
    );
  };
  const onSaveEmail = () => {
    setIsSavingEmail(true);
    const newClient = { ...client, clients: [], email: userData.email };
    dataService.patch(
      `customers/${client.id}/email`,
      newClient,
      (data) => {
        toast.success("L'email de l'utilisateur a bien été modifié");
        updateClient(newClient);
      },
      setErrors,
      () => setIsSavingEmail(false)
    );
  };
  const onSaveUser = () => {
    setIsSavingUser(true);
    const newClient = {
      ...client,
      clients: [],
      gender: userData.gender,
      firstname: userData.firstname,
      lastname: userData.lastname,
    };
    dataService.patch(
      `customers/${client.id}`,
      newClient,
      (data) => {
        toast.success(
          "Les informations de l'utilisateur ont bien été modifiées"
        );
        updateClient(newClient);
      },
      setErrors,
      () => setIsSavingUser(false)
    );
  };

  const onSavePassword = () => {
    setIsSavingPassword(true);
    dataService.patch(
      `customers/${client.id}/password`,
      { ...client, clients: [], password: userData.password },
      (data) => {
        toast.success("Le mot de passe de l'utilisateur a bien été modifié");
      },
      setErrors,
      () => setIsSavingPassword(false)
    );
  };

  const toggleDisabled = (inputName) => {
    switch (inputName) {
      case "email":
        setIsEmailDisabled(!isEmailDisabled);
        return;
      case "phone":
        setIsPhoneDisabled(!isPhoneDisabled);
        return;
    }
  };
  return {
    userData,
    isEmailDisabled,
    isPhoneDisabled,
    isSavingEmail,
    isSavingPassword,
    isSavingPhone,
    isSavingUser,
    errors,
    onSavePhone,
    onSaveEmail,
    onSaveUser,
    onSavePassword,
    toggleDisabled,
    handleChangeUser,
  };
};

export default useUserForm;
