import { atom, useAtom } from "jotai";
import { useState } from "react";
import dataService from "../../../../../../../../../helpers/dataService";

const companyPoliciesAtom = atom([]);
const policyStatusFilterAtom = atom([0, 10]);

const useCompanyPolicies = () => {
  const [isLoadingCompanyPolicies, setIsLoadingCompanyPolicies] = useState(false);
  const [companyPolicies, setCompanyPolicies] = useAtom(companyPoliciesAtom);
  const [policyStatusFilter, setPolicyStatusFilter] = useAtom(policyStatusFilterAtom);

  const initCompanyPolicies = (policies) => {
    setCompanyPolicies(policies);
    onGetCompanyPolicies(policies);
  };

  const onGetCompanyPolicies = (policies) => {
    setIsLoadingCompanyPolicies(true);
    dataService.getMany(
      policies.map((pol) => ({
        type: "GET",
        path: `amendments/policy/${pol.id}`,
        name: pol.id,
      })),
      (data) => {
        let newCompanyPolicies = policies;
        Object.keys(data).forEach((polId) => {
          let polRef = data[polId];
          newCompanyPolicies.find((c) => c.id == polId).amendments = polRef;
        });
        setCompanyPolicies(newCompanyPolicies);
      },
      (err) => {},
      () => setIsLoadingCompanyPolicies(false)
    );
  };

  const onChangePolicyStatusFilter = (e) => {
    setPolicyStatusFilter(e.target.value);
  };

  return {
    isLoadingCompanyPolicies,
    companyPolicies,
    policyStatusFilter,
    onGetCompanyPolicies,
    initCompanyPolicies,
    onChangePolicyStatusFilter,
  };
};

export default useCompanyPolicies;
