import { Provider } from "jotai";
import React from "react";
import useClientItem from "../useClientItem";
import CompanyItem from "./CompanyItem/CompanyItem";
import { PanelSectionTitleAbs } from "../../../../../common/layout/StyledComponents/StyledComponents";

const CompanyList = () => {
  const { client } = useClientItem();

  return (
    <div
      style={{
        borderLeft: "3px solid #d3d3d3",
      }}
    >
      <PanelSectionTitleAbs>
        Entreprises ({client.clients.length})
      </PanelSectionTitleAbs>
      {client.clients.map((company) => (
        <Provider key={`companyitem${company.id}`}>
          <CompanyItem companyData={company} />
        </Provider>
      ))}
    </div>
  );
};

export default CompanyList;
