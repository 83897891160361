import React from "react";
import SaveBtn from "../../../../../../../../../common/SaveBtn";
import useCompanyItem from "../../../useCompanyItem";
import useCompanyOtherActions from "./useCompanyOtherActions";

const CompanyOtherActions = () => {
  const { company, actions } = useCompanyItem();

  const {
    isImportingModulr,
    isImportedModulr,
    openMigrationModal,
    importModulr,
    isRadierEntreprise,
    isRadieredEntreprise,
    radierEntreprise,
  } = useCompanyOtherActions(company, actions.setCompany);

  return (
    <>
      <div className="dropdown">
        <button
          className="btn btn-default btn-sm border"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v mx-2" />
          Autres actions
        </button>
        <div
          className="dropdown-menu py-0"
          aria-labelledby="dropdownMenuButton"
        >
          <SaveBtn
            dataPriv="bo_updateclientfrommodulr"
            className="dropdown-item shadow-none btn-sm"
            type="default"
            isSaving={isImportingModulr}
            isSaved={isImportedModulr}
            save={importModulr}
            text={
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Importer les données du client depuis Modulr
              </>
            }
          />
          <button
            data-priv="bo_migratecompany"
            onClick={openMigrationModal}
            className="btn btn-default dropdown-item shadow-none btn-sm"
          >
            <i className="fas fa-sign-out-alt mr-2" /> Migrer vers un autre
            compte
          </button>
          {!company.isDelisted && (
            <SaveBtn
              dataPriv="bo_radierclient"
              className="dropdown-item shadow-none btn-sm"
              type="default"
              isSaving={isRadierEntreprise}
              isSaved={isRadieredEntreprise}
              save={radierEntreprise}
              text={
                <>
                  <i className="fa fa-store-slash mr-2" />
                  Radier l'entreprise
                </>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyOtherActions;
