import useStore from "../../../../../../context/useStore";

const useStatus = (status) => {
  const [state] = useStore();
  const { LEAD_STATUS } = state.constants.items;

  const statusRef = LEAD_STATUS.find((l) => l.id == status);

  return { statusRef };
};

export default useStatus;
