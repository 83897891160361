import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import { DateTime } from "luxon";
import { WEB_URL } from "../config";

export const PAGES = [
  {
    id: "HOME",
    name: "Page d'accueil",
    link: `${WEB_URL}`,
  },
  {
    id: "PJ",
    name: "Page Protection Juridique",
    link: `${WEB_URL}protection-juridique`,
  },
  {
    id: "AC",
    name: "Page Assurance Construction",
    link: `${WEB_URL}assurance-construction`,
  },
  {
    id: "CYBER",
    name: "Page Assurance Cyber",
    link: `${WEB_URL}assurance-cyber`,
  },
  {
    id: "MRP",
    name: "Page Multirisque Professionnelle",
    link: `${WEB_URL}multirisque-professionnelle`,
  },
  {
    id: "FORM",
    name: "Formulaire de souscription",
    link: `${WEB_URL}assurer-mon-entreprise`,
  },
];

const tools = {
  findIn: (inTheArray, theValue, theField) => {
    var returnValue = {};
    if (inTheArray.length && theField) {
      returnValue = inTheArray.find((en) => en[theField] == theValue) || {};
    }
    return returnValue;
  },
  checkEntryDateValid: (date) => {
    if (date && date.length >= 10) {
      if (DateTime.fromISO(date).isValid) {
        return true;
      }
      return false;
    }
  },
  getDateFrom: (date) => {
    return date;
  },
  getDateIso: (date) => {
    return date.length >= 10
      ? DateTime.fromFormat(date, "dd/MM/yyyy").toISO()
      : date;
  },
  formatDate: (date, format = "dd/MM/yyyy") => {
    return date ? DateTime.fromISO(date).toFormat(format) : "";
  },
  formatDateHours: (date) => {
    return DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm");
  },
  getFullAddress: (location) => {
    return location
      ? `
    ${location.address || ""} ${location.address2 || ""} ${
          location.postalCode || ""
        } ${location.city || ""}
    `
      : "";
  },
  formatFollowingPattern: (value = "", separation = [], separator = "") => {
    if (!value) return "";
    var nirconcat = value.split(separator).join("");

    var newnir = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < nirconcat.length) {
        newnir += nirconcat.substring(curr, ns);
        var newnirsp = newnir.split(separator);
        if (
          newnirsp[newnirsp.length - 1].length == separation[key] &&
          newnirsp.length < separation.length
        ) {
          newnir += separator;
        }
      }

      curr = ns;
    }
    if (newnir.substr(newnir.length - separator.length) == separator) {
      newnir = newnir.substring(0, newnir.length - separator.length);
    }
    return newnir;
  },
  formatPhone: (phone) => {
    if (!phone) return "";
    let normalVal = phone.split(".").join("").split(" ").join("");
    return tools.formatFollowingPattern(normalVal, [4, 2, 2, 2], " ");
  },
  formatSIREN: (siren) => {
    if (!siren) return "";
    let normalVal = siren.split(".").join("").split(" ").join("");
    return tools.formatFollowingPattern(normalVal, [3, 3, 3], " ");
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },

  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },

  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);
    console.log(ls);
    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
    console.log(localStorage.getItem(page));
  },
  truenumberWithSpaces: (x) => {
    if (!x) return "";
    x = x == "0" ? "" : x + "";
    x = x.replace(/\s/g, "");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  isParentRoleActive(roleKey, roleConfig, dependsOn) {
    if (!dependsOn) {
      return true;
    }
    var isActive = true;
    dependsOn.forEach((d) => {
      if (!roleConfig[d][roleKey]) {
        isActive = false;
      }
    });
    return isActive;
  },
  getFullName(user) {
    return user && Object.values(user).length > 0
      ? (user.gender == 1 ? "Mr " : user.gender == 2 ? "Mme " : "") +
          (tools.capitalize((user.firstname + "").toLowerCase()) || "") +
          " " +
          (tools.capitalize((user.lastname + "").toLowerCase()) || "")
      : "";
  },
  capitalize: (s) => {
    if (typeof s !== "string") return "";
    let words = s.split(" ");
    let result = "";
    words.forEach((word) => {
      result += word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });
    return result;
  },
  getFullNameAbbr(user) {
    return user
      ? tools.capitalize(user.firstname) +
          " " +
          user.lastname.toUpperCase()[0] +
          "."
      : "";
  },
  isOwner: (qualityOccupant) => {
    return qualityOccupant == 1 || qualityOccupant == 2;
  },
  calculateContentValue: (sumArray) => {
    return sumArray.reduce((a, b) => a + b);
  },
  round: (value) => {
    if (!value) return 0;
    return Math.round(parseFloat(value) * 100) / 100;
  },
  populateNamesFromId: (users, array = []) => {
    return array.map((t) => {
      let createdBy = tools.getFullNameAbbr(
        users.find((u) => u.id == t.createdBy)
      );
      let updatedBy = tools.getFullNameAbbr(
        users.find((u) => u.id == t.updatedBy)
      );
      return { ...t, id: t._id, createdBy, updatedBy };
    });
  },

  fallbackCopyTextToClipboard: (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  },
  copyTextToClipboard: (text) => {
    if (!navigator.clipboard) {
      tools.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  },
  getAffiliateLink: (affiliateCode, withUrl = "") => {
    return `${withUrl}?afcode=${affiliateCode}`;
  },
  getAmendmentReason: (detailsText) => {
    if (!detailsText) return "";
    const texts = detailsText.split("\n");
    if (texts.length > 1) {
      return texts[0];
    }
    return "";
  },
  validateEmail: (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  },
  getDocumentName: ({
    fileExt,
    documentName,
    productName,
    companyName,
    uploadedAt,
  }) => {
    var filename = `${documentName}${productName ? "-" + productName : ""}${
      companyName ? "-" + companyName : ""
    }-${DateTime.fromISO(uploadedAt).toFormat("yyyy")}.${fileExt}`;

    return filename;
  },
};

export default tools;
