import { useState } from "react";
import Control from "../../../../../../../../../../../common/Controls/Control";
import SaveBtn from "../../../../../../../../../../../common/SaveBtn";
import useReceiptItem from "../useReceiptItem";

const AddModulrIdForm = () => {
  const { isSavingModulrId, saveModulrId } = useReceiptItem();
  const [modulrId, setModulrId] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleChangeInput = (e) => {
    setModulrId(e.target.value);
  };

  const onSubmitModulrId = () => {
    saveModulrId(modulrId);
  };

  const commonControlProps = {
    noMarginOnFormGroup: true,
    noPaddingOnFormGroup: true,
    margin: "m-0",
    noBg: true,
    fullWidth: true,
  };

  return (
    <div className="my-2">
      <button className="btn btn-light btn-sm w-100" onClick={toggleIsOpen}>
        {isOpen ? "Annuler" : "Ajouter le Modulr ID"}
      </button>

      {isOpen && (
        <div className="p-2 bg-blue mt-2">
          <Control
            label="Modulr ID"
            type="number"
            placeholder="ex : 1234"
            value={modulrId}
            change={handleChangeInput}
            {...commonControlProps}
          />
          <SaveBtn className="w-100 d-center" isSaving={isSavingModulrId} save={onSubmitModulrId} />
        </div>
      )}
    </div>
  );
};

export default AddModulrIdForm;
