import React from "react";
import Pagination from "../../../../common/Pagination";
import useLeadFilters from "../../LeadListFilters/useLeadFilters";
import useLeads from "../../useLeads";

const LeadListPagination = ({ leadCount }) => {
  const { leadsFilters, actions } = useLeadFilters();

  return (
    <Pagination
      page={leadsFilters.page}
      nbPages={Math.ceil(leadCount / 25)}
      changePage={actions.updatePage}
    />
  );
};

export default LeadListPagination;
