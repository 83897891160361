import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";

const useForms = ({ clientId }) => {
  const [clients, setClients] = useState({});

  useEffect(() => {
    getForms();
  }, []);

  const getForms = () => {
    dataService.get(`clients/${clientId}/quotations`, setClients);
  };

  const hasMRP = clients.MRP?.length > 0;
  const hasAC = clients.AC?.length > 0;
  const hasCYBER = clients.CYBER?.length > 0;

  return {
    hasMRP,
    hasAC,
    hasCYBER,
    clients,
  };
};

export default useForms;
