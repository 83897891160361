import { GRAAL_API_URL } from "../../config";
import { graalApiAxiosInstance } from "../../middlewares/useApiAuthMiddleware";

const tasksURI = `${GRAAL_API_URL}partenaires`;

const useProducerService = () => {
  const producerServiceActions = {
    getAllProducers: () => {
      return graalApiAxiosInstance
        .get(`${tasksURI}/chercher?paginate=0`)
        .then((res) => res.data);
    },
  };

  return { producerServiceActions };
};

export default useProducerService;
