import React from "react";
import ResultsContainer from "./ResultsContainer";
import ResultItem from "./ResultItem";

const Results = ({ results }) => {
  return (
    <div className="row mt-5 pb-5 overflow-hidden">
      {results.map((res, resk) => (
        <ResultsContainer res={res} key={resk} resk={resk}>
          <ul className="list-group">
            <ResultItem
              key={resk}
              dat={res.tarifs_annuels}
              name="Tarifs annuels"
              datk={0}
            />
            <ResultItem
              key={resk}
              dat={res.tarifs_proratises}
              name="Tarifs proratisés"
              datk={1}
            />
            {/* {res.datas.map((dat, datk) => (
              <ResultItem
                key={resk}
                dat={dat}
                datk={datk}
                nameMatch={nameMatch}
              />
            ))} */}
          </ul>
          <h4 className="m-0 mb-3 text-center p-0">Commission</h4>
          <ul className="list-group d-flex flex-row mx-4">
            <li
              className="list-group-item d-flex flex-column  align-items-center justify-content-between flex-fill py-0 px-2"
              style={{ borderRadius: 3 }}
            >
              <label>Annuelle</label>
              <label>{res.commission?.annual} €</label>
            </li>
            <li
              className="list-group-item d-flex flex-column  align-items-center justify-content-between flex-fill py-0 px-2"
              style={{ borderRadius: 3 }}
            >
              <label>Proratisée</label>
              <label>{res.commission?.prorata} €</label>
            </li>
          </ul>
        </ResultsContainer>
      ))}
    </div>
  );
};

export default Results;
