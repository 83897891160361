import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import { sidenavActions } from "../../../../context/actions/sidenavActions";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import EmailLink from "../../../common/CommentList/common/EmailLink/EmailLink";
import PhoneLink from "../../../common/CommentList/common/PhoneLink/PhoneLink";
import CompanyInfo from "../../../common/CompanyInfo/CompanyInfo";
import LabelValuePair from "../../../common/LabelValuePair/LabelValuePair";
import Loader from "../../../common/Loader";
import TimelineFiltered from "../../../common/Timeline/TimelineFiltered";
import SendLinkForm from "./SendLinkForm/SendLinkForm";
import usePartnerInfos from "./usePartnerInfos";
import WebPageSelector from "./WebPageSelector/WebPageSelector";

const PartnerInfos = () => {
  const [state, dispatch] = useStore();
  const sActions = sidenavActions(state, dispatch);
  const { isLoadingPartnerInfos, affiliateLink, actions } = usePartnerInfos();
  const { user } = state.auth;
  console.log(user);
  useEffect(() => {
    actions.getPartnerInfos();
  }, []);

  const openSendLinkModal = () => {
    sActions.updateSidenav({
      isOpen: true,
      content: <SendLinkForm link={affiliateLink} onLinkSent={onLinkSent} />,
    });
  };

  const onLinkSent = () => {
    sActions.updateSidenav({
      isOpen: false,
    });
  };

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="shadow-sm bg-white rounded p-4">
          <h1>Ma société</h1>
          {isLoadingPartnerInfos || !user.producer ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-12">
                <CompanyInfo
                  noBadge
                  showCompanyLink={false}
                  company={
                    <div className="d-flex align-items-center">
                      {user.producer.company}
                      <i className="fa fa-eye text-grey ml-1" style={{ fontSize: 12 }} />
                    </div>
                  }
                  siret={user.producer.orias}
                  infos={[
                    `${tools.getFullAddress(user.producer.location)}`,

                    <div className="mt-1">
                      <div className="d-flex align-items-center">
                        <div>{tools.getFullName(user)}</div>
                        <i className="fa fa-eye text-grey ml-1" />
                      </div>
                      <div>
                        <LabelValuePair
                          label="Email"
                          value={
                            <>
                              <EmailLink email={user.producer.email} />
                              <i className="fa fa-eye text-grey ml-1" />
                            </>
                          }
                        />
                      </div>
                      <div>
                        <LabelValuePair
                          label="Téléphone 1"
                          value={
                            <>
                              <PhoneLink phone={user.producer.phone2} />
                              <i className="fa fa-eye text-grey ml-1" />
                            </>
                          }
                        />
                      </div>{" "}
                      <div>
                        <LabelValuePair
                          label="Téléphone 2"
                          value={<PhoneLink phone={user.producer.phone1} />}
                        />
                      </div>{" "}
                      <div>
                        <LabelValuePair
                          label="Mobile"
                          value={<PhoneLink phone={user.producer.mobilePhone} />}
                        />
                      </div>
                      <div className="mt-2" style={{ background: "#eef5f9", padding: 10 }}>
                        <div className="text-grey  d-flex align-items-center">
                          <i className="fa fa-eye mr-1" />
                          <i>
                            Ces données peuvent être visibles par vos clients et vos prospects. Si
                            elles sont erronées, veuillez contacter Perceval.
                          </i>
                        </div>
                      </div>
                    </div>,
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-12">
        <div className="shadow-sm bg-white rounded p-4">
          <h1>Mon lien d'affiliation</h1>
          {isLoadingPartnerInfos ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-12 col-md-6">
                <WebPageSelector onChangeSelectedPage={actions.setSelectedUrl} />
              </div>
              <div className="col-12 col-md-6">
                <label>Votre lien d'affiliation (cliquez pour copier) :</label>
                <button onClick={actions.copyAffiliateLink} className="btn btn-link text-left px-0">
                  {affiliateLink}
                  <i className="far fa-copy ml-2" />
                </button>
                <button
                  className="btn btn-default border w-100 shadow-sm"
                  onClick={openSendLinkModal}
                >
                  Envoyer ce lien à mes contacts
                  <i className="fa fa-envelope ml-2" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-12">
        <TimelineFiltered
          title="Historique des envois d'emails d'affiliation"
          className="mt-4"
          autoSearch
          hideTypeFilter
          initialSearch={{
            model: "users",
            id: "me",
            searchString: "PARTNER_AFFILIATE_CODE_LINK",
            beginAt: DateTime.local().minus({ months: 1 }).startOf("day"),
            endAt: DateTime.local().endOf("day"),
            type: "email",
          }}
          style={{ marginBottom: 20 }}
        />
      </div>
    </div>
  );
};

export default PartnerInfos;
