import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../../../../../../../../context/actions/modalActions";
import useStore from "../../../../../../../../../../context/useStore";
import dataService from "../../../../../../../../../../helpers/dataService";
import { ClientContext } from "../../../../../../ClientContext/ClientContext";
import MigrateClient from "./MigrateClient/MigrateClient";

const useCompanyOtherActions = (company, setCompany = () => {}) => {
  const [isImportingModulr, setIsImportingModulr] = useState(false);
  const [isImportedModulr, setIsImportedModulr] = useState(false);
  const [isRadierEntreprise, setIsRadierEntreprise] = useState(false);
  const [isRadieredEntreprise, setIsRadieredEntreprise] = useState(false);

  const [errors, setErrors] = useState({});
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const { userCustomers, userCustomersActions } = useContext(ClientContext);

  const importModulr = () => {
    if (
      !window.confirm("Confirmez-vous l'importation du client depuis Modulr ?")
    ) {
      return false;
    }
    setIsImportingModulr(true);
    dataService.get(
      `modulr/clients/${company.id}`,
      (data) => {
        toast.success(
          "L'importation du client depuis Modulr a bien été démarrée, rechargez la page d'ici quelques minutes."
        );
        setIsImportedModulr(true);
      },
      setErrors,
      () => setIsImportingModulr(false)
    );
  };

  const radierEntreprise = () => {
    if (!window.confirm("Confirmez-vous la radiation de l'entreprise ?")) {
      return false;
    }
    setIsRadierEntreprise(true);
    dataService.patch(
      `clients/${company.id}/radier`,
      {},
      (data) => {
        toast.success("L'entreprise a été radiée.");
        setCompany({ ...company, isDelisted: true });
        setIsRadieredEntreprise(true);
      },
      setErrors,
      () => setIsRadierEntreprise(false)
    );
  };

  const openMigrationModal = () => {
    mActions.updateModal({
      isOpen: true,
      content: <MigrateClient companyId={company.id} />,
    });
  };

  return {
    isImportingModulr,
    isImportedModulr,
    errors,
    importModulr,
    openMigrationModal,
    isRadierEntreprise,
    isRadieredEntreprise,
    radierEntreprise,
  };
};

export default useCompanyOtherActions;
