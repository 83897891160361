import React from "react";
//import { Editor } from "@tinymce/tinymce-react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const DynamicEditor = ({ page, setPage }) => {
  function handleEditorChange(content) {
    var pg = JSON.parse(JSON.stringify(page));
    pg.content = content;
    setPage(pg);
  }
  return (
    <>
      <CKEditor
        editor={Editor}
        data={page.content}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleEditorChange(data);
          console.log({ event, editor, data });
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
      {/* <Editor
        apiKey={process.env.REACT_APP_TINY_MCE}
        initialValue={page.content}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
        }}
        onEditorChange={handleEditorChange}
      /> */}
    </>
  );
};

export default DynamicEditor;
