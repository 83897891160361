import { DateTime } from "luxon";

export const simplifiedFormSample = {
  id: "123456789",
  comments: [],
  tasks: [],
  est_finalisee: false,
  createdAt: DateTime.local().toISO(),
  updatedAt: DateTime.local().toISO(),
  parameters: {
    product: "PJ",
    demande_id: "123456789",
    partenaire_lie: {
      id: "5eb16a12943734ecdef171af",
      nom_societe: "ARK' ASSUR",
    },
    client_lie: {
      id: "6304debc28f9b669e0d2f31d",
      gender: 1,
      email: "joan+volaille@10positif.com",
      phone: "0692434343",
      firstname: "joan",
      lastname: "volaille",
      clients: [
        {
          id: "6304dec228f9b669e0d2f336",
          location1: {
            address: "CIELS",
            address2: "",
            postalCode: "15250",
            city: "AYRENS",
          },
          company: "LES VOLAILLES D'ALICE",
          siren: "821523719",
          siret: "82152371900010",
          companyActivity: "Élevage de volailles",
          registrationAt: "2016-06-09T00:00:00.000Z",
          ape: "0147Z",
        },
      ],
    },
    est_public: true,
    est_modification_formulaire_produit: true,
    est_rattachement_compte_utilisateur: false,
    est_finaliser_demande: false,
  },
  formData: {
    selectedCompany: {
      nom_entreprise: "SCI LA BROSSE",
      siren: "327851689",
      siret: "32785168900023",
      activite: "Location de terrains et d'autres biens immobiliers",
      code_ape: "6820B",
      id: "63118294c4a7c940096cade4d8ea87dbc54037a4",
      est_siege: true,
      chiffre_affaires: "123123",
      nombre_salaries: "23",
      adresse: "TEST MILON",
      adresse_complement: "",
      code_postal: "89560",
      ville: "SEMENTRON",
      forme_juridique: "61f26e019b9b8f1e8ca0c91a",
      categorie_socioprofessionnelle: 2,
    },
    selectedEstablishment: {
      nom_etablissement: "SCI LA BROSSE",
      siren: "327851689",
      siret: "32785168900023",
      activite: "Location de terrains et d'autres biens immobiliers",
      code_ape: "6820B",
      id: "63118294c4a7c940096cade4d8ea87dbc54037a4",
      est_siege: true,
      chiffre_affaires: 0,
      nombre_salaries: 0,
      adresse: "TEST MILON",
      adresse_complement: "",
      code_postal: "89560",
      ville: "SEMENTRON",
      est_unique: true,
    },
    productOptions: {
      garanties_renforcees: true,
      option_fiscale_et_sociale: true,
      option_recouvrement_de_creances: true,
      date_debut_contrat: "2023-04-04T17:38:03.189+04:00",
    },
  },
};

export const simplifiedFormAdapter = {
  transformListForBO: (data) => {
    return data;
  },

  transformNewItemForAPI: (data) => {
    return {
      produit_id: data.product,
      partenaire_lie: data.partenaire_lie.uuid,
      est_public: data.est_public,
      est_modification_formulaire_produit:
        data.est_modification_formulaire_produit,
      est_rattachement_compte_utilisateur:
        data.est_rattachement_compte_utilisateur,
      est_finaliser_demande: data.est_finaliser_demande,
      client_lie: data.client_lie?.id
        ? {
            id: data.client_lie.id,
            email: data.client_lie.email,
            civilite: data.client_lie.gender,
            prenom: data.client_lie.firstname,
            nom: data.client_lie.lastname,
            telephone: data.client_lie.phone,
          }
        : null,
    };
  },
  transformExistingItemForAPI: (data) => {
    return {
      demande_id: data.id,
      produit_id: data.product,
      partenaire_lie: data.partenaire_lie.uuid,
      est_public: data.est_public,
      est_modification_formulaire_produit:
        data.est_modification_formulaire_produit,
      est_rattachement_compte_utilisateur:
        data.est_rattachement_compte_utilisateur,
      est_finaliser_demande: data.est_finaliser_demande,
      client_lie: data.client_lie?.id
        ? {
            id: data.client_lie.id,
            email: data.client_lie.email,
            civilite: data.client_lie.gender,
            prenom: data.client_lie.firstname,
            nom: data.client_lie.lastname,
            telephone: data.client_lie.phone,
          }
        : null,
    };
  },
  transformNewItemForBO: (data) => {
    return { ...data };
  },
  getItemParametersForm: (data) => {
    return {
      product: data.parameters.product,
      id: data.parameters.demande_id,
      partenaire_lie: data.parameters.partenaire_lie,
      client_lie: data.parameters.client_lie,
      est_public: data.parameters.est_public,
      est_modification_formulaire_produit:
        data.parameters.est_modification_formulaire_produit,
      est_rattachement_compte_utilisateur:
        data.parameters.est_rattachement_compte_utilisateur,
      est_finaliser_demande: data.parameters.est_finaliser_demande,
    };
  },
};
