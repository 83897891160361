import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const InputsBlock = ({ title, forceOpen, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (forceOpen) {
      setIsOpen(true);
    }
  });

  const toggleIsOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div>
      <div
        className="bg-white border p-3 mb-2 d-flex justify-content-between align-items-center flex-row flex-wrap cursor-pointer rounded"
        onClick={toggleIsOpen}
      >
        <strong className="m-0 p-0" style={{ fontSize: 16, color: "black" }}>
          {title}
        </strong>
        <i
          className={`fa fa-2x text-secondary ${
            isOpen ? "fa-chevron-up" : "fa-chevron-down"
          }`}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: "0px" }}
            animate={{ height: "auto" }}
            exit={{ height: "0px" }}
            transition={{ ease: "linear" }}
            className={`d-flex flex-row align-items-start justify-content-around flex-wrap w-100 overflow-hidden`}
          >
            {props.children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default InputsBlock;
