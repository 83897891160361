import React, { useEffect, useState } from "react";
import Avatar from "../Avatar";
import tools from "../../../helpers/tools";
import EmailLink from "../CommentList/common/EmailLink/EmailLink";
import { getClient } from "../../../services/clientService";
import PhoneLink from "../CommentList/common/PhoneLink/PhoneLink";

const ClientCard = ({
  client,
  onSelectClient = null,
  onRemoveClient = null,
  className = "col-12 col-md-4 col-lg-4",
  disabled,
}) => {
  const [clientData, setClientData] = useState(client);
  console.log("client", client);

  useEffect(() => {
    if (!client.clients) {
      getClient(client.id).then((data) => {
        setClientData((c) => ({
          ...c,
          clients: data,
        }));
      });
    }
  }, [client.id]);

  console.log("client", client);

  return (
    <div className={`${className} mb-3`}>
      <div className="rounded bg-white p-3 shadow-sm h-100 d-flex flex-column justify-content-between align-items-center text-center border">
        <div className="w-100">
          <div className="d-center mb-2">
            <Avatar width={40} height={40} id={clientData.id} />
          </div>

          <div className="my-1">
            <strong>
              {tools.getFullName(
                client
                  ? {
                      gender: client.civilite,
                      firstname: client.prenom,
                      lastname: client.nom,
                    }
                  : {}
              )}
            </strong>
          </div>
          <div>
            <badge className={`badge border badge-light`}>Client</badge>
          </div>
          <div className="my-1">
            <EmailLink email={clientData.email} />
          </div>
          <div className="my-1">
            <PhoneLink phone={clientData.telephone} />
          </div>
          <div>
            {
              <ul className="text-left pl-0" style={{ listStyle: "none" }}>
                {clientData.clients?.map((c, cck) => {
                  return (
                    <li key={cck} className="border-bottom">
                      <small className="d-block">
                        {c.company} ({c.location1?.postalCode})
                      </small>
                      <small>
                        <i>{tools.formatSIREN(c.siren)}</i>
                      </small>
                    </li>
                  );
                })}
              </ul>
            }
          </div>
        </div>
        {onSelectClient != null && (
          <div className="mt-2">
            <button
              onClick={() => onSelectClient(client)}
              style={{ minWidth: 0 }}
              className="btn btn-sm btn-primary "
            >
              Sélectionner
            </button>
          </div>
        )}
        {onRemoveClient != null && !disabled && (
          <div className="mt-2">
            <button
              disabled={disabled}
              onClick={() => onRemoveClient(client)}
              style={{ minWidth: 0 }}
              className="btn btn-sm btn-danger "
            >
              Retirer
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientCard;
