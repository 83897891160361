import React from "react";
import { PanelSectionTitle } from "../../layout/StyledComponents/StyledComponents";
import ProductDataList from "../ProductDataList/ProductDataList";
const PJInfos = () => {
  return (
    <div>
      <PanelSectionTitle>Protection Juridique</PanelSectionTitle>
      <ProductDataList />
    </div>
  );
};

export default PJInfos;
