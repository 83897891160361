import React, { useState } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import Axios from "axios";
import { API_URL, REF_BO } from "../../../config";
import Loader from "../../common/Loader";

const ForgotPassword = ({ setForgotPassword, captchaToken }) => {
  const [email, setEmail] = useState("");
  const [emailOk, setEmailOk] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function checkEmail() {
    setCheckingEmail(true);
    Axios.post(API_URL + "auth/renew-password/" + REF_BO, {
      login: email,
      captchaToken,
    })
      .then((res) => {
        setCheckingEmail(false);
        setEmailSent(true);
      })
      .catch((err) => {
        console.log(err);
        setCheckingEmail(false);

        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  return (
    <div className="card m-auto col-md-6 col-lg-6 col-12 px-0 animated fadeInRight faster">
      {emailSent ? (
        <div className="card-body p-4 p-lg-5 p-md-4 text-center overflow-hidden">
          <p className="text-success mb-0">
            Un email vous a été envoyé pour réinitialiser votre mot de passe. Consultez votre boîte
            email et cliquez sur le lien qu'il contient.
          </p>
          <button onClick={() => setForgotPassword(false)} className="btn btn-link w-100 mt-3">
            <i className="fa fa-chevron-left mr-2"></i>Revenir à la connexion
          </button>
        </div>
      ) : (
        <>
          <div className="card-header">
            <h1 className="text-white">Mot de passe oublié</h1>
          </div>
          <div className="card-body p-4 p-lg-5 p-md-4">
            <p className="mb-4">
              Une fois ce formulaire rempli, vous recevrez un email contenant un lien qui vous
              permettra de définir un nouveau mot de passe pour votre compte.
            </p>
            <Control
              label="Entrez votre email"
              value={email}
              change={(e) => setEmail(e.target.value)}
              name="email"
              error={errors}
            />
            {checkingEmail ? (
              <Loader withContainer={true} />
            ) : (
              <>
                {captchaToken && (
                  <button className="btn btn-primary w-100" onClick={checkEmail}>
                    Envoyer le lien par email
                  </button>
                )}
                <button
                  onClick={() => setForgotPassword(false)}
                  className="btn btn-link w-100 mt-3"
                >
                  <i className="fa fa-chevron-left mr-2"></i>Revenir à la connexion
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
