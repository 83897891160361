import React from "react";
import Loader from "../../../common/Loader";
import useLeads from "../useLeads";

const LinkedLeadsFilters = () => {
  const { actions, setShowOnlyLinkedLeads, isSearching } = useLeads();

  const handleLinkedLeadsClick = () => {
    setShowOnlyLinkedLeads(false);
    actions.getLeads();
  };

  return (
    <div className="alert alert-warning d-flex align-items-center justify-content-between px-3">
      <div className="py-3">Affichage des demandes liées</div>
      {isSearching ? (
        <Loader color="white" />
      ) : (
        <button onClick={handleLinkedLeadsClick} className="btn btn-link text-white">
          <i className="fa fa-times" />
        </button>
      )}
    </div>
  );
};

export default LinkedLeadsFilters;
