import React from "react";
import LabelContainer from "../LabelContainer";
import ButtonItem from "./ButtonItem";

const BtnListInput = (props) => {
  const {
    name,
    value,
    k,
    btnInline,
    datas,
    dataIndex = "id",
    change,
    inColumn,
  } = props;

  const valueChange = (e, val, isExclusive) => {
    var filteredValue = val;
    let changeEvent = {
      target: {
        name: name,
        value: filteredValue == props.value ? "" : filteredValue,
        k: props.k,
      },
    };

    change(changeEvent);
  };

  return (
    <LabelContainer {...props}>
      <div
        className={`
          d-flex flex-fill 
          ${
            btnInline
              ? "d-flex flex-row flex-wrap "
              : "flex-column align-items-start justify-content-start"
          } 
          

        `}
      >
        {datas.map((data, dk) => {
          let isActive = data[dataIndex] == value;
          return (
            <div className={`${inColumn ? "w-100" : ""}`}>
              <ButtonItem
                key={"btnlist" + props.name + props.id + dk}
                {...props}
                isActive={isActive}
                data={data}
                dk={dk}
                valueChange={valueChange}
              />
            </div>
          );
        })}
      </div>
    </LabelContainer>
  );
};

export default BtnListInput;
