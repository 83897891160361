import React from "react";
import Pagination from "../../common/Pagination";
import usePjRenewList from "./usePjRenewList";

const PjRenewListPagination = ({ pjCount }) => {
  const {
    pjListFilters: { page },
    actions,
  } = usePjRenewList();

  return (
    <Pagination page={page} nbPages={Math.ceil(pjCount / 25)} changePage={actions.updatePage} />
  );
};

export default PjRenewListPagination;
