import React from "react";
import styled from "styled-components";
import Control from "../../../Controls/Control";
import useTodoList from "../../useTodoList";

const TodoNameEdit = ({ todo, updateTodo }) => {
  const { availableTasks } = useTodoList();

  const handleChangeTodo = (e) => {
    const { name, value } = e.target;
    updateTodo({
      ...todo,
      [name]: value,
    });
  };

  const editedTasks = availableTasks.map((t) => {
    if (todo.taskId == "11" && t.id == "11") {
      const text = todo.text ? ` : ${todo.text}` : "";
      return { ...t, name: `${t.name}${text}` };
    }
    return t;
  });

  return (
    <div>
      <StyledControl
        label=""
        type="select"
        name="taskId"
        value={todo.taskId}
        datas={editedTasks}
        dataIndex="id"
        dataLabel="name"
        change={handleChangeTodo}
        margin="m-0"
        noPaddingOnFormGroup
        noMarginInsideLabel
        noBg
        labelClassname="w-100 my-0"
      />
    </div>
  );
};

const StyledControl = styled(Control)`
  select {
    border-radius: 0px;
    border: 0px !important;
  }
`;

export default TodoNameEdit;
