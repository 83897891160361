import React from "react";

const ResultsContainer = ({ resk, res, children }) => {
  return (
    <div
      className="col-12 col-md-6 animated fadeInRight"
      style={{
        animationDelay: resk * 80 + "ms",
      }}
    >
      <div className="card">
        <div className="card-header">
          <h2 className="text-white m-0">
            {`GOZEN ${res.garanties_renforcees ? "PLUS" : ""}`} (€)
          </h2>
        </div>
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default ResultsContainer;
