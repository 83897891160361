import React from "react";
import ACBlocks from "./ACBlocks";
import ClientAccordion from "./ClientAccordion";
import DownloadButton from "./DownloadButton";
import MRPBlocks from "./MRPBlocks";
import MRPDocs from "./MRPDocs";
import ProductTitle from "./ProductTitle";

const MRPContainer = ({ MRPClients, clientId }) => {
  return (
    <div className="mb-5">
      <ProductTitle icon="store" title="Demande d'Assurance Multirisque Professionnelle" />
      <div className="mt-3">
        <p>
          <strong>Téléchargez les questionnaires PDF</strong> en fonction des Compagnies de votre
          choix. Mettez à jour vos réponses si besoin.
        </p>
      </div>
      {MRPClients.map((client) => {
        return (
          <ClientAccordion client={client}>
            {client.establishments.map((company) => (
              <MRPBlocks company={company} clientId={clientId} key={`compac${company.name}`} />
            ))}
            <MRPDocs client={client} />
          </ClientAccordion>
        );
      })}
    </div>
  );
};

export default MRPContainer;
