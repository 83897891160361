import React from "react";
import DropFile from "../../common/DropFile";
import ModalBtn from "../../common/ModalBtn";
import Modal from "../../common/Modal";

const DocumentsDisplay = ({
  documents,
  documentUploaded,
  documentRemoved,
  uploadNewFile,
  cancelNewVersion,
  authorizedModels,
  small = false,
}) => {
  function checkDocActionAvailable(doc) {
    if (authorizedModels) {
      return authorizedModels.includes(doc.entity.ref);
    }
    return true;
  }

  return (
    <>
      {documents.map((doc, dock) => {
        return (
          <div
            className={
              "text-center mb-3 d-flex flex-column  " +
              (small ? " col " : " col-12 col-md-4 col-lg-3 ")
            }
            style={{
              animationDelay: dock * 50 + "ms",
              maxWidth: small ? 190 : "none",
            }}
            key={"doc" + dock}
          >
            <DropFile
              document={doc[0]}
              documentUploaded={
                checkDocActionAvailable(doc[0])
                  ? (d) => documentUploaded(dock, 0, d)
                  : false
              }
              documentRemoved={
                checkDocActionAvailable(doc[0])
                  ? (d) => documentRemoved(dock, 0, d)
                  : false
              }
              uploadNewFile={
                checkDocActionAvailable(doc[0])
                  ? () => uploadNewFile(dock, 0)
                  : false
              }
              cancelNewVersion={cancelNewVersion}
              small={small}
            />

            {doc.length > 1 && (
              <div className="">
                {!doc[0].isNewVersion && (
                  <ModalBtn
                    id={"viewFileVersionsModal" + dock + "" + doc[0].id}
                    label="Voir les autres versions"
                    btnClass="btn btn-link bg-white btn-sm border mt-2"
                    backdrop={false}
                  />
                )}

                <Modal
                  id={"viewFileVersionsModal" + dock + "" + doc[0].id}
                  title="Historique des versions du document"
                  bodyStyle={{
                    background: "#f0f3f5",
                  }}
                >
                  {doc.map((sdoc, sdock) => {
                    return (
                      <div className="mb-2" key={"doc" + dock + "" + sdock}>
                        <DropFile
                          document={sdoc}
                          documentUploaded={(d) =>
                            documentUploaded(dock, sdock, d)
                          }
                          documentRemoved={() => documentRemoved(dock, sdock)}
                          cancelNewVersion={cancelNewVersion}
                          small={small}
                        />
                      </div>
                    );
                  })}
                </Modal>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default DocumentsDisplay;
