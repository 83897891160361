import React, { useContext } from "react";
import styled from "styled-components";
import tools from "../../../../../../../helpers/tools";
import { CommissionContext } from "../../../context/CommissionProvider";

const PolicyInfos = ({ policy }) => {
  const { productTypes } = useContext(CommissionContext);
  return (
    <>
      <div className="d-inline-flex align-items-center">
        <span className="badge badge-primary mr-2">
          {tools.findIn(productTypes, policy.product.productType, "id").name}
        </span>
        <StyledPolicyTitle>{policy.title}</StyledPolicyTitle>
      </div>
      <StyledPolicyReference>{policy.reference}</StyledPolicyReference>
    </>
  );
};

const StyledPolicyTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
const StyledPolicyReference = styled.div`
  color: var(--color-default-dark);
  font-size: 12px;
`;

export default PolicyInfos;
