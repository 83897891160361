import { DateTime } from "luxon";
import React from "react";
import tools from "../../../../helpers/tools";
import Stepper from "../../../common/Stepper/Stepper";
import StepperHorizontal from "../../../common/Stepper/StepperHorizontal";
import Table from "../../../common/Table";

const PartnersTable = ({ state, actions }) => {
  return (
    <div className="row mt-4">
      <div className="col-12">
        <Table
          datas={state.partners}
          fields={[
            {
              name: "Nom",
              path: "*",
              render: (el) => tools.getFullName(el),
            },
            {
              name: "Téléphone",
              path: "phone",
              render: (el) => (
                <a
                  href={`tel:${el}`}
                  className="btn btn-link p-0"
                  style={{ fontSize: 13 }}
                >
                  {el}
                </a>
              ),
            },
            {
              name: "Email",
              path: "*",
              render: (el) => (
                <div className="position-relative">
                  <a
                    href={`mailto:${el.email}`}
                    className="btn btn-link p-0"
                    style={{ fontSize: 13 }}
                  >
                    {el.email}
                  </a>
                  <br />
                  <button
                    style={{
                      fontSize: 11,
                      fontWeight: "400",
                    }}
                    className="btn btn-light btn-sm mt-2"
                    onClick={() => actions.banPartner(el.id)}
                  >
                    <i className="fa fa-ban" />
                  </button>
                </div>
              ),
            },
            {
              name: "Créé le",
              path: "createdAt",
              render: (el) =>
                DateTime.fromISO(el).toFormat("dd/MM/yyyy 'à' HH'h'mm"),
            },
            {
              name: "Statut",
              path: "*",
              render: (el) => (
                <StepperHorizontal
                  steps={[
                    {
                      title: "email activé",
                      color: el.isActivated ? "primary" : "dark",
                      icon: el.isActivated ? "check" : "times",
                    },
                    {
                      title: "compte validé",
                      color: el.isValidated ? "primary" : "dark",
                      icon: el.isValidated ? "check" : "times",
                    },
                    {
                      title: "convention signée",
                      color: el.isConventionSigned ? "primary" : "dark",
                      icon: el.isConventionSigned ? "check" : "times",
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PartnersTable;
