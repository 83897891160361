import { Provider } from "jotai";
import React, { useEffect } from "react";
import useInsuranceCompanies from "../../../../hooks/useInsuranceCompanies/useInsuranceCompanies";
import useProducers from "../../../../hooks/useProducers/useProducers";
import useProductTypes from "../../../../hooks/useProductTypes/useProductTypes";
import Page from "../../../common/layout/Page";
import CommissionItem from "./CommissionItem/CommissionItem";
import CommissionListFilters from "./CommissionListFilters/CommissionListFilters";
import CommissionSummary from "./CommissionSummary";
import { CommissionProvider } from "./context/CommissionProvider";
import useCommissionsList from "./useCommissionsList";

const CommissionsList = () => {
  const { commissions, nbResults, isSearching } = useCommissionsList();
  const { productTypes, onGetProductTypes } = useProductTypes();
  const { insuranceCompanies, onGetInsuranceCompanies } = useInsuranceCompanies();
  const { producers } = useProducers(true);

  useEffect(() => {
    onGetProductTypes();
    onGetInsuranceCompanies();
  }, []);

  return (
    <Page title="Liste des commissions">
      <CommissionListFilters />
      <div className="row">
        <div className="col-12 px-0 mb-3 bg-white shadow-sm">
          <CommissionSummary />
        </div>
      </div>
      <div className="col-12 mb-3">
        <div className="text-center">
          {isSearching ? "Recherche en cours..." : <div>{nbResults} résultat(s)</div>}
        </div>
      </div>

      <div className="row">
        <CommissionProvider value={{ productTypes, insuranceCompanies, producers }}>
          {commissions.map((commission) => (
            <div
              className="col-12 shadow-sm px-0 mx-0 mb-4 bg-white"
              key={`com${commission.client.id}`}
            >
              <Provider>
                <CommissionItem commissionData={commission} />
              </Provider>
            </div>
          ))}
        </CommissionProvider>
      </div>
    </Page>
  );
};

export default CommissionsList;
