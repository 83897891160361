import React from "react";
import Control from "../../common/Control";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import Colors from "../../../variables.scss";

const SearchCompany = ({
  search,
  updateSearch,
  searchLoading,
  searchCompany,
}) => {
  return (
    <>
      <Control
        label="Rechercher une société"
        name="search"
        value={search.search}
        change={updateSearch}
      />
      <Control
        label="Source de la recherche"
        type="btnList"
        name="source"
        value={search.source}
        datas={[
          { id: "perceval", name: "Clients Perceval" },
          { id: "opendata", name: "OpenData" },
        ]}
        change={updateSearch}
        btnInline
      />

      {search.companies.length > 0 && !searchLoading && (
        <Control
          label="Société sélectionnée"
          name="company"
          type="select"
          datas={search.companies}
          change={updateSearch}
          value={search.company}
          dataIndex="siret"
          dataLabel="company"
          dataLabel2="siret"
          dataLabel3="ape"
        />
      )}
      <SaveBtn
        type="default"
        save={searchCompany}
        isSaving={searchLoading}
        className="m-auto"
        text="Rechercher"
        typeBtn="submit"
        loaderColor={Colors.primary}
      />
    </>
  );
};

export default SearchCompany;
