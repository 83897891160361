import React from "react";
import Page from "../../common/layout/Page";
import LeadList from "./LeadList/LeadList";
import { LeadProvider } from "./useLeads";

const LeadListDomiPage = () => {
  return (
    <Page container="container-fluid" title="Liste des leads DOMI" subTitle="Demandes en cours">
      <LeadProvider value={{ productCategory: "DOMI" }}>
        <LeadList />
      </LeadProvider>
    </Page>
  );
};

export default LeadListDomiPage;
