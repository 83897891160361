import React, { useContext } from "react";
import tools from "../../../../../../helpers/tools";
import CompanyInfo from "../../../../../common/CompanyInfo/CompanyInfo";
import OpenDetailButton from "../../../../LeadListPage/LeadList/LeadItem/OpenDetailButton/OpenDetailButton";
import { CommissionContext } from "../../context/CommissionProvider";
import CompanyDetails from "../DetailPanels/CompanyDetails";
import DetailPanel from "../DetailPanels/DetailPanel";
import PolicyDetails from "../DetailPanels/PolicyDetails";
import useCommissionItem from "../useCommissionItem";
import PolicyInfos from "./PolicyInfos/PolicyInfos";

const ClientAndPolicyInfos = () => {
  const { isCompanyOpen, isPolicyOpen, commission, actions } = useCommissionItem();

  const { client, policy, amendment } = commission;

  return !client ? null : (
    <div className=" bg-light p-3">
      <div className="row">
        <div className="col-12">
          <CompanyInfo company={client.company} siret={client.siret} noBadge />
          <OpenDetailButton
            className="d-inline-flex"
            onClick={actions.toggleCompanyDetail}
            title={<>Détails de la société</>}
          />
          {isCompanyOpen && <CompanyDetails client={client || {}} />}
        </div>
        <div className="col-12 my-2">
          <PolicyInfos policy={policy} />
          <OpenDetailButton
            className="d-inline-flex"
            onClick={actions.togglePolicyDetail}
            title={<>Détails du contrat</>}
          />
          {isPolicyOpen && <PolicyDetails amendment={amendment} policy={policy} />}
        </div>
      </div>
    </div>
  );
};

export default ClientAndPolicyInfos;
