import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { TodoContext } from "../../context/TodoContext";
import useTodoList from "../../useTodoList";

const TodoBlockList = () => {
  const { todos, activeTodoId, setActiveTodoId } = useTodoList();
  const { actions } = useContext(TodoContext);
  const onUpdateActiveTodoId = (todoId) => {
    if (activeTodoId == todoId) {
      const todo = todos.find((t) => t.id == todoId);
      const newTodo = { ...todo, isDone: !todo.isDone };
      actions.updateTodo(newTodo);
    } else {
      setActiveTodoId(todoId);
    }
  };

  return (
    <StyledTodoBlockList nbBlocks={todos.length}>
      {todos.map((todo) => {
        const todoId = todo.id;
        const isActive = activeTodoId == todoId;
        const isDone = todo.isDone;
        return (
          <li
            onClick={() => onUpdateActiveTodoId(todoId)}
            key={`todoblock${todoId}`}
            className={`todo__block d-center ${isDone ? "done" : ""} ${isActive ? "active" : ""}`}
          >
            <div className="inner-icon">
              <i
                className={`fa ${isDone ? "fa-check text-white animated fadeInDown faster" : ""}`}
              />
            </div>
          </li>
        );
      })}
    </StyledTodoBlockList>
  );
};

const pulseEdgeOut = keyframes`
0% {
  transform: scale(1);
  opacity:1;
}

100% {
  transform: scale(1.4);
  opacity: 0;
}
`;

const StyledTodoBlockList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  padding-bottom: 7px;
  margin: 0;

  & li {
    position: relative;
    border: 1px solid var(--color-default);
    width: ${({ nbBlocks }) => 100 / nbBlocks}%;
    max-width: min(30px, ${({ nbBlocks }) => 100 / nbBlocks}%);
    height: 30px;
    border-radius: 50%;
    margin-right: 6px;
    cursor: pointer;
    transition: 400ms;

    &.done {
      border: 1px solid var(--success);
      background-color: var(--success);
    }

    &.active {
      transform: scale(1.2);
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid var(--success);
        top: 0;
        left: 0;
        border-radius: 50%;
        animation-name: ${pulseEdgeOut};
        animation-duration: 1200ms;
        animation-iteration-count: infinite;
        opacity: 0;
        transform: scale(1);
        animation-timing-function: ease-out;
      }
    }
  }
`;

export default TodoBlockList;
