import React, { useContext, useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import ForgotPassword from "./ForgotPassword";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import CanvasParticle from "../../common/CanvasParticle";
import logoPerceval from "../../images64/logoPerceval";
import logoPercevalSmall from "../../images64/logoPercevalSmall";
import Timer from "../../common/Timer/Timer";
import { ReCaptcha } from "react-recaptcha-v3";
import { recaptcha_token } from "../../../config";

var timerInterval = null;
var recaptcha;

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(false);

  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        clearInterval(timerInterval);
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);
    setCaptchaToken(false);
    updateToken();
  }, [forgotPassword]);

  function loginHandle(e) {
    e.preventDefault();
    // if (process.env.NODE_ENV == "development") {
    //   verifyCallback("ok");
    // } else {
    //   setIsLoading(true);
    // }
    setIsLoading(true);
  }

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    if (!forgotPassword) {
      actions.loginUser({ login, password, captchaToken: recaptchaToken });
    }
    setCaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };

  return (
    <div className="d-center" style={{ minHeight: "100%" }}>
      <div className="d-center p-0 mt-4 mt-lg-0 mt-md-0 mx-auto auth-container flex-column">
        {!forgotPassword ? (
          <div
            className="d-flex align-items-center justify-content-center flex-column bg-white p-5 animated fadeIn mx-auto"
            style={{ maxWidth: "500px" }}
          >
            {/* <CanvasParticle id="canvas1" image64={logoPerceval} radius={50} /> */}
            <form
              onSubmit={loginHandle}
              className="d-flex align-items-center justify-content-center flex-column w-100 h-100 login-form"
            >
              <div className="mb-3 text-center">
                <img className="img-fluid" width="60" src="/images/logo-small.svg" />
                {/* <CanvasParticle
                  id="canvas2"
                  image64={logoPercevalSmall}
                  radius={30}
                /> */}
                <div className="mt-3 mb-3" style={{ fontSize: "2.5rem" }}>
                  Backoffice Perceval
                </div>
                <p className="mb-0">Connectez-vous pour accéder à votre espace</p>
              </div>
              <Control
                autoFocus={true}
                className="w-100"
                margin="my-2"
                label="Email"
                name="login"
                id="login"
                type="email"
                value={login}
                change={(e) => setLogin(e.target.value)}
                error={state.errors}
              />

              <Control
                className="w-100"
                margin="my-2"
                label="Mot de passe"
                name="password"
                id="password"
                type="password"
                value={password}
                change={(e) => setPassword(e.target.value)}
                error={state.errors}
              />
              <div className="w-50 text-center d-flex flex-column justify-content-center align-items-center">
                {state.errors.nbAttempts > 0 && (
                  <span className="text-primary">
                    {state.errors.nbAttempts} tentatives restantes
                  </span>
                )}
                {!canLoggin && (
                  <>
                    <span className="text-danger mb-3">
                      L'accès à votre compte est temporairement bloqué suite à plusieurs tentatives
                      de connexion infructueuses.
                    </span>
                    <Timer timeLimit={timer} timerFinish={timerFinish} />
                  </>
                )}
              </div>
              <div className="text-center my-3">
                {canLoggin && (
                  <SaveBtn
                    className="animated zoomIn"
                    isSaving={isLoading}
                    text="Connexion"
                    type="primary"
                    typeBtn="submit"
                  />
                )}
              </div>
              {canLoggin && (
                <div className="text-center">
                  <button
                    style={{ textTransform: "lowercase" }}
                    type="button"
                    onClick={() => setForgotPassword(true)}
                    className="btn btn-link"
                  >
                    <i className="fa fa-lock mr-2" />
                    Mot de passe oublié ?
                  </button>
                </div>
              )}
            </form>
          </div>
        ) : (
          <div className="m-5">
            <ForgotPassword setForgotPassword={setForgotPassword} captchaToken={captchaToken} />
          </div>
        )}

        <div className=" text-center w-100 p-3">
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
      </div>
      {(isLoading || forgotPassword) && (
        <ReCaptcha
          ref={(ref) => (recaptcha = ref)}
          sitekey={recaptcha_token}
          action="login"
          verifyCallback={verifyCallback}
        />
      )}
    </div>
  );
};

export default Login;
