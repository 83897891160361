import React, { useState } from "react";
import styled from "styled-components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const itemFormatter = (item) =>
  `@${item.firstname} ${item.lastname} (${item.email})`;

function RichInputEditor({
  users = [],
  onSubmit = () => {},
  initialComment = "",
}) {
  const [htmlContent, setHtmlContent] = useState(initialComment);

  const usersFeed = users
    .filter((manager) => {
      if (manager.contractEndAt) {
        return new Date(manager.contractEndAt) > new Date();
      }
      return true;
    })
    .map((manager) => {
      return {
        id: itemFormatter(manager),
        name: `${manager.firstname} ${manager.lastname}`,
      };
    });

  const handleEditorChange = (content) => {
    var pg = content;
    setHtmlContent(pg);
  };

  const editorConfiguration = {
    // ... other configuration
    // plugins: [Mention],
    mention: {
      feeds: [
        {
          feed: usersFeed,
          marker: "@",
          minimumCharacters: 1,
        },
      ],
    },
  };

  function extractUsersFromDataMentions(htmlString) {
    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the string as HTML
    const doc = parser.parseFromString(htmlString, "text/html");

    // Query the document for all elements with the 'data-mention' attribute
    const mentionElements = doc.querySelectorAll(".mention[data-mention]");

    // Map over the NodeList and return an array of 'data-mention' values
    const dataMentions = Array.from(mentionElements).map((element) =>
      element.getAttribute("data-mention")
    );
    const mentionedUsers = users.filter((manager) =>
      dataMentions.includes(itemFormatter(manager))
    );

    return mentionedUsers;
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const mentionedUsers = extractUsersFromDataMentions(htmlContent);
    setHtmlContent("");
    //remove duplicated in mentionedUsers
    const uniqueMentionedUsers = mentionedUsers.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    onSubmit({
      comment: {
        text: htmlContent,
        mentionedUsers: uniqueMentionedUsers.map((m) => m.id),
      },
    });
  };

  return (
    <StyledRichInputEditor>
      Écrire un commentaire :
      <CKEditor
        editor={Editor}
        data={htmlContent}
        config={editorConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleEditorChange(data);
        }}
        onReady={(editor) => {}}
      />
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-default btn-sm border-top-0"
          onClick={onSubmitForm}
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
          }}
        >
          <i className="fa fa-send" />
        </button>
      </div>
    </StyledRichInputEditor>
  );
}

const StyledRichInputEditor = styled.div`
  .ck-editor__top {
    display: none;
  }
  .ck-editor__editable {
    border-top-right-radius: 3px !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 0px !important;
    border: 1px solid #e2e2e2 !important;
    min-height: 80px !important;
  }
`;

export default RichInputEditor;
