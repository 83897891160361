import React, { useState } from "react";
import styled from "styled-components";
import Control from "../../../Controls/Control";
import useTodoList from "../../useTodoList";

const TodoTextEdit = ({ todo, updateTodo }) => {
  const { availableTasks } = useTodoList();
  const [inputValue, setInputValue] = useState(todo.text);
  const [hasChanged, setHasChanged] = useState(false);

  const handleChangeTodo = (e) => {
    setInputValue(e.target.value);
    setHasChanged(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateTodo({
      ...todo,
      text: inputValue,
    });
    setHasChanged(false);
  };

  return (
    <form onSubmit={onSubmit} className="position-relative">
      <StyledTextControl
        placeholder="💬"
        label=""
        type="textarea"
        name="text"
        value={inputValue}
        datas={availableTasks}
        dataIndex="id"
        dataLabel="name"
        k={todo.id}
        change={handleChangeTodo}
        margin="m-0"
        noPaddingOnFormGroup
        noMarginInsideLabel
        noBg
        labelClassname="w-100 mt-1"
        labelStyle={{ borderRadius: 0 }}
        noEmptyBtn
        inputStyle={{
          borderRadius: 0,
          color: "var(--color-default-dark)",
          fontWeight: "400",
          textAlign: "center",
          fontStyle: "italic",
        }}
        suffix={
          <>
            {hasChanged && (
              <button className="btn btn-primary btn-sm" type="submit">
                <i className="fa fa-save" />
              </button>
            )}
          </>
        }
      />
    </form>
  );
};

const StyledTextControl = styled(Control)`
  textarea {
    border: 0 !important;
    min-width: 200px;
  }
`;

const StyledIcon = styled.i`
  position: absolute;
  height: 20px;
  width: 20px;
  z-index: 9;
  left: -9px;
  top: 17px;
  color: var(--color-default-light);
`;

export default TodoTextEdit;
