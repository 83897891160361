import { useState } from "react";
import { patchReceiptPayment } from "../../../../../../../../../../../../services/policyService";
import usePolicyItem from "../../usePolicyItem";
import useReceiptItem from "../useReceiptItem";

const useModifyReceiptForm = (receiptData) => {
  const { PAYMENT_STATUS } = usePolicyItem();
  const { onUpdateReceipt } = useReceiptItem();
  const [isSavingPayment, setIsSavingPayment] = useState(false);
  const [errors, setErrors] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [receiptForm, setReceiptForm] = useState({
    status: receiptData.status,
    amount: "",
    comment: "",
    receipts: [],
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setReceiptForm({ ...receiptForm, [name]: value });
  };

  const toggleShowForm = () => {
    setShowForm(!showForm);
  };

  const onSaveReceiptPayment = () => {
    setIsSavingPayment(true);
    console.log("receiptForm", receiptForm);
    patchReceiptPayment(receiptData.payments.id, {
      ...receiptForm,
      amount: (parseFloat(receiptForm.amount) * 100).toString(),
    })
      .then((data) => {
        onUpdateReceipt({ ...receiptData, payments: { ...receiptData.payments, ...data } });
        setShowForm(false);
        setReceiptForm({
          status: receiptData.status,
          amount: "",
          comment: "",
          receipts: [],
        });
      })
      .catch(setErrors)
      .finally(() => setIsSavingPayment(false));
  };

  return {
    PAYMENT_STATUS,
    receiptForm,
    isSavingPayment,
    errors,
    handleChangeInput,
    onSaveReceiptPayment,
    showForm,
    toggleShowForm,
  };
};

export default useModifyReceiptForm;
