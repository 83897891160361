import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import useRole from "../useRole/useRole";
import useStore from "../../context/useStore";
import useProducerService from "../../services/graal-api/useProducerService";
import useGlobalError from "../useGlobalError/useGlobalError";

const producersAtom = atom([]);
const orderedProducersAtom = atom((get) => {
  const producers = get(producersAtom);
  return producers.sort((a, b) => (a.company > b.company ? 1 : -1));
});
const isLoadingProducersAtom = atom(false);

const useGraalProducers = (fetchOnLoad = false) => {
  const [state] = useStore();
  const [producers, setProducers] = useAtom(producersAtom);
  const [orderedProducers] = useAtom(orderedProducersAtom);
  const [isLoadingProducers, setIsLoadingProducers] = useAtom(
    isLoadingProducersAtom
  );
  const { myRole } = useRole();
  const { producerServiceActions } = useProducerService();
  const { globalErrorActions } = useGlobalError();

  useEffect(() => {
    if (fetchOnLoad && producers.length == 0) actions.getProducers();
  }, []);

  const actions = {
    getProducers: () => {
      setIsLoadingProducers(true);
      producerServiceActions
        .getAllProducers()
        .then((data) => {
          setProducers(data.data);
          setIsLoadingProducers(false);
        })
        .catch((err) => {
          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => {
              actions.getProducers();
            },
          });
        });
    },
  };

  const myProducers =
    myRole === "PARTNER"
      ? producers.filter(
          (p) => p.modulr_id == state.auth.user.producer?.modulrId
        )
      : producers;

  return {
    isLoadingProducers,
    producers,
    myProducers,
    orderedProducers,
    actions,
    iAmPartner: myRole === "PARTNER",
  };
};

export default useGraalProducers;
