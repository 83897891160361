import React, { useEffect } from "react";
import styled from "styled-components";
import tools from "../../../../helpers/tools";
import EmailLink from "../../../common/CommentList/common/EmailLink/EmailLink";
import PhoneLink from "../../../common/CommentList/common/PhoneLink/PhoneLink";
import CompanyInfo from "../../../common/CompanyInfo/CompanyInfo";
import DocumentList from "../../../common/DocumentList/DocumentList";
import OpenDetailButton from "../../LeadListPage/LeadList/LeadItem/OpenDetailButton/OpenDetailButton";
import BottomPanel from "./BottomPanel";
import RBEItem from "./RBEItem/RBEItem";
import { CloseRBEBottomPanelButton } from "./RBEItemBottomPanel/RBEItemBottomPanel";
import RBELastUpdate from "./RBELastUpdate";
import useRBEListItem from "./useRBEListItem";
import "./rbeListItem.scss";

const RBEListItem = ({ rbeData }) => {
  const { actions, rbeListItem, bottomPanelContent } = useRBEListItem();

  useEffect(() => {
    actions.initItem(rbeData);
  }, [rbeData]);

  return !rbeListItem ? null : (
    <StyledRBEListItem
      className="shadow rounded mb-3 position-relative"
      id={`rbeListItem${rbeListItem.id}`}
    >
      <div className="row bg-white py-3  rounded-top animated fadeIn">
        <div className="col-12 col-lg-4">
          <CompanyInfo
            isHeadOffice={true}
            company={rbeListItem.client.company}
            siret={rbeListItem.client.siret}
            infos={[
              `${rbeListItem.client.location1?.postalCode} ${rbeListItem.client.location1?.city}`,
              `${rbeListItem.client.ape} - ${rbeListItem.client.companyActivity}`,
              tools.getFullName(rbeListItem.userCustomer),
              <PhoneLink phone={rbeListItem.userCustomer?.phone} />,
              <EmailLink email={rbeListItem.userCustomer?.email} />,
              <OpenDetailButton
                className="mt-2"
                onClick={actions.openInfos}
                title={<>Plus d'infos</>}
              />,
            ]}
          />
          <RBELastUpdate />
        </div>
        <div className="col-12 col-lg-5 border-left border-right rbeListItem">
          {rbeListItem.beList.length > 0 ? (
            rbeListItem.beList.map((rbeItemData, rbelk) => (
              <RBEItem rbeItemData={rbeItemData} key={`rbelitemk${rbelk}`} />
            ))
          ) : (
            <p className="text-black-50">Aucun bénéficiaire trouvé.</p>
          )}
        </div>
        <div className="col-12 col-lg-3 d-center align-items-start">
          {rbeListItem.rbeFile ? (
            <DocumentList files={rbeListItem.rbeFile} />
          ) : (
            <p className="text-black-50">Aucun fichier RBE trouvé.</p>
          )}
        </div>
      </div>
      {bottomPanelContent && (
        <div className="row bg-blue border-left border-right border-bottom rounded-bottom">
          <div className="col-12 px-0 position-relative">
            <CloseRBEBottomPanelButton />
            <BottomPanel />
          </div>
        </div>
      )}
    </StyledRBEListItem>
  );
};

const StyledRBEListItem = styled.div`
  margin-top: 40px;
`;

export default RBEListItem;
