import React from "react";
import Avatar from "../../../common/Avatar";
import ImageFileCrop from "../../../common/ImageFileCrop/ImageFileCrop";

const AvatarForm = ({ isModifyingAvatar, setModifyAvatar, userId }) => {
  return isModifyingAvatar ? (
    <ImageFileCrop setModifyAvatar={setModifyAvatar} userId={userId} />
  ) : (
    <div className="d-flex flex-column align-items-center justify-content-center mb-3 position-relative">
      <Avatar width={200} height={200} id={userId} />
      <button
        style={{
          left: "50%",
          top: "67%",
          transform: "translateX(-50%) translateY(-50%)",
          opacity: ".8",
        }}
        className="btn btn-default btn-sm mt-2 position-absolute"
        onClick={() => setModifyAvatar(true)}
      >
        <i className="fa fa-edit mr-2" />
        Modifier ma photo
      </button>
    </div>
  );
};

export default AvatarForm;
