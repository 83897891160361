import React, { useState } from "react";
import Control from "../../../../common/Controls/Control";
import ErrorMessage from "../../../../common/Controls/ErrorMessage";

const PasteLines = ({
  label,
  buttonText,
  validateLine,
  onUpdateLines,
  placeholder,
  maxLines = 0,
}) => {
  const [rawText, setRawText] = useState("");
  const [items, setItems] = useState([]);
  const [errors, setErrors] = useState([]);

  const onChangeText = (e) => {
    setRawText(e.target.value);
  };

  const onGenerateItems = () => {
    var lines = rawText.split(/\r?\n/);
    setErrors([]);
    let errs = [];
    lines = lines.filter((line) => line);
    if (lines.length == 0) {
      errs.push(["Veuillez saisir au moins une ligne"]);
    }
    lines.forEach((line) => {
      if (!validateLine(line)) {
        errs.push(`La ligne ${line} n'a pas un format valide`);
      }
    });
    if (errs.length > 0) {
      setErrors(errs);
    } else {
      if (lines.length > maxLines && maxLines > 0) {
        setErrors([`Seules les ${maxLines} premières lignes ont été importées`]);
        lines = lines.slice(0, 25);
      }
      onUpdateLines(lines);
    }
  };

  return (
    <div>
      <Control
        label={label}
        value={rawText}
        change={onChangeText}
        type="textarea"
        noMarginOnFormGroup
        noPaddingOnFormGroup
        fullWidth
        noBg
        placeholder={placeholder}
        inputStyle={{ height: 200 }}
      />
      <button className="btn btn-default border" onClick={onGenerateItems}>
        {buttonText}
      </button>
      {errors.length > 0 && errors.map((error) => <ErrorMessage message={error} />)}
    </div>
  );
};

export default PasteLines;
