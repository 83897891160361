import { useEffect } from "react";
import tools from "../../../../../../../../../../helpers/tools";
import LabelValuePair from "../../../../../../../../../common/LabelValuePair/LabelValuePair";
import Loader from "../../../../../../../../../common/Loader";
import ModifyGroupamaStatusForm from "./ModifyGroupamaStatusForm/ModifyGroupamaStatusForm";
import PolicyBottomPanel from "./PolicyBottomPanel";
import usePolicyItem from "./usePolicyItem";

const PolicyItem = ({ companyData, policyData }) => {
  const {
    POLICIES_STATUS,
    PERIODICITIES,
    productTypes,
    insuranceCompanies,
    isLoadingCompanyPolicies,
    policy,
    bottomPanels,
    bottomPanelContent,
    policyActions,
    isGroupamaProduct,
  } = usePolicyItem();

  useEffect(() => {
    policyActions.initPolicy(policyData);
    policyActions.initCompanyPolicy(companyData);
  }, [policyData]);

  return !policy ? null : (
    <div
      className={`col-12 shadow-sm bg-white py-2 mb-3 rounded policy-${policy.id}`}
    >
      <div className="row">
        <div className="col-12 col-md-5">
          <div className="d-flex flex-column align-items-start">
            <span className="badge badge-primary mr-2">
              {
                tools.findIn(productTypes, policy.product.productType, "id")
                  .name
              }
            </span>
            <div className="my-1">
              <strong>{policy.title}</strong>
              <small className="ml-2">{policy.product.name}</small>
            </div>
          </div>

          <div>
            {policy.reference}{" "}
            {policy.identification ? `- ${policy.identification}` : ""}
          </div>
          <div>
            <LabelValuePair
              label="Status"
              value={
                <span
                  className={`badge badge-${
                    policy.status == 30 ? "danger" : "default"
                  }`}
                >
                  {tools.findIn(POLICIES_STATUS, policy.status, "id").name}
                </span>
              }
            />
          </div>
          <div>
            <LabelValuePair
              label="Compagnie d'assurance"
              value={
                tools.findIn(insuranceCompanies, policy.company, "id").name
              }
            />
          </div>
          <div>
            <LabelValuePair
              label="Origine de l'affaire"
              value={policy.businessOrigin}
            />
          </div>
          <div>
            <LabelValuePair
              label="Gestionnaires"
              value={
                <>
                  {policy.producer?.company} {policy.producer2?.company}
                </>
              }
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div>
            <LabelValuePair
              label="Date de création"
              value={tools.formatDate(policy.createdAt)}
            />
          </div>
          <div>
            <LabelValuePair
              label="Date de modif"
              value={tools.formatDate(policy.updatedAt)}
            />
          </div>
          {isLoadingCompanyPolicies && <Loader />}
          {policy.amendments?.length > 0 && (
            <>
              <div>
                <LabelValuePair
                  label="Date d'effet"
                  value={tools.formatDate(policy.amendments[0].effectDate)}
                />
              </div>
              <div>
                <LabelValuePair
                  label="Échéance principale"
                  value={tools.formatDate(policy.amendments[0].mainEcheance)}
                />
              </div>
              <div>
                <LabelValuePair
                  label="Prime annuelle TTC"
                  value={`${policy.amendments[0].annualPremium || 0} €`}
                />
              </div>

              <div>
                <LabelValuePair
                  label="Honoraires"
                  value={`${policy.amendments[0].fees || 0} €`}
                />
              </div>
              <div>
                <LabelValuePair
                  label="Périodicité"
                  value={`${
                    tools.findIn(
                      PERIODICITIES,
                      policy.amendments[0].periodicity,
                      "id"
                    ).name
                  }`}
                />
              </div>
            </>
          )}
        </div>
        <div className="col-12 col-md-3">
          <div>
            <LabelValuePair
              label="Options"
              value={policy.options.map((opt) => opt.name).join(", ")}
            />
          </div>
          {isGroupamaProduct && (
            <div className="mt-2">
              <ModifyGroupamaStatusForm />
            </div>
          )}
        </div>
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between mt-3 flex-wrap">
            {bottomPanels.map((panel) => (
              <button
                data-priv={panel.dataPriv}
                className={`btn border-0  btn-light mr-1 mb-1 btn-sm d-center flex-fill ${
                  panel.content == bottomPanelContent ? "active" : ""
                }`}
                onClick={
                  panel.onClick
                    ? panel.onClick
                    : () => policyActions.openBottomPanel(panel.content)
                }
              >
                {panel.name}
              </button>
            ))}
          </div>
        </div>
        <div className="col-12">
          <PolicyBottomPanel />
        </div>
      </div>
    </div>
  );
};

export default PolicyItem;
