import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { API_URL } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import CharCounter from "../../common/CharCounter";

const TestimonialDetail = (props) => {
  const [state] = useStore();

  const [testimonial, setTestimonial] = useState({
    isVisible: false,
    comment: "",
    company: "",
    firstname: "",
    lastname: "",
    createdAt: "",
    function: "",
    image: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (props.match.params.id != "create") {
      setIsLoading(true);
      Axios.get(API_URL + "testimonials/" + props.match.params.id)
        .then((res) => {
          setTestimonial(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          toast.error(
            "Une erreur est survenue lors de la récupération des témoignages"
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, []);

  function updateData(e) {
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }
    if (name == "comment") {
      if (value.length >= 300) {
        value = value.slice(0, 300);
      }
    }
    setTestimonial({ ...testimonial, [name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      Axios.post(API_URL + "testimonials/", testimonial)
        .then((res) => {
          setTestimonial(res.data);
          setIsSaved(true);
          toast.success("Le témoignage a bien été créé");
          window.location.href = "/testimonials/" + res.data.id;
        })
        .catch((err) => {
          toast.error(
            "Une erreur est survenue lors de la création du témoignage"
          );
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    } else {
      //update
      Axios.patch(
        API_URL + "testimonials/" + props.match.params.id,
        testimonial
      )
        .then((res) => {
          setTestimonial(res.data);
          setIsSaved(true);
          toast.success("Le témoignage a bien été modifié");
        })
        .catch((err) => {
          toast.error(
            "Une erreur est survenue lors de la modification du témoignage"
          );
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    }
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader withContainer={true} />
  ) : (
    <Page
      container={"container-fluid px-5"}
      title={
        (props.match.params.id == "create" ? "Création" : "Modification") +
        " d'un témoignage"
      }
      errors={errors}
      back={"/testimonials"}
    >
      <Control
        label="Est visible ?"
        name="isVisible"
        type="checkbox"
        checked={testimonial.isVisible}
        value={testimonial.isVisible}
        change={updateData}
        error={errors}
      />
      <Control
        label="Nom"
        name="lastname"
        value={testimonial.lastname}
        change={updateData}
        error={errors}
      />
      <Control
        label="Prénom"
        name="firstname"
        value={testimonial.firstname}
        change={updateData}
        error={errors}
      />
      <Control
        label="Société"
        name="company"
        value={testimonial.company}
        change={updateData}
        error={errors}
      />
      <Control
        label="Fonction"
        name="function"
        value={testimonial.function}
        change={updateData}
        error={errors}
      />
      <Control
        label="Image"
        name="image"
        value={testimonial.image}
        change={updateData}
        error={errors}
      />
      <Control
        margin={"my-0"}
        label="Commentaire"
        type="textarea"
        name="comment"
        value={testimonial.comment}
        change={updateData}
        error={errors}
      />
      <CharCounter str={testimonial.comment} max={300} />
      <SaveBtn
        className="mt-4"
        save={save}
        isSaving={isSaving}
        isSaved={isSaved}
      />
    </Page>
  );
};

export default TestimonialDetail;
