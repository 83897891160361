import React from "react";
import tools from "../../../../helpers/tools";
import CheckList from "./CheckList";
import CompanyRecapWrapper from "./CompanyRecapWrapper";
import CompanyTopRecap from "./CompanyTopRecap";
import InputDisplay from "./InputDisplay";

const CYBERBlocks = ({ company, clientId }) => {
  return (
    <CompanyRecapWrapper>
      <CompanyTopRecap
        company={{ ...company, type: "Siège social + Établissement" }}
        product="CYBER"
      />
      <div className="mt-3">
        <CheckList
          items={[
            company.domainName && {
              text: (
                <>
                  Votre nom de domaine :{" "}
                  <InputDisplay
                    inputName="domainName"
                    inputType="text"
                    inputValue={company.domainName}
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  Fréquence de vos sauvegardes :{" "}
                  <InputDisplay
                    inputName="updateFrequency"
                    inputType="btnList"
                    inputValue={company.updateFrequency}
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  Montant de garantie annuel :{" "}
                  <InputDisplay
                    inputName="turnoverOutsourced"
                    inputType="text"
                    inputValue={
                      <>
                        {tools.truenumberWithSpaces(
                          company.garantieLimitPerYear
                        ) || 0}{" "}
                        €
                      </>
                    }
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  Franchise :{" "}
                  <InputDisplay
                    inputName="franchiseAmount"
                    inputType="text"
                    inputValue={
                      <>
                        {tools.truenumberWithSpaces(company.franchiseAmount) ||
                          0}{" "}
                        €
                      </>
                    }
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  Option Responsabilité Civile transmission de virus :{" "}
                  <InputDisplay
                    inputName="hasOptRCVirus"
                    inputType="btnList"
                    inputValue={company.hasOptRCVirus}
                  />
                </>
              ),
              isDisabled: company.hasOptRCVirus == 2,
            },
            {
              text: (
                <>
                  Option Cyber-fraude :{" "}
                  <InputDisplay
                    inputName="hasOptCyberFraud"
                    inputType="btnList"
                    inputValue={company.hasOptCyberFraud}
                  />
                </>
              ),
              isDisabled: company.hasOptCyberFraud == 2,
            },
          ].filter((m) => m)}
        />
      </div>
    </CompanyRecapWrapper>
  );
};

export default CYBERBlocks;
