import React, { useEffect, useState } from "react";
import TimelineFilters from "./TimelineFilters";
import Timeline from "./Timeline";
import Axios from "axios";
import { API_URL } from "../../../config";
import useStore from "../../../context/useStore";
import Loader from "../Loader";

const TimelineFiltered = ({
  setFiltersOpen = () => {},
  initialSearch = {
    searchString: "",
    beginAt: "",
    endAt: "",
    type: "",
    action: "",
    model: "",
    id: "",
  },
  autoSearch = false,
  showFilters = true,
  className = "",
  title = "Timeline",
  style = {},
  hideTypeFilter = false,
}) => {
  var searchTimeout = null;

  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState({
    searchString: initialSearch.searchString,
    type: initialSearch.type,
    action: initialSearch.action,
    beginAt: initialSearch.beginAt,
    endAt: initialSearch.endAt,
    type: initialSearch.type,
    model: initialSearch.model,
    id: initialSearch.id,
  });
  const [events, setEvents] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [actions, setActions] = useState([]);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (autoSearch) {
      getEvents();
    }
  }, []);

  useEffect(() => {
    if (autoSearch && isInit) {
      getEvents();
    }
  }, [search]);

  useEffect(() => {
    if (state.constants.items.EVENT_TYPES) {
      setTypes(state.constants.items.EVENT_TYPES);
      setActions(state.constants.items.EVENT_ACTIONS);
    }
  }, [state.constants.items]);

  const updateSearch = (e) => {
    var { name, value } = e.target;
    var sh = JSON.parse(JSON.stringify(search));
    sh[name] = value;
    setSearch(sh);
  };

  const getEvents = () => {
    setIsInit(true);
    if (isLoading) return false;
    setIsLoading(true);
    Axios.post(API_URL + "events/" + search.model + "/" + search.id, search)
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={className} style={{ marginBottom: 500, ...style }}>
      <h1 className="pt-0 mb-3">{title}</h1>
      <div className={showFilters ? "" : "d-none"}>
        <TimelineFilters
          search={search}
          updateSearch={updateSearch}
          setSearch={setSearch}
          errors={errors}
          types={types}
          actions={actions}
          getEvents={getEvents}
          isLoading={isLoading}
          autoSearch={autoSearch}
          setFiltersOpen={setFiltersOpen}
          hideTypeFilter={hideTypeFilter}
        />
      </div>

      {isLoading ? (
        <Loader size={30} className="mt-5" text="Chargement..." />
      ) : events && events.length == 0 ? ( // <Loader size={70} className="mt-5" text="Chargement..." />
        <p className="text-center">Aucun événement trouvé</p>
      ) : !events ? (
        ""
      ) : (
        <Timeline events={events} noSwitch />
      )}
    </div>
  );
};

export default TimelineFiltered;
