import React, { useState } from "react";

const Accordion = ({ title, type, typeColor, ...props }) => {
  const [isOpen, setIsOpen] = useState(null);

  return (
    <div
      className={`position-relative w-100  ${
        isOpen ? "bg-white" : "bg-default"
      }`}
    >
      <div
        className={` p-3 d-flex justify-content-between align-items-center w-100 flex-row shadow-sm cursor-pointer`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className=" text-left" style={{ width: "90%" }}>
          <span className={`badge badge-${typeColor}`}>{type}</span>
          {title}
        </div>
        <div className=" d-flex justify-content-end" style={{ width: "10%" }}>
          <i
            className={`fa fa-chevron-${
              isOpen ? "up" : "down"
            } fa-2x text-primary`}
          />
        </div>
      </div>
      <div
        className={`accordion-content p-2 py-2 bg-default  ${
          isOpen ? "d-block" : "d-none"
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
