import React from "react";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn";
import FiltersContainer from "../FiltersContainer/FiltersContainer";
import useStatFilters from "./useStatFilters";

const commonInputProps = {
  margin: "m-0",
  noPaddingOnFormGroup: true,
  noBg: true,
  inputStyle: { borderRadius: 5 },
};

const StatFilters = ({ statActions, isLoading }) => {
  const { statFilters, selectedShortcut, actions } = useStatFilters(statActions.refreshWithFilters);

  return (
    <FiltersContainer title="Période des compteurs">
      <div className="col-12 col-md-8">
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Entre le"
              type="date"
              value={statFilters.beginAt}
              change={actions.handleChangeStatFilters}
              name="beginAt"
              {...commonInputProps}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Et le"
              type="date"
              value={statFilters.endAt}
              change={actions.handleChangeStatFilters}
              name="endAt"
              {...commonInputProps}
            />
          </div>
          <div className="col-12 mt-3">
            <SaveBtn
              className="w-100 d-center border"
              type="default"
              save={actions.updateFiltersForever}
              isSaving={isLoading}
              text="Rechercher"
              loaderColor="primary"
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-md-4">
        <button
          className={`btn btn-sm w-100 my-1 border ${
            selectedShortcut == "1" ? "btn-primary" : "btn-default"
          }`}
          onClick={actions.updateFiltersLast30Days}
        >
          30 derniers jours
        </button>
        <button
          className={`btn btn-sm w-100 my-1 border ${
            selectedShortcut == "2" ? "btn-primary" : "btn-default"
          }`}
          onClick={actions.updateFiltersLast3Months}
        >
          3 derniers mois
        </button>

        <button
          className={`btn btn-sm w-100 my-1 border ${
            selectedShortcut == "3" ? "btn-primary" : "btn-default"
          }`}
          onClick={actions.updateFiltersForever}
        >
          Depuis toujours
        </button>
      </div>
    </FiltersContainer>
  );
};

export default StatFilters;
