import React from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { notifActions } from "../../../context/actions/notifActions";

const NotifAction = ({ action }) => {
  const [state, dispatch] = useStore();
  const nActions = notifActions(state, dispatch);

  const closeNotifs = () => {
    nActions.updateNotif({ isOpen: false });
  };

  const getActionComponent = () => {
    switch (action.type) {
      case "PROFILE_PICTURE":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to="/me?avatar"
          >
            Ajouter ma photo
          </Link>
        );

      case "EXPIRED_PAYMENT_METHOD":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/paymentmethods?clientId=${action.data}`}
          >
            Accéder aux moyens de paiement
          </Link>
        );
      case "CP_GENERATED":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/protection-juridique`}
          >
            Télécharger le document
          </Link>
        );
      case "TAGGED_IN_LEAD_COMPANY":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/leads?leadId=${action.data}`}
          >
            Accéder au lead entreprise
          </Link>
        );
      case "TAGGED_IN_LEAD_DOMI":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/leads-domi?leadId=${action.data}`}
          >
            Accéder au lead DOMI
          </Link>
        );
      case "TAGGED_IN_LEAD_INTERNAL":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/leads-internes?leadId=${action.data}`}
          >
            Accéder au lead interne
          </Link>
        );
      case "TAGGED_IN_RENEW_PJ":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/pj-renew?policyId=${action.data}`}
          >
            Accéder au renouvellement PJ
          </Link>
        );
      default:
        return <></>;
    }
  };

  return <div className="d-center pt-3 pb-2">{getActionComponent()}</div>;
};

export default NotifAction;
