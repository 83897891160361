import { atom, useAtom } from "jotai";
import dataService from "../../../../helpers/dataService";

const userCustomersAtom = atom([]);
const isLoadingUserCustomersAtom = atom(false);

const useUserCustomers = () => {
  const [userCustomers, setUserCustomers] = useAtom(userCustomersAtom);
  const [isLoadingUserCustomers, setIsLoadingUserCustomers] = useAtom(isLoadingUserCustomersAtom);

  const getUserCustomers = () => {
    setIsLoadingUserCustomers(true);

    dataService.get(
      `customers`,
      setUserCustomers,
      (err) => {},
      () => setIsLoadingUserCustomers(false)
    );
  };

  return {
    isLoadingUserCustomers,
    userCustomers,
    actions: {
      getUserCustomers,
    },
  };
};

export default useUserCustomers;
