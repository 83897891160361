import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_URL } from "./config";
import tools from "./helpers/tools";
import useRole from "./hooks/useRole/useRole";
import useStore from "./context/useStore";

const RoleStyle = ({ children }) => {
  const [state] = useStore();
  const [isLoadingRole, setIsLoadingRole] = useState(false);
  const [hiddenPriv, setHiddenPriv] = useState([]);
  const [privCss, setPrivCss] = useState("");
  const { myRole, setMyRole, setMyPrivileges, myPrivileges } = useRole();

  useEffect(() => {
    if (state.auth.isAuthenticated) getConfig();
  }, [state.auth.isAuthenticated]);

  useEffect(() => {
    if (myPrivileges.length && hiddenPriv.length) {
      generateCss();
    }
  }, [myPrivileges, hiddenPriv]);

  const getConfig = () => {
    setIsLoadingRole(true);
    Axios.get(API_URL + "roles/mine")
      .then((res) => {
        if (res.data[0]) {
          setMyPrivileges(res.data[0].privileges);
          setHiddenPriv(res.data[0].privilegesOff);
          setMyRole(res.data[0].roleKey);
        }
      })
      .catch((err) => {})
      .finally(() => setIsLoadingRole(false));
  };

  const generateCss = () => {
    var css = "";
    hiddenPriv.map((h) => {
      css += "[data-priv='" + h + "'] { display: none !important;}";
    });
    setPrivCss(css);
  };

  const getRender = () => {
    if (!isLoadingRole) {
      if (myRole.length > 0) return children;
      return null;
    }
    return children;
  };

  return (
    <>
      {state.auth.isAuthenticated ? getRender() : children}
      <style
        dangerouslySetInnerHTML={{
          __html: `
          ${privCss}
      `,
        }}
      />
    </>
  );
};

export default RoleStyle;
