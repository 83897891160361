import React, { useRef } from "react";
import styled from "styled-components";
import LabelContainer from "../LabelContainer";
import EmptyButton from "./EmptyButton";

const TextareaInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  maxLength = null,
  ...props
}) => {
  const inputRef = useRef(null);
  const valueChange = (e) => {
    let filteredValue = e.target.value;
    if (maxLength && maxLength > 0 && filteredValue.length > maxLength) {
      return false;
    }
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };
  return (
    <LabelContainer {...props} value={value}>
      <textarea
        ref={inputRef}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        style={inputStyle}
        className={"form-control " + inputClassName}
        type="text"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={value}
        onChange={valueChange}
        placeholder={placeholder}
        onBlur={props.onBlur || null}
        // onInput={
        //   props.onInput
        //     ? () => {
        //         this.parentNode.dataset.replicatedValue = this.value;
        //       }
        //     : () => {}
        // }
      />
      {!disabled && !props.noEmptyBtn && (
        <EmptyButton change={change} name={props.name} targetInput={inputRef} value={value} />
      )}
    </LabelContainer>
  );
};

const StyledGrowGap = styled.div`
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  width: 100%;
  height: 90px;

  &::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  & > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }
  & > textarea,
  &::after {
    /* Identical styling required!! */
    border: 1px solid black;
    padding: 0.5rem;
    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
`;

export default TextareaInput;
