import React from "react";
import { ClientSelectorProvider } from "./useClientSelector";
import ClientSelectorFilters from "./ClientSelectorFilters";
import ClientSelectorList from "./ClientSelectorList";

const ClientSelector = ({ onSelectClient }) => {
  return (
    <ClientSelectorProvider value={{ onSelectClient }}>
      <ClientSelectorFilters />
      <ClientSelectorList />
    </ClientSelectorProvider>
  );
};

export default ClientSelector;
