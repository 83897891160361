import React, { useState, useEffect } from "react";

const Table = ({ headers, datas, fields }) => {
  const [datasFiltered, setDataFiltered] = useState([]);

  useEffect(() => {
    setDataFiltered(datas);
  }, [datas]);

  return (
    <table className="table table-striped table-borderless shadow-sm">
      <thead>
        <tr>
          {fields.map((h, hk) => (
            <th>{h.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {datas.map((d, dk) => (
          <tr
            key={"dk" + dk}
            className="animated fadeInUp faster"
            style={{
              animationDelay: dk * 30 + "ms",
            }}
          >
            {fields.map((f, fk) => (
              <td key={"df" + dk + "" + fk}>
                {f.render ? f.render(f.path == "*" ? d : d[f.path]) : d[f.path]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
