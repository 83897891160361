import { useAtom } from "jotai";
import { atom } from "jotai";
import { DateTime } from "luxon";

export const statFiltersAtom = atom({
  beginAt: DateTime.local().minus({ days: 30 }).toISO(),
  endAt: DateTime.local().toISO(),
});
export const selectedShortcutAtom = atom("");

const useStatFilters = (refreshWithFilters = () => {}) => {
  const [statFilters, setStatFilters] = useAtom(statFiltersAtom);
  const [selectedShortcut, setSelectedShortcut] = useAtom(selectedShortcutAtom);

  const handleChangeStatFilters = (e) => {
    const { name, value } = e.target;
    setSelectedShortcut("");
    setStatFilters({ ...statFilters, [name]: value });
  };

  const updateFiltersLast30Days = () => {
    const newFilters = {
      ...statFilters,
      beginAt: DateTime.local().minus({ days: 30 }).toISO(),
      endAt: DateTime.local().toISO(),
    };
    setSelectedShortcut("1");
    refreshWithFilters(newFilters);
    setStatFilters(newFilters);
  };
  const updateFiltersLast3Months = () => {
    const newFilters = {
      ...statFilters,
      beginAt: DateTime.local().minus({ months: 3 }).toISO(),
      endAt: DateTime.local().toISO(),
    };
    setSelectedShortcut("2");
    refreshWithFilters(newFilters);
    setStatFilters(newFilters);
  };
  const updateFiltersForever = () => {
    const newFilters = {
      ...statFilters,
      beginAt: "",
      endAt: "",
    };
    setSelectedShortcut("3");
    refreshWithFilters(newFilters);
    setStatFilters(newFilters);
  };

  return {
    statFilters,
    selectedShortcut,
    actions: {
      handleChangeStatFilters,
      updateFiltersLast30Days,
      updateFiltersLast3Months,
      updateFiltersForever,
    },
  };
};

export default useStatFilters;
