import React, { useEffect } from "react";
import styled from "styled-components";
import tools from "../../../../../../../helpers/tools";
import CompanyInfo from "../../../../../../common/CompanyInfo/CompanyInfo";
import LabelValuePair from "../../../../../../common/LabelValuePair/LabelValuePair";
import LegalFormValue from "../../../../../../common/LegalFormValue/LegalFormValue";
import OpenDetailButton from "../../../../../LeadListPage/LeadList/LeadItem/OpenDetailButton/OpenDetailButton";
import useContextualTimeline from "../../useContextualTimeline/useContextualTimeline";
import BottomPanel from "./BottomPanel/BottomPanel";
import { CloseCompanyBottomPanelButton } from "./BottomPanel/CompanyBottomPanel/CompanyBottomPanel";
import CompanyPoliciesRecap from "./CompanyPoliciesRecap/CompanyPoliciesRecap";
import useCompanyItem from "./useCompanyItem";
import { useLocation } from "react-router-dom";
const queryString = require("query-string");

const CompanyItem = ({ companyData }) => {
  const { company, isDeleted, isDeleting, actions } = useCompanyItem();
  const { openTimeline } = useContextualTimeline();
  const location = useLocation();

  useEffect(() => {
    actions.initCompany(companyData);
  }, []);

  useEffect(() => {
    if (company?.id) {
      let parsed = queryString.parse(location.search, {
        arrayFormat: "bracket",
      });
      console.log("company", company);
      if (parsed?.panel && company.siret.includes(parsed.sirenOuSiret)) {
        setTimeout(() => {
          actions.openBottomPanel(parsed.panel);
        }, 1000);
      }
    }
  }, [company?.id]);
  return !company ? null : (
    <CompanyContainer
      isDeleted={isDeleted}
      className="  position-relative"
      id={`leaditem${company.id}`}
    >
      <div className="row mx-0 bg-white pt-3 pb-0 animated fadeIn">
        <div className="col-12 col-md-4">
          {company.isBlackListed === true && (
            <span className="badge badge-dark"> Blacklisté </span>
          )}
          {company.isDelisted && (
            <span className="badge badge-danger"> Radiée </span>
          )}

          <CompanyInfo
            noBadge
            company={company.company}
            siret={company.siret}
            infos={[
              `${tools.getFullAddress(company.location1)}`,
              `${company.ape || ""} - ${company.companyActivity || ""}`,

              <LabelValuePair
                label="Forme juridique"
                value={<LegalFormValue legalForm={company.legalForm} />}
              />,
              <LabelValuePair
                label="CA"
                value={`${tools.truenumberWithSpaces(company.turnover)} €`}
              />,
              <LabelValuePair
                label="Immatriculée le"
                value={tools.formatDate(company.registrationAt)}
              />,
              <LabelValuePair
                label="Effectif"
                value={
                  company.workforce === 0
                    ? "0"
                    : tools.truenumberWithSpaces(company.workforce)
                }
              />,
            ]}
          />
        </div>
        <div className="col-12 col-md-4">
          <CompanyPoliciesRecap />
        </div>
        <div className="col-12 col-md-4 text-right">
          <OpenDetailButton
            className="pt-2"
            onClick={() => actions.openBottomPanel("COMPANY")}
            title={<>Informations détaillées</>}
          />
          <OpenDetailButton
            dataPriv="bo_viewcompanydocs"
            className="pt-2"
            onClick={() => actions.openBottomPanel("DOCUMENTS")}
            title={<>Documents</>}
          />
          {company.policies?.length > 0 && (
            <OpenDetailButton
              className="pt-2"
              onClick={() => actions.openBottomPanel("POLICIES")}
              title={<>Contrats</>}
            />
          )}
          <OpenDetailButton
            dataPriv="bo_viewcontractdocs"
            className="pt-2"
            onClick={() => actions.openBottomPanel("BILLS")}
            title={<>Factures</>}
          />
          <OpenDetailButton
            className="pt-2"
            onClick={() => actions.openBottomPanel("USERS")}
            title={<>Utilisateurs</>}
          />
          <OpenDetailButton
            className="pt-2"
            onClick={() =>
              openTimeline({
                model: "clients",
                id: company.id,
                searchString: "",
                type: "",
              })
            }
            title={<>Evénements de l'entreprise</>}
          />
          <OpenDetailButton
            className="pt-2 text-danger"
            onClick={actions.onRemoveCompany}
            dataPriv="bo_deleteclient"
            title={
              <span className="text-danger">
                {isDeleting
                  ? "suppression en cours"
                  : "Supprimer cette société"}
              </span>
            }
            icon="fa-times"
          />
        </div>
        <div className="col-12 mt-5 position-relative ">
          <CloseCompanyBottomPanelButton />
          <BottomPanel />
        </div>
      </div>
    </CompanyContainer>
  );
};

const CompanyContainer = styled.div`
  filter: ${({ isDeleted }) => (isDeleted ? "grayscale(1)" : "none")};
  /* border-bottom: 10px solid #ebecf7; */

  &::after {
    display: ${({ isDeleted }) => (isDeleted ? "flex" : "none")};
    content: "supprimée";
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    z-index: 99;
    background: rgba(0, 0, 0, 0.32);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
  }
`;

export default CompanyItem;
