import RichInputEditor from "../../RichInputEditor/RichInputEditor";

const AddCommentForm = ({ addComment, users = [] }) => {
  const onSubmit = (commentData) => {
    addComment(commentData.comment);
  };

  return <RichInputEditor onSubmit={onSubmit} users={users} />;
};

export default AddCommentForm;
