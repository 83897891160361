import React from "react";
import Control from "./Control";

const SimpleDynamicList = ({
  listLabel,
  uniqueKey,
  element,
  fields,
  items,
  btnLabel,
  change,
  className = "",
  maxItems = 1000,
}) => {
  function addItem() {
    change({ target: { name: element, value: [...items, ""] } });
  }

  function changeItem(e, k) {
    change({
      target: { name: element, value: items.map((i, ik) => (ik == k ? e.target.value : i)) },
    });
  }
  function removeItem(kk) {
    change({ target: { name: element, value: items.filter((i, k) => k !== kk) } });
  }

  return (
    <div className={`form-group ${className}`}>
      <label className="mb-3">{listLabel}</label>
      <ul className="list-group list-unstyled">
        {items.map((it, k) => {
          console.log("item", it);
          return (
            <li key={"dyn" + element + uniqueKey + "_" + k}>
              <div className="d-flex align-items-center ">
                {fields.map((fi, kk) => (
                  <Control
                    className={"mr-0 "}
                    k={k}
                    labelClassname="mb-0 mt-0 mr-2 text-black-50"
                    inputStyle={
                      kk == fields.length - 1
                        ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                        : {}
                    }
                    key={"dynlistinp" + kk}
                    label={`${parseInt(k) + 1} - ${fi.label}`}
                    name={fi.name}
                    type={fi.type}
                    value={it}
                    datas={fi.datas}
                    change={changeItem}
                    suffix={fi.suffix ? fi.suffix : null}
                    margin={fi.margin ? fi.margin : ""}
                  />
                ))}
                <div
                  style={{
                    background: "white",
                    height: 40,
                  }}
                  className="d-flex align-items-center px-2"
                >
                  <button
                    type="button"
                    className="btn btn-default btn-sm shadow-none"
                    onClick={() => removeItem(k)}
                  >
                    <i className="fa fa-times text-danger" />
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {btnLabel && maxItems > 0 && items.length < maxItems && (
        <button className="btn btn-default btn-sm" onClick={addItem}>
          <i className="fa fa-plus mr-2" />
          {btnLabel}
        </button>
      )}
    </div>
  );
};

export default SimpleDynamicList;
