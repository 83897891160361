import dataService from "../helpers/dataService";

export const postTask = (taskData) => {
  return new Promise((resolve, reject) => {
    dataService.post(`tasks/tasks`, taskData, resolve, reject);
  });
};
export const patchTask = (taskId, taskData) => {
  return new Promise((resolve, reject) => {
    dataService.patch(`tasks/tasks/${taskId}`, taskData, resolve, reject);
  });
};
export const patchTasksOrder = (tasksId, tasksData) => {
  return new Promise((resolve, reject) => {
    dataService.patch(`tasks/${tasksId}/order`, tasksData, resolve, reject);
  });
};
export const deleteTask = (taskId) => {
  return new Promise((resolve, reject) => {
    dataService.remove(`tasks/tasks/${taskId}`, {}, resolve, reject);
  });
};
