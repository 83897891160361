import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import NotifContainer from "../Notifications/NotifContainer";
import "./notifications.scss";
import { notifActions } from "../../../context/actions/notifActions";
import dataService from "../../../helpers/dataService";
import useStore from "../../../context/useStore";

let notifTimeout = null;

const Notifications = () => {
  const [state, dispatch] = useStore();
  const nActions = notifActions(state, dispatch);
  const [notifications, setNotifications] = useState([]);
  const [notifOpen, setNotifOpen] = useState(false);

  const getNofifications = () => {
    clearTimeout(notifTimeout);
    dataService.get(`notifications/mines`, setNotifications);
    notifTimeout = setTimeout(() => {
      getNofifications();
    }, 60000);
  };

  useEffect(() => {
    getNofifications();
  }, []);

  // useEffect(() => {
  //   setNotifOpen(state.notif.isOpen);
  // }, [state.notif.isOpen]);

  // useEffect(() => {
  //   if (notifOpen) {
  //     openNotif();
  //   } else {
  //     nActions.updateNotif({
  //       isOpen: false,
  //     });
  //   }
  // }, [notifOpen]);

  const updateReadStatus = (isRead, id) => {
    setNotifications(
      notifications.map((n) => {
        if (n.id == id) {
          n.readAt = isRead ? DateTime.local().toISO() : false;
        }
        return n;
      })
    );
  };

  const openNotif = () => {
    nActions.updateNotif({
      isOpen: true,
      content: (
        <>
          {/* <button
            className="btn btn-link pl-0"
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: "black",
            }}
          >
            Tout marquer comme lu
          </button> */}
          <NotifContainer
            notifications={notifications}
            updateReadStatus={updateReadStatus}
          />
        </>
      ),
    });
  };

  const unreadNotifNb = notifications.filter((n) => !n.readAt).length;

  return (
    <>
      <div
        onClick={openNotif}
        className={` d-center notif-arrow ${notifOpen ? "" : "notif-closed"}`}
        style={{ marginRight: 5, cursor: "pointer" }}
      >
        <i
          className="fa fa-bell text-primary"
          style={{
            fontSize: 20,
          }}
        />

        {unreadNotifNb > 0 ? (
          <div
            className=" text-white d-center position-absolute animated bounceIn faster"
            style={{
              background: "#FF7171",
              width: 15,
              height: 15,
              borderRadius: "50%",
              fontSize: 10,
              top: 8,
              right: -6,
            }}
          >
            {unreadNotifNb}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Notifications;

//délcarer un ligitge par email
//accès digidroit
