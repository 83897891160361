import { DateTime } from "luxon";
import React from "react";
import styled from "styled-components";

const ExpireBadge = ({ expireDate }) => {
  const isExpired =
    DateTime.fromISO(expireDate).plus({ day: 1 }) <= DateTime.local();
  const nbDays = Math.ceil(
    Math.abs(
      DateTime.local()
        .diff(DateTime.fromISO(expireDate).plus({ day: 1 }), "days")
        .toObject().days
    )
  );

  const text = isExpired
    ? `Échu depuis ${nbDays} jours`
    : `Échéance dans ${nbDays} jours`;

  return (
    <StyledExpireBadge className="font-weight-bold" isExpired={isExpired}>
      {text}
    </StyledExpireBadge>
  );
};

const StyledExpireBadge = styled.div`
  padding: 4px 8px 4px 0px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export default ExpireBadge;
