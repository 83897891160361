import React from "react";
import colors from "../../../../helpers/colors";
import Loader from "../../../common/Loader";

const DownloadButton = ({ text, image, disabled = false, downloadFile, isLoading, timer }) => {
  return (
    <button
      className="btn py-2 px-3 bg-white text-primary d-center flex-row my-3 shadow-small mx-2"
      onClick={downloadFile}
      disabled={disabled || timer > 0}
      style={{ borderRadius: 10 }}
    >
      <img className="pt-1 mr-2" src={`/images/${image}`} />
      <strong className={timer > 0 ? "mr-1" : ""} style={{ fontWeight: "bold", fontSize: 16 }}>
        {isLoading ? (
          <Loader color={colors.primary} size={38} />
        ) : timer > 0 ? (
          "Génération..."
        ) : (
          text
        )}
      </strong>
    </button>
  );
};

export default DownloadButton;
