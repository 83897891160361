import React, { useState, useEffect } from "react";
import Control from "../Controls/Control";

const CheckboxNumber = ({
  isChecked,
  setIsChecked,
  nbValue,
  setNbValue,
  checkboxLabel,
  checkboxName,
  numberLabel,
  numberName,
  error,
  onBlur,
  checkboxInfo,
  numberInfo,
  inputParams = {
    min: 0,
    max: 100000,
    step: 1000,
  },
  trueValue,
  defaultValue = null,
}) => {
  const [hasValue, setHasValue] = useState(false);

  const numberChange = (e) => {
    console.log(e);
    setNbValue(e);
  };

  const checkedChange = (e) => {
    setIsChecked(e);
  };

  useEffect(() => {
    if (!isChecked && nbValue) {
      setNbValue({
        target: {
          name: numberName,
          value: "",
        },
      });
    }
    setHasValue(isChecked && nbValue);
  }, [isChecked]);

  useEffect(() => {
    setHasValue(isChecked && nbValue);
  }, [nbValue]);

  useEffect(() => {
    if ((nbValue > inputParams.max || nbValue < inputParams.min) && defaultValue) {
      setNbValue({
        target: {
          name: numberName,
          value: defaultValue,
        },
      });
    }
  }, [inputParams, defaultValue]);

  useEffect(() => {
    if (isChecked && !trueValue && defaultValue) {
      setNbValue({
        target: {
          name: numberName,
          value: defaultValue,
        },
      });
    }
  }, [isChecked, defaultValue]);

  return (
    <>
      <div
        className={`form-group mb-4 p-3 ${
          hasValue ? "bg-blue has-value" : "bg-default"
        } rounded shadow-small d-flex flex-column align-items-start w-100`}
      >
        <Control
          type="checkbox"
          label={checkboxLabel}
          name={checkboxName}
          change={checkedChange}
          checked={isChecked}
          noBg
          noFormGroup
          noPaddingOnFormGroup
          noMarginOnFormGroup
          inputStyle={{ height: "auto" }}
          className="w-100"
          onBlur={onBlur}
          info={checkboxInfo}
        />
        {isChecked && (
          <Control
            type="range"
            label={numberLabel}
            name={numberName}
            inputParams={inputParams}
            change={numberChange}
            value={nbValue}
            noBg
            noFormGroup
            noPaddingOnFormGroup
            noMarginOnFormGroup
            center
            labelClassname="w-100"
            className="w-100"
            after={<span className="pl-1">€</span>}
            error={error}
            onBlur={onBlur}
            info={numberInfo}
            fullWidth
          />
        )}
      </div>
    </>
  );
};

export default CheckboxNumber;
