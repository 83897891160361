import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tools from "../../../helpers/tools";
import Badge from "../Badge/Badge";

const CompanyInfo = ({
  isHeadOffice,
  noBadge,
  company,
  siret,
  infos = [],
  showCompanyLink = true,
}) => {
  return (
    <StyledCompanyInfo>
      {!noBadge && (
        <Badge color="default">
          {isHeadOffice ? "Siège" : "Établissement"}
        </Badge>
      )}
      <div className="info__company  d-flex align-items-start justify-content-start flex-row">
        {showCompanyLink && (
          <Link
            className="pt-1 mr-1 company__link"
            title="Ouvrir la société dans un nouvel onglet"
            to={`/client-list?sirenOuSiret=${siret}`}
            target="_blank"
          >
            <i className="fa fa-external-link" />
          </Link>
        )}
        {company}
      </div>
      <div className="info__siret" data-value={siret}>
        {tools.formatFollowingPattern(siret, [3, 3, 3, 5], " ")}
      </div>
      {infos.map((info, ik) => (
        <div
          className="info__item"
          data-value={typeof info == "string" ? info : ""}
          key={`inf${ik}`}
          title={typeof info == "string" ? info : ""}
        >
          {info}
        </div>
      ))}
    </StyledCompanyInfo>
  );
};

const StyledCompanyInfo = styled.div`
  & .company__link {
    font-size: 12px;
  }
  & .info__company {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 600;
  }
  & .info__siret {
    font-size: 12px;
    color: var(--color-default-dark);
  }

  & .info__item {
    font-size: 12px;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export default CompanyInfo;
