import React from "react";
import Control from "../../../../common/Control";
import SaveBtn from "../../../../common/SaveBtn";

const SearchInput = ({ search, updateSearch, searchLoading, searchCompany }) => {
  return (
    <div className="row bg-blue border p-3 rounded mb-3 shadow-sm">
      <div className="col-12">
        <Control
          label="Rechercher une société"
          name="search"
          value={search.search}
          change={updateSearch}
        />
      </div>
      <div className="col-12 d-flex justify-center">
        <SaveBtn
          save={searchCompany}
          isSaving={searchLoading}
          className="w-100 d-center"
          text="Rechercher"
        />
      </div>
    </div>
  );
};

export default SearchInput;
