import React, { useContext } from "react";
import styled from "styled-components";
import CommentCreatedBy from "../../common/CommentCreatedBy/CommentCreatedBy";
import CommentNameView from "../../common/CommentNameView/CommentNameView";
import { CommentContext } from "../../context/CommentContext";
import useCommentList from "../../useCommentList";

const ActiveComment = () => {
  const { activeCommentId, comments } = useCommentList();
  const { actions } = useContext(CommentContext);

  const activeComment = comments.find((t) => t.id == activeCommentId);

  return activeComment ? (
    <StyledActiveComment className="row ">
      {/* <div className="col-2 px-0 d-center">
        <CommentStatus comment={activeComment} updateComment={actions.updateComment} />
      </div> */}
      <div className="col-12">
        <CommentNameView comment={activeComment} />
      </div>
      <div className="col-12">
        <CommentCreatedBy comment={activeComment} />
      </div>
    </StyledActiveComment>
  ) : null;
};

const StyledActiveComment = styled.div``;

export default ActiveComment;
