import { atom, useAtom } from "jotai";
import { useContext } from "react";
import dataService from "../../../../helpers/dataService";
import { getCompanyFiles } from "../../../../services/fileService";
import { RBEListContext } from "../RBEListContext";

const rbeListItemAtom = atom(null);
const bottomPanelContentAtom = atom(null);
const isSearchingFilesAtom = atom(null);
const isFilesLoadedAtom = atom(false);
const isCheckingRBEAtom = atom(false);

const useRBEListItem = () => {
  const [rbeListItem, setRbeListItem] = useAtom(rbeListItemAtom);
  const [bottomPanelContent, setBottomPanelContent] = useAtom(bottomPanelContentAtom);
  const [isSearchingFiles, setIsSearchingFiles] = useAtom(isSearchingFilesAtom);
  const [isFilesLoaded, setIsFilesLoaded] = useAtom(isFilesLoadedAtom);
  const [isCheckingRBE, setIsCheckingRBE] = useAtom(isCheckingRBEAtom);
  const RBEListActions = useContext(RBEListContext);

  const initItem = (data) => {
    setRbeListItem({ ...data, files: [] });
  };

  const openInfos = async () => {
    const isAlreadyOpen = bottomPanelContent == "INFOS";
    setBottomPanelContent(isAlreadyOpen ? null : "INFOS");
    scrollItemToTop();

    if (isFilesLoaded) return;
    setIsSearchingFiles(true);
    let companyFiles = await getCompanyFiles(rbeListItem.client.id);

    const newLead = { ...rbeListItem, files: [...rbeListItem.files, ...companyFiles] };
    setIsFilesLoaded(true);
    setIsSearchingFiles(false);
    setRbeListItem(newLead);
  };

  // const getCompanyFiles = async () => {
  //   return new Promise((resolve, reject) => {
  //     dataService.get(
  //       `clients/${rbeListItem.client.id}/files`,
  //       (data) => {
  //         resolve(data);
  //       },
  //       (err) => {}
  //     );
  //   });
  // };

  const closeBottomPanel = () => {
    setBottomPanelContent(false);
  };

  const scrollItemToTop = () => {
    const rbeListItemElem = document.querySelector(`#rbeListItem${rbeListItem.id}`);
    rbeListItemElem.scrollIntoView({ behavior: "smooth" });
  };

  const checkRBEDocById = (rbeId) => {
    if (isCheckingRBE) return;
    setIsCheckingRBE(rbeId);
    dataService.patch(
      `beneficiaries/client/${rbeId}`,
      {},
      (data) => {
        RBEListActions.getRbeList();
      },
      (err) => {},
      () => setIsCheckingRBE(false)
    );
  };

  return {
    rbeListItem,
    isSearchingFiles,
    isFilesLoaded,
    bottomPanelContent,
    isCheckingRBE,
    actions: {
      initItem,
      openInfos,
      closeBottomPanel,
      checkRBEDocById,
    },
  };
};

export default useRBEListItem;
