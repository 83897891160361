import React, { useState } from 'react';
import Page from '../../common/layout/Page';
import useStore from '../../../context/useStore';
import Axios from 'axios';
import { API_URL } from '../../../config';
import Loader from '../../common/Loader';
import Control from '../../common/Control';
import FileCategoryFilter from './FileCategoryFilter';
import Pagination from '../../common/Pagination';
import Table from '../../common/Table';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';



const FileCategoryList = () => {

    const [categories, setCategories] = useState([]);
    const [state, dispatch] = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [search, setSearch] = useState({
        fileCategory: { searchString: "" },
        page: 1,
    });
    const [isSearching, setIsSearching] = useState(false)
    const [nbPages, setNbPages] = useState(1);
    const [nbResults, setNbResults] = useState(0);

    function searchCategories() {
        setErrors(false);
        setIsLoading(true)
        Axios.post(API_URL + "filecategories/search", search)
            .then((res) => {
                setCategories(res.data.fileCategories);
                setNbResults(res.data.count)
                setNbPages(Math.ceil(parseInt(res.data.count) / 25));
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setErrors(err.response.data);
                }
                setCategories([]);
            })
            .then(() => {
                setIsLoading(false);
            });
    }
    function changePage(i) {
        setSearch((search) => {
            return { ...search, page: i };
        });
    }
    function updateSearch(e) {
        var { name, value } = e.target;
        var su = { ...search };
        if (value == "") {
            delete su.fileCategory[name];
        } else {
            su.fileCategory[name] = value;
        }

        setSearch(su);
    }
    function removeCategory(id) {
        if (!window.confirm("Confirmez-vous la suppression de cette catégorie ?")) return false
        setIsLoading(true);
        Axios.delete(API_URL + "filecategories/" + id)
            .then((res) => {
                searchCategories();
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    setErrors(err.response.data);
                    toast.error("Une erreur est survenue lors de la suppression");
                }
            })
            .then(() => {
                setIsLoading(false);
            });
    }

    return (
        <Page
            container="container-fluid"
            title={"Liste des catégories de document"}
            errors={errors}
            action={{ to: "/document-categories/create", text: "Créer une catégorie" }}
        >
            <FileCategoryFilter
                search={search} categories={categories}
                updateSearch={updateSearch}
                startSearch={searchCategories}
                nbResults={nbResults}
                isLoading={isLoading}
            />
            <div className="row">
                <div className="col-12 mt-3">
                    {errors && errors.other ? (
                        <div className="text-danger m-auto text-center">{errors.other}</div>
                    ) : isLoading ? (
                        <Loader withContainer={true} />
                    ) : (
                                <Table
                                    datas={categories}
                                    fields={[
                                        {
                                            name: "Nom",
                                            path: "name"
                                        },
                                        {
                                            name: "Modifier",
                                            path: "id",
                                            render: (elem) => (
                                                <Link
                                                    to={"/document-categories/" + elem}
                                                    style={{ minWidth: 0 }}
                                                    className="btn btn-sm w-100 btn-default d-flex align-items-center justify-content-center"
                                                >
                                                    <i className="fa fa-cog" />
                                                </Link>
                                            ),
                                        },
                                        {
                                            name: "Supprimer",
                                            path: "id",
                                            render: (elem) => (
                                                <button
                                                    onClick={() => removeCategory(elem)}
                                                    style={{ minWidth: 0 }}
                                                    className="btn btn-sm w-100 btn-danger d-flex align-items-center justify-content-center"
                                                >
                                                    <i className="fa fa-times" />
                                                </button>
                                            ),
                                        },
                                    ]}
                                />
                            )

                    }
                </div>
            </div>

            <Pagination
                nbPages={nbPages}
                page={search.page}
                changePage={changePage}
            />

        </Page>
    );
}

export default FileCategoryList;