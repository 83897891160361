import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";

const useFAQ = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [isSavingFaq, setIsSavingFaq] = useState(false);
  const [isSavingSection, setIsSavingSection] = useState(false);
  const [isCreatingSection, setIsCreatingSection] = useState(false);
  const [isCreatingFaq, setIsCreatingFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const addFaq = (newFaq) => {
    setFaqs([...faqs, ...newFaq]);
  };

  useEffect(() => {
    getSections();
    getFaqs();
  }, []);

  const getSections = () => {
    dataService.get(`faqsections`, setSections);
  };
  const getFaqs = () => {
    dataService.get(`faqs`, setFaqs);
  };

  const updateSection = (newSection, sectionId) => {
    let newSections = [...sections];
    newSections = newSections.map((section) => {
      if (section.id == sectionId) {
        return newSection;
      }
      return section;
    });
    setSections(newSections);
  };

  const updateFaq = (newFaq, faqId) => {
    let newFaqs = [...faqs];
    newFaqs = newFaqs.map((faq, faqk) => {
      if (faq.id == faqId && faq.faqSection == newFaq.faqSection) {
        return newFaq;
      }
      return faq;
    });
    setFaqs(newFaqs);
  };

  const saveSection = (section) => {
    setIsSavingSection(section.id);
    console.log("saving section", section);
    dataService.patch(
      `faqsections/${section.id}`,
      section,
      (datas) => {},
      (err) => {},
      () => setIsSavingSection(false)
    );
  };

  const saveFaq = (faq) => {
    setIsSavingFaq(faq.id);
    console.log("saving faq", faq);
    dataService.patch(
      `faqs/${faq.id}`,
      faq,
      (datas) => {
        // var newFaqs = faqs.map((fa) => (fa.id == datas.id ? datas : fa));
        // console.log("newFaqs", newFaqs, datas);
        // setFaqs(newFaqs);
      },
      (err) => {},
      () => setIsSavingFaq(false)
    );
  };

  const createSection = (section) => {
    setIsCreatingSection(true);
    console.log("creating section", section);
    dataService.post(
      `faqsections`,
      section,
      (datas) => {
        setSections([...sections, datas]);
      },
      (err) => {},
      () => setIsCreatingSection(false)
    );
  };

  const createFaq = (faq) => {
    setIsCreatingFaq(true);
    console.log("creating faq", faq);
    dataService.post(
      `faqs`,
      faq,
      (datas) => {
        setFaqs([...faqs, datas]);
      },
      (err) => {},
      () => setIsCreatingFaq(false)
    );
  };

  const sectionGoUp = (section) => {
    var newSections = [...sections];
    let sectionstoSave = [];

    newSections = newSections.map((sec) => {
      if (sec.order == section.order - 1) {
        sectionstoSave.push({ ...sec, order: section.order });
        return { ...sec, order: section.order };
      }
      if (sec.order == section.order) {
        sectionstoSave.push({ ...sec, order: section.order - 1 });
        return { ...sec, order: section.order - 1 };
      }
      return sec;
    });

    setSections(newSections);
    saveSectionsArray(sectionstoSave);
  };
  const sectionGoDown = (section) => {
    var newSections = [...sections];
    let sectionstoSave = [];
    newSections = newSections.map((sec) => {
      if (sec.order == section.order + 1) {
        sectionstoSave.push({ ...sec, order: section.order });
        return { ...sec, order: section.order };
      }
      if (sec.order == section.order) {
        sectionstoSave.push({ ...sec, order: section.order + 1 });
        return { ...sec, order: section.order + 1 };
      }
      return sec;
    });

    setSections(newSections);
    saveSectionsArray(sectionstoSave);
  };

  const saveSectionsArray = (secsArr) => {
    secsArr.forEach((sec) => {
      saveSection(sec);
    });
  };

  const faqGoUp = (faq) => {
    var newFaqs = [...faqs];
    let faqsToSave = [];
    newFaqs = newFaqs.map((sec) => {
      if (sec.faqSection == faq.faqSection) {
        if (sec.order == faq.order - 1) {
          faqsToSave.push({ ...sec, order: faq.order });
          return { ...sec, order: faq.order };
        }
        if (sec.order == faq.order) {
          faqsToSave.push({ ...sec, order: faq.order - 1 });
          return { ...sec, order: faq.order - 1 };
        }
      }
      return sec;
    });
    setFaqs(newFaqs);
    saveFaqsArray(faqsToSave);
  };
  const faqGoDown = (faq) => {
    var newFaqs = [...faqs];
    let faqsToSave = [];

    newFaqs = newFaqs.map((sec) => {
      if (sec.faqSection == faq.faqSection) {
        if (sec.order == faq.order + 1) {
          faqsToSave.push({ ...sec, order: faq.order });

          return { ...sec, order: faq.order };
        }
        if (sec.order == faq.order) {
          faqsToSave.push({ ...sec, order: faq.order + 1 });
          return { ...sec, order: faq.order + 1 };
        }
      }
      return sec;
    });

    setFaqs(newFaqs);
    saveFaqsArray(faqsToSave);
  };

  const saveFaqsArray = (faqsArr) => {
    faqsArr.forEach((fa) => {
      saveFaq(fa);
    });
  };

  return {
    state: {
      isLoading,
      isSavingFaq,
      isSavingSection,
      sections,
      faqs,
      isCreatingSection,
      isCreatingFaq,
    },
    actions: {
      setIsLoading,
      setSections,
      setFaqs,
      addFaq,
      updateSection,
      updateFaq,
      saveFaq,
      saveSection,
      createSection,
      createFaq,
      sectionGoUp,
      sectionGoDown,
      faqGoUp,
      faqGoDown,
    },
  };
};

export default useFAQ;
