import { useAtom } from "jotai";
import { useEffect } from "react";
import useStore from "../../context/useStore";
import { tasksAtom, tasksByProductAtom } from "./tasksAtoms";

const useTasks = () => {
  const [tasks, setTasks] = useAtom(tasksAtom);
  const [tasksByProduct] = useAtom(tasksByProductAtom);

  const [state] = useStore();
  const { LEAD_TASKS } = state.constants.items;
  useEffect(() => {
    setTasks(LEAD_TASKS);
  }, []);

  return {
    tasks,
    tasksByProduct,
  };
};

export default useTasks;
