import React, { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../../../../hooks/useClickOutside/useClickOutside";
import useLeadItem from "../useLeadItem";
import StatusIcon from "./StatusIcon";
import StatusName from "./StatusName";
import StatusSelector from "./StatusSelector";
import ViewCancelReason from "./ViewCancelReason";

const LeadStatus = () => {
  const { lead } = useLeadItem();
  const ref = useRef();
  const [statusSelectorOpen, setStatusSelectorOpen] = useState(false);

  const toggleStatusSelector = () => setStatusSelectorOpen(!statusSelectorOpen);

  useOnClickOutside(ref, () => setStatusSelectorOpen(false));

  return (
    <div className="d-center justify-content-start flex-column position-relative" ref={ref}>
      <StatusIcon status={lead.status} onClick={toggleStatusSelector} />
      <StatusName status={lead.status} />
      {lead.status == "CANCEL" && (
        <ViewCancelReason cancelReason={lead.cancelReason} cancelText={lead.cancelText} />
      )}
      {statusSelectorOpen && <StatusSelector onUpdateStatus={toggleStatusSelector} />}
    </div>
  );
};

export default LeadStatus;
