import { useEffect } from "react";
import Loader from "../../../../../../../../../../common/Loader";
import BPDocumentItem from "../../../BPDocuments/BPDocumentItem/BPDocumentItem";
import usePolicyItem from "../usePolicyItem";
import RegenerateCP from "./RegenerateCP";
import useBPPolicyDocuments from "./useBPPolicyDocuments";

const BPPolicyDocuments = ({ docType }) => {
  const { policy, companyPolicy } = usePolicyItem();
  const { isLoadingPolicyDocuments, policyDocuments, policyDocumentsActions } =
    useBPPolicyDocuments();

  useEffect(() => {
    if (policyDocuments.length == 0) {
      policyDocumentsActions.onGetPolicyDocuments(policy.id);
    }
  }, []);

  console.log("DOC META", { productName: policy.title, companyName: companyPolicy?.company || "" });

  const isGozen = ["504901", "504901+", "504901 BTP", "504901+ BTP"].includes(policy.product?.name);

  return (
    <div className="row mt-3 bg-body">
      {isLoadingPolicyDocuments && <Loader withContainer />}
      {isGozen && (
        <div className="col-12" data-priv="bo_viewparameters">
          <RegenerateCP />
        </div>
      )}
      {policyDocuments.map((document) => {
        return (
          <div
            className="col-12 col-md-6 col-lg-4 p-2"
            key={`poldocgroup${document.map((d) => d.fileType.name).join("-")}`}
          >
            <BPDocumentItem
              nbDocs={document.length}
              doc={document}
              docMeta={{ productName: policy.title, companyName: companyPolicy?.company || "" }}
              docType={docType}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BPPolicyDocuments;
