import React, { useState, useEffect } from "react";
import Control from "../../common/Controls/Control";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";
import {
  commonControlProps,
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../common/Controls/filterContainer";
import SaveBtn from "../../common/SaveBtn";

const ClientListFilters = ({
  search,
  updateClientSearch,
  errors,
  nbResults,
  startSearch,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState("Rechercher");

  console.log("search", search.client);
  useEffect(() => {
    setSearchText("Rechercher");
  }, [search]);

  useEffect(() => {
    if (nbResults > 0) setSearchText(nbResults + " résultats trouvés");
  }, [nbResults]);

  return (
    <div className={filterContainerClassName}>
      <input
        autocomplete="false"
        name="hidden"
        type="text"
        style={{ display: "none" }}
      ></input>

      <div className="col-12 ">
        <Control
          label="Donnée manquante (obligatoire)"
          btnInline
          name="unimportableList"
          type="btnList"
          datas={[
            { id: "email", name: "Email" },
            { id: "company", name: "Société" },
            { id: "phone", name: "Mobile" },
            { id: "lastname", name: "Nom" },
            { id: "firstname", name: "Prénom" },
          ]}
          value={search.client.unimportableList}
          change={updateClientSearch}
          {...commonControlProps}
        />
      </div>

      <div className="col-12 col-md-6">
        <Control
          label="Entre le"
          value={tools.getDateFrom(search.client.beginAt)}
          change={(e) =>
            updateClientSearch({
              target: {
                name: "beginAt",
                value: tools.getDateFrom(e.target.value),
              },
            })
          }
          type="date"
          error={errors}
          name="beginAt"
          {...commonControlProps}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="et le"
          value={DateTime.fromISO(search.client.endAt).toFormat("yyyy-MM-dd")}
          change={(e) =>
            updateClientSearch({
              target: {
                name: "endAt",
                value: tools.getDateFrom(e.target.value),
              },
            })
          }
          type="date"
          error={errors}
          name="endAt"
          {...commonControlProps}
        />
      </div>

      <div className={searchButtonContainerClassName}>
        <SaveBtn
          className={searchButtonClassName}
          typeBtn="button"
          save={startSearch}
          text={searchText}
          isSaving={isLoading}
          type={searchButtonType}
          loaderColor={searchButtonLoaderColor}
        />
      </div>
    </div>
  );
};

export default ClientListFilters;
