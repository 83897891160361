import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import Accordion from "../../common/Accordion";
import useStore from "../../../context/useStore";
import SaveBtn from "../../common/SaveBtn";
import Axios from "axios";
import { API_URL } from "../../../config";
import { DateTime } from "luxon";
import DropFile from "../../common/DropFile";
import DocumentsDisplay from "./DocumentsDisplay";
import Loader from "../../common/Loader";
import ModalBtn from "../../common/ModalBtn";
import Modal from "../../common/Modal";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

const TabClient = ({
  client,
  openClient,
  documents,
  documentUploaded,
  documentRemoved,
  uploadNewFile,
  cancelNewVersion,
  isDocsLoading,
  isBOA,
  closeOverlay,
}) => {
  const [state, dispatch] = useStore();
  const [isImportingModulr, setIsImportingModulr] = useState(false);
  const [isImportedModulr, setIsImportedModulr] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [selectedUserMigration, setSelectedUserMigration] = useState("");
  const [isMigrating, setIsMigrating] = useState(false);

  const constantData = state.constants.items;

  function importModulr() {
    setIsImportingModulr(true);
    Axios.get(API_URL + "modulr/clients/" + client.id)
      .then((res) => {
        setIsImportedModulr(true);
        openClient();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsImportingModulr(false);
      });
  }

  function getUsers() {
    setIsLoadingUsers(true);
    Axios.get(API_URL + "customers")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoadingUsers(false);
      });
  }

  function migrateClient() {
    setIsMigrating(true);
    Axios.patch(API_URL + "clients/" + client.id + "/migration", {
      user: selectedUserMigration,
    })
      .then((res) => {
        window.$("#migrateClientModal").modal("hide");
        closeOverlay(true);
        toast.success("La société a bien été migrée");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsMigrating(false);
      });
  }

  function updateData(e) {
    setSelectedUserMigration(e.target.value);
  }

  return (
    <div className="px-3">
      <div className="mx-auto" style={{ maxWidth: 900 }}>
        <div>
          <div className="row">
            <div className="col-12 col-md-6">
              <h4>Identité</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Type de client"
                className="readonly"
                value={
                  constantData.CLIENT_TYPES
                    ? constantData.CLIENT_TYPES.find((c) => c.id == client.type)
                      ? constantData.CLIENT_TYPES.find(
                          (c) => c.id == client.type
                        ).name
                      : ""
                    : ""
                }
              />

              <Control
                label="Référence"
                className="readonly"
                value={client.reference}
              />
              <Control
                label="Société"
                className="readonly"
                value={client.company}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Civilité"
                type="select"
                className="readonly"
                datas={[
                  { id: 1, name: "Monsieur" },
                  { id: 2, name: "Madame" },
                ]}
                value={client.gender}
              />
              <Control
                label="Nom du dirigeant"
                className="readonly"
                value={client.lastname}
              />
              <Control
                label="Prénom du dirigeant"
                className="readonly"
                value={client.firstname}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="mt-3">Informations de la société</h4>
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Raison sociale"
                className="readonly"
                value={client.businessName}
              />

              <Control
                label="SIRET"
                className="readonly"
                value={client.siret}
              />
              <Control
                label="Code APE"
                className="readonly"
                value={client.ape}
              />
              <Control
                label="Activité"
                className="readonly"
                value={client.companyActivity}
              />
              <Control
                label="Date d'immat."
                className="readonly"
                value={
                  client.registrationAt
                    ? DateTime.fromISO(client.registrationAt).toFormat(
                        "dd/MM/yyyy"
                      )
                    : ""
                }
              />
              <Control
                label="Activité de construction"
                className="readonly"
                value={
                  constantData.CONSTRUCTION_ACTIVITIES.find(
                    (c) => c.id == client.constructionActivity
                  )
                    ? constantData.CONSTRUCTION_ACTIVITIES.find(
                        (c) => c.id == client.constructionActivity
                      ).name
                    : ""
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Forme juridique"
                className="readonly"
                value={client.legalForm ? client.legalForm.value : ""}
              />
              <Control
                label="Chiffre d'affaires"
                className="readonly"
                value={client.turnover}
              />
              <Control
                label="Capital social"
                className="readonly"
                value={client.capital}
              />
              <Control
                label="Effectif"
                className="readonly"
                value={client.workforce}
              />
              <Control
                label="Résultat"
                className="readonly"
                value={client.income}
              />
              <Control
                label="CA sous-traité (%)"
                className="readonly"
                value={client.turnoverOutsourced}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="mt-3">Informations de contact</h4>
          <div className="row">
            <div className="col-12 col-md-6 mt-3">
              <Accordion
                btnClassName="shadow-none"
                cardClassName="shadow-none bg-transparent mb-1"
                color="default"
                className="readonly"
                btnStyle={{
                  border: "1px solid #d8d8d8",
                  borderRadius: 8,
                  overflowX: "hidden",
                }}
                bodyClassName={"p-2"}
                items={[
                  {
                    title: "Adresse principale",
                    content: (
                      <div className="row">
                        <div className="col-12">
                          <Control
                            label="Adresse"
                            className="readonly"
                            value={client.location1 && client.location1.address}
                          />
                          <Control
                            label="Adresse (suite)"
                            className="readonly"
                            value={
                              client.location1 && client.location1.address2
                            }
                          />

                          <Control
                            label="Adresse (fin)"
                            className="readonly"
                            value={
                              client.location1 && client.location1.address3
                            }
                          />
                        </div>
                        <div className="col-12">
                          <Control
                            label="Code postal"
                            className="readonly"
                            value={
                              client.location1 && client.location1.postalCode
                            }
                          />
                          <Control
                            label="Ville"
                            className="readonly"
                            value={client.location1 && client.location1.city}
                          />

                          <Control
                            label="Pays"
                            className="readonly"
                            value={client.location1 && client.location1.country}
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Adresse secondaire",
                    content: (
                      <div className="row">
                        <div className="col-12">
                          <Control
                            label="Adresse"
                            className="readonly"
                            value={
                              client.location2 && client.location2.addressUsage
                            }
                          />
                          <Control
                            label="Adresse (suite)"
                            className="readonly"
                            value={
                              client.location2 && client.location2.address2
                            }
                          />

                          <Control
                            label="Adresse (fin)"
                            className="readonly"
                            value={
                              client.location2 && client.location2.address3
                            }
                          />
                        </div>
                        <div className="col-12">
                          <Control
                            label="Code postal"
                            className="readonly"
                            value={
                              client.location2 && client.location2.postalCode
                            }
                          />
                          <Control
                            label="Ville"
                            className="readonly"
                            value={client.location2 && client.location2.city}
                          />

                          <Control
                            label="Pays"
                            className="readonly"
                            value={client.location2 && client.location2.country}
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Email"
                className="readonly"
                value={client.email}
              />
              <Control
                label="Téléphone domicile"
                className="readonly"
                value={client.phone1}
              />
              <Control
                label="Téléphone bureau"
                className="readonly"
                value={client.phone2}
              />
              <Control
                label="Téléphone mobile"
                className="readonly"
                value={client.mobilePhone}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="mt-3">Notes</h4>
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                type="textarea"
                className="readonly"
                label="Commentaires"
                value={client.notes}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                type="textarea"
                className="readonly"
                label="Note importante"
                value={client.importantNote}
              />
              <div className="form-group">
                <label>Centres d'intérêt : </label>
                {client.interests.map((ci, cik) => {
                  var interest = constantData.CLIENT_INTERESTS.find(
                    (cc) => cc.id == ci
                  );
                  return (
                    <span className="badge badge-primary mx-1">
                      {interest?.name || ""}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row px-2 mt-5">
        {isDocsLoading ? (
          <Loader withContainer text={"Chargement des documents..."} />
        ) : (
          <DocumentsDisplay
            documents={documents}
            documentUploaded={documentUploaded}
            documentRemoved={documentRemoved}
            uploadNewFile={uploadNewFile}
            cancelNewVersion={cancelNewVersion}
            authorizedModels={isBOA ? false : ["Client", "UserCustomer"]}
            inline={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default TabClient;
