import tools from "../../../../../../../../../../../helpers/tools";
import LabelValuePair from "../../../../../../../../../../common/LabelValuePair/LabelValuePair";
import usePolicyItem from "../usePolicyItem";

const BPPolicyAmendments = () => {
  const { policy, PERIODICITIES } = usePolicyItem();

  return (
    <div className="row mx-0">
      {policy?.amendments?.map((amendment) => (
        <div
          className="col-12 p-2 border rounded shadow-sm my-2 bg-light"
          key={`amend${amendment.id}`}
        >
          <div className="row">
            <div className="col-12 col-md-4">
              <div>
                <strong>Avenant</strong>
              </div>

              <div>
                <LabelValuePair
                  label="Date d'effet"
                  value={tools.formatDate(amendment.effectDate)}
                />
              </div>
              <div>
                <LabelValuePair
                  label="Échéance principale"
                  value={tools.formatDate(amendment.mainEcheance)}
                />
              </div>
              <div>
                <LabelValuePair label="Créé le" value={tools.formatDate(amendment.createdAt)} />
              </div>
              <div>
                <LabelValuePair label="Modifié le" value={tools.formatDate(amendment.updatedAt)} />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div>
                <LabelValuePair
                  label="Périodicité"
                  value={tools.findIn(PERIODICITIES, amendment.periodicity, "id").name}
                />
              </div>
              <div>
                <LabelValuePair label="Prime HT" value={`${amendment.premiumWithoutVat || 0} €`} />
              </div>
              <div>
                <LabelValuePair label="Prime TTC" value={`${amendment.premiumWithVat || 0} €`} />
              </div>
              <div>
                <LabelValuePair
                  label="Prime annuelle TTC"
                  value={`${amendment.annualPremium || 0} €`}
                />
              </div>

              <div>
                <LabelValuePair label="Honoraires" value={`${amendment.fees || 0} €`} />
              </div>
              <div>
                <LabelValuePair label="Indice" value={amendment.index} />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <p style={{ whiteSpace: "pre-line" }}>{amendment.details}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BPPolicyAmendments;
