import { useEffect } from "react";
import tools from "../../../../../../../../../../../helpers/tools";
import LabelValuePair from "../../../../../../../../../../common/LabelValuePair/LabelValuePair";
import AddModulrIdForm from "./AddModulrIdForm/AddModulrIdForm";
import ModifyReceiptForm from "./ModifyReceiptForm/ModifyReceiptForm";
import ReceiptPaymentHistorics from "./ReceiptPaymentHistorics/ReceiptPaymentHistorics";
import useReceiptItem from "./useReceiptItem";

const ReceiptItem = ({ receiptData }) => {
  const { receipt, receiptStatusRef, initReceipt } = useReceiptItem();

  useEffect(() => {
    initReceipt(receiptData);
  }, []);

  return !receipt ? null : (
    <div className="col-12 p-2 border rounded shadow-sm my-2 bg-light">
      <div className="row">
        <div className="col-12 col-lg-4 col-md-6">
          <div>
            <strong>Quittance</strong>{" "}
            <span className={`ml-2 badge badge-${receiptStatusRef.color}`}>
              {receiptStatusRef.name}
            </span>
            <span className="ml-2 font-weight-bold">{receipt.premiumWithVat} €</span>
          </div>
          <div>
            <LabelValuePair label="Commission" value={receipt.commission + " €"} />
          </div>
          <div>
            <LabelValuePair
              label="Période"
              value={
                <>
                  {tools.formatDate(receipt.effectDate)}
                  <i className="fa fa-chevron-right mx-1" />
                  {tools.formatDate(receipt.endDate)}
                </>
              }
            />
          </div>
          <div>
            <LabelValuePair label="Date limite" value={tools.formatDate(receipt.limitDate)} />
          </div>
          <div>
            <LabelValuePair label="Créée le" value={tools.formatDate(receipt.createdAt)} />
          </div>
          <div>
            <LabelValuePair label="Modifiée le" value={tools.formatDate(receipt.updatedAt)} />
          </div>
          <div data-priv="bo_viewpaymenthistory">
            <LabelValuePair label="Modulr ID" value={receipt.modulrId} />
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-6" data-priv="bo_viewpaymenthistory">
          <ModifyReceiptForm receiptData={receipt} />
          {!receipt.modulrId && <AddModulrIdForm />}
        </div>
        <div className="col-12 col-lg-4 col-md-6" data-priv="bo_viewpaymenthistory">
          <ReceiptPaymentHistorics />
        </div>
      </div>
    </div>
  );
};

export default ReceiptItem;
