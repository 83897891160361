import { useState } from "react";
import useInsuranceCompanies from "../../../../../hooks/useInsuranceCompanies/useInsuranceCompanies";
import useProducers from "../../../../../hooks/useProducers/useProducers";
import useProducts from "../../../../../hooks/useProducts/useProducts";
import useProductTypes from "../../../../../hooks/useProductTypes/useProductTypes";
import useRole from "../../../../../hooks/useRole/useRole";
import Control from "../../../../common/Controls/Control";
import { smallInputProps } from "../../../../common/Controls/Inputs/smallInputProps";
import SaveBtn from "../../../../common/SaveBtn";
import useClientList from "../useClientList";
import {
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../../../common/Controls/filterContainer";

const ClientFilters = () => {
  const {
    clientFilters,
    isLoadingClients,
    actions,
    ACCOUNT_STATUS,
    POLICIES_STATUS,
  } = useClientList();
  const { productTypes } = useProductTypes();
  const { products } = useProducts();
  const { insuranceCompanies } = useInsuranceCompanies();
  const { producers } = useProducers(true);
  const { myRole } = useRole();

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const handleChangeFilters = (e) => {
    let { name, value, type, checked } = e.target;
    value = type === "checkbox" ? (checked ? true : false) : value;

    const newFilters = {
      ...clientFilters,
      user: {
        ...clientFilters.user,
        [name]: value,
      },
    };
    if (name === "utilisateurSansClient" && value) {
      newFilters.user.numeroContrat = "";
      newFilters.user.nomSociete = "";
      newFilters.user.sirenOuSiret = "";
      newFilters.user.compagniesAssurances = [];
      newFilters.user.typesProduits = [];
      newFilters.user.produits = [];
      newFilters.user.statutContrat = "";
      newFilters.user.auMoins1Contrat = false;
    }
    actions.onClientFiltersChange(newFilters);
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    actions.onGetClients(clientFilters);
  };

  return (
    <form onSubmit={onSubmitSearch}>
      <div className={filterContainerClassName}>
        <div className="col-12 col-md-4">
          <Control
            label="Email"
            type="text"
            name="email"
            value={clientFilters.user.email}
            change={handleChangeFilters}
            fullWidth
            {...smallInputProps}
          />

          <Control
            label="Nom/Prénom"
            type="text"
            name="nomPrenomClient"
            value={clientFilters.user.nomPrenomClient}
            change={handleChangeFilters}
            fullWidth
            {...smallInputProps}
          />
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Nom de l'entreprise"
            type="text"
            name="nomSociete"
            value={clientFilters.user.nomSociete}
            change={handleChangeFilters}
            fullWidth
            disabled={clientFilters.user.utilisateurSansClient}
            {...smallInputProps}
          />

          <Control
            label="SIREN ou SIRET"
            type="text"
            name="sirenOuSiret"
            value={clientFilters.user.sirenOuSiret}
            change={(e) => {
              //remove spaces from sirenOuSiret
              e.target.value = e.target.value.replace(/\s/g, "");
              handleChangeFilters(e);
            }}
            fullWidth
            disabled={clientFilters.user.utilisateurSansClient}
            {...smallInputProps}
          />
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Numéro de contrat"
            type="text"
            name="numeroContrat"
            value={clientFilters.user.numeroContrat}
            change={handleChangeFilters}
            fullWidth
            disabled={clientFilters.user.utilisateurSansClient}
            {...smallInputProps}
          />

          <Control
            label="Statut du contrat"
            type="btnList"
            name="statutContrat"
            datas={POLICIES_STATUS.filter((status) => status.id)}
            value={parseInt(clientFilters.user.statutContrat)}
            change={handleChangeFilters}
            dataIndex="id"
            btnInline
            dataLabel="name"
            disabled={clientFilters.user.utilisateurSansClient}
            {...smallInputProps}
          />
        </div>

        {showAdvancedSearch && (
          <>
            <div className="col-12 col-md-4">
              <Control
                label="Statut du compte"
                type="btnList"
                name="statutCompte"
                datas={ACCOUNT_STATUS}
                value={clientFilters.user.statutCompte}
                change={handleChangeFilters}
                dataIndex="id"
                dataLabel="name"
                {...smallInputProps}
                inColumn
              />
            </div>

            <div className="col-12 col-md-4">
              <Control
                label="Compagnies d'assurance"
                name="compagniesAssurances"
                type="selectList"
                datas={insuranceCompanies.sort((a, b) =>
                  a.name > b.name ? 1 : -1
                )}
                value={clientFilters.user.compagniesAssurances}
                change={handleChangeFilters}
                disabled={clientFilters.user.utilisateurSansClient}
                {...smallInputProps}
              />
            </div>
            <div className="col-12 col-md-4 ">
              <Control
                label="Utilisateurs ayant au moins 1 contrat"
                name="auMoins1Contrat"
                type="checkbox"
                checked={clientFilters.user.auMoins1Contrat}
                change={handleChangeFilters}
                disabled={clientFilters.user.utilisateurSansClient}
                {...smallInputProps}
              />
            </div>
            <div className="col-12 col-md-4">
              <Control
                label="Types de produit"
                name="typesProduits"
                type="selectList"
                datas={productTypes.sort((a, b) => (a.name > b.name ? 1 : -1))}
                value={clientFilters.user.typesProduits}
                change={handleChangeFilters}
                disabled={clientFilters.user.utilisateurSansClient}
                {...smallInputProps}
              />
            </div>
            <div className="col-12 col-md-4">
              <Control
                label="Produits"
                name="produits"
                type="selectList"
                datas={products.sort((a, b) => (a.name > b.name ? 1 : -1))}
                value={clientFilters.user.produits}
                change={handleChangeFilters}
                disabled={clientFilters.user.utilisateurSansClient}
                {...smallInputProps}
              />
            </div>
            <div className="col-12 col-md-4">
              {myRole != "PARTNER" && (
                <>
                  <Control
                    label="Société partenaire"
                    name="partenaire"
                    type="select"
                    datas={producers.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    value={clientFilters.user.partenaire}
                    change={handleChangeFilters}
                    dataIndex="id"
                    dataLabel="company"
                    disabled={clientFilters.user.utilisateurSansClient}
                    {...smallInputProps}
                  />
                  <Control
                    label="N'afficher que les utilisateurs sans aucun client"
                    name="utilisateurSansClient"
                    type="checkbox"
                    checked={clientFilters.user.utilisateurSansClient}
                    change={handleChangeFilters}
                    {...smallInputProps}
                  />
                </>
              )}
            </div>
          </>
        )}

        <div className={`${searchButtonContainerClassName} align-items-center`}>
          <SaveBtn
            className={searchButtonClassName}
            typeBtn="submit"
            save={() => {}}
            text="Rechercher"
            type={searchButtonType}
            isSaving={isLoadingClients}
            loaderColor={searchButtonLoaderColor}
          />
          {showAdvancedSearch ? (
            <button
              type="button"
              className="btn btn-link "
              onClick={() => setShowAdvancedSearch(false)}
            >
              <i className="fa fa-cog mr-1" />
              Recherche simplifiée
            </button>
          ) : (
            <button
              className="btn btn-link "
              type="button"
              onClick={() => setShowAdvancedSearch(true)}
            >
              <i className="fa fa-cog mr-1" />
              Recherche avancée
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ClientFilters;
