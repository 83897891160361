import { atom, useAtom } from "jotai";
import useApiAuthMiddleware from "../../middlewares/useApiAuthMiddleware";

export const ERROR_TYPES = {
  API_TOKEN_EXPIRED: {
    key: "API_TOKEN_EXPIRED",
    status: 401,
  },
};

const globalErrorAtom = atom("");

const useGlobalError = () => {
  const [globalError, setGlobalError] = useAtom(globalErrorAtom);

  const { apiAuthActions } = useApiAuthMiddleware();

  const globalErrorActions = {
    handleGlobalError: async (statusCode, params) => {
      switch (statusCode) {
        case ERROR_TYPES.API_TOKEN_EXPIRED.status:
          const authRequest =
            await apiAuthActions.authenticateWithTarificatorApi();
          if (authRequest && params.callback) {
            params.callback();
          } else {
            window.location.reload();
          }

          break;
        default:
          break;
      }
    },
  };

  return {
    globalError,
    setGlobalError,
    globalErrorActions,
  };
};

export default useGlobalError;
