import { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import FileDownload from "js-file-download";
import axios from "axios";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";

const useFtpClient = () => {
  const [files, setFiles] = useState([]);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    connectFtp();
  }, []);

  const connectFtp = () => {
    setIsConnecting(true);
    dataService.get(`sftp/bfc`, setFiles, console.log, () =>
      setIsConnecting(false)
    );
  };

  const downloadFile = (file) => {
    setIsDownloading(file.name);

    axios({
      url: API_URL + "sftp/download",
      method: "POST",
      responseType: "blob",
      data: { fileName: file.name },
    })
      .then((res) => {
        var filename = res.headers["content-disposition"].split("=")[1];
        FileDownload(res.data, filename);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  const deleteFile = (file) => {
    setIsDeleting(file.name);
    setTimeout(() => {
      setIsDeleting(false);
    }, 2000);
    if (
      window.confirm(
        "Confirmez la suppression du fichier sur le serveur distant"
      )
    ) {
      dataService.post(
        `sftp/delete`,
        { fileName: file.name },
        setFiles,
        console.log,
        () => setIsDeleting(false)
      );
    }
  };

  return {
    state: {
      files,
      isConnecting,
      isDownloading,
      isDeleting,
    },
    actions: {
      connectFtp,
      downloadFile,
      deleteFile,
    },
  };
};

export default useFtpClient;
