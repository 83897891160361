import React from "react";
import ReactApexChart from "react-apexcharts";
import SaveBtn from "../../../../common/SaveBtn";
import useCharts from "../useCharts";

const ChartItem = ({ url, postData }) => {
  const { isLoading, datas, getDatas, total } = useCharts(url, postData);

  return (
    <>
      <div className="d-flex justify-content-start w-100">
        <SaveBtn
          save={getDatas}
          isSaving={isLoading}
          text="Afficher"
          type="btn-light border"
          loaderColor="black"
        />
      </div>
      {!isLoading && datas ? (
        <div className="w-100">
          <ReactApexChart
            options={datas.line.options}
            series={datas.line.series}
            type="bar"
            height={350}
          />

          <div className="col-12 text-center font-weight-bold">TOTAL : {total}</div>
        </div>
      ) : null}
    </>
  );
};

export default ChartItem;
