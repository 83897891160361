import { useState } from "react";
import styled from "styled-components";
import Control from "../../Controls/Control";
import useTodoList from "../useTodoList";

const AddTodoForm = ({ addTodo }) => {
  const { availableTasks } = useTodoList();
  const [newTodo, setNewTodo] = useState({
    taskId: "",
    isDone: false,
    text: "",
  });
  const resetNewTodo = () => {
    setNewTodo({ text: "", taskId: "", isDone: false });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addTodo(newTodo);
    resetNewTodo();
  };

  const handleChangeTodo = (e) => {
    const { name, value } = e.target;
    setNewTodo({ ...newTodo, [name]: value });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="mt-5 ml-auto"
      style={{
        maxWidth: 400,
      }}
    >
      <StyledControl
        label="Nouvelle tâche"
        type="select"
        name="taskId"
        value={newTodo.taskId}
        datas={availableTasks}
        dataIndex="id"
        dataLabel="name"
        change={handleChangeTodo}
        margin="m-0"
        noPaddingOnFormGroup
        noMarginInsideLabel
        noBg
        labelClassname="w-100 mt-0"
        inputStyle={{ borderRadius: 5 }}
        fullWidth
      />
      <StyledControl
        label="Note (facultatif)"
        type="text"
        name="text"
        value={newTodo.text}
        change={handleChangeTodo}
        margin="m-0"
        noPaddingOnFormGroup
        noMarginInsideLabel
        noBg
        labelClassname="w-100 mt-1"
        inputStyle={{
          background: "white",
          borderRadius: 5,
          color: "black",
          fontWeight: "400",
          fontStyle: "italic",
        }}
        fullWidth
      />
      <div className="d-flex justify-content-end">
        <button
          disabled={newTodo.taskId ? "" : "disabled"}
          className="btn btn-sm btn-primary mt-2"
          type="submit"
        >
          <i className="fa fa-plus mr-1" />
          Ajouter la tâche
        </button>
      </div>
    </form>
  );
};

const StyledControl = styled(Control)`
  input,
  select {
  }
`;

export default AddTodoForm;
