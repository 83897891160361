import React, { useEffect, useState } from "react";
import CheckboxNumber from "../../../../../common/CheckboxNumber/CheckboxNumber";
import Control from "../../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";
import HighFeature from "../Common/HighFeature/HighFeature";
import useFormState from "../useFormState";
import FranchiseTOCBtn from "./FranchiseTOCBtn";
import useCalculatedValues from "./useCalculatedValues";

const AccidentInputs = () => {
  const { constants, actions, errors, formData, inputsConfig, apiData } = useFormState();
  var indiceFFB = constants.ID_FFB;
  const inputState = formData.accident1Inputs;

  const updateMrpData = (e) => {
    const { name, value, type, checked } = e.target;
    var realValue = type == "checkbox" ? (checked ? true : false) : value;
    actions.updateAccident1Inputs({ [name]: realValue });
  };

  const { calculatedValues } = useCalculatedValues({
    company: { ape: apiData.ape, state: formData },
  });

  useEffect(() => {
    let updateObj = {};

    Object.keys(calculatedValues).forEach((valkey) => {
      if (!inputState[valkey]) {
        updateObj[valkey] = calculatedValues[valkey].value;
      }
    });
    actions.updateFormData({ accident1Inputs: { ...inputState, ...updateObj } });
  }, [calculatedValues]);

  const onBlur = () => {};

  return (
    <BlockWrapper>
      <Control
        label={inputsConfig.isElectricalInstallationChecked.label}
        name="isElectricalInstallationChecked"
        type="btnList"
        datas={inputsConfig.isElectricalInstallationChecked.datas}
        dataIndex="id"
        value={inputState.isElectricalInstallationChecked}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      <Control
        label={inputsConfig.isFireEstinguishedChecked.label}
        name="isFireEstinguishedChecked"
        type="btnList"
        datas={inputsConfig.isFireEstinguishedChecked.datas}
        dataIndex="id"
        value={inputState.isFireEstinguishedChecked}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      <Control
        label={inputsConfig.isFireAlarmChecked.label}
        name="isFireAlarmChecked"
        type="btnList"
        datas={inputsConfig.isFireAlarmChecked.datas}
        dataIndex="id"
        value={inputState.isFireAlarmChecked}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      <HighFeature
        title={
          <>
            <div style={{ fontSize: 20 }}>
              Tempête, Ouragan, Cyclone (T.O.C) Grêle, Neige (T.G.N)
            </div>
            <div style={{ lineHeight: "12px", marginTop: 10 }}>Contenu assuré</div>
          </>
        }
        value={calculatedValues.franchiseTOCValue.value}
        items={[
          {
            text: (
              <>
                <strong>Dommages</strong> aux bâtiments + <strong>marchandises</strong> /{" "}
                <strong>matériels</strong> à l’intérieur de vos locaux.
              </>
            ),
          },
        ]}
        content={
          <Control
            label={inputsConfig.franchiseTOC.label}
            name="franchiseTOC"
            type="btnList"
            margin="mb-0"
            innerLabelStyle={{
              //color: "white",
              textAlign: "center",
              marginBottom: 20,
            }}
            datas={[
              {
                id: 1,
                name: (
                  <FranchiseTOCBtn
                    isActive={inputState.franchiseTOC == 1}
                    indiceFFB={indiceFFB}
                    FFBMultiplicatorMini={0.75}
                    FFBMultiplicatorMaxi={8}
                    label="Franchise Basse"
                    icon="franchise-toc-1.svg"
                  />
                ),
              },
              {
                id: 2,
                name: (
                  <FranchiseTOCBtn
                    isActive={inputState.franchiseTOC == 2}
                    indiceFFB={indiceFFB}
                    FFBMultiplicatorMini={3}
                    FFBMultiplicatorMaxi={16}
                    label="Franchise Moyenne"
                    icon="franchise-toc-2.svg"
                  />
                ),
              },
              {
                id: 3,
                name: (
                  <FranchiseTOCBtn
                    isActive={inputState.franchiseTOC == 3}
                    indiceFFB={indiceFFB}
                    FFBMultiplicatorMini={8}
                    FFBMultiplicatorMaxi={32}
                    label="Franchise Haute"
                    icon="franchise-toc-3.svg"
                  />
                ),
              },
            ]}
            dataIndex="id"
            value={inputState.franchiseTOC}
            change={updateMrpData}
            fullWidth
            labelClassname="w-100"
            labelStyle={{
              paddingTop: 50,
            }}
            containerStyle={{ marginTop: -29 }}
            error={errors}
            onBlur={onBlur}
          />
        }
      />

      <CheckboxNumber
        checkboxLabel={inputsConfig.isFloodDamage.label}
        numberLabel={inputsConfig.floodDamageValue.label}
        checkboxName="isFloodDamage"
        numberName="floodDamageValue"
        isChecked={inputState.isFloodDamage}
        nbValue={inputState.floodDamageValue || calculatedValues.floodDamageValue.value}
        trueValue={inputState.floodDamageValue}
        defaultValue={calculatedValues.floodDamageValue.value}
        setIsChecked={updateMrpData}
        setNbValue={updateMrpData}
        error={errors}
        onBlur={onBlur}
        checkboxInfo="Dégâts matériels suite à débordement / refoulement de sources, caniveaux, étendues d’eau."
        numberInfo=""
        inputParams={{
          min: parseInt(calculatedValues.floodDamageValue.min),
          max: parseInt(calculatedValues.floodDamageValue.max),
          step: (1 * parseInt(calculatedValues.floodDamageValue.max)) / 100,
        }}
      />
      <CheckboxNumber
        checkboxLabel={inputsConfig.isEarthQuakeDamage.label}
        numberLabel={inputsConfig.earthQuakeDamageValue.label}
        checkboxName="isEarthQuakeDamage"
        numberName="earthQuakeDamageValue"
        isChecked={inputState.isEarthQuakeDamage}
        nbValue={inputState.earthQuakeDamageValue || calculatedValues.earthQuakeDamageValue.value}
        trueValue={inputState.earthQuakeDamageValue}
        defaultValue={calculatedValues.earthQuakeDamageValue.value}
        setIsChecked={updateMrpData}
        setNbValue={updateMrpData}
        error={errors}
        onBlur={onBlur}
        checkboxInfo="Valeur assurée en cas d’éruption volcanique ou de tremblement de terre."
        numberInfo=""
        inputParams={{
          min: parseInt(calculatedValues.earthQuakeDamageValue.min),
          max: parseInt(calculatedValues.earthQuakeDamageValue.max),
          step: (1 * parseInt(calculatedValues.earthQuakeDamageValue.max)) / 100,
        }}
      />
      <CheckboxNumber
        checkboxLabel={inputsConfig.isRainDamage.label}
        numberLabel={inputsConfig.rainDamageValue.label}
        checkboxName="isRainDamage"
        numberName="rainDamageValue"
        isChecked={inputState.isRainDamage}
        nbValue={inputState.rainDamageValue || calculatedValues.rainDamageValue.value}
        trueValue={inputState.rainDamageValue}
        defaultValue={calculatedValues.rainDamageValue.value}
        setIsChecked={updateMrpData}
        setNbValue={updateMrpData}
        error={errors}
        onBlur={onBlur}
        checkboxInfo="Valeur assurée en cas de dégâts matériels causés par l’eau de pluie ayant pénétré dans les bâtiments sans dommage préalable aux toitures, murs, portes, fenêtres, et trappes."
        numberInfo=""
        inputParams={{
          min: parseInt(calculatedValues.rainDamageValue.min),
          max: parseInt(calculatedValues.rainDamageValue.max),
          step: (1 * parseInt(calculatedValues.rainDamageValue.max)) / 100,
        }}
      />
      <Control
        label={inputsConfig.isResponsibilityAfterWork.label}
        name="isResponsibilityAfterWork"
        type="btnList"
        datas={inputsConfig.isResponsibilityAfterWork.datas}
        dataIndex="id"
        value={inputState.isResponsibilityAfterWork}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        info="Couvre les dommages causés à des tiers après livraison ou vente d’un produit défectueux."
        onBlur={onBlur}
      />
      <Control
        label={inputsConfig.isDeliveringToTiers.label}
        name="isDeliveringToTiers"
        type="btnList"
        datas={inputsConfig.isDeliveringToTiers.datas}
        dataIndex="id"
        value={inputState.isDeliveringToTiers}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
        info="Clients, fournisseurs, partenaires."
      />
    </BlockWrapper>
  );
};

export default AccidentInputs;
