import { atom } from "jotai";
import { DateTime } from "luxon";

export const pjListAtom = atom(null);
export const isLoadingPjListAtom = atom(false);
export const pjListFiltersAtom = atom({
  page: 1,
  filters: {
    renewableInNbDays: 30,
  },
});
export const isPjListInitAtom = atom(false);
