import React, { useState } from "react";
import Control from "../../common/Controls/Control";
import Loader from "../../common/Loader";
import DynamicList from "../../common/DynamicList";
import useDocumentList from "./useDocumentList";
import clsx from "clsx";
import useProducts from "../../../hooks/useProducts/useProducts";
import useProductTypes from "../../../hooks/useProductTypes/useProductTypes";
import {
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
} from "../../common/Controls/filterContainer";

const FastSearchButton = ({ onClick, text }) => (
  <button className="btn btn-default border btn-sm m-1" onClick={onClick}>
    {text}
  </button>
);

const DocumentFilters = () => {
  const {
    search,
    searchDocuments,
    searchText,
    updateDocumentSearch,
    isLoading,
    customFilters,
    fileTypes,
    fastSearches,
  } = useDocumentList();

  const { products } = useProducts();
  const { productTypes } = useProductTypes();

  const [productType, setProductType] = useState("");

  const filteredFileTypes = fileTypes.filter((f) =>
    f.fileCategories.find((fc) => fc.entityModel == search.file.entityModel)
  );

  const onChangeProductType = (e) => {
    updateDocumentSearch({
      target: {
        name: "products",
        value: products
          .filter((p) => p.productType == e.target.value)
          .map((p) => p.id),
      },
    });
    setProductType(e.target.value);
  };

  return (
    <div className={filterContainerClassName}>
      <div className="col-12">
        <Control
          label="Entité concernée par la recherche :"
          margin="my-0"
          labelClassname="mt-0"
          type="btnList"
          datas={customFilters}
          value={search.file.entityModel}
          dataIndex="id"
          dataLabel="name"
          name="entityModel"
          change={updateDocumentSearch}
          btnInline
        />
        {filteredFileTypes.length > 0 && (
          <Control
            label="Documents recherchés :"
            margin="my-0"
            labelClassname="mt-0"
            name="fileTypes"
            type="checkBtnList"
            value={search.file.fileTypes}
            datas={filteredFileTypes}
            dataLabel="name"
            dataIndex="id"
            change={updateDocumentSearch}
            btnInline
          />
        )}
        {["Client", "Policy", "Amendment", "Receipt"].includes(
          search.file.entityModel
        ) && (
          <Control
            label="Documents liés à des utilisateurs actifs uniquement"
            margin="my-0"
            labelClassname="mt-0"
            type="checkbox"
            name="activeUsersOnly"
            checked={search.file.activeUsersOnly}
            change={updateDocumentSearch}
          />
        )}
        {["Amendment", "Receipt"].includes(search.file.entityModel) && (
          <div className="row">
            <div className="col-12 col-md-3">
              <Control
                label="Inclure ou exclure des produits :"
                type="select"
                placeholder="Ne pas filtrer"
                name="productInclusionType"
                value={search.file.productInclusionType}
                datas={[
                  { id: "include", name: "Inclure les produits >" },
                  { id: "exclude", name: "Exclure les produits >" },
                ]}
                change={updateDocumentSearch}
              />
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12">
                  <Control
                    label="Sélectionner tous les produits du type :"
                    type="select"
                    name="productTypes"
                    value={productType}
                    datas={productTypes}
                    dataLabel="name"
                    change={onChangeProductType}
                    renderDataLabel={(entry) =>
                      `${entry.businessName} - ${entry.name}`
                    }
                    margin="my-0"
                    labelClassname="mt-0"
                    fullWidth
                  />
                </div>
                <div className="col-12">
                  <Control
                    label="Produits sélectionnés :"
                    type="selectList"
                    name="products"
                    value={search.file.products}
                    datas={products}
                    dataLabel="name"
                    change={updateDocumentSearch}
                    renderDataLabel={(entry) =>
                      `${entry.businessName} - ${entry.name}`
                    }
                    margin="my-0"
                    labelClassname="mt-0"
                    fullWidth
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <SimpleDynamicList
          listLabel="Type de document"
          uniqueKey="1"
          element={"fileTypes"}
          btnLabel="Ajouter un type de fichier"
          fields={[
            {
              label: "Type",
              name: "fileType",
              type: "select",
              datas: fileTypes.filter((f) =>
                f.fileCategories.find((fc) => fc.entityModel == search.file.entityModel)
              ),
            },
          ]}
          items={search.file.fileTypes}
          change={updateDocumentSearch}
        /> */}

        {customFilters.find((e) => e.id == search.file.entityModel) && (
          <DynamicList
            formGroupClassname="p-3"
            labelClassname={"my-0 mb-2"}
            listLabel="Si l'entité choisie doit contenir une valeur précise, ajoutez des filtres de champ :"
            uniqueKey="1"
            element="filters"
            change={updateDocumentSearch}
            fields={[
              {
                label: "L'entité doit avoir le champ...",
                type: "select",
                name: "id",
                datas: customFilters.find(
                  (e) => e.id == search.file.entityModel
                ).filters,
                dataIndex: "id",
                dataLabel: "name",
              },
              {
                label: "avec la valeur...",
                type: "text",
                name: "value",
                isDynamic: true,
                datas: [],
              },
            ]}
            dynamicTypes={
              customFilters.find((e) => e.id == search.file.entityModel).filters
            }
            items={search.file.filters}
            btnLabel="Ajouter un filtre"
          />
        )}
        <Control
          label="Vous pouvez obtenir uniquement les résultats où les documents sont présents, ou uniquement ceux où les documents sont absents :"
          name="isExist"
          type="btnList"
          margin={"mb-2"}
          labelClassname={"mt-0 mb-2"}
          btnInline
          datas={[
            {
              id: "-",
              name: "Tous",
            },
            {
              id: true,
              name: "Documents présents",
              value: "",
            },
            {
              id: false,
              name: "Documents absents",
            },
          ]}
          dataIndex="id"
          dataLabel="name"
          change={updateDocumentSearch}
          value={search.file.isExist}
        />
        {search.file.isExist === true && (
          <Control
            label="Date d'ajout du document inférieure à :"
            type="date"
            value={search.file.uploadedAt}
            change={updateDocumentSearch}
            name="uploadedAt"
            labelClassname={"my-0 mb-2"}
            margin={"mb-2"}
          />
        )}
      </div>
      <hr className="w-100" />
      <div className="col-12 mb-4">
        <label>Boutons de recherche rapide</label>
        <div>
          <FastSearchButton
            onClick={fastSearches.searchAmendmentsWithMissingCP}
            text={"Avenants - Conditions Particulières manquantes"}
          />
          <FastSearchButton
            onClick={fastSearches.searchAmendmentsWithMissingAttestation}
            text={"Avenants - Attestation d'Assurance manquante"}
          />
          <FastSearchButton
            onClick={fastSearches.searchAmendmentsWithOutdatedAttestation}
            text={"Avenants - Attestation d'Assurance périmée"}
          />
          <FastSearchButton
            onClick={fastSearches.searchUsersWithMissingID}
            text={"Client (Modulr) - Pièce d'identité manquante"}
          />
          <FastSearchButton
            onClick={fastSearches.searchUsersWithMissingKbis}
            text={"Client (Modulr) - KBIS manquant"}
          />
          <FastSearchButton
            onClick={fastSearches.searchUsersWithOutdatedKbis}
            text={"Client (Modulr) - KBIS expiré"}
          />
        </div>
      </div>

      <div className={searchButtonContainerClassName}>
        <button
          style={{ fontWeight: "600" }}
          className={clsx(
            searchButtonClassName,
            "btn  mb-2",
            { "btn-default ": searchText == "Rechercher" },
            { "btn-light": searchText != "Rechercher" }
          )}
          disabled={!search.file.entityModel}
          onClick={searchDocuments}
        >
          {isLoading ? <Loader color="black" /> : "Rechercher"}
        </button>
      </div>
    </div>
  );
};

export default DocumentFilters;
