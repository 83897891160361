import React from "react";
import SaveBtn from "../../../../common/SaveBtn";

const FormContainer = (props) => {
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    props.onSubmit();
  };

  return (
    <>
      <div id="mrpformcontainer">{props.children}</div>

      <div className="pb-3">
        <SaveBtn text="Enregistrer mes réponses" save={handleSubmit} isSaving={props.isSaving} />
      </div>
    </>
  );
};

export default FormContainer;
