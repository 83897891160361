import { DateTime } from "luxon";
import React, { useState } from "react";
import useStore from "../../../../../context/useStore";
import Control from "../../../../common/Controls/Control";

const ResiliationForm = ({ onValidate, cancelReasons }) => {
  const [reason, setReason] = useState({
    cancelReason: "",
    cancelComment: "",
    statusAt: "",
  });

  const handleChangeReason = (e) => {
    const { name, value } = e.target;
    setReason({
      ...reason,
      [name]: value,
    });
  };

  const submitData = () => {
    if (window.confirm("Confirmez-vous la résiliation de ce contrat ?")) {
      onValidate(reason);
    }
  };

  return (
    <div>
      <Control
        label="Date de la résiliation"
        type="date"
        name="statusAt"
        value={reason.statusAt}
        change={handleChangeReason}
      />
      <Control
        label="Motif de la résiliation"
        type="btnList"
        name="cancelReason"
        datas={cancelReasons}
        dataIndex="id"
        dataLabel="name"
        value={reason.cancelReason}
        change={handleChangeReason}
      />
      <Control
        label="Commentaire optionnel"
        type="textarea"
        name="cancelComment"
        value={reason.cancelComment}
        change={handleChangeReason}
        labelClassname="w-100"
        inputClassName="border rounded"
      />
      <div className="d-center w-100">
        <button className="btn btn-primary" onClick={submitData}>
          Résilier le contrat
        </button>
      </div>
    </div>
  );
};

export default ResiliationForm;
