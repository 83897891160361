import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import useProducers from "../../../../hooks/useProducers/useProducers";
import Avatar from "../../../common/Avatar";
import EmailLink from "../../../common/CommentList/common/EmailLink/EmailLink";
import { DateTime } from "luxon";

const UserCard = ({ user }) => {
  const { producers } = useProducers(true);

  const isExpired = user.contractEndAt 
    ? DateTime.now().startOf("day") > DateTime.fromISO(user.contractEndAt).startOf("day")
    : false;

  return (
    <div className="col-12 col-md-4 col-lg-3 mb-3">
      <div className="rounded bg-white p-3 shadow-sm h-100 d-flex flex-column justify-content-between align-items-center text-center border">
        <div>
          <div className="d-center mb-2">
            <Avatar width={40} height={40} id={user.id} />
          </div>

          <div className="my-1">
            <strong>{tools.getFullName(user)}</strong>
          </div>
          <div>
            <badge
              className={`badge border ${
                user.role == "Partenaire" ? "badge-orange text-white" : "badge-light"
              }`}
            >
              {user.role}
            </badge>
          </div>
          <div className="my-1">
            <EmailLink email={user.email} />
          </div>
          <div>{tools.findIn(producers, user.producer, "id").company}</div>
          {isExpired && <div className="text-danger">Expiré le {DateTime.fromISO(user.contractEndAt).toFormat('dd/MM/yyyy')}</div>}
        </div>
        <div className="mt-2">
          <Link
            to={"/users/" + user.id}
            style={{ minWidth: 0 }}
            className="btn btn-sm btn-primary "
            data-priv="bo_modifyuser"
          >
            Modifier
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
