import Page from "../../common/layout/Page";
import BlackListFilters from "./BlackListFilters/BlackListFilters";
import BlackListPagination from "./BlackListPagination";
import useBlackList from "./useBlackList";
import { BlackListContext } from "./BlackListContext";
import { Provider } from "jotai";
import BlackListItem from "./BlackListItem/BlackListItem";
import { useEffect } from "react";

const BlackList = () => {
  const { actions, blackList } = useBlackList();

  useEffect(() => {
    actions.getBlackList();
  }, []);

  return (
    <Page
      title="Liste noire"
      action={{
        to: "/black-list/add-establishment",
        text: "Blacklister une société",
      }}
      containerClassname="m-3"
    >
      <BlackListFilters />
      <BlackListPagination />
      <div className="col-12 row py-3 rounded-top  animated fadeIn ">
        <BlackListContext.Provider value={actions}>
          {blackList.map((blackList) => (
            <Provider>
              <BlackListItem blackListData={blackList} key={`blackListItem${blackList.id}`} />
            </Provider>
          ))}
        </BlackListContext.Provider>
      </div>
    </Page>
  );
};

export default BlackList;
