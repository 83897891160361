import React from "react";
import styled from "styled-components";
import tools from "../../../../../helpers/tools";

const CommentCreatedBy = ({ comment }) => {
  return (
    <>
      <StyledCommentCreatedBy>
        Créé par {comment.createdBy} - {tools.formatDateHours(comment.createdAt)}
      </StyledCommentCreatedBy>
      {comment.updatedBy && (
        <StyledCommentCreatedBy>
          Modifié par {comment.updatedBy} - {tools.formatDateHours(comment.updatedAt)}
        </StyledCommentCreatedBy>
      )}
    </>
  );
};

const StyledCommentCreatedBy = styled.div`
  color: var(--color-default);
  font-size: 12px;
`;

export default CommentCreatedBy;
