import { atom, useAtom } from "jotai";
import dataService from "../../../../helpers/dataService";
import useRole from "../../../../hooks/useRole/useRole";

const commissionsAtom = atom([]);
const isSearchingAtom = atom(false);
const nbResultsAtom = atom(0);

const useCommissionsList = () => {
  const [commissions, setCommissions] = useAtom(commissionsAtom);
  const [isSearching, setIsSearching] = useAtom(isSearchingAtom);
  const [nbResults, setNbResults] = useAtom(nbResultsAtom);
  const { myRole } = useRole();

  const actions = {
    onSearchCommissions: (filters = {}, page) => {
      setIsSearching(true);
      //exexute query
      const url =
        myRole == "PARTNER"
          ? "producers/search-my-commissions"
          : `producers/${filters.producer}/search-commissions`;
      dataService.post(
        url,
        {
          filters,
          page,
        },
        (data) => {
          setCommissions(data);
          setNbResults(data.length);
        },
        (err) => {},
        () => setIsSearching(false)
      );
    },
  };

  return {
    commissions,
    isSearching,
    nbResults,
    actions,
  };
};

export default useCommissionsList;
