import React from "react";
import useSimplifiedFormList from "../../useSimplifiedFormList";
import Control from "../../../../common/Controls/Control";
import { smallInputProps } from "../../../../common/Controls/Inputs/smallInputProps";
import useStore from "../../../../../context/useStore";
import SaveBtn from "../../../../common/SaveBtn";
import useGraalProducers from "../../../../../hooks/useProducers/useGraalProducers";
import {
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../../../common/Controls/filterContainer";

const SimplifiedFormFilters = () => {
  const [state] = useStore();
  const { actions, simplifiedFormFilters, isLoading } = useSimplifiedFormList();
  const { myProducers } = useGraalProducers();

  const onSubmitSearch = (e) => {
    e.preventDefault();
    actions.onFetchSimplifiedFormList(simplifiedFormFilters);
  };

  return (
    <form onSubmit={onSubmitSearch}>
      <div className={filterContainerClassName}>
        <div className="col-12 col-md-6">
          <Control
            label={
              <>
                <div>
                  <strong>Par société</strong>
                </div>
                <div>(nom société, siren ou siret)</div>
              </>
            }
            type="text"
            name="nom_societe_liee"
            value={simplifiedFormFilters.nom_societe_liee}
            change={actions.onFiltersChange}
            fullWidth
            {...smallInputProps}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label={
              <>
                <div>
                  <strong>SIREN ou SIRET</strong>
                </div>
                <div>(9 ou 14 chiffres)</div>
              </>
            }
            type="text"
            name="siren_siret_societe_liee"
            value={simplifiedFormFilters.siren_siret_societe_liee}
            change={(e) => {
              //remove spaces from sirenOuSiret
              const tev = {
                ...e,
                target: {
                  ...e.target,
                  value: e.target.value.replace(/\s/g, ""),
                },
              };
              actions.onFiltersChange(tev);
            }}
            fullWidth
            {...smallInputProps}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label={
              <>
                <div>
                  <strong>Email du client</strong>
                </div>
                <div>(email)</div>
              </>
            }
            type="text"
            name="client_lie"
            value={simplifiedFormFilters.client_lie}
            change={actions.onFiltersChange}
            fullWidth
            {...smallInputProps}
          />
        </div>
        {myProducers.length > 1 && (
          <div className="col-12 col-md-6">
            <Control
              label={
                <>
                  <div>
                    <strong>Recherche par partenaire</strong>
                  </div>
                  <div>(sélectionnez dans la liste)</div>
                </>
              }
              type="select"
              name="partenaire_lie"
              value={simplifiedFormFilters.partenaire_lie}
              change={actions.onFiltersChange}
              datas={myProducers}
              dataIndex="uuid"
              dataLabel="nom_societe"
              fullWidth
              {...smallInputProps}
            />
          </div>
        )}
        <div className="col-12 col-md-6">
          <Control
            label={
              <>
                <div>
                  <strong>Par produits</strong>
                </div>
                <div>(sélectionnez plusieurs dans la liste)</div>
              </>
            }
            name="produits"
            type="selectList"
            datas={state.constants.items.QUOTATION_PRODUCT_TYPES}
            value={simplifiedFormFilters.produits}
            change={actions.onFiltersChange}
            fullWidth
            {...smallInputProps}
          />
        </div>
        <div className={searchButtonContainerClassName}>
          <SaveBtn
            className={searchButtonClassName}
            typeBtn="submit"
            save={() => {}}
            text="Rechercher"
            isSaving={isLoading}
            type={searchButtonType}
            loaderColor={searchButtonLoaderColor}
          />
        </div>
      </div>
    </form>
  );
};

export default SimplifiedFormFilters;
