import React from "react";
import styled from "styled-components";
import tools from "../../../../../helpers/tools";

const TodoCreatedBy = ({ todo }) => {
  return (
    <StyledTodoCreatedBy>
      Créée par {todo.createdBy || "Perceval"} - {tools.formatDateHours(todo.createdAt)}
    </StyledTodoCreatedBy>
  );
};

const StyledTodoCreatedBy = styled.span`
  color: var(--color-default);
  font-size: 12px;
  margin: 0 3px;
`;

export default TodoCreatedBy;
