import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import { API_URL } from "../../../config";
import Axios from "axios";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";

const Constants = () => {
  const [constants, setConstants] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(API_URL + "constants/dynamic")
      .then((res) => {
        setConstants(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  function updateData(e, k) {
    var cst = JSON.parse(JSON.stringify(constants));
    var { name, value } = e.target;
    cst.find((c) => c.entity == name).values[k].value = value;

    setIsSaved(false);
    setConstants(cst);
  }

  function save() {
    setIsSaving(true);
    Axios.patch(API_URL + "constants/dynamic", constants)
      .then((res) => {
        toast.success("Les constantes ont bien été enregistrées");
        setIsSaved(true);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSaving(false);
      });
  }

  return (
    <Page
      title="Constantes dynamiques"
      container="container-fluid px-5"
      errors={errors}
    >
      {isLoading || !constants ? (
        <Loader withContainer={true} />
      ) : (
        <>
          {constants.map((cst, cstk) =>
            cst.values.map((cstval, cstvalk) => (
              <Control
                key={"cst" + cstk + "" + cstvalk}
                label={cstval.name}
                name={cst.entity}
                value={cstval.value}
                change={updateData}
                k={cstvalk}
                error={errors}
              />
            ))
          )}

          <SaveBtn isSaved={isSaved} isSaving={isSaving} save={save} />
        </>
      )}
    </Page>
  );
};

export default Constants;
