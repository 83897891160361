import { GRAAL_API_URL } from "../../config";
import useStore from "../../context/useStore";
import { graalApiAxiosInstance } from "../../middlewares/useApiAuthMiddleware";

const tasksURI = `${GRAAL_API_URL}lead-tasks/tasks`;

const useTaskService = () => {
  const [state] = useStore();

  const taskServiceActions = {
    postTask: (taskData) => {
      return graalApiAxiosInstance
        .post(`${tasksURI}/tasks`, {
          ...taskData,
          createdBy: state.auth.user.id,
        })
        .then((res) => res.data);
    },
    patchTask: (taskId, taskData) => {
      return graalApiAxiosInstance
        .patch(`${tasksURI}/${taskId}`, {
          ...taskData,
          updatedBy: state.auth.user.id,
        })
        .then((res) => res.data);
    },
    patchTasksOrder: (tasksId, tasksData) => {
      return graalApiAxiosInstance
        .patch(`${tasksURI}/${tasksId}/order`, tasksData)
        .then((res) => res.data);
    },
    deleteTask: (taskId) => {
      return graalApiAxiosInstance
        .delete(`${tasksURI}/${taskId}`, {})
        .then((res) => res.data);
    },
  };

  return { taskServiceActions };
};

export default useTaskService;
