import React from "react";
import styled from "styled-components";

const OpenDetailButton = ({
  onClick,
  title,
  className = "",
  dataPriv,
  icon = "fa-eye",
  ...props
}) => {
  return (
    <StyleDetailButton
      data-priv={dataPriv}
      onClick={onClick}
      className={`my-2 my-md-0  cursor-pointer ${className}`}
      {...props}
    >
      <div className="bg-blue px-2 py-1 rounded d-flex align-items-center justify-content-between">
        <strong className="text-left">
          <span className="">{title}</span>
        </strong>
        <i className={`fa ml-2 ${icon}`} />
      </div>
    </StyleDetailButton>
  );
};

const StyleDetailButton = styled.div`
  color: var(--color-primary);
  &:hover {
    color: var(--color-success);
  }
`;

export default OpenDetailButton;
