import React, { useEffect } from "react";
import Loader from "../components/common/Loader";
import useApiAuthMiddleware from "./useApiAuthMiddleware";

const ApiAuthMiddleware = ({ children }) => {
  const { apiAuthError, graalApiAuth, apiAuthActions } = useApiAuthMiddleware();

  useEffect(() => {
    apiAuthActions.authenticateWithTarificatorApi();
  }, []);

  if (apiAuthError) return <div>{apiAuthError}</div>;

  if (!graalApiAuth)
    return (
      <Loader
        containerStyle={{
          height: "100vh",
        }}
        text="Nous préparons votre espace"
      />
    );

  return children;
};

export default ApiAuthMiddleware;
