import React from "react";
import usePjRenewList from "../usePjRenewList";

const PjCount = () => {
  const { isLoadingPjList, pjList } = usePjRenewList();

  return (
    <div className="text-center">
      {isLoadingPjList ? "Recherche en cours..." : <>{pjList.count} contrats trouvés</>}
    </div>
  );
};

export default PjCount;
