import React, { useState } from "react";
import Control from "../Control";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";
import DateRange from "./DateRange";
import moment from "moment";
import Loader from "../Loader";
import Colors from "../../../variables.scss";

const TimelineFilters = ({
  search,
  updateSearch,
  setSearch,
  errors,
  actions,
  types,
  getEvents,
  isLoading,
  autoSearch,
  setFiltersOpen,
  hideTypeFilter,
}) => {
  //bornes dates
  //type
  //searchString
  const [selectedPeriod, setSelectedPeriod] = useState(1);
  const [showFilters, setShowFilters] = useState(false);

  var icons = {
    email: {
      icon: "paper-plane",
      color: "event-info",
    },
    auth: {
      icon: "power-off",
      color: "event-light",
    },
    user: {
      icon: "users",
      color: "event-secondary",
    },
    document: {
      icon: "book",
      color: "event-danger",
    },
    policy: {
      icon: "file-contract",
      color: "event-tertiary",
    },
  };

  return (
    <form
      className="row mb-3"
      onSubmit={(e) => {
        e.preventDefault();
        getEvents();
      }}
    >
      {showFilters ? null : (
        <div className="w-100">
          <button
            className="btn btn-link"
            onClick={() => {
              setFiltersOpen(true);
              setShowFilters(true);
            }}
          >
            Recherche étendue
          </button>
        </div>
      )}
      {!showFilters ? null : (
        <>
          <div className={"form-group col-12"}>
            <label className="d-block">Période</label>
            <div className="d-flex flex-wrap justify-content-start">
              <button
                className={`btn  mb-2 mr-2 btn-sm ${
                  selectedPeriod == 1
                    ? "bg-success text-white border"
                    : "bg-white text-primary border"
                }`}
                disabled={isLoading}
                onClick={() => {
                  setSelectedPeriod(1);
                  setSearch({
                    ...search,
                    beginAt: DateTime.local().minus({ months: 3 }).toISO(),
                  });
                }}
                style={{ borderRadius: 10 }}
              >
                3 derniers mois
              </button>
              <button
                className={`btn  mb-2 mr-2  btn-sm ${
                  selectedPeriod == 2
                    ? "bg-success text-white border"
                    : "bg-white text-primary border"
                }`}
                disabled={isLoading}
                onClick={() => {
                  setSelectedPeriod(2);
                  setSearch({
                    ...search,
                    beginAt: DateTime.local().minus({ months: 6 }).toISO(),
                  });
                }}
                style={{ borderRadius: 10 }}
              >
                6 derniers mois
              </button>
              <button
                className={`btn  mb-2 mr-2  btn-sm ${
                  selectedPeriod == 3
                    ? "bg-success text-white border"
                    : "bg-white text-primary border"
                }`}
                disabled={isLoading}
                onClick={() => {
                  setSelectedPeriod(3);
                  setSearch({
                    ...search,
                    beginAt: DateTime.local().startOf("year").toISO(),
                  });
                }}
                style={{ borderRadius: 10 }}
              >
                Année en cours
              </button>
              <button
                className={`btn  mb-2 mr-2 btn-sm  ${
                  selectedPeriod == 4
                    ? "bg-success text-white border"
                    : "bg-white text-primary border"
                }`}
                disabled={isLoading}
                onClick={() => {
                  setSelectedPeriod(4);
                  setSearch({
                    ...search,
                    beginAt: DateTime.local(2020, 1, 1).toISO(),
                  });
                }}
                style={{ borderRadius: 10 }}
              >
                Depuis toujours
              </button>
            </div>
          </div>
          {!hideTypeFilter && (
            <div className={"form-group col-12"}>
              <label htmlFor={"type"} className={"d-block "}>
                Filtrer par type d'événement
              </label>
              <div className={"d-flex flex-wrap "}>
                {types
                  .filter((f) => (search.model.length ? f.models.includes(search.model) : true))
                  .map((d, dk) => {
                    return (
                      <button
                        disabled={isLoading}
                        onClick={() => {
                          if (search.type == d.id) {
                            setSearch({ ...search, type: "" });
                          } else {
                            updateSearch({
                              target: {
                                name: "type",
                                value: d.id,
                              },
                            });
                          }
                        }}
                        className={
                          "btn btn-event pl-2 shadow-sm m-1 mb-2 d-flex align-items-center justify-content-start " +
                          (search.type == d.id ? "active " : "") +
                          "btn-default text-left "
                        }
                        style={{
                          border: `1px solid ${Colors.primary}`,
                          borderRadius: 10,
                          color: Colors.primary,
                          height: 40,
                        }}
                        type="button"
                        key={"type" + dk}
                      >
                        <div
                          className="rounded-circle d-center mr-2 shadow-sm"
                          style={{
                            background: Colors[d.color],
                            fontSize: 12,
                            width: 30,
                            height: 30,
                            lineHeight: "20px",
                            border: "3px solid white",
                          }}
                        >
                          <i
                            className={`fa d-center text-white text-center p-2  fa-${d.icon}`}
                            style={{
                              //border: "3px solid white",
                              borderRadius: "100%",
                              //width: 34,
                              //minWidth: 34,
                              //height: 34,
                            }}
                          />
                        </div>
                        {d.name}
                      </button>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )}
      {/* <div className="col-12 col-md-6">
        {search.type.length ? (
          <Control
            label="Action"
            type="btnList"
            name="action"
            btnInline
            datas={actions.filter((a) => a.type == search.type)}
            value={search.action}
            change={updateSearch}
            error={errors}
          />
        ) : null}
      </div> */}

      {autoSearch ? null : (
        <div className="col-12 d-flex align-items-end justify-content-start mt-2">
          <button className="btn btn-primary btn-sm" type="submit">
            {isLoading ? <Loader color="white" size={24} /> : <>Afficher l'historique</>}
          </button>
        </div>
      )}
    </form>
  );
};

export default TimelineFilters;
