import React from "react";
import Pagination from "../../common/Pagination";
import useBlackList from "./useBlackList";

const BlackListPagination = () => {
  const { actions, nbResults, blackListFilters } = useBlackList();

  return (
    <>
      <div className="w-100 d-center">{nbResults} résultat(s)</div>
      <Pagination
        page={blackListFilters.page}
        nbPages={Math.ceil(nbResults / 25)}
        changePage={actions.changePage}
      />
    </>
  );
};

export default BlackListPagination;
