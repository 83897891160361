import React from "react";
import tools from "../../../../../../../helpers/tools";

const HighFeature = ({
  title,
  value,
  content = null,
  items = [],
  tips = null,
  isActive,
  ...props
}) => {
  const lightblue = "#518ce5";

  return (
    <div className="rounded text-white my-4 position-relative shadow-small overflow-hidden">
      <div
        className="p-3"
        style={{
          background: lightblue,
        }}
      >
        <div className="text-center font-weight-bold">{title}</div>
        {value && (
          <div
            className="text-center my-3"
            style={{
              fontSize: 24,
              fontWeight: "800",
            }}
          >
            {tools.truenumberWithSpaces(value)} €
          </div>
        )}

        <ul className="list-group">
          {items.map((item, itk) => {
            return (
              <li
                className="list-group-item bg-transparent border-0 d-flex flex-row align-items-start justify-content-start py-1 pl-0"
                key={`likeyhf${itk}`}
              >
                <img src="/images/icons/star.png" className="mr-2 animated rotate very-slow" />
                <p className="mb-0">{item.text}</p>
              </li>
            );
          })}
        </ul>
      </div>
      {content && (
        <div className={`${isActive ? "bg-blue" : "bg-default"}`}>
          <div style={{ height: 30 }}>
            <svg
              className="divider"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{ height: 30, width: "100%", fill: lightblue }}
            >
              <path d="M0 0 L50 100 L100 0 Z"></path>
            </svg>
          </div>
          {content}
        </div>
      )}
    </div>
  );
};

export default HighFeature;
