import React, { useEffect } from "react";
import Page from "../../common/layout/Page";
import useSimplifiedFormList, {
  SimplifiedFormListProvider,
} from "./useSimplifiedFormList";
import useManagers from "../../../hooks/useManagers/useManagers";
import SimplifiedFormWrapper from "../SimplifiedFormDetailPage/SimplifiedFormWrapper";
import SimplifiedFormList from "./SimplifiedFormList/SimplifiedFormList";
import useRole from "../../../hooks/useRole/useRole";
import SimplifiedFormFilters from "./SimplifiedFormList/SimplifiedFormFilters/SimplifiedFormFilters";
import useGraalProducers from "../../../hooks/useProducers/useGraalProducers";
import Pagination from "../../common/Pagination";
import Loader from "../../common/Loader";
import MerlinHelp from "../Documentation/MerlinHelp";

const SimplifiedFormListPage = () => {
  const { actions, isCreateMode, simplifiedFormFilters, nbLead, isLoading } =
    useSimplifiedFormList();
  const { managers } = useManagers(true);
  const { myRole } = useRole();
  const { myProducers } = useGraalProducers(true);

  if (managers.length == 0) return <Loader />;
  if (myProducers.length == 0) return <Loader />;

  return (
    <Page
      title="Leads internes"
      subTitle="Demandes issues du réseau de partenaires Perceval"
      titleMargin="mb-2"
    >
      <MerlinHelp linkKey="leads-internes" />
      <SimplifiedFormListProvider value={{ myProducers, myRole }}>
        {isCreateMode && (
          <>
            <button
              className="btn btn-link mb-3"
              onClick={actions.onClickReturnToList}
            >
              <i className="fa fa-arrow-left mr-2" />
              Revenir à la liste
            </button>
            <SimplifiedFormWrapper
              onAfterSubmitParameters={actions.onNewLeadCreated}
              myProducers={myProducers}
              myRole={myRole}
            />
          </>
        )}
        {!isCreateMode && (
          <>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary mb-2"
                onClick={actions.onClickCreateNewLead}
              >
                <i className="fa fa-plus mr-2" />
                Créer un nouveau lead interne
              </button>
            </div>
            <SimplifiedFormFilters />
            <SimplifiedFormList isLoading={isLoading} />
            <Pagination
              page={simplifiedFormFilters.page}
              nbPages={Math.ceil(nbLead / 25)}
              changePage={actions.updatePage}
            />
          </>
        )}
      </SimplifiedFormListProvider>
    </Page>
  );
};

export default SimplifiedFormListPage;
