import React, { useEffect, useState } from "react";
import Control from "../../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";
import useFormState from "../useFormState";

const GlobalInfosInputs = () => {
  const { constants, actions, errors, formData, inputsConfig } = useFormState();
  const inputState = formData.globalInfosInputs;
  const updateMrpData = (e) => {
    const { name, value } = e.target;
    actions.updateGlobalInfosInputs({ [name]: value });
  };

  const onBlur = () => {};

  return (
    <BlockWrapper>
      <Control
        label={inputsConfig.turnover.label}
        type="number"
        name="turnover"
        value={inputState.turnover}
        change={updateMrpData}
        error={errors}
        suffix={"€"}
        labelClassname="w-100"
        info={
          "Indiquez le Chiffre d'Affaires prévisionnel Hors Taxes pour votre année fiscale en cours."
        }
        onBlur={onBlur}
      />

      <Control
        label={inputsConfig.workforce.label}
        type="number"
        name="workforce"
        value={inputState.workforce}
        change={updateMrpData}
        error={errors}
        labelClassname="w-100"
        info={
          "Indiquez 0 si vous êtes entrepreneur individuel et/ou que votre entreprise ne compte pas de salariés."
        }
        onBlur={onBlur}
      />
    </BlockWrapper>
  );
};

export default GlobalInfosInputs;
