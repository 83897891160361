import { Provider } from "jotai";
import React from "react";
import Page from "../../common/layout/Page";
import RBEFilters from "./RBEFilters/RBEFilters";
import { RBEListContext } from "./RBEListContext";
import RBEListItem from "./RBEListItem/RBEListItem";
import RBEPagination from "./RBEListItem/RBEPagination";
import useRbe from "./useRbe";

const RBEList = () => {
  const { rbeList, actions } = useRbe();

  return (
    <Page
      title="Registre des Bénéficiaires Effectifs"
      subTitle="Les données de cette liste sont actualisées tous les matins. Si de nouvelles entrées ont été ajoutées en cours de journée, celles-ci ne seront donc visibles que le lendemain."
    >
      <RBEFilters />
      <RBEPagination />

      <RBEListContext.Provider value={actions}>
        {rbeList.map((rbe) => (
          <Provider>
            <RBEListItem rbeData={rbe} key={`rbeItem${rbe.id}`} />
          </Provider>
        ))}
      </RBEListContext.Provider>
      {rbeList.length > 0 && <RBEPagination />}
    </Page>
  );
};

export default RBEList;
