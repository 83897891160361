import React from "react";
import tools from "../../../../helpers/tools";
import CheckList from "./CheckList";
import CompanyRecapWrapper from "./CompanyRecapWrapper";
import CompanyTopRecap from "./CompanyTopRecap";
import InputDisplay from "./InputDisplay";

const ACBlocks = ({ company, clientId }) => {
  return (
    <CompanyRecapWrapper>
      <CompanyTopRecap company={company} product="AC" />
      <div className="mt-3">
        <CheckList
          items={[
            {
              text: (
                <>
                  Activité BTP principale :{" "}
                  <InputDisplay inputName="activity" inputType="btnList" inputValue={"1"} />
                </>
              ),
            },
            {
              text: (
                <>
                  Chiffre d'Affaires :{" "}
                  <InputDisplay
                    inputName="turnover"
                    inputType="text"
                    inputValue={<>{tools.truenumberWithSpaces(company.turnover)} €</>}
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  CA sous-traité :{" "}
                  <InputDisplay
                    inputName="turnoverOutsourced"
                    inputType="text"
                    inputValue={
                      <>
                        {tools.truenumberWithSpaces(
                          Math.round((company.turnoverOutsourced * company.turnover) / 100)
                        ) || 0}{" "}
                        €
                      </>
                    }
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  CA donné en sous-traitance :{" "}
                  <InputDisplay
                    inputName="turnoverProviderOutsourced"
                    inputType="text"
                    inputValue={
                      <>
                        {tools.truenumberWithSpaces(
                          Math.round((company.turnoverProviderOutsourced * company.turnover) / 100)
                        ) || 0}{" "}
                        €
                      </>
                    }
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </CompanyRecapWrapper>
  );
};

export default ACBlocks;
