import React, { useEffect } from "react";
import ClientAndPolicyInfos from "./ClientAndPolicyInfos/ClientAndPolicyInfos";
import CommissionDetail from "./CommissionDetail/CommissionDetail";
import useCommissionItem from "./useCommissionItem";

const CommissionItem = ({ commissionData }) => {
  const { actions } = useCommissionItem();

  useEffect(() => {
    actions.initCommission(commissionData);
  }, [commissionData]);

  return (
    <div className="row">
      <div className="col-12 col-md-5">
        <ClientAndPolicyInfos />
      </div>
      <div className="col-12 col-md-7">
        <CommissionDetail />
      </div>
    </div>
  );
};

export default CommissionItem;
