import React from "react";
import useClientSelector from "./useClientSelector";
import ClientCard from "../ClientCard/ClientCard";

const ClientSelectorList = () => {
  const { clients, actions } = useClientSelector();

  return (
    <div className="row">
      {clients.map((client, ck) => {
        return (
          <ClientCard
            key={`client${ck}`}
            client={{
              ...client,
              civilite: client.gender,
              prenom: client.firstname,
              nom: client.lastname,
              telephone: client.phone,
            }}
            onSelectClient={actions.onSelectClient}
          />
        );
      })}
    </div>
  );
};

export default ClientSelectorList;
