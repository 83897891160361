import React, { useEffect } from "react";
import styled from "styled-components";
import tools from "../../../../../../helpers/tools";
import LabelValuePair from "../../../../../common/LabelValuePair/LabelValuePair";
import ReceiptPaymentHistorics from "../../../../ClientListPage/ClientList/ClientItem/CompanyList/CompanyItem/BottomPanel/BPPolicies/PolicyItem/BPPolicyReceipts/ReceiptPaymentHistorics/ReceiptPaymentHistorics";
import useReceiptItem from "../../../../ClientListPage/ClientList/ClientItem/CompanyList/CompanyItem/BottomPanel/BPPolicies/PolicyItem/BPPolicyReceipts/useReceiptItem";
import useCommissionItem from "../useCommissionItem";
import Amount from "./Amount";

const CommissionDetail = () => {
  const { commission } = useCommissionItem();
  const { receiptStatusRef, initReceipt } = useReceiptItem();
  const { receipt, amendment } = commission;

  useEffect(() => {
    if (receipt) {
      initReceipt(receipt);
    }
  }, [receipt]);

  return !receipt ? null : (
    <StyledCommissionDetail className="bg-light p-3 border border-top-0 rounded-bottom">
      <div className="row">
        <div className="col-12 ">
          <div>
            <span className="text-big-2">Quittance</span> du{" "}
            <strong>{tools.formatDate(receipt.createdAt)}</strong>
          </div>
          <div className=" ">
            Motif :{" "}
            <span className="text-big-3">{tools.getAmendmentReason(amendment.details)}</span>
          </div>
        </div>
        <div className="col-12 ">
          {receipt.payments.status === "WAITING" && (
            <div className={`text-big-3 badge badge-${receiptStatusRef.color}`}>
              {receiptStatusRef.name}
            </div>
          )}
          <div>
            <LabelValuePair label="Date limite" value={tools.formatDate(receipt.limitDate)} />
          </div>
        </div>
      </div>
      <div className="row mt-2 ">
        <div className="col-12">
          <div className="row mx-0  justify-content-between">
            <div className="col-12 col-md-6 text-center">
              <div className="bg-blue py-2 border rounded">
                <Amount label="Montant de la quittance" amount={receipt.premiumWithVat} />
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="bg-blue py-2 border rounded">
                <Amount label="Montant de la commission" amount={receipt.commission} />
              </div>
            </div>
            <div className="col-12 text-center mt-2">
              du <strong>{tools.formatDate(receipt.effectDate)}</strong> au{" "}
              <strong>{tools.formatDate(receipt.endDate)}</strong>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div>Paiements : </div>
          <ReceiptPaymentHistorics />
        </div>
      </div>
    </StyledCommissionDetail>
  );
};

export default CommissionDetail;

const StyledCommissionDetail = styled.div`
  & .text-big-1 {
    font-size: 26px;
    font-weight: 800;
  }
  & .text-big-2 {
    font-size: 20px;
    font-weight: 800;
  }
  & .text-big-3 {
    font-size: 16px;
    font-weight: 600;
  }
`;
