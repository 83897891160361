import React from "react";
import Colors from "../../variables.scss";

const Accordion = ({
  items,
  k = "1",
  className = "",
  color = "primary",
  btnClassName = "",
  cardClassName = "",
  btnStyle = {},
  btnContainerClassName = "",
  bodyClassName = "p-0",
  style = {},
  borderColor = "default",
  collapseClassName = "",
  noChevron = false,
}) => {
  return (
    <div
      id={"accordion" + k}
      className={"accordion-container " + className}
      key={"acc" + k}
      style={style}
    >
      {items.map((it, itk) => (
        <div
          data-priv={it.dataPriv}
          className={"card " + cardClassName}
          key={"acc" + itk + k}
        >
          <div
            className="card-header p-0 bg-transparent"
            id={"heading" + itk + k}
          >
            <h5 className={"mb-0 acc-content " + btnContainerClassName}>
              <div
                onClick={it.onClick ? it.onClick : () => {}}
                style={{
                  ...btnStyle,
                  borderRadius: window.borderRadius,
                  cursor: "pointer",
                }}
                className={
                  "btn acc-btn d-flex align-items-center justify-content-between w-100 acc-btn btn-" +
                  color +
                  " " +
                  btnClassName
                }
                data-toggle="collapse"
                data-target={"#collapseOne" + itk + k}
                aria-expanded="false"
                aria-controls={"collapseOne" + itk + k}
              >
                {it.title}
                {!noChevron && (
                  <i className="fa fa-chevron-down text-success p-2" />
                )}
              </div>
            </h5>
          </div>

          <div
            id={"collapseOne" + itk + k}
            className={"collapse " + collapseClassName}
            aria-labelledby={"heading" + itk + k}
            data-parent={"#accordion" + k}
            data-ref={it.ref}
            style={{
              borderLeft: "1px dashed " + Colors[borderColor],
              borderBottomLeftRadius: window.borderRadius,
              background: "white",
            }}
          >
            <div
              className={"card-body " + bodyClassName}
              style={{
                borderRadius: window.borderRadius,
              }}
            >
              {it.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
