import React from "react";
import tools from "../../../../../../helpers/tools";
import LabelValuePair from "../../../../../common/LabelValuePair/LabelValuePair";
import LegalFormValue from "../../../../../common/LegalFormValue/LegalFormValue";

const CompanyDetails = ({ client }) => {
  return (
    <div>
      <div>
        <LabelValuePair
          label="Forme juridique"
          value={<LegalFormValue legalForm={client.legalForm} />}
        />
      </div>
      <div>
        <LabelValuePair label="Activité" value={client.companyActivity} />
      </div>
      <div>
        <LabelValuePair
          label="Chiffre d'affaires"
          value={`${tools.truenumberWithSpaces(client.turnover)} €`}
        />
      </div>
      <div>
        <LabelValuePair label="Code APE" value={client.ape} />
      </div>
      <div>
        <LabelValuePair label="Effectif" value={client.workforce} />
      </div>
      <div>
        <LabelValuePair label="Code postal" value={client.location1?.postalCode} />
      </div>
    </div>
  );
};

export default CompanyDetails;
