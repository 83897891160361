import React from "react";
import TimelineFiltered from "../../common/Timeline/TimelineFiltered";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";

const MyTimeline = () => {
  const [state, dispatch] = useStore();

  return (
    <div className="  my-5 mx-5">
      {state.auth.user.id && (
        <TimelineFiltered
          showFilters={true}
          initialSearch={{
            model: "users",
            id: "me",
            searchString: "",
            action: "",
            type: "",
            beginAt: DateTime.local().minus({ months: 1 }).startOf("day"),
            endAt: DateTime.local().endOf("day"),
          }}
          autoSearch={true}
        />
      )}
    </div>
  );
};

export default MyTimeline;
