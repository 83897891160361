import React, { useState } from "react";
import Control from "../../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";
import useFormState from "../useFormState";
import constructionTips from "./constructionTips";

const ConstructionInputs = () => {
  const { constants, actions, errors, formData, inputsConfig } = useFormState();
  const inputState = formData.constructionInputs;
  const updateMrpData = (e) => {
    const { name, value } = e.target;
    actions.updateConstructionInputs({ [name]: value });
  };

  const updateMrpDataQualityOccupant = (e) => {
    const { name, value, type, checked } = e.target;

    let updateObj = {
      qualityOccupant: value,
      isWaiverOfRecourse: value == 4 ? 1 : "",
      waiverOfRecourseType: value == 4 ? 1 : "",
    };
    actions.updateConstructionInputs(updateObj);
  };

  const onBlur = () => {};

  return (
    <BlockWrapper>
      <Control
        label={inputsConfig.buildingArea.label}
        name="buildingArea"
        type="float"
        value={inputState.buildingArea}
        change={updateMrpData}
        labelClassname="w-100"
        error={errors}
        suffix="m²"
        tips={constructionTips.buildingArea}
        onBlur={onBlur}
      />
      <Control
        name="wallType"
        label={inputsConfig.wallType.label}
        type="checkBtnList"
        value={inputState.wallType}
        datas={inputsConfig.wallType.datas}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        dataIcon={"icon"}
        error={errors}
        info="Choix multiples"
        onBlur={onBlur}
      />
      <Control
        name="roofType"
        label={inputsConfig.roofType.label}
        type="checkBtnList"
        value={inputState.roofType}
        datas={inputsConfig.roofType.datas}
        change={updateMrpData}
        btnInline
        fullWidth
        dataIcon={"icon"}
        labelClassname="w-100"
        error={errors}
        info="Choix multiples"
        onBlur={onBlur}
      />
      <Control
        name="qualityOccupant"
        label={inputsConfig.qualityOccupant.label}
        type="btnList"
        value={inputState.qualityOccupant}
        datas={inputsConfig.qualityOccupant.datas}
        dataIndex="id"
        change={updateMrpDataQualityOccupant}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      {inputState.qualityOccupant && inputState.qualityOccupant != "4" && (
        <>
          <Control
            name="isWaiverOfRecourse"
            label={inputsConfig.isWaiverOfRecourse?.label}
            type="btnList"
            value={inputState.isWaiverOfRecourse}
            datas={inputsConfig.isWaiverOfRecourse?.datas || []}
            dataIndex="id"
            change={updateMrpData}
            btnInline
            fullWidth
            labelClassname="w-100"
            error={errors}
            tips={constructionTips.isWaiverOfRecourse}
            onBlur={onBlur}
          />
          {inputState.isWaiverOfRecourse == 1 && (
            <Control
              name="waiverOfRecourseType"
              label={inputsConfig.waiverOfRecourseType?.label}
              type="btnList"
              value={inputState.waiverOfRecourseType}
              datas={inputsConfig.waiverOfRecourseType?.datas || []}
              dataIndex="id"
              change={updateMrpData}
              btnInline
              fullWidth
              labelClassname="w-100"
              tips={constructionTips.waiverOfRecourseType}
              error={errors}
              onBlur={onBlur}
            />
          )}
        </>
      )}
    </BlockWrapper>
  );
};

export default ConstructionInputs;
