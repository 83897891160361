import dataService from "../../helpers/dataService";

const useSearchCompanies = () => {
  const searchEnterprise = ({ searchValue }) => {
    return new Promise((resolve, reject) => {
      dataService.get(`companies/search/${searchValue}`, resolve, reject);
    });
  };

  const searchEstablishments = ({ searchValue }) => {
    return new Promise((resolve, reject) => {
      dataService.get(
        `companies/${searchValue}/establishments`,
        resolve,
        reject
      );
    });
  };

  const addBlackListCompany = (searchData) => {
    return new Promise((resolve, reject) => {
      dataService.post(`blacklists`, searchData, resolve, () => {});
    });
  };

  return { searchEnterprise, searchEstablishments, addBlackListCompany };
};

export default useSearchCompanies;
