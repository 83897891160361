import { Provider } from "jotai";
import { useEffect } from "react";
import Loader from "../../../../../../../../../../common/Loader";
import usePolicyItem from "../usePolicyItem";
import ReceiptItem from "./ReceiptItem";

const BPPolicyReceipts = () => {
  const { policy, isLoadingPolicyReceipts, policyActions } = usePolicyItem();

  useEffect(() => {
    if (!policy.receipts?.length) {
      policyActions.onGetPolicyReceipts();
    }
  }, []);

  return (
    <div className="row mx-0">
      {isLoadingPolicyReceipts && <Loader withContainer />}
      {policy.receipts?.length > 0 &&
        policy.receipts.map((receipt) => {
          console.log("RECEIPT", receipt);

          return (
            <Provider>
              <ReceiptItem receiptData={receipt} key={`recei${receipt.id}`} />
            </Provider>
          );
        })}
    </div>
  );
};

export default BPPolicyReceipts;
