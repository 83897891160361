import React, { useEffect } from "react";
import useCompanyItem from "../../useCompanyItem";
import useBills from "./useBills";
import Loader from "../../../../../../../../common/Loader";
import MesFacturesList from "./MesFactures";

const BPBills = () => {
  const { company, bottomPanelParams } = useCompanyItem();

  const { bills, getClientBills, isLoadingBills } = useBills();

  useEffect(() => {
    getClientBills(company.id);
  }, []);

  if (isLoadingBills) return <Loader />;
  if (!bills) return null;

  return (
    <div style={{ margin: "0 auto", maxWidth: 960 }}>
      <div className="mb-2">
        <h2 className="text-success">Factures</h2>
      </div>
      <MesFacturesList factures={bills} />
    </div>
  );
};

export default BPBills;
