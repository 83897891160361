import React, { useState, useEffect } from "react";
import TabClient from "./TabClient";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import { toast } from "react-toastify";

var keepDocumentK = [];

const ViewClient = ({
  datas,
  updateFile,
  setUsers,
  openClient,
  isBOA,
  closeOverlay,
}) => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocsLoading, setIsDocsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  var user = datas.user;
  var client = datas.client;
  var currentTab = datas.currentTab;
  var currentIndex = 0;

  useEffect(() => {
    if (datas.currentTab == 0) {
      //getDocuments();
    }
  }, []);

  useEffect(() => {
    if (datas.currentTab == 0) {
      getDocuments();
    }
  }, [datas]);

  function getDocuments() {
    setIsDocsLoading(true);
    Axios.get(API_URL + "clients/" + client.id + "/files")
      .then((res) => {
        setDocuments(res.data);
      })
      .then(() => {
        setIsDocsLoading(false);
      });
  }

  function documentUploaded(k, ck, doc) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs = JSON.parse(JSON.stringify(keepDocumentK));
    dcs[k][0] = JSON.parse(JSON.stringify(doc));
    toast.success("Document envoyé avec succès");
    setDocuments(dcs);
  }
  function documentRemoved(k, ck, oldDoc) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[k] = dcs[k].filter((dc, dck) => {
      return dck != ck;
    });

    if (dcs[k].length == 0) {
      dcs[k].push({
        fileType: oldDoc.fileType,
        entity: oldDoc.entity,
      });
    }
    keepDocumentK = JSON.parse(JSON.stringify(dcs));
    toast.success("Document supprimé avec succès");
    setDocuments(dcs);
  }

  function uploadNewFile(k, ck) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[k] = [
      {
        fileType: dcs[k][ck].fileType,
        entity: dcs[k][ck].entity,
        isNewVersion: true,
      },
    ].concat(dcs[k]);
    keepDocumentK = JSON.parse(JSON.stringify(dcs));

    setDocuments(dcs);
  }

  useEffect(() => {
    keepDocumentK = JSON.parse(JSON.stringify(documents));
  }, [documents]);

  function cancelNewVersion() {
    getDocuments();
  }

  return isLoading ? (
    <Loader withContainer />
  ) : (
    <>
      <div className="pt-2 mb-5 d-flex w-75 justify-content-center mx-auto align-items-center flex-wrap">
        <div className="text-center">
          <h4>Détail de la société {client.company}</h4>
        </div>
      </div>
      <TabClient
        client={client}
        openClient={() => openClient(user, 0)}
        isDocsLoading={isDocsLoading}
        documents={documents}
        documentUploaded={documentUploaded}
        documentRemoved={documentRemoved}
        uploadNewFile={uploadNewFile}
        cancelNewVersion={cancelNewVersion}
        isBOA={isBOA}
        closeOverlay={closeOverlay}
      />
    </>
  );
};

export default ViewClient;
