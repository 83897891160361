import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Results from "./Results/Results";
import InputsPJ from "./InputsPJ";
import SearchCompany from "./SearchCompany";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import useGraalApi from "../../../hooks/useGraalApi/useGraalApi";
import useApiAuthMiddleware from "../../../middlewares/useApiAuthMiddleware";

var searchTimeout = null;

const Simulator = () => {
  const [params, setParams] = useState({
    productType: "PJ",
    postalCode: "974",
    ape: "6311Z",
    commission: 30,
    turnover: "100000",
    beginAt: DateTime.local().startOf("day").toISO(),
    endAt: DateTime.local().plus({ year: 1 }).startOf("day"),
    rc: false,
    fs: false,
  });
  const [search, setSearch] = useState({
    search: "",
    source: "opendata",
    company: "",
    companies: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [results, setResults] = useState([]);
  const { graalApiActions } = useGraalApi();
  const { apiAuthActions } = useApiAuthMiddleware();

  const updateSearch = (e) => {
    let sh = JSON.parse(JSON.stringify(search));
    let { name, value } = e.target;
    sh[name] = value;
    setSearch(sh);
  };

  useEffect(() => {
    Object.values(errors).forEach((err) => toast.error(err));
  }, [errors]);

  useEffect(() => {
    if (search.company != "") {
      var par = JSON.parse(JSON.stringify(params));

      var selectedCompany = search.companies.find(
        (s) => s.siret == search.company
      );
      var spCp = ["973", "976"];
      var cp = selectedCompany.location1?.postalCode?.substring(0, 3);

      par.postalCode = spCp.includes(cp) ? "973" : "974";
      par.ape = selectedCompany.ape;
      par.turnover = selectedCompany.turnover;
      setParams(par);
    }
  }, [search.company]);

  const searchCompany = (e) => {
    e?.preventDefault();
    setErrors({});
    setSearchLoading(true);
    Axios.post(API_URL + "tarificators/companies/search/", search)
      .then((res) => {
        setSearch((s) => {
          return { ...s, companies: res.data };
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setSearchLoading(false);
      });
  };

  const updateInput = (e) => {
    var par = JSON.parse(JSON.stringify(params));
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      par[name] = checked;
    } else {
      par[name] = value;
    }
    setParams(par);
  };

  const calculate = async (e) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    var formattedParams = {
      ...params,
      beginAt: DateTime.fromISO(params.beginAt).toFormat("yyyy-MM-dd"),
      endAt: DateTime.fromISO(params.endAt).toFormat("yyyy-MM-dd"),
      postalCode: params.postalCode + "00",
      rc: undefined,
      fs: undefined,
      optRc: params.rc,
      optFs: params.fs,
    };

    try {
      const result = await graalApiActions.getTarification(formattedParams);
      console.log("result", result);
      setResults(result.data);
    } catch (err) {
      if (err.response?.status == 401) {
        const authReq = await apiAuthActions.authenticateWithTarificatorApi();
        if (authReq) {
          calculate();
        }
      }
    }
    setIsLoading(false);

    // dataService.post(
    //   `tarificators/products/PJ/prices/private`,
    //   formattedParams,
    //   setResults,
    //   setErrors,
    //   () => setIsLoading(false)
    // );
  };

  return (
    <Page title="Simulateur PJ" errors={errors}>
      <form onSubmit={searchCompany}>
        <SearchCompany
          search={search}
          updateSearch={updateSearch}
          searchLoading={searchLoading}
          searchCompany={searchCompany}
        />
      </form>
      <hr />
      <form onSubmit={calculate}>
        <InputsPJ params={params} updateInput={updateInput} />

        <SaveBtn
          className="m-auto"
          type="primary"
          typeBtn="submit"
          text="Calculer"
          isSaving={isLoading}
        />
      </form>

      <Results results={results || []} />
    </Page>
  );
};

export default Simulator;
