import React from "react";
import Page from "../../common/layout/Page";
import ClientList from "./ClientList/ClientList";

const ClientListPage = () => {
  return (
    <Page container="container-fluid" title="Liste des clients">
      <ClientList />
    </Page>
  );
};

export default ClientListPage;
