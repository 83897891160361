import React from "react";
import tools from "../../../../helpers/tools";
import EmailLink from "../../../common/CommentList/common/EmailLink/EmailLink";
import PhoneLink from "../../../common/CommentList/common/PhoneLink/PhoneLink";
import CompanyInfo from "../../../common/CompanyInfo/CompanyInfo";
import DocumentList from "../../../common/DocumentList/DocumentList";
import LabelValuePair from "../../../common/LabelValuePair/LabelValuePair";
import { PanelSectionTitle } from "../../../common/layout/StyledComponents/StyledComponents";
import LegalFormValue from "../../../common/LegalFormValue/LegalFormValue";
import RBEItemBottomPanel from "./RBEItemBottomPanel/RBEItemBottomPanel";
import useRBEListItem from "./useRBEListItem";

const BottomPanel = () => {
  const { rbeListItem, bottomPanelContent, isSearchingFiles } = useRBEListItem();

  return (
    <RBEItemBottomPanel>
      {(() => {
        switch (bottomPanelContent) {
          case "INFOS":
            return (
              <>
                <DocumentList
                  title="Documents de la société"
                  files={rbeListItem.files.filter((f) => f[0].entity.ref == "Client")}
                  isLoading={isSearchingFiles}
                />
                <PanelSectionTitle>Société</PanelSectionTitle>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <CompanyInfo
                      isHeadOffice
                      company={rbeListItem.client.company}
                      siret={rbeListItem.client.siret}
                      infos={[
                        tools.getFullName(rbeListItem.userCustomer),
                        <PhoneLink phone={rbeListItem.userCustomer.phone} />,
                        <EmailLink email={rbeListItem.userCustomer.email} />,
                        <LabelValuePair
                          label="Forme juridique"
                          value={<LegalFormValue legalForm={rbeListItem.client.legalForm} />}
                        />,
                        <LabelValuePair
                          label="Adresse"
                          value={tools.getFullAddress(rbeListItem.client.location1)}
                        />,
                        <LabelValuePair
                          label="CA"
                          value={`${tools.truenumberWithSpaces(rbeListItem.client.turnover)} €`}
                        />,
                        <LabelValuePair
                          label="Immatriculée le"
                          value={tools.formatDate(rbeListItem.client.registrationAt)}
                        />,
                        <LabelValuePair
                          label="Effectif"
                          value={
                            rbeListItem.client.workforce === 0
                              ? "0"
                              : tools.truenumberWithSpaces(rbeListItem.client.workforce)
                          }
                        />,
                      ]}
                    />
                  </div>
                </div>
              </>
            );
        }
      })()}
    </RBEItemBottomPanel>
  );
};

export default BottomPanel;
