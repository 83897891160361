import React from "react";
import styled from "styled-components";
import tools from "../../../../../../helpers/tools";
import useLeadItem from "../useLeadItem";

const UpdatedAt = () => {
  const { lead } = useLeadItem();

  return <StyledUpdatedAt>Modifié le {tools.formatDateHours(lead.updatedAt)}</StyledUpdatedAt>;
};

const StyledUpdatedAt = styled.div``;

export default UpdatedAt;
