import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { REF_BO } from "../../../config";
import dataService from "../../../helpers/dataService";

const useUserDetail = (urlId) => {
  const [account, setAccount] = useState({
    email: "",
    title: "",
    firstname: "",
    lastname: "",
    phone: "",
    password: "",
    affiliateLink: "",
    producer: "",
  });

  const [userRoles, setUserRoles] = useState([
    {
      roleKey: "",
      privileges: [],
    },
  ]);
  const [accountLoading, setAccountLoading] = useState(true);
  const [privilegesLoading, setPrivilegesLoading] = useState(true);
  const [pwdIdentical, setPwdIdentical] = useState(false);
  const [PRIVILEGE_CATEGORIES, SET_PRIVILEGES_CATEGORIES] = useState({});
  const [ROLES, SET_ROLES] = useState({});
  const [PRIVILEGES, SET_PRIVILEGES] = useState({});
  const [roleConfig, setRoleConfig] = useState({});
  const [accountId, setAccountId] = useState(urlId);
  const [errors, setErrors] = useState({});
  const [isInfoSaving, setIsInfoSaving] = useState(false);
  const [isPrivilegesSaving, setIsPrivilegesSaving] = useState(false);
  const [isInfoSaved, setIsInfoSaved] = useState(false);
  const [isPrivilegesSaved, setIsPrivilegesInfoSaved] = useState(false);
  const [modifyAvatar, setModifyAvatar] = useState(false);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (accountId != "create") {
      setAccountLoading(true);
      setPrivilegesLoading(true);

      dataService.get(`managers/${accountId}`, (data) => {
        setAccount(data);
        dataService.get(
          `managers/${accountId}/roles`,
          (roleData) => {
            setUserRoles(roleData);
            actions.getConfig();
          },
          setErrors,
          () => setPrivilegesLoading(false)
        );
      });
    } else {
      actions.getConfig();
    }
  }, []);

  const actions = {
    getConfig: () => {
      dataService.get(`constants/roles`, (roledata) => {
        SET_PRIVILEGES(roledata.PRIVILEGES);
        SET_PRIVILEGES_CATEGORIES(roledata.PRIVILEGE_CATEGORIES);
        SET_ROLES(roledata.ROLES);
        dataService.get(`roles/${REF_BO}`, (data) => {
          setRoleConfig(data);
          setTimeout(() => {
            if (accountId == "create") {
              var minimumPriv = [];
              for (var key in data) {
                if (data[key].SOCIAL) {
                  minimumPriv.push(key);
                }
              }

              setUserRoles((ur) =>
                ur.map((u, uk) => (uk == 0 ? { roleKey: "SOCIAL", privileges: minimumPriv } : u))
              );
            }

            setAccountLoading(false);
            setPrivilegesLoading(false);
          }, 1000);
        });
      });
    },

    updateData: (e) => {
      const { name, value } = e.target;
      const newAccount = { ...account, [name]: value };
      if (name == "password" || name == "password_confirm") {
        actions.checkPwdIdentical(newAccount);
      }
      setAccount(newAccount);
      setErrors({});
      setIsInfoSaved(false);
    },

    changeRole: (e) => {
      var ac = JSON.parse(JSON.stringify(userRoles));

      const { value } = e.target;

      ac[0].roleKey = value;
      var rc = [];
      for (var key in roleConfig) {
        if (roleConfig[key][value]) {
          rc.push(key);
        }
      }
      setIsPrivilegesInfoSaved(false);
      ac[0].privileges = rc;

      setUserRoles(ac);
    },

    changePrivilege: (e) => {
      const { checked, name } = e.target;
      var ac = JSON.parse(JSON.stringify(userRoles));
      if (checked) {
        ac[0].privileges.push(name);
      } else {
        ac[0].privileges = ac[0].privileges.filter((c) => c != name);
        ac[0].privileges = actions.turnOffDependencies(ac[0].privileges, name);
      }

      setIsPrivilegesInfoSaved(false);
      setUserRoles(ac);
    },
    turnOffDependencies: (rc, privilegeKey) => {
      PRIVILEGES[REF_BO].forEach((priv) => {
        if (priv.dependsOn) {
          if (priv.dependsOn.includes(privilegeKey)) {
            rc = rc.filter((r) => r != priv.key);
            rc = actions.turnOffDependencies(rc, priv.key);
          }
        }
      });
      return rc;
    },

    checkPwdIdentical: (ac) => {
      if (ac.password == ac.password_confirm && ac.password && ac.password != "" && !pwdIdentical) {
        setPwdIdentical(true);
      } else {
        setPwdIdentical(false);
      }
    },

    saveInfo: () => {
      if (account.password && account.password != "" && !pwdIdentical) {
        alert("Un problème est présent dans le format du mot de passe");
        return false;
      }
      setIsInfoSaving(true);
      if (accountId == "create") {
        //post
        dataService.post(
          `managers`,
          account,
          (managerData) => {
            setAccountId(managerData.id);
            setIsInfoSaved(true);
            setIsPrivilegesSaving(true);
            toast.success("L'utilisateur a bien été créé");

            //création du role
            dataService.post(
              `managers/${managerData.id}/roles`,
              userRoles[0],
              (roleData) => {
                setUserRoles([roleData]);
                setIsPrivilegesInfoSaved(true);
                toast.success("Les rôles de l'utilisateur ont bien été créés");
              },
              (err) => setErrors(err),
              () => {
                setIsPrivilegesInfoSaved(true);
                setIsPrivilegesSaving(false);
              }
            );
          },
          (err) => setErrors(err),
          () => {
            setIsInfoSaved(true);
            setIsInfoSaving(false);
          }
        );
      } else {
        //patch
        dataService.patch(
          `managers/${accountId}`,
          account,
          (data) => {
            setIsInfoSaved(true);
            toast.success("L'utilisateur a bien été modifié");
          },
          setErrors,
          () => setIsInfoSaving(false)
        );
      }
    },
    savePrivileges: () => {
      setIsPrivilegesSaving(true);
      if (!userRoles[0].id) {
        //post
      } else {
        //patch
        dataService.patch(
          `managers/${accountId}/roles/${userRoles[0].id}`,
          userRoles[0],
          (data) => {
            setIsPrivilegesInfoSaved(true);
            toast.success("Les rôles de l'utilisateur ont bien été enregistrés");
          },
          setErrors,
          () => setIsPrivilegesSaving(false)
        );
      }
    },

    removeUser: () => {
      dataService.remove(
        `managers/${accountId}`,
        {},
        () => {
          toast.success("L'utilisateur a bien été supprimé.");
          history.push("/users");
        },
        setErrors,
        () => {},
        true
      );
    },

    unlockAccount: () => {
      setIsUnlocking(true);
      dataService.get(
        `managers/${accountId}/authorization/reset`,
        () => {
          setIsUnlocked(true);
          setTimeout(() => {
            setIsUnlocked(false);
          }, 3000);
          toast.success("Le compte a été déverouillé");
        },
        (err) => toast.error(`Erreur : ${err?.other}`),
        () => setIsUnlocking(false)
      );
    },
    setModifyAvatar,
    setErrors,
  };

  return {
    actions,
    PRIVILEGES,
    ROLES,
    PRIVILEGE_CATEGORIES,
    roleConfig,
    accountId,
    account,
    errors,
    accountLoading,
    privilegesLoading,
    modifyAvatar,
    pwdIdentical,
    isInfoSaved,
    isInfoSaving,
    userRoles,
    isUnlocked,
    isUnlocking,
    isPrivilegesSaved,
    isPrivilegesSaving,
  };
};

export default useUserDetail;
