import React from "react";

const DateInfo = ({ textColor, children }) => {
  return (
    <div className={`my-1 font-weight-bold text-${textColor}`}>
      <i className="fa fa-calendar-alt mr-1" />
      {children}
    </div>
  );
};

export default DateInfo;
