import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import { API_URL, REF_APP, REF_BO } from "../../../config";
import tools from "../../../helpers/tools";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import Page from "../../common/layout/Page";

const RoleTemplate = () => {
  const [PRIVILEGE_CATEGORIES, SET_PRIVILEGES_CATEGORIES] = useState({});
  const [ROLES, SET_ROLES] = useState({});
  const [PRIVILEGES, SET_PRIVILEGES] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [roleConfig, setRoleConfig] = useState({});
  const [platform, setPlatform] = useState(REF_BO);
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    Axios.get(API_URL + "constants/roles").then((res) => {
      SET_PRIVILEGES(res.data.PRIVILEGES);
      SET_PRIVILEGES_CATEGORIES(res.data.PRIVILEGE_CATEGORIES);
      SET_ROLES(res.data.ROLES);
      getConfig();
    });
  }, []);

  function getConfig() {
    Axios.get(API_URL + "roles/" + platform).then((res) => {
      setRoleConfig(res.data);
      setIsLoading(false);
      setIsInit(true);
    });
  }

  useEffect(() => {
    if (isInit) {
      getConfig();
    }
  }, [platform]);
  useEffect(() => {
    setIsSaved(false);
  }, [roleConfig]);

  useEffect(() => {
    var hoverable = document.querySelectorAll(".highlightable");
    hoverable.forEach((elem) => {
      elem.addEventListener("mouseover", () => {
        activeBgColor(elem, "#efefef", "#dc354540");
      });
      elem.addEventListener("mouseout", () => {
        activeBgColor(elem, "transparent", "#f8f9fa");
      });
    });
  });

  function activeBgColor(elem, bgColor, colorHl) {
    elem.style.background = bgColor;
    let dependencies = elem.getAttribute("data-ref");
    if (dependencies != "") {
      dependencies = dependencies.split("+");
      if (dependencies.length > 0) {
        dependencies.forEach((dep) => {
          var links = document.querySelectorAll("tr[data-key=" + dep + "] .badge");

          links.forEach((lk) => {
            lk.style.background = colorHl;
          });
        });
      }
    }
  }
  function togglePrivilege(e, privilegeKey, roleKey) {
    var rc = JSON.parse(JSON.stringify(roleConfig));

    if (e.target.checked) {
      rc[privilegeKey][roleKey] = true;
    } else {
      rc[privilegeKey][roleKey] = false;
      rc = turnOffDependencies(rc, privilegeKey, roleKey);
    }

    setRoleConfig(rc);
  }
  function turnOffDependencies(rc, privilegeKey, roleKey) {
    PRIVILEGES[platform].forEach((priv) => {
      if (priv.dependsOn) {
        if (priv.dependsOn.includes(privilegeKey)) {
          rc[priv.key][roleKey] = false;
          rc = turnOffDependencies(rc, priv.key, roleKey);
        }
      }
    });
    return rc;
  }
  function changePlatform(p) {
    setIsLoading(true);
    setPlatform(p);
  }
  function toggleCat(catKey) {
    var elems = document.querySelectorAll("[data-cat=c" + catKey + "]");

    elems.forEach((elem) => {
      elem.classList.toggle("d-none");
    });
  }

  function save() {
    setIsSaving(true);
    Axios.patch(API_URL + "roles/" + platform, roleConfig)
      .then((res) => {
        setIsSaved(true);
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaved(false);
        setIsSaving(false);
      });
  }

  return (
    <Page title="Modifier les rôles" container="container-fluid px-5">
      {isLoading ? (
        <Loader withContainer={true} />
      ) : (
        <>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className={"nav-link " + (platform == REF_BO ? "active" : "")}
                id={REF_BO + "-tab"}
                data-toggle="tab"
                href={"#" + REF_BO}
                role="tab"
                aria-controls={REF_BO}
                onClick={() => changePlatform(REF_BO)}
              >
                {REF_BO}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={"nav-link " + (platform == REF_APP ? "active" : "")}
                id={REF_APP + "-tab"}
                data-toggle="tab"
                href={"#" + REF_APP}
                role="tab"
                aria-controls={REF_APP}
                onClick={() => changePlatform(REF_APP)}
              >
                {REF_APP}
              </a>
            </li>
          </ul>

          <table className="table w-100 bg-white mt-3 table-borderless">
            <thead>
              <tr>
                <th>Privilèges</th>
                {Object.values(ROLES[platform]).map((r, rk) => (
                  <th key={"rl" + rk}>{r.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(PRIVILEGE_CATEGORIES).map((c, ck) => {
                if (!PRIVILEGES[platform].find((p) => p.category == c.key)) {
                  return null;
                }
                return (
                  <Fragment key={"priv" + ck}>
                    <tr
                      className="tr-hover animated fadeInDown faster"
                      style={{
                        animationDelay: ck * 50 + "ms",
                      }}
                    >
                      <td
                        onClick={() => toggleCat(ck)}
                        className="text-left py-3"
                        style={{ fontSize: 14 }}
                        colSpan={Object.values(ROLES[platform]).length + 1}
                      >
                        <div className="d-flex align-items-center justify-content-between text-success">
                          {c.label}
                          <i className=" fa fa-sort-down" />
                        </div>
                      </td>
                    </tr>

                    {PRIVILEGES[platform]
                      .filter((p) => p.category == c.key)
                      .map((p, pk) => {
                        return (
                          <tr
                            key={"tr" + ck + "" + pk}
                            className="highlightable d-none animated fadeInUp faster"
                            style={{ animationDelay: pk * 30 + "ms" }}
                            data-key={p.key}
                            data-cat={"c" + ck}
                            data-ref={p.dependsOn ? p.dependsOn.join("+") : ""}
                          >
                            <td>
                              <span className="badge badge-light mr-2">{pk + 1}</span>
                              {p.label}
                              <div></div>
                            </td>
                            {Object.values(ROLES[platform]).map((r, rk) => (
                              <td className="text-center" key={"tab" + ck + "" + rk + pk}>
                                <div className="custom-control custom-switch ">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={ck + "ck" + pk + "" + rk}
                                    name={ck + "ck" + pk + "" + rk}
                                    checked={roleConfig[p.key][r.key] == false ? false : "checked"}
                                    onChange={(e) => togglePrivilege(e, p.key, r.key)}
                                    disabled={
                                      p.key == "bo_updaterolesconfig" && r.key == "ADMIN"
                                        ? "disabled"
                                        : tools.isParentRoleActive(r.key, roleConfig, p.dependsOn)
                                        ? false
                                        : "disabled"
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={ck + "ck" + pk + "" + rk}
                                  ></label>
                                </div>
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      <SaveBtn save={save} isSaved={isSaved} isSaving={isSaving} />
    </Page>
  );
};

export default RoleTemplate;
