import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn";
import useBlackList from "../useBlackList";
import {
  commonControlProps,
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../../common/Controls/filterContainer";

const BlackListFilters = () => {
  const { actions, blackListFilters, isLoadingBlackList } = useBlackList();
  const { filters } = blackListFilters;

  function BlackListHandle(e) {
    e.preventDefault();
    actions.getBlackList();
  }

  return (
    <form onSubmit={BlackListHandle}>
      <div className={filterContainerClassName}>
        <div className="col-12">
          <Control
            label="Société"
            placeholder="Nom, SIRET ou SIREN"
            type="text"
            name="terms"
            value={filters.terms}
            change={actions.handleFilterChange}
            {...commonControlProps}
          />
        </div>
        <div className={searchButtonContainerClassName}>
          <SaveBtn
            className={searchButtonClassName}
            text="Rechercher"
            typeBtn="submit"
            type={searchButtonType}
            isSaving={isLoadingBlackList}
            loaderColor={searchButtonLoaderColor}
          />
        </div>
      </div>
    </form>
  );
};

export default BlackListFilters;
