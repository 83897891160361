export const DOC_LINKS = [
  {
    key: "guides-utilisation",
    url: "#",
    title: "Découvrez les fonctionnalités de votre backoffice",
    description:
      "Cette liste de guides vous permettra d'apprendre à utiliser votre backoffice et ses fonctionnalités.",
  },
  {
    key: "leads-internes",
    url: "https://app.tango.us/app/workflow/Leads-internes-11227acc7b8e49cbad58d4cb38335fc3",
    title: "Comment utiliser les leads internes ?",
    description:
      "Je vous ai concocté un petit guide pour vous aider à utiliser les leads internes, cliquez ici pour le consulter.",
  },
];

export const getDocByKey = (key) => {
  return DOC_LINKS.find((link) => link.key === key);
};
