import React, { useState } from "react";
import Control from "../../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";
import useFormState from "../useFormState";

const HeadOfficeInfosInputs = () => {
  const { constants, actions, errors, formData, inputsConfig } = useFormState();
  const inputState = formData.headOfficeInfosInputs;

  const updateMrpData = (e) => {
    const { name, value } = e.target;

    actions.updateHeadOfficeInfosInputs({ [name]: value });
  };

  const onBlur = () => {};

  return (
    <BlockWrapper>
      <Control
        name="headOfficeTurnover"
        label={"Quel est le Chiffre d'Affaires global de l'entreprise ?"}
        type="number"
        value={inputState.headOfficeTurnover}
        change={updateMrpData}
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
        suffix="€"
      />
      <Control
        name="headOfficeLegalForm"
        label="Quelle est la forme juridique de l'entreprise ?"
        type="select"
        datas={inputsConfig.headOfficeLegalForm.datas}
        value={inputState.headOfficeLegalForm}
        change={updateMrpData}
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      <Control
        label={inputsConfig.headOfficeCsp.label}
        name="headOfficeCsp"
        type="select"
        value={inputState.headOfficeCsp}
        datas={inputsConfig.headOfficeCsp.datas}
        dataIndex="id"
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      <Control
        label={<>Combien y a t-il de salariés au global au sein de la société ?</>}
        type="number"
        name="headOfficeWorkforce"
        value={inputState.headOfficeWorkforce}
        change={updateMrpData}
        error={errors}
        labelClassname="w-100"
        info={
          "Tous établissements confondus. Indiquez 0 si vous êtes entrepreneur individuel et/ou que votre entreprise ne compte pas de salariés."
        }
        onBlur={onBlur}
      />
      <Control
        label="À quelle fin voulez-vous utiliser ce questionnaire ?"
        type="btnList"
        name="quotationType"
        value={inputState.quotationType}
        change={updateMrpData}
        error={errors}
        labelClassname="w-100"
        datas={[
          { id: 1, name: "Un nouveau contrat" },
          { id: 2, name: "La modification d'un contrat existant" },
        ]}
        dataIndex="id"
        dataLabel="name"
        btnInline
        fullWidth
        onBlur={onBlur}
        info="Cette mention apparaitra dans la demande MRP générée en PDF. Mentionnez si cette demande concerne la souscription d'un nouveau contrat MRP ou la modification d'un contrat MRP existant (déjà assuré)."
      />
      <Control
        label="En quelle qualité agissez-vous pour cette demande de MRP ?"
        placeholder="Exemple : co-gérant, comptable, chargé des assurances, directeur, etc."
        type="text"
        name="qualityApplicant"
        value={inputState.qualityApplicant}
        change={updateMrpData}
        error={errors}
        labelClassname="w-100"
        fullWidth
        onBlur={onBlur}
        maxLength={60}
        info={`(${60 - inputState.qualityApplicant.length} caractères restants)`}
      />
    </BlockWrapper>
  );
};

export default HeadOfficeInfosInputs;
