import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import tools from "../../../../helpers/tools";
import useSearchCompanies from "../../../../hooks/useSearchCompanies/useSearchCompanies";
import Page from "../../../common/layout/Page";
import SearchInput from "./SearchCompany/SearchInput";
import { useHistory } from "react-router-dom";
import Loader from "../../../common/Loader";
import dataService from "../../../../helpers/dataService";

const AddCompanyBlackList = () => {
  const [searchLoading, setSearchLoading] = useState(false);
  const { searchEnterprise, addBlackListCompany } = useSearchCompanies();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({
    search: "",
    company: "",
    companies: [],
  });
  let history = useHistory();

  const updateSearch = (e) => {
    let sh = JSON.parse(JSON.stringify(search));
    let { name, value } = e.target;
    sh[name] = value;
    setSearch(sh);
  };

  const searchCompany = async (e) => {
    e?.preventDefault();
    setErrors({});
    setSearchLoading(true);
    const results = await searchEnterprise({
      searchValue: search.search,
    })
      .then((res) => {
        setSearch((s) => {
          return { ...s, companies: res };
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setSearchLoading(false);
      });
  };
  const companyClick = (company) => {
    console.log("company", company);
    if (!company.isBlackListed) {
      setIsLoading(true);
      addBlackListCompany(company)
        .then((res) => {
          toast.success("Cette société a bien été rajoutée à la liste noire.");
          setSearchLoading(false);
          history.push("/black-list");
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      addBlackListCompany(company);
      setIsLoading(false);
    }
  };

  return (
    <Page title="Blacklister une société">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchCompany();
        }}
      >
        <SearchInput
          search={search}
          updateSearch={updateSearch}
          searchLoading={searchLoading}
          searchCompany={searchCompany}
        />
      </form>
      <div className="w-100 d-center">
        {search.companies.length} résultat(s)
      </div>
      <StyledBlackListAddCompany className="col-12 ">
        <div className="row  py-3 rounded-top animated fadeIn">
          {search.companies.map((result, res) => {
            return (
              <div className="info__block  mb-2 rounded bg-white p-3">
                <div className="info__company">{result.company}</div>
                <div className="info__siret">
                  {tools.formatFollowingPattern(
                    result.siret,
                    [3, 3, 3, 5],
                    " "
                  )}
                </div>
                <div className="info__item">
                  {result.location1?.postalCode} {result.location1?.city}
                  <br />
                  {result.ape} - {result.companyActivity}
                </div>

                <div className="d-flex justify-content-center mt-3">
                  {result.isBlackListed ? (
                    "Déjà blacklistée"
                  ) : (
                    <button
                      className="btn text-white bg-dark btn-sm "
                      onClick={() => companyClick(result)}
                    >
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <i className="fa fa-ban" /> Blacklister cette société
                        </>
                      )}
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </StyledBlackListAddCompany>
    </Page>
  );
};

const StyledBlackListAddCompany = styled.div`
  margin-top: 40px;
  & .info__block {
    margin-left: 5px;
    width: 48%;
  }
  & .info__company {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 600;
  }
  & .info__siret {
    font-size: 12px;
    color: var(--color-default-dark);
  }
`;

export default AddCompanyBlackList;
