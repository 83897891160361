import Axios from "axios";
import React, { useState } from "react";
import { DatePicker } from "react-dates";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";

const CreateCommentClient = ({ clientId, setShowComment }) => {
    const [comment, setComment] = useState("");
    const [isSavingComment, setIsSavingComment] = useState(false);
    const [createdAt, setCreatedAt] = useState("");
    const [errors, setErrors] = useState({});


    function saveComment() {
        setIsSavingComment(true);

        // define route for comment in timeline
        Axios.post(API_URL + 'clients/' + clientId + '/comment', {
            comment: comment,
            createdAt: createdAt,
        })
            .then((res) => {
                toast.success("L'observation a bien été enregistrée");
                setShowComment(false);
                console.log("ma data", res.data)
            })
            .catch((err) => {
                toast.error("Une erreur est survenue");
                if (err.response && err.response.data) {
                    setErrors(err.response.data);
                    console.log(err.response)
                }
            })
            .then(() => {
                setIsSavingComment(false);
            });

    }


    return (
        <>

            <Control
                label="Ecrire une observation"
                name="comment"
                type="textarea"
                autocomplete={"off"}
                value={comment}
                change={(e) => setComment(e.target.value)}
                error={errors}
            />
            <Control
                label="Date de l'observation (optionnel)"
                autocomplete={"off"}
                name="createdAt"
                type="datetime-local"
                value={createdAt}
                change={(e) => setCreatedAt(e.target.value)}
                error={errors}
            />

            <SaveBtn
                text="Enregistrer"
                className="mt-4"
                save={saveComment}
                isSaving={isSavingComment}
            />
        </>
    );
};

export default CreateCommentClient;
