import React from "react";

//steps
// [
//   { title: "titre", color: "primary", icon: "check" },
//   ...
// ]

const StepperHorizontal = ({ steps }) => {
  var width = 100 / Math.ceil(steps.length) + "%";

  return (
    <ul className="list-group animated fadeIn d-flex flex-row">
      {steps.map((step, stepk) => {
        let isFirst = stepk == 0;
        let isLast = stepk == steps.length - 1;

        var barStyle = {
          width: isFirst || isLast ? "50%" : "100%",
          left: isFirst ? "50%" : "0%",
        };

        return (
          <li
            key={"step" + stepk}
            className="list-group-item d-flex flex-column align-items-center border-0 bg-transparent position-relative flex-fill py-0 px-1"
            style={{ width }}
          >
            <button
              className={`btn btn-${step.color} rounded-circle d-flex align-items-center justify-content-center position-relative`}
              style={{
                borderRadius: "100%",
                padding: 10,
                width: 33,
                height: 33,
                zIndex: "5",
              }}
            >
              <i
                className={"position-relative fa fa-" + step.icon}
                style={{ zIndex: "9999" }}
              />
            </button>
            <div
              className={`bg-${step.color}`}
              style={{
                position: "absolute",
                top: 17,
                height: 1,
                zIndex: "0",
                ...barStyle,
              }}
            ></div>
            <span
              className={`text-center mt-2 text-${step.color}`}
              style={{ fontSize: 10, lineHeight: "12px" }}
            >
              {step.title}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default StepperHorizontal;
