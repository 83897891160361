import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";

const formConfigAtom = atom(null);

export const inputDefaultValues = {
  BTN_LIST: "",
  TEXT: "",
  CHECK_BTN_LIST: [],
  NUMBER_INT: "",
  JS_OBJECT: {},
  DATE: "",
  SELECT: "",
  CHECKBOX: false,
};

const useDOMIInfos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formConfig, setFormConfig] = useAtom(formConfigAtom);

  useEffect(() => {
    if (!formConfig) getFormConfig();
  }, []);

  const getFormConfig = () => {
    setIsLoading(true);
    dataService.get(
      `quotes/products/domi/form-config`,
      setFormConfig,
      (er) => {},
      () => setIsLoading(false)
    );
  };

  const formatData = (data) => {
    const entries = [];
    Object.keys(data).forEach((inputKey) => {
      const entryValue = data[inputKey];
      const inputConfig = formConfig[inputKey];
      if (inputConfig) {
        const output = {
          id: inputKey,
          label: inputConfig.label,
          order: inputConfig.order,
        };

        switch (inputConfig.type) {
          case "TEXT":
            if (entryValue == inputDefaultValues[inputConfig.type]) {
              output.value = null;
              break;
            }
            output.value = entryValue;
            break;
          case "NUMBER_INT":
            if (typeof entryValue !== "number" || (entryValue + "").length == 0) {
              output.value = null;
              break;
            }
            output.value = tools.truenumberWithSpaces(entryValue);
            break;
          case "BTN_LIST":
            if (entryValue === inputDefaultValues[inputConfig.type]) {
              output.value = null;
              break;
            }
            output.value = tools.findIn(inputConfig.datas, entryValue, "id").name;
            break;
          case "SELECT":
            if (entryValue === inputDefaultValues[inputConfig.type]) {
              output.value = null;
              break;
            }
            output.value = tools.findIn(inputConfig.datas, entryValue, "id").name;
            break;
          case "DATE":
            if (entryValue === inputDefaultValues[inputConfig.type]) {
              output.value = null;
              break;
            }
            output.value = tools.formatDate(entryValue);
            break;
          case "CHECK_BTN_LIST":
            if (entryValue.length == 0) {
              output.value = null;
              break;
            }
            let value = [];
            for (var key in entryValue) {
              const val = entryValue[key];
              value.push(tools.findIn(inputConfig.datas, val, "id").name);
            }
            output.value = value.join(", ");
            break;
          case "CHECKBOX":
            output.value = entryValue ? "Oui" : "Non";
            break;
          case "JS_OBJECT":
            output.value = `${entryValue?.company} - ${entryValue?.siren} - ${
              entryValue?.ape
            } - ${tools.getFullAddress(entryValue.location1)}`;
        }
        if (!!output.value) {
          entries.push(output);
        }
      }
    });
    return entries.sort((a, b) => (a.order > b.order ? 1 : -1));
  };

  return {
    isLoading,
    formConfig,
    getFormConfig,
    formatData,
  };
};

export default useDOMIInfos;
