import { useAtom } from "jotai";
import {
  activeTodoIdAtom,
  availableTasksAtom,
  todosAtom,
  todoUsersAtom,
} from "./atoms/todoListAtoms";
import tools from "../../../helpers/tools";

const useTodoList = (
  onUpdateTodos = () => {},
  onUpdateTodo = () => {},
  onAddTodo = () => {},
  onDeleteTodo = () => {}
) => {
  const [todos, setTodos] = useAtom(todosAtom);
  const [todoUsers, setTodoUsers] = useAtom(todoUsersAtom);
  const [activeTodoId, setActiveTodoId] = useAtom(activeTodoIdAtom);
  const [availableTasks, setAvailableTasks] = useAtom(availableTasksAtom);

  const setupTodos = (initTodos, initTasks, users) => {
    setTodoUsers(users);
    setTodos(initTodos);
    setAvailableTasks(initTasks);
    for (var key in initTodos) {
      const t = initTodos[key];
      if (!t.isDone) {
        setActiveTodoId(t.id);
        break;
      }
    }
  };

  const updateTodos = (todos) => {
    onUpdateTodos(todos);
  };

  const updateTodo = (todo) => {
    //const newTodos = [...todos.map((t) => (t.id == todo.id ? todo : t))];
    onUpdateTodo(todo);
  };

  const addTodo = (todo) => {
    //const newTodos = [...todos, todo];
    //setTodos(newTodos);
    onAddTodo(todo);
  };

  const deleteTodo = (todoId) => {
    //const newTodos = [...todos.filter((todo) => todo.id != todoId)];
    //setTodos(newTodos);

    onDeleteTodo(todoId);
  };

  //ajouter updateTodo pour modifier le texte d'une todo

  return {
    todos,
    setTodos,
    activeTodoId,
    setActiveTodoId,
    availableTasks,
    setAvailableTasks,
    todoUsers,
    setTodoUsers,
    actions: { updateTodos, addTodo, deleteTodo, updateTodo },
    setupTodos,
  };
};

export default useTodoList;
