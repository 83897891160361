import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <small
      className={"form-text input-error-msg text-danger animated flash"}
      style={{
        top: 6,
        right: 8,
        fontWeight: "bold",
        fontSize: 12,
      }}
    >
      {message}
    </small>
  );
};

export default ErrorMessage;
