import { useContext } from "react";
import { TodoContext } from "../../context/TodoContext";
import useTodoList from "../../useTodoList";

const TodoActions = () => {
  const { todo, actions } = useContext(TodoContext);

  const onDeleteTodo = () => {
    actions.deleteTodo(todo.id);
  };

  return (
    <div className="text-right w-100">
      <button className="btn  btn-link text-danger" onClick={onDeleteTodo}>
        <i className="fa fa-times" />
      </button>
    </div>
  );
};

export default TodoActions;
