import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { DateTime } from "luxon";
import Accordion from "../../common/Accordion";
import ClientItem from "./ClientItem";
import OverlayBottom from "../../common/OverlayBottom";
import ClientListFilters from "./ClientListFilters";
import ViewClient from "./ViewClient";

const isBOA = true;

const ClientTempList = () => {
  const [isInit, setIsInit] = useState(isBOA ? true : false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [search, setSearch] = useState({
    client: {
      searchString: "",
      beginAt: DateTime.local().startOf("month").toISO(),
      endAt: DateTime.local().toISO(),
    },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [overlayData, setOverlayData] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
  const [, setIsHighlight] = useState(false);
  const [isDeletingClient, setIsDeletingClient] = useState(false);
  const [resetPage, setResetPage] = useState(false);

  useEffect(() => {
    setIsHighlight(false);
  }, [search.client.searchString]);

  useEffect(() => {
    if (!isInit) {
      searchTempClients();
    }
    setIsInit(false);
  }, [search.page]);

  function searchTempClients() {
    setErrors(false);

    if (isLoading) return false;
    setIsLoading(true);
    setUsers([]);
    Axios.post(
      API_URL + "clients/search/prospects",
      resetPage ? { ...search, page: 1 } : search
    )
      .then((res) => {
        if (resetPage) setSearch({ ...search, page: 1 });
        setResetPage(false);
        setUsers(res.data.clients);
        setNbResults(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        //setUsers([]);
      })
      .then(() => {
        setIsLoading(false);
      });
  }
  function updateClientSearch(e) {
    var { name, value, type, checked } = e.target;

    var su = { ...search };
    if (type == "checkbox") {
      if (checked) {
        su.client[name] = true;
      } else {
        su.client[name] = false;
      }
    } else {
      su.client[name] = value;
    }
    if (name == "productTypes") {
      su.client.products = [];
    }
    setResetPage(true);
    setSearch(su);
  }
  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function openClient(client, currentTab = false) {
    setOverlayOpen(true);

    if (client) {
      setIsLoadingOverlay(true);
      Axios.get(API_URL + "clients/" + client.id)
        .then((res) => {
          setOverlayData({
            client: res.data,
            currentTab: currentTab,
          });
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoadingOverlay(false);
        });
    }
  }
  function closeOverlay(andRefresh = false) {
    setOverlayData(null);
    setOverlayOpen(false);
    if (andRefresh && typeof andRefresh == "boolean") {
      searchTempClients();
    }
  }

  useEffect(() => {
    highlightResults();
  });

  function highlightResults() {
    var strSearch = search.client.searchString.split(" ");
    var accOpened = [];

    window.$(".highlight").each((i, elem) => {
      window.$(elem).removeClass("highlight");
    });

    if (strSearch[0].length > 1) {
      for (var key in strSearch) {
        let str = strSearch[key];

        window.$(".user-name").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
          }
        });
        window.$(".user-email").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
          }
        });
        window.$(".client-name").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
            //window.$("." + window.$(elem).attr('data-ref')).click()
            if (!accOpened.includes(window.$(elem).attr("data-ref"))) {
              accOpened.push(window.$(elem).attr("data-ref"));
            }
          }
        });
        window.$(".client-siret").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
            //window.$("." + window.$(elem).attr('data-ref')).click()
            if (!accOpened.includes(window.$(elem).attr("data-ref"))) {
              accOpened.push(window.$(elem).attr("data-ref"));
            }
          }
        });
      }

      accOpened.forEach((acc) => {
        window.$("#" + acc).collapse("show");
      });
    }
    window.$(".collapse").each((i, elem) => {
      if (!accOpened.includes(window.$(elem).attr("id"))) {
        window.$(elem).collapse("hide");
      }
    });
    setIsHighlight(true);
  }

  function updateUsersData(e) {
    console.log("update user data");
  }
  function removeClient(clientId) {
    if (!window.confirm("Confirmez-vous la suppression de ce client ?"))
      return false;

    setIsDeletingClient(true);
    Axios.delete(API_URL + "clients/" + clientId)
      .then((res) => {
        searchTempClients();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsDeletingClient(false);
      });
  }

  return (
    <>
      <Page
        container="container-fluid"
        title={"Liste des leads"}
        errors={errors}
      >
        <ClientListFilters
          search={search}
          updateClientSearch={updateClientSearch}
          errors={errors}
          nbResults={nbResults}
          startSearch={searchTempClients}
          isLoading={isLoading}
        />
        {isDeletingClient ? (
          <Loader text="Suppression du client en cours..." />
        ) : (
          users.map((client, clientk) => (
            <Accordion
              noChevron={true}
              className="mt-1 acc-2"
              btnClassName={"shadow-none p-0 " + "client-" + clientk}
              btnStyle={{
                borderRadius: 8,
                border: "1px solid #e6e6e6",
                overflowX: "hidden",
              }}
              borderColor="success"
              cardClassName="shadow-none bg-transparent"
              color={"default"}
              bodyClassName=" pr-0  py-0"
              k={"clcomp" + clientk}
              items={[
                {
                  title: (
                    <>
                      <ClientItem
                        client={client}
                        openClient={() => openClient(client, 0)}
                        removeClient={() => removeClient(client.id)}
                      />
                    </>
                  ),
                  content: "",
                },
              ]}
            />
          ))
        )}
        <Pagination
          nbPages={nbPages}
          page={search.page}
          changePage={changePage}
        />
      </Page>
      <OverlayBottom overlayOpen={overlayOpen} closeOverlay={closeOverlay}>
        {isLoadingOverlay ? (
          <Loader
            withContainer
            containerStyle={{
              position: "absolute",
              left: 0,
            }}
          />
        ) : (
          overlayData && (
            <ViewClient
              datas={overlayData}
              setUsers={setUsers}
              updateUsersData={updateUsersData}
              openClient={openClient}
              isBOA={isBOA}
              closeOverlay={closeOverlay}
            />
          )
        )}
      </OverlayBottom>
    </>
  );
};

export default ClientTempList;
