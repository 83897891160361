import React from "react";
import styled from "styled-components";

const Amount = ({ label, amount, className }) => {
  return (
    <StyledAmount className={`py-2 amount-component ${className}`}>
      <div className="amount-label">{label}</div>
      <span className="text-big-1 ml-2 font-weight-bold">{amount} €</span>
    </StyledAmount>
  );
};

export default Amount;

const StyledAmount = styled.div``;
