import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";
import tools from "../../helpers/tools";
import { managersAtom } from "./managersAtoms";
import { useEffect } from "react";

const isLoadingManagersAtom = atom(false);

const useManagers = (fetchOnLoad) => {
  const [managers, setManagers] = useAtom(managersAtom);
  const [isLoadingManagers, setIsLoadingManagers] = useAtom(
    isLoadingManagersAtom
  );

  useEffect(() => {
    if (fetchOnLoad && !managers.length && !isLoadingManagers) {
      getManagers();
    }
  }, []);

  const getManagers = () => {
    setIsLoadingManagers(true);
    dataService.get(
      `managers`,
      (data) => {
        setManagers(
          data.map((d) => ({ ...d, fullName: tools.getFullNameAbbr(d) }))
        );
      },
      (err) => {},
      () => setIsLoadingManagers(false)
    );
  };

  return { getManagers, managers };
};

export default useManagers;
