import React from "react";
import Page from "../../../common/layout/Page";
import ACContainer from "./ACContainer";
import MRPContainer from "./MRPContainer";
import useForms from "./useForms";
import CYBERContainer from "./CYBERContainer";

const Forms = (props) => {
  let { clientId } = props.match.params;
  const { hasAC, hasMRP, hasCYBER, clients } = useForms({ clientId });

  return (
    <Page margin="mt-5">
      <div className="mb-4">
        {!hasAC && !hasMRP && !hasCYBER && (
          <p className="custom-p text-center">
            <strong>Aucune demande en cours pour le moment.</strong>
          </p>
        )}
      </div>
      {hasMRP > 0 && (
        <MRPContainer MRPClients={clients.MRP} clientId={clientId} />
      )}
      {hasAC > 0 && <ACContainer ACClients={clients.AC} clientId={clientId} />}
      {hasCYBER > 0 && (
        <CYBERContainer CYBERClients={clients.CYBER} clientId={clientId} />
      )}
    </Page>
  );
};

export default Forms;
