import React, { useState } from "react";
import useProducers from "../../../../hooks/useProducers/useProducers";
import Control from "../../../common/Controls/Control";
import useLeadFilters from "./useLeadFilters";

const ProducerSelect = ({ commonControlProps }) => {
  const { leadsFilters, actions, setLeadsFilters } = useLeadFilters();
  const { producers } = useProducers(true);

  const onChangeProducer = (e) => {
    setLeadsFilters({
      ...leadsFilters,
      filters: {
        ...leadsFilters.filters,
        producer: e.target.value,
        assignedTo: [],
      },
    });
  };

  return (
    <Control
      label="Société partenaire"
      name="producer"
      type="select"
      change={onChangeProducer}
      value={leadsFilters.filters.producer}
      datas={producers}
      dataIndex="id"
      dataLabel="company"
      {...commonControlProps}
    />
  );
};

export default ProducerSelect;
