import React, { useEffect, useState } from "react";

const EggButton = () => {
  const [isRayMode, setIsRayMode] = useState(false);

  useEffect(() => {
    if (isRayMode) {
      document.querySelector("body").style.filter = "invert(1) hue-rotate(180deg)";
    } else {
      document.querySelector("body").style.filter = "none";
    }
  }, [isRayMode]);

  const toggleRayMode = () => {
    setIsRayMode(!isRayMode);
  };

  return <button style={{ border: 0, background: "transparent" }} onClick={toggleRayMode}></button>;
};

export default EggButton;
