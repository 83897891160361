import React, { useContext, useState } from "react";
import styled from "styled-components";
import useRole from "../../../../../../hooks/useRole/useRole";
import Avatar from "../../../../../common/Avatar";
import Control from "../../../../../common/Controls/Control";
import { LeadPageContext } from "../../../useLeads";
import { LeadContext } from "../../LeadContext";
import useLeadItem from "../useLeadItem";
import "./assignTo.scss";

const MANAGER_TYPES = [
  {
    id: "PERCEVAL",
    name: "Perceval",
  },
  { id: "PARTNER", name: "Partenaires" },
];

const AssignTo = () => {
  const { productCategory } = useContext(LeadPageContext);
  const { managers, myRole } = useContext(LeadContext);
  const { lead, actions } = useLeadItem();
  const initialSelectedRolesByProductCategory = {
    PRO: ["PERCEVAL", "PARTNER"],
    DOMI: ["PERCEVAL"],
  };
  const [selectedUserRoles, setSelectedUserRoles] = useState(
    initialSelectedRolesByProductCategory[productCategory]
  );

  const handleChangeManager = (e) => {
    actions.onUpdateAssignTo(e.target.value);
  };

  const handleChangeSelectedUserRole = (e) => {
    const { value } = e.target;
    if (value.length == 0) return;

    setSelectedUserRoles(value);
  };

  const filteredManagersBySelectedRole = () => {
    return managers
      .sort((a, b) => (a.id == lead.assignedTo ? -1 : 1))
      .filter((manager) => {
        if (!manager.roles.length > 0) return false;
        if (manager.id == lead.assignedTo) return true;
        switch (manager.roles[0].roleKey) {
          case "PARTNER":
            return selectedUserRoles.includes("PARTNER") ? true : false;
          default:
            return selectedUserRoles.includes("PERCEVAL") ? true : false;
        }
      });
  };

  return (
    <div className="assignTo border-left border-top border-right pt-1 border d-center assignTo">
      {myRole !== "PARTNER" && productCategory == "PRO" && (
        <Control
          label=""
          type="checkBtnList"
          datas={MANAGER_TYPES}
          name="selectedUserRoles"
          dataIndex="id"
          dataLabel="name"
          change={handleChangeSelectedUserRole}
          value={selectedUserRoles}
          btnInline
          noPaddingOnFormGroup
          noMarginInsideLabel
          noBg
          labelClassname="pt-4"
          itemClassName="border-0 btn-default text-dark shadow-none"
          iconStyle={{
            top: 4,
            right: -10,
          }}
        />
      )}
      {lead.assignedTo && (
        <Avatar id={lead.assignedTo} width={30} height={30} />
      )}
      <StyledControl
        label=""
        type="select"
        value={lead.assignedTo}
        datas={filteredManagersBySelectedRole()}
        dataIndex="id"
        dataLabel="fullName"
        change={handleChangeManager}
        noBg
        noPaddingOnFormGroup
        noMarginInsideLabel
        margin="m-0"
        placeholder="Assigner à..."
      />
    </div>
  );
};

const StyledControl = styled(Control)`
  select {
    border: 0 !important;
    border-radius: 5px;
  }
  height: 49px;
  display: flex;
  align-items: center;
`;

export default AssignTo;
