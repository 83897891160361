import tools from "../../../../../helpers/tools";

function ItemAssignTo(props) {
  return (
    <div
      className="assignTo border-left border-top border-right border d-center assignTo"
      style={{
        left: -4,
      }}
    >
      {props.simplifiedFormItem.parameters?.partenaire_lie?.nom_societe}
      {props.simplifiedFormItem.parameters?.client_lie?.firstname &&
        " + " +
          tools.getFullName(
            props.simplifiedFormItem.parameters?.client_lie || {}
          )}
    </div>
  );
}

export default ItemAssignTo;
