import React from "react";
import styled from "styled-components";
import tools from "../../../../../helpers/tools";

const TodoUpdatedBy = ({ todo }) => {
  return (
    <StyledTodoUpdatedBy>
      {todo.updatedBy && (
        <>
          Modifiée par {todo.updatedBy} - {tools.formatDateHours(todo.updatedAt)}
        </>
      )}
    </StyledTodoUpdatedBy>
  );
};

const StyledTodoUpdatedBy = styled.span`
  color: var(--color-default);
  font-size: 12px;
  margin: 0 3px;
`;

export default TodoUpdatedBy;
