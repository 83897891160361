import React from "react";
import Page from "../../../common/layout/Page";
import PartnersFilters from "./PartnersFilters";
import PartnersTable from "./PartnersTable";
import usePartners from "./usePartners";
const PartnersList = () => {
  const { state, actions } = usePartners();
  return (
    <Page
      title="Liste des partenaires"
      errors={state.errors}
      container="container-fluid"
    >
      <PartnersFilters state={state} actions={actions} />
      <PartnersTable state={state} actions={actions} />
    </Page>
  );
};

export default PartnersList;
