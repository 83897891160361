import { GRAAL_API_URL } from "../../config";
import { graalApiAxiosInstance } from "../../middlewares/useApiAuthMiddleware";

export const leadsURI = `${GRAAL_API_URL}leads`;

export const submitParameters = (data) => {
  return graalApiAxiosInstance.post(`${leadsURI}/creer`, data);
};
export const updateParameters = (data) => {
  return graalApiAxiosInstance.patch(
    `${leadsURI}/${data.demande_id}/modifier-parametres`,
    data
  );
};
export const updateStatus = (leadUuid, statut, raison_abandon = null) => {
  return graalApiAxiosInstance.patch(
    `${leadsURI}/${leadUuid}/modifier-statut`,
    {
      statut,
      detail_abandon: raison_abandon
        ? {
            raison_abandon: raison_abandon.cancelReason,
            texte_abandon: raison_abandon.cancelText,
          }
        : null,
    }
  );
};

export const onSearchLeads = (data) => {
  return graalApiAxiosInstance.post(`${leadsURI}/chercher`, data);
};
export const onDeleteLead = (leadId) => {
  return graalApiAxiosInstance.delete(`${leadsURI}/${leadId}`);
};
