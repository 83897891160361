import React from "react";
import "./topBadge.scss";

const TopBadge = ({ children, position = "left", bgColor = "", textColor = "dark" }) => {
  return (
    <div
      className={`topBadge border-left border-top border-right d-inline-flex align-items-center ${position} bg-${bgColor} text-${textColor}`}
    >
      {children}
    </div>
  );
};

export default TopBadge;
