import React from "react";
import Control from "../../../common/Control";
import SaveBtn from "../../../common/SaveBtn";

const PartnersFilters = ({ state, actions }) => {
  const { searchString } = state.search.user;
  const { setSearch, searchPartners } = actions;

  const handleChange = (e) => {
    const { name, value } = e.target;
    var newSearchState = {
      ...state.search,
      user: {
        ...state.search.user,
        [name]: value,
      },
    };
    setSearch(newSearchState);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        searchPartners();
      }}
    >
      <div className="row">
        <div className="col-12">
          <Control
            margin={"my-2"}
            label="Recherche"
            name="searchString"
            value={searchString}
            change={handleChange}
            suffix={<i className="fa fa-search ml-2"></i>}
          />
        </div>
        <div className="col-12 justify-content-center d-flex font-weight-bold">
          <SaveBtn
            save={searchPartners}
            isSaving={state.isLoading}
            text="Rechercher"
          />
        </div>
      </div>
    </form>
  );
};

export default PartnersFilters;
