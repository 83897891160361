import useRole from "../../../hooks/useRole/useRole";

const useDashboard = () => {
  const { myRole } = useRole();
  const isPartner = myRole == "PARTNER";
  const pageTitle = isPartner ? "Espace partenaire" : "Espace administrateur";
  const canSeeStats = !isPartner;
  const canSeeShortcuts = myRole == "ADMIN" || myRole == "SUPER_ADMIN";

  return {
    myRole,
    pageTitle,
    canSeeStats,
    canSeeShortcuts,
    isPartner,
  };
};

export default useDashboard;
