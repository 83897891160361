import { atom, useAtom } from "jotai";
import { useState } from "react";
import axios from "axios";
import useGraalApi from "../hooks/useGraalApi/useGraalApi";

const graalApiAuthAtom = atom(null);

//axios instance using a bearer token
export const graalApiAxiosInstance = axios.create({
  withCredentials: true,
});

const useApiAuthMiddleware = () => {
  const [apiAuthError, setApiAuthError] = useState("");
  const [graalApiAuth, setGraalApiAuth] = useAtom(graalApiAuthAtom);
  const { graalApiActions } = useGraalApi();

  const apiAuthActions = {
    authenticateWithTarificatorApi: async () => {
      const response = await graalApiActions.getAuthToken();
      setGraalApiAuth(response.data);
      if (response.data.token) {
        graalApiAxiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
        //set the axios instance to use the token
        graalApiAxiosInstance.defaults.withCredentials = true;
        return true;
      } else {
        setApiAuthError("Jeton de connexion au tarificateur invalide");
        return false;
      }
    },
  };
  return {
    apiAuthError,
    graalApiAuth,
    apiAuthActions,
  };
};

export default useApiAuthMiddleware;
