import { atom } from "jotai";

export const leadsAtom = atom([]);
export const linkedLeadsAtom = atom([]);
export const isListInitAtom = atom(false);
export const isSearchingAtom = atom(false);
export const leadCountAtom = atom(0);
export const showOnlyLinkedLeadsAtom = atom(false);
export const leadsFiltersAtom = atom({
  page: 1,
  filters: {
    terms: "",
    products: [],
    assignedTo: [],
    statuses: [],
    beginAt: "",
    endAt: "",
    producer: "",
  },
});
