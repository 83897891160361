import { useAtom } from "jotai";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import { productDataAtom } from "./productInfosAtoms";

const useProductInfos = () => {
  const [productData, setProductData] = useAtom(productDataAtom);

  const [state] = useStore();
  const { inputsConfig, constants } = state;
  const { CONSTRUCTION_ACTIVITIES } = constants.items;

  const customInputsConfig = {
    PJ_BEGIN_AT: {
      label: "Date de début",
    },
    PJ_END_AT: {
      label: "Date de fin",
    },
    MRP_NATURAL_DISASTER_VALUE: {
      label: "Valeur catastrophes naturelles",
    },
    MRP_FRANCHISE_TOC: {
      label: "Franchise TOC",
      datas: [
        { id: 1, name: "Franchise Basse" },
        { id: 2, name: "Franchise Moyenne" },
        { id: 3, name: "Franchise Haute" },
      ],
    },
    MRP_FRANCHISE_TOC_VALUE: {
      label: "Valeur franchise TOC",
    },
    MRP_FLOOD_DAMAGE_VALUE: {
      label:
        "Dégâts matériels suite à débordement / refoulement de sources, caniveaux, étendues d’eau.",
    },
    MRP_RAIN_DAMAGE_VALUE: {
      label:
        "Valeur assurée en cas de dégâts matériels causés par l’eau de pluie ayant pénétré dans les bâtiments sans dommage préalable aux toitures, murs, portes, fenêtres, et trappes.",
    },
    MRP_EARTHQUAKE_DAMAGE_VALUE: {
      label:
        "Valeur assurée en cas d’éruption volcanique ou de tremblement de terre.",
    },
    MRP_ELECTRICAL_DAMAGE_VALUE: {
      label:
        "Accidents d’ordre électrique et chute de la foudre sur le matériel, les machines électriques ou électroniques.",
    },
    MRP_WATER_DAMAGE_VALUE: {
      label:
        "Dommages consécutifs à l’action de l’eau comme une fuite, un débordement, une infiltration, etc.",
    },
    MRP_STEAL_DAMAGE_VALUE: {
      label:
        "Valeur assurée en cas d’effraction ou de vol sur votre caisse, tiroirs-caisses, caisses enregistreuses.",
    },
    MRP_ICE_BREAK_DAMAGE_VALUE: {
      label:
        "Valeur assurée en cas de dommages sur vos vitrines, fenêtres, portes vitrées, etc.",
    },
    MRP_SIGN_BREAK_DAMAGE_VALUE: {
      label: "Dommages sur vos enseignes lumineuses ou non lumineuses.",
    },
    MRP_TRANSPORTED_GOODS_DAMAGE_VALUE: {
      label:
        "Dommages et pertes qui résultent du transport, du chargement ou du déchargement de marchandises.",
    },
    MRP_MACHINE_DAMAGE_VALUE: {
      label:
        "Dommages sur vos machines et équipements techniques ou industriels.",
    },
    MRP_FREEZE_ROOM_GOODS_DAMAGE_VALUE: {
      label:
        "Valeur assurée en cas de perte des marchandises périssables, entreposées dans les appareils de la chaîne du froid.",
    },
    MRP_OPERATING_LOSS_DAMAGE_VALUE: {
      label:
        "Assurer la perte de marge brute, commissions, honoraires en cas de d’arrêt d’activité suite à un sinistre garanti. Pour faire face aux charges, salaires, impôts, loyers, etc.",
    },
    MRP_GOODWILL_MARKET_VALUE_DAMAGE_VALUE: {
      label:
        "Assurer la perte de valeur de l’entreprise (droit au bail, pas-de-porte, clientèle, implantation, etc.) après un sinistre.",
    },
    MRP_HARDWARE_DAMAGE_VALUE: {
      label:
        "Dommages sur vos ordinateurs portables et de bureau, tablettes, photocopieurs.",
    },
    PJ_ANNUAL_PRICE: {
      id: "PJ_ANNUAL_PRICE",
      label: "Prix annuel TTC",
      type: "number",
    },
    PRODUCT_REASON_UNAVAILABLE: {
      id: "PJ_ANNUAL_PRICE",
      label: "Produit non disponible",
      type: "text",
    },
    AC_TURNOVER_OUTSOURCED: {
      label: "Chiffre d'affaires sous-traité (%)",
    },
    constructionActivity: {
      label: "Activité de construction",
      type: "number",
      id: "CONSTRUCTION_ACTIVITY",
      datas: CONSTRUCTION_ACTIVITIES,
    },
    PJ_PRODUCT_TYPE: {
      id: "PJ_PRODUCT_TYPE",
      label: "Type produit PJ",
      type: "text",
    },
  };

  const setupProductData = (data) => {
    setProductData(getInputsFormatted(data));
  };

  const getInputsFormatted = (data) => {
    const inputsArray = Object.keys(data)
      .map((key) => {
        const objRef = data[key];
        const inputConfigRef = inputsConfig[key];
        const customRef = inputConfigRef
          ? customInputsConfig[inputConfigRef.id] || {}
          : customInputsConfig[key];
        let objDataValue = handleSpecialValues(inputConfigRef?.id, objRef);
        const ref = { ...inputConfigRef, ...customRef } || {};
        const entry = {
          dataValue: objDataValue,
          ...ref,
        };
        return {
          ...entry,
          realValue: getDataValue(entry),
        };
      })
      .filter((d) => d.id && d.realValue != "NO_VALUE")
      .sort((a, b) => (a.order > b.order ? 1 : -1));

    console.log("inputsArray", inputsArray);

    return inputsArray;
  };

  const getDataValue = (data) => {
    var dataValue = "";
    if (!data.type || data.dataValue === null || data.dataValue === "") {
      return "NO_VALUE";
    }
    switch (data.type) {
      case "date":
        dataValue = tools.formatDate(data.dataValue);
        break;
      case "boolean":
        dataValue = data.dataValue ? "Oui" : "Non";
        break;
      case "number":
        const peculiarNumberInputs = [
          "CYBER_GARANTIE_LIMIT_PER_YEAR",
          "CYBER_FRANCHISE_AMOUNT",
        ];
        if (peculiarNumberInputs.includes(data.id)) {
          dataValue =
            data.dataValue === 0
              ? "0"
              : tools.truenumberWithSpaces(data.dataValue);
        } else {
          if (data.datas) {
            let dataRef = data.datas.find((d) => d.id == data.dataValue);
            if (dataRef === undefined) {
              dataValue = "";
            } else {
              dataValue =
                `${dataRef?.name} ${dataRef?.description1 || ""}` || `aucun`;
            }
          } else if (data.id === "WORKFORCE") {
            dataValue = data.dataValue === 0 ? "0" : data.dataValue;
          } else {
            dataValue =
              data.dataValue === null
                ? "aucun"
                : tools.truenumberWithSpaces(data.dataValue);
          }
        }
        break;
      case "array":
        if (data.datas) {
          dataValue = data.dataValue
            .map((d) => {
              let dataRef = data.datas.find((dr) => dr.id == d);
              return dataRef.name;
            })
            .join(", ");
        }
        break;
      default:
        dataValue = data.dataValue === null ? "aucun" : data.dataValue;
        break;
    }

    return dataValue;
  };

  const handleSpecialValues = (id, data) => {
    switch (id) {
      case "PJ_END_AT":
        return DateTime.fromISO(data).minus({ days: 1 }).toISO();
      default:
        return data;
    }
  };

  return {
    productData,
    actions: {
      setupProductData,
      getDataValue,
    },
  };
};

export default useProductInfos;
