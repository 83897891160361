import tools from "../../../../helpers/tools";
import useRBEListItem from "./useRBEListItem";

const RBELastUpdate = () => {
  const { rbeListItem, isCheckingRBE, actions } = useRBEListItem();

  const checkRBE = () => {
    actions.checkRBEDocById(rbeListItem.id);
  };

  const isLoading = rbeListItem.id == isCheckingRBE;

  return (
    <div className="my-3 bg-blue p-2 rounded d-center">
      <div>Dernière vérification : {tools.formatDateHours(rbeListItem.lastCheckAt)}</div>
      <button data-priv="bo_updaterolesconfig" className="btn btn-link" onClick={checkRBE}>
        <i className={`fa fa-refresh mr-2 ${isLoading ? "animated rotateOut infinite" : ""}`} />{" "}
        Revérifier
      </button>
    </div>
  );
};

export default RBELastUpdate;
