import React, { useState } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Controls/Control";
import ErrorMessage from "../../../../common/Controls/ErrorMessage";
import SaveBtn from "../../../../common/SaveBtn";
import SimpleDynamicList from "../../../../common/SimpleDynamicList";
import ProductTitle from "../../../ClientList/Forms/ProductTitle";
import PasteLines from "../PasteLines/PasteLines";
import usePartnerInfos from "../usePartnerInfos";

const SendLinkForm = ({ link, onLinkSent = () => {} }) => {
  const { isSendingLink, errors, actions } = usePartnerInfos();
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState("");

  const onChangeEmails = (e) => {
    setEmails(e.target.value);
  };

  const onSendLink = () => {
    actions.sendAffiliateLink(emails, link, message, onLinkSent);
  };

  return (
    <div className="mt-3">
      <ProductTitle
        title="Envoyer mon lien d'affiliation par email"
        icon={`paper-plane`}
      />
      <p className="my-3">
        Vos destinataires recevront un message d'invitation à utiliser votre
        lien d'affiliation. Entrez simplement les adresses emails de vos
        contacts, nous nous chargeons du reste !
      </p>
      <PasteLines
        label="Entrez les adresses emails de vos destinataires séparées par des sauts de ligne (25 max) :"
        buttonText={
          <>
            Importer les adresses email saisies
            <i className="fa fa-arrow-down ml-2" />
          </>
        }
        validateLine={tools.validateEmail}
        onUpdateLines={setEmails}
        placeholder="email1@exemple.com&#10;email2@exemple.com&#10;..."
        maxLines={25}
      />

      {emails.length > 0 && (
        <>
          <SimpleDynamicList
            listLabel="Vérifiez que les adresses emails sont correctes, vous pouvez les modifier ou supprimer individuellement dans la liste ci-dessous :"
            uniqueKey="sendlink"
            element="emails"
            fields={[
              {
                label: "Email",
                name: "email",
                type: "email",
              },
            ]}
            items={emails}
            btnLabel="Ajouter un email"
            change={onChangeEmails}
            className="mt-3"
            maxItems={25}
          />
          {errors.emails && typeof errors.emails == "object" ? (
            errors.emails.map((err) => <ErrorMessage message={err} />)
          ) : (
            <ErrorMessage message={errors.emails} />
          )}
          <Control
            label="Ajoutez optionnellement un message personnalisé qui apparaîtra dans l'email transmis à vos destinataires"
            type="textarea"
            value={message}
            change={(e) => setMessage(e.target.value)}
            noMarginOnFormGroup
            noPaddingOnFormGroup
            fullWidth
            noBg
            placeholder="Mon message personnalisé"
            error={errors}
          />
          <div>
            <SaveBtn
              disabled={emails.length == 0}
              save={onSendLink}
              text="Envoyer le lien d'affiliation"
              isSaving={isSendingLink}
              type="primary"
              className="w-100 d-center"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SendLinkForm;
