import tools from "../../../../../helpers/tools";

function ItemCreatedAt(props) {
  return (
    <div className="createdAt d-center d-flex flex-column text-start border-left border-top border-right border createdAt">
      {/* <i className="fa fa-calendar-alt mr-2" /> */}
      <div>
        Modifié le {tools.formatDateHours(props.simplifiedFormItem.updatedAt)}
      </div>
      <div>
        Créé le {tools.formatDateHours(props.simplifiedFormItem.createdAt)}
      </div>
    </div>
  );
}

export default ItemCreatedAt;
