import { GRAAL_API_URL } from "../../config";
import useStore from "../../context/useStore";
import { graalApiAxiosInstance } from "../../middlewares/useApiAuthMiddleware";

const commentsURI = `${GRAAL_API_URL}lead-commentaires`;

const useCommentService = () => {
  const [state] = useStore();

  const commentsServiceActions = {
    postComment: (commentData) => {
      return graalApiAxiosInstance
        .post(`${commentsURI}`, {
          ...commentData,
          createdBy: state.auth.user.id,
        })
        .then((res) => res.data);
    },
    patchComment: (commentId, commentData) => {
      return graalApiAxiosInstance
        .patch(`${commentsURI}/commentaires/${commentId}`, {
          ...commentData,
          updatedBy: state.auth.user.id,
        })
        .then((res) => res.data);
    },
    deleteComment: (commentId) => {
      return graalApiAxiosInstance
        .delete(`${commentsURI}/commentaires/${commentId}`, {})
        .then((res) => res.data);
    },
  };

  return { commentsServiceActions };
};

export default useCommentService;
