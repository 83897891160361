import React from "react";
import { EllipsisContent } from "../../../common/layout/StyledComponents/StyledComponents";
import DocumentsDisplay from "../../ClientList/DocumentsDisplay";
import useDocumentList from "../useDocumentList";

const DocumentItem = ({ model, entityIndex }) => {
  const { displayInline, fileEntitiesData, documentUploaded, documentRemoved, uploadNewFile } =
    useDocumentList();

  const fileEntityRef = fileEntitiesData.find((c) => c.id == model.entity.type);
  const modelEntity = model.entity;

  return (
    <div className="mb-3 shadow-sm" key={"list" + entityIndex}>
      <div
        className="row p-3 border bg-white"
        style={{
          borderRadius: window.borderRadius,
          position: "inherit",
        }}
      >
        <div className="border-right col-12 col-md-3">
          <div
            style={{
              color: fileEntityRef?.color || "black",
            }}
          >
            <i className={`${fileEntityRef?.icon || "fa fa-home"} mr-1`} />
            {fileEntityRef?.name || ""}
            {fileEntityRef?.title || ""}
          </div>
          <div>
            <strong>{modelEntity.title}</strong>
          </div>
          {modelEntity.subtitle.split("-").map((d) => (
            <EllipsisContent>{d}</EllipsisContent>
          ))}
          <div>
            <i>{modelEntity.description}</i>
          </div>
        </div>
        <div className="col-12 col-md-9 pb-0 pt-2">
          <div className="row">
            <DocumentsDisplay
              trueList
              small
              dataPriv="bo_crudfile"
              inline={displayInline}
              documents={model.files}
              documentUploaded={(dk, k, d) => documentUploaded(dk, k, d, entityIndex)}
              documentRemoved={(dk, k, d) => documentRemoved(dk, k, d, entityIndex)}
              uploadNewFile={(dk, k) => uploadNewFile(dk, k, entityIndex)}
              cancelNewVersion={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentItem;
