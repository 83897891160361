import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";

export const cancelReasonsAtom = atom([]);

const useCancelReasons = () => {
  const [cancelReasons, setCancelReasons] = useAtom(cancelReasonsAtom);

  const getCancelReasons = () => {
    dataService.get(`constants/policy-cancel-reasons`, setCancelReasons);
  };

  return { getCancelReasons, cancelReasons };
};

export default useCancelReasons;
