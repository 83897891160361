import React from "react";
import Loader from "./Loader";

const FileUpload = ({ progress }) => {
  return (
    <>
      <Loader text="Envoi en cours..." />

      <div
        style={{
          background: "#e9ecef",
          bottom: 0,
          left: 0,
          borderRadius: window.borderRadius,
          overflow: "hidden",
        }}
        className="progress_container w-100 position-absolute"
      >
        <div
          className="progress align-items-center p-2"
          style={{
            background: "#37556d",
            width: progress + "%",
            color: "white",
            justifyContent: "center",
          }}
        >
          {progress}%
        </div>
      </div>
    </>
  );
};

export default FileUpload;
