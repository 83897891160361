import React, { useEffect } from "react";
import styled from "styled-components";
import ACInfos from "./ACInfos/ACInfos";
import DOMIInfos from "./DOMIInfos/DOMIInfos";
import MRPInfos from "./MRPInfos/MRPInfos";
import PJInfos from "./PJInfos/PJInfos";
import useProductInfos from "./useProductInfos";
import CYBERInfos from "./CYBERInfos/CYBERInfos";

const ProductInfos = ({ productId, productData }) => {
  const { actions } = useProductInfos();

  useEffect(() => {
    actions.setupProductData(productData);
  }, []);

  let productComponent = null;
  switch (productId) {
    case "PJ":
      productComponent = <PJInfos />;
      break;
    case "AC":
      productComponent = <ACInfos />;
      break;
    case "CYBER":
      productComponent = <CYBERInfos />;
      break;
    case "MRP":
      productComponent = <MRPInfos />;
      break;
    case "domi":
      productComponent = <DOMIInfos productData={productData} />;
      break;
    default:
      break;
  }

  return <StyledProductInfos>{productComponent}</StyledProductInfos>;
};

const StyledProductInfos = styled.div`
  margin: 10px 0px;
`;

export default ProductInfos;
