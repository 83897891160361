import dataService from "../helpers/dataService";

export const getFileFilters = () => {
  return new Promise((resolve, reject) => {
    dataService.get(`constants/files/filters`, resolve, reject);
  });
};
export const getFileTypes = () => {
  return new Promise((resolve, reject) => {
    dataService.get(`filetypes`, resolve, reject);
  });
};
export const searchFiles = (searchData) => {
  return new Promise((resolve, reject) => {
    dataService.post(`files/search`, searchData, resolve, reject);
  });
};
export const getCompanyFiles = async (clientId) => {
  return new Promise((resolve, reject) => {
    dataService.get(`clients/${clientId}/files`, resolve, reject);
  });
};
