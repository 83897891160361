import { atom } from "jotai";

export const tasksAtom = atom([]);

export const tasksByProductAtom = atom((get) => {
  return {
    PJ: get(tasksAtom).filter((t) => t.products.includes("PJ")),
    AC: get(tasksAtom).filter((t) => t.products.includes("AC")),
    MRP: get(tasksAtom).filter((t) => t.products.includes("MRP")),
    CYBER: get(tasksAtom).filter((t) => t.products.includes("CYBER")),
    domi: get(tasksAtom).filter((t) => t.products.includes("domi")),
  };
});
