import React from "react";
import colors from "../../../../helpers/colors";
import LabelContainer from "../LabelContainer";

const CheckboxListInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",

  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  datas = [],
  dataIndex = "id",
  dataLabel = "name",
  dataLabelIndex = false,
  ...props
}) => {
  //console.log(Colors);
  const valueChange = (e, val, isExclusive) => {
    let newArray = JSON.parse(JSON.stringify(value));

    if (e.target.checked) {
      newArray.push(val);
    } else {
      newArray = newArray.filter((a) => a !== val);
    }
    change(
      {
        target: {
          name: props.name,
          uniqueValue: value,
          value: newArray,
          formArray: e,
          isExclusive: isExclusive,
        },
      },
      k
    );
  };

  return (
    <LabelContainer {...props} value={value}>
      <div className=" w-100">
        {datas.map((data, k) => (
          <div
            key={"chklist" + props.name + props.id + k}
            className={"my-0 d-flex align-items-center"}
          >
            <label
              className=" p-3 d-flex align-items-center border-0 "
              style={{ minHeight: 0, cursor: "pointer" }}
              htmlFor={k != -1 ? `customSwitch${props.name}-${k}` : `customSwitch${props.name}`}
            >
              <strong style={{ color: colors.primary }}>
                {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}
              </strong>
            </label>
          </div>
        ))}
      </div>
    </LabelContainer>
  );
};

export default CheckboxListInput;
