import React, { useContext } from "react";
import styled from "styled-components";
import { LeadPageContext } from "../../../useLeads";
import useLeadItem from "../useLeadItem";

const LeadProduct = () => {
  const { productCategory } = useContext(LeadPageContext);
  const { lead } = useLeadItem();

  const getUnavailableMessage = () => {
    const mess = lead.productData?.PRODUCT_REASON_UNAVAILABLE;
    return mess ? `Produit non disponible ${mess}` : "";
  };

  return (
    <StyledLeadProduct
      className="d-center"
      style={{ textTransform: "uppercase" }}
    >
      {lead.productId}
      <StyledProductStatus>
        {lead.isFinalized && (
          <StyledFinalizedIcon color="success" title="Demande finalisée">
            <i className="fa fa-check" />
          </StyledFinalizedIcon>
        )}
        {lead.productData?.PRODUCT_REASON_UNAVAILABLE && (
          <StyledFinalizedIcon
            color="danger"
            title={getUnavailableMessage()}
            onClick={() => alert(getUnavailableMessage())}
            clickable
          >
            <i className="fa fa-times" />
          </StyledFinalizedIcon>
        )}
      </StyledProductStatus>
    </StyledLeadProduct>
  );
};

export const StyledLeadProduct = styled.div`
  font-size: 18px;
  font-weight: 800;
  color: var(--primary);
`;

export const StyledProductStatus = styled.div`
  position: absolute;
  top: -8px;
  left: 7px;
`;

export const StyledFinalizedIcon = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  color: white;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "")};
  background: var(--${({ color }) => color});
`;

export default LeadProduct;
