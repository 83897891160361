import React, { useEffect } from "react";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import DocumentFilters from "./DocumentFilters";
import useDocumentList from "./useDocumentList";
import DocumentItem from "./DocumentItem/DocumentItem";
import { useLocation } from "react-router-dom";
import Control from "../../common/Controls/Control";
const queryString = require("query-string");

const DocumentList = () => {
  let {
    documents,
    isLoading,
    search,
    setSearchText,
    nbResults,
    errors,
    getDatas,
    updateSearchWithQueryParams,
    displayInline,
    setDisplayInline,
    fileTypes,
  } = useDocumentList();

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    if (fileTypes.length > 0) {
      const parsed = queryString.parse(location.search, { arrayFormat: "bracket" });
      if (Object.values(parsed).length > 0) {
        updateSearchWithQueryParams(parsed);
      }
    }
  }, [fileTypes]);

  const location = useLocation();

  return (
    <Page container="container-fluid px-5" title={"Liste des documents"} notAnimated>
      <DocumentFilters />
      <div className="text-center">
        {isLoading ? "Recherche en cours..." : <>{nbResults} documents trouvés</>}
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <Control
            btnInline={true}
            label="Affichage des documents"
            type="btnList"
            name="displayInline"
            noBg
            value={displayInline}
            datas={[
              { id: true, name: "En lignes" },
              { id: false, name: "En colonnes" },
            ]}
            change={(e) => setDisplayInline(e.target.value)}
          />
        </div>
        <div className="col-12" style={{ position: "inherit" }}>
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader withContainer={true} />
          ) : (
            documents.map((model, entityIndex) => (
              <DocumentItem entityIndex={entityIndex} model={model} key={`docki${entityIndex}`} />
            ))
          )}
        </div>
      </div>
    </Page>
  );
};

export default DocumentList;
