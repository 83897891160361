import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { API_URL } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import CharCounter from "../../common/CharCounter";
import ImageFileCrop2 from "../../common/ImageFileCrop/ImageFileCrop2";
import Avatar from "../../common/Avatar";
import PasswordControl from "../../common/PasswordControl";
import EggButton from "../../common/layout/EggButton";

var keepDocumentK = [];
const isBOA = true;

const UserDetail = (props) => {
  const [state, dispatch] = useStore();
  const [modifyAvatar, setModifyAvatar] = useState(false);

  const [user, setUser] = useState({
    comment: "",
    company: "",
    firstname: "",
    lastname: "",
    createdAt: "",
    function: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isDocsLoading, setIsDocsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isSavingPassword, setIsSavingPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(API_URL + "managers/me")
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error(
            "Une erreur est suvenue lors de la récupération des informations de votre compte"
          );
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  function updateData(e) {
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setIsSaved(false);
    setUser({ ...user, [name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});

    Axios.patch(API_URL + "managers/me", user)
      .then((res) => {
        setUser(res.data);
        setIsSaved(true);
        toast.success("Vos informations ont bien été modifiées");
      })
      .catch((err) => {
        toast.error("Une erreur est survenue lors de la modification de vos informations");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSaving(false);
      });
  }

  var pwdIdentical = password === passwordConfirm && (password + "").length;

  function savePassword() {
    if (!pwdIdentical) {
      toast.error("Les mots de passe ne sont pas identiques");
    } else {
      setIsSavingPassword(true);
      Axios.patch(API_URL + "managers/me/password", { password: password })
        .then((res) => {
          toast.success("Votre mot de passe a bien été modifié.");
        })
        .catch((err) => {
          toast.error("Une erreur est survenue");
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSavingPassword(false);
        });
    }
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader withContainer={true} />
  ) : (
    <Page container={"container-fluid px-5"} title={"Mes informations"} errors={errors}>
      <EggButton />
      {modifyAvatar ? (
        <ImageFileCrop2 setModifyAvatar={setModifyAvatar} />
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center mb-3 position-relative">
          <Avatar width={200} height={200} id={user.id} />
          <button
            style={{
              left: "50%",
              top: "67%",
              transform: "translateX(-50%) translateY(-50%)",
              opacity: ".8",
            }}
            className="btn btn-default btn-sm mt-2 position-absolute"
            onClick={() => setModifyAvatar(true)}
          >
            <i className="fa fa-edit mr-2" />
            Modifier ma photo
          </button>
        </div>
      )}
      {!modifyAvatar && (
        <>
          <button className="btn btn-default mt-5" onClick={() => setShowPassword((p) => !p)}>
            {showPassword ? (
              <>
                <i className="fa fa-arrow-left mr-2" /> Revenir à mon profil
              </>
            ) : (
              "Changer mon mot de passe"
            )}
          </button>
          {!showPassword && (
            <>
              <hr />
              <Control
                label="Civilité"
                name="gender"
                type="btnList"
                datas={state.constants.items.GENDERS}
                btnInline={true}
                value={user.gender}
                change={updateData}
                error={errors}
              />
              <Control
                label="Nom"
                name="lastname"
                value={user.lastname}
                change={updateData}
                error={errors}
              />
              <Control
                label="Prénom"
                name="firstname"
                value={user.firstname}
                change={updateData}
                error={errors}
              />
              <Control
                label="Téléphone"
                name="phone"
                value={user.phone}
                change={updateData}
                error={errors}
              />
              <Control
                label="Email"
                name="email"
                value={user.email}
                change={updateData}
                error={errors}
              />

              <SaveBtn className="mt-4" save={save} isSaving={isSaving} isSaved={isSaved} />
            </>
          )}

          {showPassword && (
            <div>
              <Control
                label="Nouveau mot de passe"
                name="password"
                type="password"
                autocomplete={"off"}
                value={password}
                change={(e) => setPassword(e.target.value)}
                error={errors}
              />
              <PasswordControl
                password={password}
                minLength={6}
                minUppercase={1}
                minLowercase={3}
                minNumber={1}
                setCanSave={setPasswordValid}
              />
              <Control
                label="Retapez le mot de passe"
                name="passwordConfirm"
                type="password"
                value={passwordConfirm}
                change={(e) => setPasswordConfirm(e.target.value)}
                error={errors}
              />
              <span
                className={
                  "d-flex align-items-center ml-2 " +
                  (pwdIdentical ? "text-success" : "text-danger")
                }
              >
                {<i className={"mr-2 fa fa-" + (pwdIdentical ? "check" : "times")} />}
                Doit-être identique à celui du dessus{" "}
              </span>
              {pwdIdentical && passwordValid ? (
                <SaveBtn className="mt-4" save={savePassword} isSaving={isSavingPassword} />
              ) : null}
            </div>
          )}
        </>
      )}
    </Page>
  );
};

export default UserDetail;
