import React from "react";
import Control from "../../common/Controls/Control";
import useSimplifiedForm from "./useSimplifiedForm";
import SaveBtn from "../../common/SaveBtn";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import ClientSelector from "../../common/ClientSelector/ClientSelector";
import LabelContainer from "../../common/Controls/LabelContainer";
import ClientCard from "../../common/ClientCard/ClientCard";
import tools from "../../../helpers/tools";

const ParametersForm = ({ goToForm = () => {} }) => {
  const {
    parameterState,
    simplifiedFormActions,
    isSaving,
    commonControlProps,
    formContext,
    formState,
    parameterStateErrors,
  } = useSimplifiedForm();

  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);

  const onOpenSelectClientModal = () => {
    modal_actions.updateModal({
      isOpen: true,
      content: (
        <>
          <h4 className="mb-3">
            Sélectionnez un client pour le rattacher à la demande
          </h4>
          <ClientSelector
            onSelectClient={(client) => {
              modal_actions.updateModal({ isOpen: false });

              simplifiedFormActions.onUpdateParameterState({
                ...parameterState,
                client_lie: client,
                est_rattachement_compte_utilisateur: true,
              });
            }}
          />
        </>
      ),
    });
  };

  return (
    <form onSubmit={simplifiedFormActions.onSubmitForm}>
      <div className="row ">
        <div className="col-12 col-md-6">
          <Control
            type="select"
            name="partenaire_lie"
            label="Partenaire :"
            datas={formContext.myProducers.map((p) => ({
              id: p.uuid,
              name: p.nom_societe,
            }))}
            dataIndex="id"
            dataLabel="name"
            required
            value={parameterState.partenaire_lie?.uuid}
            change={(e) => {
              simplifiedFormActions.onUpdateParameterInput({
                target: {
                  name: "partenaire_lie",
                  value: tools.findIn(
                    formContext.myProducers,
                    e.target.value,
                    "uuid"
                  ),
                },
              });
            }}
            disabled={
              formContext.myRole === "PARTNER" || formState.est_finalise
            }
            {...commonControlProps}
          />

          <LabelContainer
            labelClassname="mb-3"
            label="Client lié à la demande :"
            fullWidth
          >
            {!parameterState.client_lie && (
              <>
                <p className="mb-2">
                  <small className="text-grey font-italic">
                    Aucun client lié
                  </small>
                </p>
                <button
                  type="button"
                  className="btn btn-link p-0"
                  disabled={formState.est_finalise}
                  onClick={onOpenSelectClientModal}
                >
                  <i className="fa fa-link mr-2 p-0" />
                  Lier la demande à un client existant
                </button>
              </>
            )}
          </LabelContainer>

          {parameterState.client_lie && (
            <div className="row">
              <ClientCard
                className="col-12"
                client={parameterState.client_lie}
                disabled={formState.est_finalise}
                onRemoveClient={() =>
                  simplifiedFormActions.onUpdateParameterState({
                    ...parameterState,
                    client_lie: null,
                  })
                }
              />
            </div>
          )}

          <Control
            type="btnList"
            name="product"
            label="Type de produit :"
            datas={[
              { id: "PJ", name: "Protection Juridique" },
              { id: "AC", name: "Assurance Construction" },
              { id: "CYBER", name: "Assurance Cyber" },
              // { id: "MRP", name: "Multirisque professionnelle" },
            ]}
            value={parameterState.product}
            change={simplifiedFormActions.onUpdateParameterInput}
            btnInline
            disabled={parameterState.id}
            required
            info={
              parameterState.id
                ? "Le produit n'est plus modifiable une fois le lead enregistré"
                : ""
            }
            {...commonControlProps}
          />
        </div>
        <div className="col-12 col-md-6">
          <LabelContainer label="Paramètres du formulaire :"></LabelContainer>
          <Control
            type="checkbox"
            name="est_public"
            label="Formulaire public ?"
            checked={parameterState.est_public}
            change={simplifiedFormActions.onUpdateParameterInput}
            info="Un lien sera créé vers ce formulaire une fois les paramètres enregistrés.
            Partagez le lien avec votre client pour lui permettre de visualiser le formulaire en ligne (en tant que demande ou devis)."
            disabled={formState.est_finalise}
            {...commonControlProps}
          />
          <Control
            type="checkbox"
            name="est_modification_formulaire_produit"
            label="Autoriser la modification des données ?"
            checked={parameterState.est_modification_formulaire_produit}
            change={simplifiedFormActions.onUpdateParameterInput}
            info="Le client pourra modifier les données du formulaire (entreprise, établissements, options du produit)."
            disabled={formState.est_finalise}
            {...commonControlProps}
          />
          <Control
            type="checkbox"
            name="est_rattachement_compte_utilisateur"
            label="Autoriser le client à s’identifier sur le formulaire ?            "
            checked={parameterState.est_rattachement_compte_utilisateur}
            change={simplifiedFormActions.onUpdateParameterInput}
            info="Le client pourra créer un compte Perceval (ou se connecter avec un compte existant). Dans ce cas la demande/devis lui sera rattaché. À selectionner pour permettre au client de finaliser une demande/devis."
            disabled={parameterState.client_lie || formState.est_finalise}
            {...commonControlProps}
          />
          <Control
            type="checkbox"
            name="est_finaliser_demande"
            label="Autoriser la finalisation de la demande ?"
            checked={parameterState.est_finaliser_demande}
            change={simplifiedFormActions.onUpdateParameterInput}
            info="Permet au client de valider une demande et/ou d’effectuer un paiement en ligne (CB,SEPA) pour souscrire à une offre."
            disabled={formState.est_finalise}
            {...commonControlProps}
          />
        </div>
        {!formState.est_finalise && (
          <div className="col-12 mt-3 d-flex justify-content-end">
            <SaveBtn isSaving={isSaving} typeBtn="submit" />
            {parameterState.id && (
              <button
                type="button"
                className="btn btn-default btn-sm ml-2"
                onClick={goToForm}
              >
                Éditer le formulaire
                <i className="fa fa-arrow-right ml-2" />
              </button>
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default ParametersForm;
