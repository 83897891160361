import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";

export const mrpFormActions = (state, dispatch, props) => {
  const resetFormState = () => {
    dispatch({
      type: "RESET_FORM_DATA",
      payload: null,
    });
  };

  const initFormState = (apiState) => {
    console.log("APISTATE", apiState, state);
    updateApiData(apiState);
    let newStateData = { ...state.mrpForm.formData };
    Object.keys(newStateData).forEach((stkey, key) => {
      var section = newStateData[stkey];
      Object.keys(section).forEach((inpkey) => {
        if (typeof apiState[inpkey] !== "undefined") {
          newStateData[stkey][inpkey] = apiState[inpkey];
        }
      });
    });
    updateFormData(newStateData);
  };

  const updateApiData = (newObject) => {
    dispatch({
      type: "UPDATE_API_DATA",
      payload: newObject,
    });
  };
  const updateFormData = (newObject) => {
    dispatch({
      type: "UPDATE_FORM_DATA",
      payload: newObject,
    });
  };

  const updateHeadOfficeInfosInputs = (newObject) => {
    dispatch({
      type: "UPDATE_HEADOFFICE_INFOS_INPUTS",
      payload: newObject,
    });
  };
  const updateGlobalInfosInputs = (newObject) => {
    dispatch({
      type: "UPDATE_GLOBAL_INFOS_INPUTS",
      payload: newObject,
    });
  };
  const updateConstructionInputs = (newObject) => {
    dispatch({
      type: "UPDATE_CONSTRUCTION_INPUTS",
      payload: newObject,
    });
  };
  const updateStockageInputs = (newObject) => {
    dispatch({
      type: "UPDATE_STOCKAGE_INPUTS",
      payload: newObject,
    });
  };
  const updateFundsInputs = (newObject) => {
    dispatch({
      type: "UPDATE_FUNDS_INPUTS",
      payload: newObject,
    });
  };
  const updateBuildingInputs = (newObject) => {
    dispatch({
      type: "UPDATE_BUILDING_INPUTS",
      payload: newObject,
    });
  };
  const updateRisksInputs = (newObject) => {
    dispatch({
      type: "UPDATE_RISKS_INPUTS",
      payload: newObject,
    });
  };
  const updateAccident1Inputs = (newObject) => {
    dispatch({
      type: "UPDATE_ACCIDENT1_INPUTS",
      payload: newObject,
    });
  };
  const updateAccident2Inputs = (newObject) => {
    dispatch({
      type: "UPDATE_ACCIDENT2_INPUTS",
      payload: newObject,
    });
  };

  const updateErrors = (err) => {
    dispatch({
      type: "UPDATE_ERRORS",
      payload: err,
    });
  };
  const updateSaving = (isSaving) => {
    dispatch({
      type: "UPDATE_SAVING",
      payload: isSaving,
    });
  };

  const saveForm = async () => {
    console.log("STATE SAVE", state);
    let { apiData, formData } = state.mrpForm;
    let { establishmentId } = apiData;

    updateErrors({});
    updateSaving(true);

    const globalInfosData = { id: establishmentId, ...formData.globalInfosInputs };
    const constructionData = { id: establishmentId, ...formData.constructionInputs };
    const stockageData = { id: establishmentId, ...formData.stockageInputs };
    const fundsData = { id: establishmentId, ...formData.fundsInputs };
    const buildingData = { id: establishmentId, ...formData.buildingInputs };
    const risksData = { id: establishmentId, ...formData.risksInputs };
    const accident1Data = { id: establishmentId, ...formData.accident1Inputs };
    const accident2Data = { id: establishmentId, ...formData.accident2Inputs };

    let postData = {
      establishmentId,
      userId: state.auth.user.id,
      validationSchema: "",
      establishmentsData: [
        {
          ...globalInfosData,
          ...constructionData,
          ...stockageData,
          ...fundsData,
          ...buildingData,
          ...risksData,
          ...accident1Data,
          ...accident2Data,
        },
      ],
    };

    const postDataGlobalInfos = { ...postData, validationSchema: "globalInfosSchema" };
    const postDataConstruction = { ...postData, validationSchema: "constructionSchema" };
    const postDataStockage = { ...postData, validationSchema: "stockageSchema" };
    const postDataFunds = { ...postData, validationSchema: "fundsSchema" };
    const postDataBuilding = { ...postData, validationSchema: "buildingSchema" };
    const postDataRisks = { ...postData, validationSchema: "risksSchema" };
    const postDataAccident1 = { ...postData, validationSchema: "accidentSchema" };
    const postDataAccident2 = {
      ...postData,
      validationSchema: "accident2Schema",
      isLastBlock: true,
      additionalData: { ...formData.headOfficeInfosInputs },
    };

    const res0 = await saveBlockData(postDataGlobalInfos).catch((err) => ({ errors: err }));
    if (res0.errors) {
      updateErrors(res0.errors);
      updateSaving(false);
      return false;
    }

    const res1 = await saveBlockData(postDataConstruction).catch((err) => ({ errors: err }));
    if (res1.errors) {
      updateErrors(res1.errors);
      updateSaving(false);
      return false;
    }

    const res2 = await saveBlockData(postDataStockage).catch((err) => ({ errors: err }));
    if (res2.errors) {
      updateSaving(false);
      updateErrors(res2.errors);
      return false;
    }
    const res3 = await saveBlockData(postDataFunds).catch((err) => ({ errors: err }));
    if (res3.errors) {
      updateSaving(false);
      updateErrors(res3.errors);
      return false;
    }
    const res4 = await saveBlockData(postDataBuilding).catch((err) => ({ errors: err }));
    if (res4.errors) {
      updateSaving(false);
      updateErrors(res4.errors);
      return false;
    }
    const res5 = await saveBlockData(postDataRisks).catch((err) => ({ errors: err }));
    if (res5.errors) {
      updateSaving(false);
      updateErrors(res5.errors);
      return false;
    }
    const res6 = await saveBlockData(postDataAccident1).catch((err) => ({ errors: err }));
    if (res6.errors) {
      updateSaving(false);
      updateErrors(res6.errors);
      return false;
    }
    const res7 = await saveBlockData(postDataAccident2).catch((err) => ({ errors: err }));
    if (res7.errors) {
      updateSaving(false);
      updateErrors(res7.errors);
      return false;
    }
    updateSaving(false);
    toast.success("Vos modifications ont bien été enregistrées");
  };

  const saveBlockData = async (postData) => {
    return new Promise((resolve, reject) => {
      let path = `quotations/${state.mrpForm.apiData.quotationId}/finalized`;
      dataService.patch(path, postData, resolve, reject);
    });
  };

  return {
    updateFormData,
    updateErrors,
    updateHeadOfficeInfosInputs,
    updateGlobalInfosInputs,
    updateConstructionInputs,
    updateStockageInputs,
    updateFundsInputs,
    updateBuildingInputs,
    updateRisksInputs,
    updateAccident1Inputs,
    updateAccident2Inputs,
    saveForm,
    initFormState,
    updateApiData,
    resetFormState,
  };
};
