import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useStore from "../../../../context/useStore";
import useManagers from "../../../../hooks/useManagers/useManagers";
import useRole from "../../../../hooks/useRole/useRole";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn";
import useLeads from "../useLeads";
import LinkedLeadsFilters from "./LinkedLeadsFilters";
import ProducerSelect from "./ProviderSelect";
import useLeadFilters from "./useLeadFilters";
import {
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../../common/Controls/filterContainer";

const LeadListFilters = () => {
  const { managers } = useManagers();
  const [state] = useStore();
  const { myRole } = useRole();
  const { QUOTATION_PRODUCT_TYPES, QUOTE_PRODUCT_TYPES, LEAD_STATUS } =
    state.constants.items;
  const { leadsFilters, actions } = useLeadFilters();
  const {
    actions: leadActions,
    isSearching,
    showOnlyLinkedLeads,
    productCategory,
  } = useLeads();
  const { updateFilters, resetLeadsFilters, updateOneMonth } = actions;
  const { terms, products, assignedTo, statuses, beginAt, endAt } =
    leadsFilters.filters;
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  useEffect(() => {
    //resetLeadsFilters();
  }, []);

  const onSubmitSearch = (e) => {
    e.preventDefault();
    leadActions.getLeads();
  };

  const commonControlProps = {
    noMarginOnFormGroup: true,
    noPaddingOnFormGroup: true,
    margin: "m-0",
  };

  const productListByCategory = {
    PRO: QUOTATION_PRODUCT_TYPES,
    DOMI: QUOTE_PRODUCT_TYPES,
  };

  const searchTermsLabelByCategory = {
    PRO: (
      <>
        <div>
          <strong>Recherche Société</strong>
        </div>{" "}
        <div>(société, siret ou ape)</div>
      </>
    ),
    DOMI: (
      <>
        <div>
          <strong>Rechercher un prospect</strong>
        </div>{" "}
        <div>(nom, prénom ou email)</div>
      </>
    ),
  };

  return showOnlyLinkedLeads ? (
    <div className="row mb-3 ">
      <div className="col-12">
        <LinkedLeadsFilters />
      </div>
    </div>
  ) : (
    <>
      <form onSubmit={onSubmitSearch}>
        <div className={filterContainerClassName}>
          <div className="col-12">
            <Control
              label={searchTermsLabelByCategory[productCategory]}
              type="text"
              name="terms"
              value={terms}
              change={updateFilters}
              fullWidth
              {...commonControlProps}
            />
            {!showAdvancedSearch && (
              <button
                className="btn btn-link py-0 pb-3"
                type="button"
                onClick={() => setShowAdvancedSearch(true)}
              >
                <i className="fa fa-cog mr-1" />
                Recherche avancée
              </button>
            )}
          </div>
          {showAdvancedSearch && (
            <>
              <div className="col">
                <Control
                  label="Produits"
                  name="products"
                  type="checkBtnList"
                  value={products}
                  change={updateFilters}
                  datas={productListByCategory[productCategory].map((d) => ({
                    ...d,
                    label: d.id.toUpperCase(),
                  }))}
                  dataIndex="id"
                  dataLabel="label"
                  dataIcon="icon"
                  btnInline
                  {...commonControlProps}
                />
              </div>
              <div className="col">
                <Control
                  label="Statuts"
                  name="statuses"
                  type="checkBtnList"
                  value={statuses}
                  datas={LEAD_STATUS}
                  change={updateFilters}
                  dataIndex="id"
                  dataLabel="name"
                  dataIcon="icon"
                  btnInline
                  {...commonControlProps}
                />
              </div>

              <div className="col-12">
                {myRole !== "PARTNER" && productCategory === "PRO" && (
                  <ProducerSelect commonControlProps={commonControlProps} />
                )}
                <Control
                  label="Assigné à"
                  name="assignedTo"
                  type="checkBtnList"
                  value={assignedTo}
                  datas={managers.filter((manager) =>
                    leadsFilters.filters.producer
                      ? manager.producer &&
                        leadsFilters.filters.producer == manager.producer
                      : true
                  )}
                  change={updateFilters}
                  dataIndex="id"
                  dataLabel="fullName"
                  btnInline
                  {...commonControlProps}
                />
              </div>
              <div className="col-12 col-md-3">
                <Control
                  label="Du"
                  name="beginAt"
                  type="date"
                  value={beginAt}
                  change={updateFilters}
                  inputStyle={{ borderRadius: 5 }}
                  {...commonControlProps}
                />
              </div>
              <div className="col-12 col-md-3">
                <Control
                  label="Au"
                  name="endAt"
                  type="date"
                  value={endAt}
                  change={updateFilters}
                  inputStyle={{ borderRadius: 5 }}
                  {...commonControlProps}
                />
              </div>
              <div className="col-12 col-md-3 d-flex align-items-center">
                <button
                  className="w-95 d-center btn bg-white btn-sm"
                  onClick={updateOneMonth}
                >
                  30 derniers jours
                </button>
              </div>
              <div className="col-12 mt-4 col-md-6 d-center">
                <button
                  type="button"
                  className="btn btn-link py-0"
                  onClick={() => setShowAdvancedSearch(false)}
                >
                  <i className="fa fa-cog mr-1" />
                  Recherche simplifiée
                </button>
              </div>
              <div className="col-12 mt-4 mb-4 col-md-3 d-flex align-items-center justify-content-end">
                <button
                  className="w-100 d-center btn bg-white btn-sm"
                  onClick={resetLeadsFilters}
                >
                  Réinitialiser les filtres
                </button>
              </div>
            </>
          )}
          <div className={searchButtonContainerClassName}>
            <SaveBtn
              className={searchButtonClassName}
              typeBtn="submit"
              text="Rechercher"
              isSaving={isSearching}
              type={searchButtonType}
              loaderColor={searchButtonLoaderColor}
            />
          </div>
        </div>
      </form>
    </>
  );
};

const StyledLeadListFilters = styled.div``;

export default LeadListFilters;
