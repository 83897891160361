import React from "react";
import { SentryRoute } from "../../App";
import useStore from "../../context/useStore";
import useRole from "../../hooks/useRole/useRole";
import Login from "../pages/Login/Login";
import RenewPasswordLoggedIn from "../pages/RenewPassword/RenewPasswordLoggedIn";
import Loader from "./Loader";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [state, dispatch] = useStore();
  const { myRole } = useRole();

  return state.auth.isLoading ? (
    <Loader withContainer />
  ) : (
    <SentryRoute
      {...rest}
      render={(props) =>
        state.auth.isAuthenticated == true ? (
          state.auth.user.mustRenewPassword ? (
            <RenewPasswordLoggedIn />
          ) : myRole ? (
            <Component {...props} />
          ) : (
            <Loader withContainer />
          )
        ) : (
          <Login />
        )
      }
    />
  );
};

export default PrivateRoute;
