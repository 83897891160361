import useStore from "../../../../../../../../../context/useStore";
import tools from "../../../../../../../../../helpers/tools";
import DownloadDocument from "./DownloadDocument";

const MesFacturesList = ({ factures }) => {
  const [state] = useStore();

  const { PAYMENT_STATUS } = state.constants.items;

  if (!factures) return null;
  return (
    <div style={{ marginBottom: 25 }} className="row">
      <div className="col-12">
        {factures.length === 0 && (
          <p>Il n'y a aucune facture pour le moment.</p>
        )}
        <ul className="list-group">
          {factures.map((pol, polk) => {
            const statutRef = PAYMENT_STATUS.find((p) => p.id === pol.statut);
            //we have an array like this ["Type produit 1", "Type produit 2", "Type produit 1", "Type produit 3", ...]
            //we want to group them by type and count them

            const groupedTypeProduits = pol.typeProduits.reduce(
              (acc, produit) => {
                if (!acc[produit]) {
                  acc[produit] = 1;
                } else {
                  acc[produit]++;
                }
                return acc;
              },
              {}
            );

            return (
              <li
                className={`list-group-item mb-3 shadow-sm rounded border bg-white animated fadeInUp faster d-flex align-items-center justify-content-between flex-wrap polist`}
                style={{
                  animationDelay: `${polk * 50}ms`,
                }}
                key={`polrenew${polk}`}
              >
                <div
                  className="d-flex flex-row flex-wrap align-items-start justify-content-between w-100"
                  style={{ gap: "0.5rem" }}
                >
                  <div>
                    <div
                      className="text-success d-flex align-items-center font-weight-bold mb-2 w-100"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      <div className="">
                        <i className="fa fa-building mr-2" />
                        {pol.nomSociete}
                      </div>
                      <div
                        className={`badge ml-2 badge-${statutRef.color}`}
                        style={{ fontSize: 11 }}
                      >
                        {statutRef.name}
                      </div>
                    </div>

                    <div className="my-2">
                      {Object.keys(groupedTypeProduits).map(
                        (typeProduit, typeProduitk) => {
                          const hasMoreThan1 =
                            groupedTypeProduits[typeProduit] > 1;
                          return (
                            <div key={`pol${typeProduitk}`}>
                              <span
                                className={`badge bg-blue border shadow-small px-2 text-success rounded`}
                                style={{ fontSize: 12 }}
                              >
                                {typeProduit}
                                {hasMoreThan1 && (
                                  <span className="bg-success badge ml-1 text-white rounded-circle">
                                    {groupedTypeProduits[typeProduit]}
                                  </span>
                                )}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div>
                      <strong>
                        {tools.truenumberWithSpaces(pol.montant / 100)} €
                      </strong>{" "}
                      payés par <strong>{pol.methodePaiement}</strong>
                      <br />
                      le <strong>{tools.formatDateHours(pol.payeLe)}</strong>
                    </div>
                  </div>
                  <div>
                    {pol.medias.length > 0 ? (
                      pol.medias.map((media) => (
                        <>
                          <DownloadDocument
                            key={media.id}
                            document={{
                              id: media.id,
                              fileType: {
                                name: `Facture-${pol.numeroFacture}-${pol.nomSociete}-${pol.montant}`,
                              }, //facture-numeroFacture-nomSociete-date-montant
                              uploadedAt: pol.payeLe,
                            }}
                            name="Télécharger la facture"
                          />
                        </>
                      ))
                    ) : (
                      <p className="font-italic">
                        <i className="fa fa-clock mr-1" />
                        Facture en cours d'édition
                      </p>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MesFacturesList;
