import dataService from "../helpers/dataService";

export const postComment = (commentData) => {
  return new Promise((resolve, reject) => {
    dataService.post(`comment-lists/comments`, commentData, resolve, reject);
  });
};
export const patchComment = (commentId, commentData) => {
  return new Promise((resolve, reject) => {
    dataService.patch(`comment-lists/comments/${commentId}`, commentData, resolve, reject);
  });
};
export const deleteComment = (commentId) => {
  return new Promise((resolve, reject) => {
    dataService.remove(`comment-lists/comments/${commentId}`, {}, resolve, reject, () => {}, true);
  });
};
