import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import React, { useEffect, useState } from "react";
import Control from "../../../common/Control";
import SaveBtn from "../../../common/SaveBtn";

const AddFaqForm = ({ state, actions }) => {
  const [newFaq, setNewFaq] = useState({
    order: 1,
    name: "",
    faqSection: "",
    description: "",
  });

  useEffect(() => {
    var sectionRef = state.sections.find((sec) => sec.id == newFaq.faqSection);

    if (sectionRef) {
      var faqsRef = state.faqs.filter(
        (faq) => faq.faqSection == newFaq.faqSection
      );
      if (faqsRef.length) {
        var max = getMaxOfArray(faqsRef.map((f) => f.order));
        setNewFaq({ ...newFaq, order: max + 1 });
      }
    }
  }, [newFaq.faqSection, state.faqs]);

  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }

  return (
    <div className="shadow-sm p-3 my-3 bg-white">
      <div>
        <strong>Ajouter une FAQ</strong>
      </div>
      <span className="badge badge-default">order : {newFaq.order}</span>
      <Control
        label="Section"
        type="select"
        datas={state.sections}
        dataIndex="id"
        dataLabel="name"
        value={newFaq.faqSection}
        change={(e) => setNewFaq({ ...newFaq, faqSection: e.target.value })}
      />
      <Control
        label="Nom de la FAQ"
        type="text"
        value={newFaq.name}
        change={(e) => setNewFaq({ ...newFaq, name: e.target.value })}
      />
      <CKEditor
        editor={Editor}
        data={newFaq.description}
        onChange={(event, editor) => {
          const data = editor.getData();

          setNewFaq({ ...newFaq, description: data });
        }}
      />
      <div className="d-flex w-100 justify-content-end">
        <SaveBtn
          save={() => actions.createFaq(newFaq)}
          isSaving={state.isCreatingFaq}
          text={
            <>
              <i className="fa fa-save mr-2" />
              Créer la FAQ
            </>
          }
          className="btn-sm h-100 ml-2"
          loaderColor="#040ca5"
        />
      </div>
    </div>
  );
};

export default AddFaqForm;
