import { Form } from "@10positif/10positif.ui.form";
import React from "react";
import {
  API_URL,
  APP_URL,
  GRAAL_API_KEY,
  GRAAL_API_URL,
  recaptcha_token,
} from "../../../config";
import useSimplifiedForm from "./useSimplifiedForm";

const SimplifiedForm = () => {
  const { parameterState, formState, simplifiedFormActions } =
    useSimplifiedForm();

  if (!parameterState.id) return null;

  return (
    <div className="row mt-5">
      <div className="col-12">
        <Form
          app_url={APP_URL}
          product={parameterState.product}
          api_endpoint={API_URL.slice(0, -1)}
          graal_api_endpoint={GRAAL_API_URL.slice(0, -1)}
          graal_api_login_endpoint={`/login`}
          search_companies_endpoint={`/companies/search/:terms`}
          search_establishments_endpoint={`/companies/:siren/establishments`}
          legal_forms_endpoint={`/constantes/formes-juridiques`}
          constants_endpoint={`/constantes`}
          submit_form_data_endpoint={`/lead-form/`}
          pj_tarification_endpoint={`/tarificateurs/calculer-tarifs`}
          pj_public_document_endpoint={`/files/public/:type`}
          pj_quotation_document_endpoint={`/leads/:leadUuid/devis`}
          ac_quotation_upload_endpoint={`/lead-documents/:leadUuid/upload`}
          ac_quotation_document_list_endpoint={`/lead-documents/:leadUuid`}
          ac_quotation_document_endpoint={`/lead-form/:leadUuid/demander-devis`}
          ac_quotation_finalize_endpoint={`/lead-form/:leadUuid/finaliser`}
          cyber_quotation_document_endpoint={`/lead-form/:leadUuid/demander-devis`}
          cyber_quotation_finalize_endpoint={`/lead-form/:leadUuid/finaliser`}
          create_user_endpoint={`/customers`}
          check_user_exists_endpoint={`/customers/exists`}
          login_user_endpoint={`/auth/login/PER_APP`}
          logout_user_endpoint={`/auth/logout`}
          check_user_connected_endpoint={`/auth/me/PER_APP`}
          user_payment_methods_endpoint={`/clients/:clientId/stripe/paymentmethods`}
          payment_intent_endpoint={`/payments/payment-intent`}
          confirm_payment_endpoint={`/paiements/payer`}
          editMode={true}
          graal_api_key={GRAAL_API_KEY}
          est_finalise={formState.est_finalise}
          onFinalize={() => {}}
          onFormUpdate={(data) => simplifiedFormActions.onFormUpdate(data)}
          parameters={{
            demande_id: formState.id,
            partenaire_lie: {
              id: formState.partenaire_lie,
              nom_societe: "",
            },
            est_public: true,
            est_modification_formulaire_produit: true,
            est_rattachement_compte_utilisateur: false,
            est_finaliser_demande: false,
          }}
          formData={formState.formData}
          localDocuments={formState.documents}
          recaptcha_site_key={recaptcha_token}
          theme={{
            colors: {
              "perceval-blue": [
                "#E5EDFF",
                "#B8CCFF",
                "#8AABFF",
                "#5C8AFF",
                "#2E69FF",
                "#0049FF",
                "#3e75ff",
                "#040ca5",
                "#001D66",
                "#000F33",
              ],
            },
            primaryColor: "perceval-blue",
            defaultRadius: 5,
            fontFamily: "Montserrat, sans-serif",
            globalStyles: (theme) => ({
              ".mantine-Input-input:disabled": {
                color: "#212121",
              },
            }),
          }}
        />
      </div>
    </div>
  );
};

export default SimplifiedForm;
