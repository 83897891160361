import React, { useRef, useEffect, useState } from "react";
import Axios from "axios";
import { API_URL } from "../../config";

const Avatar = ({
  id,
  containerWidth = false,
  containerHeight = false,
  width = 200,
  height = 200,
  className = "",
  imageUrl = false,
}) => {
  useEffect(() => {
    if (id && id !== "create") {
      getImage();
    } else {
      if (imageUrl) setImageSrc(imageUrl);
    }
  }, [id]);
  const [imageSrc, setImageSrc] = useState("/images/default-avatar.jpg");

  const getImage = async () => {
    let source = await Axios.get(API_URL + "files/avatars/" + id).catch(
      (err) => err
    );

    if (source.status !== 200) {
      //gestion erreur
    }
    setImageSrc(source.data + "?" + new Date().toISOString());
  };

  return (
    <div
      className={className}
      style={{
        borderRadius: "100%",
        overflow: "hidden",
        width: containerWidth || width,
        height: containerHeight || height,
        textAlign: "center",
      }}
    >
      <img width={width} height={height} src={imageSrc} alt="Avatar" />
    </div>
  );
};

export default Avatar;
