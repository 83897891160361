import React from "react";
import styled from "styled-components";

const FiltersContainer = ({ title, children }) => {
  return (
    <StyledFiltersContainer className="row my-3 mx-0 py-3 px-1 bg-blue border">
      <div className="col-12 pb-2">
        <strong style={{ fontSize: 16 }}>{title}</strong>
      </div>
      {children}
    </StyledFiltersContainer>
  );
};

const StyledFiltersContainer = styled.div`
  border-radius: 10px;
`;

export default FiltersContainer;
