import React, { useRef } from "react";
import LabelContainer from "../LabelContainer";
import { DateTime } from "luxon";
import EmptyButton from "./EmptyButton";

const DateInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  min = "",
  max = "",
  ...props
}) => {
  const inputRef = useRef(null);
  const valueChange = (e) => {
    var filteredValue = DateTime.fromISO(e.target.value).toISO();
    //console.log(filteredValue);
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  return (
    <LabelContainer {...props} value={value}>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        style={{ ...inputStyle, display: "inline-flex" }}
        min={min}
        max={max}
        className={"form-control " + inputClassName}
        type="date"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={DateTime.fromISO(value).toFormat("yyyy-MM-dd")}
        onChange={valueChange}
        placeholder={placeholder}
        onBlur={props.onBlur || null}
      />
      {/* <EmptyButton
        change={change}
        name={props.name}
        targetInput={inputRef}
        value={value}
        right={-11}
      /> */}
    </LabelContainer>
  );
};

export default DateInput;
