import React from "react";
import { getDocByKey } from "./documentationLinks";
import styled from "styled-components";

const MerlinHelp = ({ linkKey }) => {
  const { url, title, description } = getDocByKey(linkKey);

  const isExternalLink = url !== "#";

  return (
    <MerlinLink
      href={url}
      hasLink={url !== "#"}
      target={isExternalLink ? "_blank" : ""}
      className={`d-flex flex-column  py-3 pr-3  ${
        url !== "#" ? "shadow-sm" : ""
      } mb-4`}
      rel="noreferrer"
      style={{
        cursor: isExternalLink ? "pointer" : "default",
      }}
    >
      <h4
        className="pt-0 mb-1"
        style={{
          fontSize: 15,
          textTransform: "none",
        }}
      >
        {isExternalLink && <i className="fa fa-link" />} {title}
      </h4>
      <p className="mb-0">{description}</p>
    </MerlinLink>
  );
};

export default MerlinHelp;

const MerlinLink = styled("a")`
  background-image: url(/images/icons/merlin-help.png);
  background-repeat: no-repeat;
  background-size: 56px;
  background-position: 8px 4px;
  border-radius: 20px;
  min-height: 80px;
  padding-left: 75px;
  text-decoration: none !important;
  background-color: white;

  &:hover {
    color: ${({ hasLink }) => (hasLink ? "black" : "#2196F3")};
    background-color: ${({ hasLink }) => (hasLink ? "#f6f6f6" : "white")};
  }

  @media (max-width: 768px) {
    padding-left: 58px;
    background-image: url(/images/icons/merlin-help.png);
    background-size: 45px;
    background-position: 6px 7px;
    min-height: 60px;

    & h4 {
      font-size: 13px !important;
    }

    & p {
      font-size: 11px !important;
    }
  }
`;
