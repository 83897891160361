import React from "react";
import styled from "styled-components";
import useStore from "../../../../context/useStore";

const StatusName = ({ status }) => {
  const [state] = useStore();
  const { LEAD_STATUS } = state.constants.items;

  const statusRef = LEAD_STATUS.find((l) => l.id == status);

  return (
    <StyledStatusName className="text-center">
      {statusRef?.name}
    </StyledStatusName>
  );
};

const StyledStatusName = styled.div`
  font-size: 9px;
  line-height: 10px;
  padding: 7px 0;
  font-style: italic;
  width: 50px;
`;

export default StatusName;
