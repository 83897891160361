import { DateTime } from "luxon";
import React from "react";
import useRole from "../../../../../hooks/useRole/useRole";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn";
import useCommissionsList from "../useCommissionsList";
import SelectProducers from "./SelectProducers";
import useCommissionListFilters from "./useCommissionListFilters";
import {
  commonControlProps,
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../../../common/Controls/filterContainer";

const CommissionListFilters = () => {
  const { isSearching } = useCommissionsList();
  const { filters, actions } = useCommissionListFilters();
  const { myRole } = useRole();

  return (
    <form onSubmit={actions.onSubmitSearch}>
      <div className={filterContainerClassName}>
        {myRole !== "PARTNER" && (
          <div className="col-12">
            <SelectProducers
              label="Partenaire"
              type="select"
              dataIndex="id"
              dataLabel="company"
              value={filters.producer}
              name="producer"
              change={actions.onChangeFilter}
              {...commonControlProps}
            />
          </div>
        )}
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Du"
                name="beginAt"
                type="date"
                value={filters.beginAt}
                change={actions.onChangeFilter}
                inputStyle={{ borderRadius: 5 }}
                min={DateTime.local()
                  .minus({ months: 6 })
                  .toFormat("yyyy-MM-dd")}
                {...commonControlProps}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Au"
                name="endAt"
                type="date"
                value={filters.endAt}
                change={actions.onChangeFilter}
                inputStyle={{ borderRadius: 5 }}
                min={DateTime.fromISO(filters.beginAt)
                  .plus({ days: 1 })
                  .toFormat("yyyy-MM-dd")}
                max={DateTime.fromISO(filters.beginAt)
                  .plus({ months: 6 })
                  .minus({ days: 1 })
                  .toFormat("yyyy-MM-dd")}
                {...commonControlProps}
              />
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-default border btn-sm"
                onClick={actions.onClickLastMonth}
              >
                Dernier mois
              </button>
              <button
                type="button"
                className="btn btn-default border btn-sm"
                onClick={actions.onClick3LastMonth}
              >
                3 Derniers mois
              </button>
              <button
                type="button"
                className="btn btn-default border btn-sm"
                onClick={actions.onClick6LastMonth}
              >
                6 Derniers mois
              </button>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="w-100 d-center btn bg-white btn-sm"
            onClick={actions.onResetFilters}
          >
            Réinitialiser les filtres
          </button>
        </div>
        <div className={`${searchButtonContainerClassName} mt-4`}>
          <SaveBtn
            className={searchButtonClassName}
            typeBtn="submit"
            type={searchButtonType}
            text="Rechercher"
            disabled={
              isSearching || (myRole !== "PARTNER" && !filters.producer)
            }
            isSaving={isSearching}
            loaderColor={searchButtonLoaderColor}
          />
        </div>
      </div>
    </form>
  );
};

export default CommissionListFilters;
