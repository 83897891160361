import { atom, useAtom } from "jotai";
import useStore from "../../../../context/useStore";
import { searchClients } from "../../../../services/clientService";

const clientsAtom = atom([]);
const isLoadingClientsAtom = atom(false);
const clientFiltersAtom = atom({
  user: {
    email: "",
    nomPrenomClient: "",
    nomSociete: "",
    sirenOuSiret: "",
    numeroContrat: "",
    statutContrat: 10,
    statutCompte: "",
    compagniesAssurances: [],
    typesProduits: [],
    produits: [],
    partenaire: "",
    auMoins1Contrat: false,
    utilisateurSansClient: false,
  },
  page: 1,
});
const resultCountAtom = atom(0);
const detailedCountAtom = atom({
  users: 0,
  clients: 0,
  policies: 0,
});

const useClientList = () => {
  const [state] = useStore();
  const { ACCOUNT_STATUS, POLICIES_STATUS } = state.constants.items;
  const [clientFilters, setClientFilters] = useAtom(clientFiltersAtom);
  const [clients, setClients] = useAtom(clientsAtom);
  const [resultCount, setResultCount] = useAtom(resultCountAtom);
  const [isLoadingClients, setIsLoadingClients] = useAtom(isLoadingClientsAtom);
  const [detailedCount, setDetailedCount] = useAtom(detailedCountAtom);

  const onGetClients = ({ overrideFilters = null } = {}) => {
    setIsLoadingClients(true);
    searchClients(overrideFilters || clientFilters)
      .then((data) => {
        //order policies by product.name
        setClients(
          data.users.map((user) => ({
            ...user,
            clients: user.clients.map((client) => ({
              ...client,
              policies: client.policies.sort((a, b) =>
                a.productType < b.productType ? 1 : -1
              ),
            })),
          }))
        );
        setDetailedCount({
          users: data.count,
          clients: data.users.reduce((a, b) => a + b.clients.length, 0),
          policies: data.users.reduce(
            (a, b) =>
              a + b.clients.reduce((a1, b1) => a1 + b1.policies.length, 0),
            0
          ),
        });
        setResultCount(data.count);
      })
      .finally(() => setIsLoadingClients(false));
  };

  const onClientFiltersChange = (newFilters) => {
    setClientFilters(newFilters);
  };

  const updatePage = (page) => {
    const newFilters = {
      ...clientFilters,
      page,
    };
    onGetClients({ overrideFilters: newFilters });
    setClientFilters(newFilters);
  };

  return {
    clients,
    resultCount,
    detailedCount,
    clientFilters,
    isLoadingClients,
    ACCOUNT_STATUS,
    POLICIES_STATUS,
    actions: { onGetClients, onClientFiltersChange, updatePage },
  };
};

export default useClientList;
