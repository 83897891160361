import React, { useState } from "react";
import DataResult from "./DataResult";
import Axios from "axios";
import { API_URL } from "../../../config";
import SaveBtn from "../../common/SaveBtn";

const DataLine = ({ line, setErrors }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [results, setResults] = useState(false);

  function importData(path) {
    setIsSaving(true);
    Axios.get(API_URL + "modulr/" + path)
      .then((res) => {
        setIsSaved(false);
        setResults(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSaving(false);
      });
  }

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-12 col-md-4 d-flex align-items-center">
          <span
            className="font-weight-bolder text-secondary"
            style={{
              fontSize: "1rem",
            }}
          >
            {line.name}
          </span>
        </div>
        <div className="col-12 col-md-8 text-right">
          <div className="d-flex justify-content-end">
            {results && (
              <div className="d-flex flex-row w-100 justify-content-between mr-3 flex-wrap">
                <DataResult
                  className="animated fadeInUp fast"
                  color="primary"
                  data={results.nbDatasModulr}
                  text="Sur Modulr"
                />
                <DataResult
                  className="animated fadeInUp fast"
                  style={{ animationDelay: "50ms" }}
                  color="secondary"
                  data={results.nbDatasPerceval}
                  text="Sur Perceval"
                />
                <DataResult
                  className="animated fadeInUp fast"
                  style={{ animationDelay: "100ms" }}
                  color="success"
                  data={results.nbCreate}
                  text="Créées"
                />
                <DataResult
                  className="animated fadeInUp fast"
                  style={{ animationDelay: "150ms" }}
                  color="warning"
                  data={results.nbUpdate}
                  text="Modifiées"
                />
                <DataResult
                  className="animated fadeInUp fast"
                  style={{ animationDelay: "200ms" }}
                  color="danger"
                  data={results.nbDelete}
                  text="Supprimées"
                />
                <DataResult
                  className="animated fadeInUp fast"
                  style={{ animationDelay: "200ms" }}
                  color="danger"
                  data={results.nbInvalidEntities}
                  text="Invalides"
                />
              </div>
            )}
            <SaveBtn
              text="Importer"
              isSaved={isSaved}
              isSaving={isSaving}
              save={() => importData(line.path)}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default DataLine;
