import { DateTime } from "luxon";
import React from "react";
import useProducers from "../../../hooks/useProducers/useProducers";
import Control from "../../common/Controls/Control";

const AccountInfo = ({
  account,
  updateData,
  pwdIdentical,
  errors,
  isCreate,
  isPartner = false,
}) => {
  const { orderedProducers } = useProducers(true);

  const commonInputProps = {
    fullWidth: true,
    labelClassname: "w-100",
    noMarginOnFormGroup: true,
    noPaddingOnFormGroup: true,
    noBg: true,
  };

  return (
    <div className="rounded p-4 bg-white shadow-sm border">
      <Control
        label="Civilité"
        name="gender"
        type="btnList"
        btnInline={true}
        datas={[
          { name: "Monsieur", id: 1 },
          { name: "Madame", id: 2 },
        ]}
        dataIndex="id"
        dataLabel="name"
        value={account.gender}
        change={updateData}
        error={errors}
        {...commonInputProps}
        fullWidth={false}
      />
      <Control
        label="Nom"
        name="lastname"
        type="text"
        value={account.lastname}
        change={updateData}
        error={errors}
        {...commonInputProps}
      />
      <Control
        label="Prénom"
        name="firstname"
        type="text"
        value={account.firstname}
        change={updateData}
        error={errors}
        {...commonInputProps}
      />
      <Control
        label="Téléphone mobile"
        name="phone"
        type="tel"
        value={account.phone}
        change={updateData}
        error={errors}
        {...commonInputProps}
      />
      <Control
        label="Email"
        name="email"
        type="email"
        value={account.email}
        change={updateData}
        error={errors}
        {...commonInputProps}
      />
      <Control
        label="Date d'expiration du compte"
        type="date"
        name="contractEndAt"
        value={account.contractEndAt}
        change={updateData}
        error={errors}
        {...commonInputProps}
      />

      <div
        className={`d-flex align-items-center mb-3 ${
          account.isActivated ? "text-success" : "text-dark"
        }`}
      >
        <div>
          {account.isActivated
            ? `Compte activé ${
                account.activatedAt
                  ? `le ${DateTime.fromISO(account.activatedAt).toFormat("dd/MM/yyyy")}`
                  : ""
              }`
            : "Compte non activé"}
        </div>
        <i className={`fa ml-2 ${account.isActivated ? "fa-check-double" : "fa-times"}`} />
      </div>
      {isCreate && (
        <>
          <Control
            label="Mot de passe"
            name="password"
            type="password"
            value={account.password}
            change={updateData}
            error={errors}
            fullWidth
            {...commonInputProps}
          />
          <Control
            label="Mot de passe (retapez)"
            name="password_confirm"
            type="password"
            value={account.password_confirm}
            change={updateData}
            error={errors}
            fullWidth
            {...commonInputProps}
          />
          <div className={"d-center ml-2 " + (pwdIdentical ? "text-info" : "text-danger")}>
            {<i className={"mr-2 fa fa-" + (pwdIdentical ? "check" : "times")} />}
            Identique à celui du dessus{" "}
          </div>
        </>
      )}
      {!isCreate && isPartner && (
        <>
          <hr />
          <div>Champs spécifiques aux partenaires</div>
          <Control
            label="Code d'affiliation"
            name="affiliateCode"
            type="text"
            value={account.affiliateCode}
            change={updateData}
            error={errors}
          />
          <Control
            label="Société"
            name="producer"
            type="select"
            datas={orderedProducers}
            dataLabel="company"
            dataIndex="id"
            value={account.producer}
            change={updateData}
            error={errors}
          />
        </>
      )}
    </div>
  );
};

export default AccountInfo;
