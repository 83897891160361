import React from "react";
import {
  StyledFinalizedIcon,
  StyledLeadProduct,
  StyledProductStatus,
} from "../../../LeadListPage/LeadList/LeadItem/LeadProduct/LeadProduct";
import useSimplifiedFormItem from "../useSimplifiedFormItem";

const ItemProduct = () => {
  const { simplifiedFormItem } = useSimplifiedFormItem();

  return (
    <StyledLeadProduct
      className="d-center"
      style={{ textTransform: "uppercase" }}
    >
      {simplifiedFormItem.parameters.product}
      <StyledProductStatus>
        {simplifiedFormItem.est_finalise && (
          <StyledFinalizedIcon color="success" title="Demande finalisée">
            <i className="fa fa-check" />
          </StyledFinalizedIcon>
        )}
      </StyledProductStatus>
    </StyledLeadProduct>
  );
};

export default ItemProduct;
