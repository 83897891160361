// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";

// class ScrollToTop extends Component {
//   componentDidUpdate(prevProps) {
//     if (this.props.location !== prevProps.location) {
//       window.scrollTo(0, 0);
//     }
//   }
//   render() {
//     return <React.Fragment />;
//   }
// }

// export default withRouter(ScrollToTop);

const ScrollToTop = () => {
  // let mybutton = document.getElementById("myBtn");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    let mybutton = document.getElementById("myBtn");

    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <div>
      <button onClick={topFunction} id="myBtn" title="Go to top">
        <i className="fa fa-chevron-up" aria-hidden="true"></i>
      </button>
    </div>
  );
};

export default ScrollToTop;
