import React from "react";
import styled from "styled-components";

const StatContainer = ({ title, description, index, ...props }) => {
  return (
    <StyledStatContainer className=" bg-white p-3 border  w-100 mb-2 shadow-sm">
      <h5 className="text-left w-100 font-weight-bold">{title}</h5>
      {description && <div className="font-weight-light py-2">{description}</div>}
      <div className="d-flex flex-row align-items-start justify-content-around flex-wrap mt-3">
        {props.children}
      </div>
    </StyledStatContainer>
  );
};

const StyledStatContainer = styled.div`
  border-radius: 10px;
`;

export default StatContainer;
