import React from "react";
import Control from "./Control";

const DynamicList = ({
  listLabel,
  uniqueKey,
  element,
  fields,
  items,
  btnLabel,
  change,
  error,
  dynamicTypes = false,
  labelClassname = "",
  formGroupClassname = "",
}) => {
  function addItem() {
    var newItem = {};
    fields.forEach((f) => {
      newItem[f.name] = "";
    });
    items.push(newItem);
    change({ target: { name: element, value: items } });
  }

  function changeItem(e, k) {
    let realValue = e.target.value;
    if (e.target.type == "checkbox") {
      realValue = e.target.checked;
    }
    items[k][e.target.name] = realValue;
    change({ target: { name: element, value: items } });
  }
  function removeItem(kk) {
    items = items.filter((i, k) => k !== kk);
    change({ target: { name: element, value: items } });
  }

  return (
    <div className={`form-group ${formGroupClassname}`}>
      <label className={labelClassname}>{listLabel}</label>
      <ul className="list-group list-unstyled">
        {items.map((it, k) => (
          <li key={"dyn" + element + uniqueKey + "_" + k}>
            <div className="d-flex align-items-center ">
              {fields.map((fi, kk) => (
                <Control
                  className={"mr-0 "}
                  labelClassname="mb-0 mt-0 mr-2 text-black-50"
                  inputStyle={
                    kk == fields.length - 1
                      ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                      : {}
                  }
                  k={k}
                  key={"dynlistinp" + kk}
                  label={fi.label}
                  name={fi.name}
                  type={
                    fi.isDynamic
                      ? dynamicTypes.find((d) => d.id == it.id)
                        ? dynamicTypes.find((d) => d.id == it.id).type
                        : fi.type
                      : fi.type
                  }
                  value={it[fi.name]}
                  checked={it[fi.name]}
                  change={changeItem}
                  id={fi.id ? fi.id : null}
                  suffix={fi.suffix ? fi.suffix : null}
                  datas={
                    fi.isDynamic
                      ? dynamicTypes.find((d) => d.id == it.id)
                        ? dynamicTypes.find((d) => d.id == it.id).datas
                        : fi.datas
                      : fi.datas
                  }
                  margin={fi.margin ? fi.margin : ""}
                  dataIndex={fi.dataIndex ? fi.dataIndex : "id"}
                  dataLabel={fi.dataLabel ? fi.dataLabel : "name"}
                  dataLabel2={fi.dataLabel2 ? fi.dataLabel2 : false}
                  autoCompleteDisplayIndex={
                    fi.autoCompleteDisplayIndex ? fi.autoCompleteDisplayIndex : false
                  }
                  autocomplete={fi.autocomplete}
                />
              ))}
              <div
                style={{
                  background: "white",
                  height: 40,
                  borderTopRightRadius: window.borderRadius,
                  borderBottomRightRadius: window.borderRadius,
                }}
                className="d-flex align-items-center px-2"
              >
                <button
                  className="btn btn-default btn-sm shadow-none"
                  onClick={() => removeItem(k)}
                >
                  <i className="fa fa-times text-danger" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {btnLabel && (
        <button className="btn btn-default btn-sm " type="button" onClick={addItem}>
          <i className="fa fa-plus mr-2" />
          {btnLabel}
        </button>
      )}
      {error && (
        <small className={"form-text text-danger " + (error[element] ? "animated flash" : "")}>
          {error[element]}
        </small>
      )}
    </div>
  );
};

export default DynamicList;
