import React from "react";
import styled from "styled-components";
import useRBEListItem from "../useRBEListItem";

export const CloseRBEBottomPanelButton = () => {
  const { actions, bottomPanelContent } = useRBEListItem();
  return bottomPanelContent ? (
    <StyledArrowButton
      className="fabArrow d-center shadow-sm bg-white  border cursor-pointer"
      onClick={actions.closeBottomPanel}
    >
      <i className="fa fa-chevron-down" />
    </StyledArrowButton>
  ) : null;
};

const RBEItemBottomPanel = ({ children }) => {
  return <StyledRBEItemBottomPanel>{children}</StyledRBEItemBottomPanel>;
};

const StyledRBEItemBottomPanel = styled.div`
  padding: 40px;
  position: relative;
  box-shadow: inset 0px 6px 8px -10px black;
  max-height: calc(100vh - 300px);
  overflow-x: scroll;
`;

const StyledArrowButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translateX(-50%) translateY(-20px);
  z-index: 9;
  & i {
    transition: 300ms;
  }

  &:hover {
    i {
      transform: rotate(-180deg);
    }
  }
`;

export default RBEItemBottomPanel;
