import React from "react";
import { NavLink } from "react-router-dom";
import "./SimpleStatCounter.scss";
import Colors from "../../../variables.scss";
import styled from "styled-components";

const SimpleStatCounter = ({ text, path, icon, dataPriv = "" }) => {
  return (
    <StyledNavLink
      to={path}
      className=" d-flex justify-content-center flex-column p-4 align-items-center col-6 col-md-3 col-lg-2  animated fadeIn bg-white border"
      style={{ color: Colors.success, textDecoration: "none" }}
      data-priv={dataPriv}
    >
      <i className={`fa fa-${icon} fa-3x`} />
      <div className="mt-3 text-center">{text}</div>
    </StyledNavLink>
  );
};

const StyledNavLink = styled(NavLink)`
  border-radius: 10px;
  &:hover {
    color: var(--color-primary) !important;
  }
`;

export default SimpleStatCounter;
