import React from "react";
import DropFile from "../../common/DropFile";
import ModalBtn from "../../common/ModalBtn";
import Modal from "../../common/Modal";

const DocumentsDisplay = ({
  documents,
  documentUploaded,
  documentRemoved,
  uploadNewFile,
  cancelNewVersion,
  authorizedModels,
  small = false,
  inline = false,
  trueList = false,
  dataPriv,
}) => {
  function checkDocActionAvailable(doc) {
    if (authorizedModels) {
      return authorizedModels.includes(doc.entity.ref);
    }
    return true;
  }

  var containerClass = small ? "col" : "col-12 col-md-4 col-lg-3";
  containerClass = inline ? "col-12 border-bottom" : containerClass;

  var containerMaxWidth = small ? 190 : "none";
  containerMaxWidth = inline ? "none" : containerMaxWidth;

  var containerMaxHeight = inline ? 60 : "none";

  return (
    <>
      {documents.map((doc, dock) => {
        return (
          <div
            className={`text-center ml-2 d-flex  
              ${
                inline
                  ? "flex-row align-items-center justify-content-between flex-wrap"
                  : "flex-column"
              } 
              ${containerClass}`}
            style={{
              animationDelay: dock * 50 + "ms",
              maxWidth: containerMaxWidth,
            }}
            key={"doc" + dock}
          >
            <DropFile
              dataPriv={dataPriv}
              document={doc[0]}
              documentUploaded={
                checkDocActionAvailable(doc[0]) ? (d) => documentUploaded(dock, 0, d) : false
              }
              documentRemoved={
                checkDocActionAvailable(doc[0]) ? (d) => documentRemoved(dock, 0, d) : false
              }
              uploadNewFile={checkDocActionAvailable(doc[0]) ? () => uploadNewFile(dock, 0) : false}
              cancelNewVersion={cancelNewVersion}
              small={small}
              inline={inline}
              trueList={trueList}
            />

            {doc.length > 1 && (
              <div className={inline ? "w-100 d-flex justify-content-center" : ""}>
                {!doc[0].isNewVersion && (
                  <ModalBtn
                    id={"viewFileVersionsModal" + dock + "" + doc[0].id}
                    label="Voir les autres versions"
                    btnClass="btn btn-link bg-white btn-sm border mt-2"
                    backdrop={false}
                  />
                )}

                <Modal
                  id={"viewFileVersionsModal" + dock + "" + doc[0].id}
                  title="Historique des versions du document"
                  bodyStyle={{
                    background: "#f0f3f5",
                  }}
                >
                  {doc.map((sdoc, sdock) => {
                    return (
                      <div className="mb-2" key={"doc" + dock + "" + sdock}>
                        <DropFile
                          dataPriv={dataPriv}
                          document={sdoc}
                          documentUploaded={
                            checkDocActionAvailable(sdoc)
                              ? (d) => documentUploaded(dock, sdock, d)
                              : false
                          }
                          documentRemoved={
                            checkDocActionAvailable(sdoc)
                              ? (d) => documentRemoved(dock, sdock)
                              : false
                          }
                          cancelNewVersion={cancelNewVersion}
                          small={small}
                        />
                      </div>
                    );
                  })}
                </Modal>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default DocumentsDisplay;
