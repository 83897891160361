import { atom, useAtom } from "jotai";
import { useState } from "react";

const commissionAtom = atom({});

const useCommissionItem = () => {
  const [commission, setCommission] = useAtom(commissionAtom);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);

  const actions = {
    initCommission: (data) => {
      setCommission(data);
    },
    toggleCompanyDetail: () => {
      setIsCompanyOpen((o) => !o);
    },
    togglePolicyDetail: () => {
      setIsPolicyOpen((o) => !o);
    },
  };

  return {
    commission,
    isCompanyOpen,
    isPolicyOpen,
    actions,
  };
};

export default useCommissionItem;
