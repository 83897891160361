import React, { useEffect } from "react";
import Control from "../../common/Control";
import { REF_BO } from "../../../config";

const UserPrivilege = ({
  userRoles,
  changeRole,
  ROLES,
  PRIVILEGES,
  roleConfig,
  changePrivilege,
}) => {
  useEffect(() => {
    var hoverable = document.querySelectorAll(".highlightable");
    hoverable.forEach((elem) => {
      elem.addEventListener("mouseover", () => {
        activeBgColor(elem, "#f8f9fa", "#dc354540");
      });
      elem.addEventListener("mouseout", () => {
        activeBgColor(elem, "transparent", "#f8f9fa");
      });
    });
  });

  function activeBgColor(elem, bgColor, colorHl) {
    elem.style.background = bgColor;
    let dependencies = elem.getAttribute("data-ref");
    if (dependencies != "") {
      dependencies = dependencies.split("+");
      if (dependencies.length > 0) {
        dependencies.forEach((dep) => {
          var links = document.querySelectorAll(
            "[data-key=" + dep + "] .badge"
          );

          links.forEach((lk) => {
            lk.style.background = colorHl;
          });
        });
      }
    }
  }

  return (
    <>
      <Control
        type="btnList"
        btnInline={true}
        label="Rôle"
        name="role"
        datas={Object.values(ROLES[REF_BO])}
        dataIndex={"key"}
        dataLabel={"label"}
        value={userRoles[0].roleKey}
        change={changeRole}
      />
      <div style={{ paddingLeft: 25 }}>
        {PRIVILEGES[REF_BO].map((r, rk) => {
          if (!roleConfig[r.key][userRoles[0].roleKey]) {
            return null;
          }
          return (
            <div
              key={"up-" + rk}
              className="highlightable position-relative"
              data-ref={r.dependsOn ? r.dependsOn.join("+") : ""}
              data-key={r.key}
            >
              <span
                className="badge badge-light position-absolute"
                style={{
                  left: -25,
                }}
              >
                {rk + 1}
              </span>
              <Control
                label={r.label}
                type="checkbox"
                name={r.key}
                checked={userRoles[0].privileges.includes(r.key)}
                change={changePrivilege}
                disabled={
                  r.dependsOn
                    ? userRoles[0].privileges.some((arr) =>
                        r.dependsOn.includes(arr)
                      )
                      ? false
                      : "disabled"
                    : false
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default UserPrivilege;
