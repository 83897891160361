import { useContext } from "react";
import { CommentContext } from "../../context/CommentContext";

const CommentActions = () => {
  const { comment, actions } = useContext(CommentContext);

  const onDeleteComment = () => {
    actions.deleteComment(comment.id);
  };

  return (
    <div className="text-right w-100">
      <button className="btn btn-sm btn-link text-danger" onClick={onDeleteComment}>
        <i className="fa fa-times" />
      </button>
    </div>
  );
};

export default CommentActions;
