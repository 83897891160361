import React from "react";

const EmailLink = ({ email }) => {
  return (
    <a
      href={`mailto:${email}`}
      style={{
        overflowWrap: "anywhere",
      }}
    >
      {email}
    </a>
  );
};

export default EmailLink;
