import React, { useState, useEffect } from "react";
import Colors from "../../variables.scss";

const DigitControl = ({ params = [], value = "", complete, name }) => {
  const [digits, setDigits] = useState([]);

  useEffect(() => {
    initDigits();
  }, []);

  function initDigits() {
    var dg = [];
    params.forEach((p) => {
      dg.push({
        size: p.size,
        value: "",
        placeholder: p.placeholder,
      });
    });
    setDigits(dg);
  }
  function eraseDigits() {
    complete({ target: { name: name, value: "" } });
    initDigits();
  }

  function updateData(e, dk) {
    var dg = JSON.parse(JSON.stringify(digits));

    if (e.target.value.length <= dg[dk].size) {
      dg[dk].value = e.target.value;
      setDigits(dg);
    }
    if (dg[dk].size == dg[dk].value.length && dk < dg.length - 1) {
      document.querySelector("#digit" + (dk + 1)).focus();
    }
    var finalValue = dg.map((d) => d.value).join("");
    var totalSize = 0;
    dg.forEach((d) => {
      totalSize += parseInt(d.size);
    });
    if (finalValue.length == totalSize) {
      complete({ target: { name: name, value: finalValue } });
    }
    if (finalValue.length == 0) {
      complete({ target: { name: name, value: "" } });
    }
  }

  function focusInput(e, dk) {
    if (e.target.value < digits[dk].size) {
      var inputs = document.querySelectorAll(
        "#digit-control .form-control-spec"
      );
      for (var inpk in inputs) {
        if (inputs[inpk].value.length < digits[inpk].size) {
          inputs[inpk].focus();
          break;
        }
      }
    }
  }

  return (
    <>
      <div className="row m-0 w-100 " id="digit-control">
        {digits.map((d, dk) => {
          let placeholder = d.placeholder;
          if (!placeholder) {
            placeholder = "";
            for (var i = 0; i < d.size; i++) {
              placeholder += "_";
            }
          }
          return (
            <input
              key={"digit-" + dk}
              autoFocus={dk == 0 ? true : false}
              style={{
                height: 60,
                border: "1px solid",
                color: Colors.successLight,
                fontSize: 21,
                borderRadius: 3,
                padding: 0,
              }}
              onClick={(e) => focusInput(e, dk)}
              onKeyUp={(e) => {
                if (e.keyCode == 8) {
                  document
                    .querySelectorAll("#digit-control .form-control-spec")
                    [dk > 0 ? dk - 1 : dk].focus();
                }
              }}
              placeholder={placeholder}
              id={"digit" + dk}
              name={"digit" + dk}
              className="form-control-spec col text-center mx-1"
              value={d.value}
              onChange={(e) => updateData(e, dk)}
            />
          );
        })}
      </div>
      <div className="col-12 text-center mt-2">
        <button
          type="button"
          onClick={eraseDigits}
          className="btn btn-default btn-sm"
        >
          Effacer
        </button>
      </div>
    </>
  );
};

export default DigitControl;
