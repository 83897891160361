import React, { useRef, useState } from "react";
import styled from "styled-components";

export const TooltipButton = ({ children, tooltipContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <StyledTooltipButton onClick={toggleOpen}>
        <div className="tooltip__button">{children}</div>
      </StyledTooltipButton>
      <Tooltip isOpen={isOpen} setIsOpen={setIsOpen}>
        {tooltipContent}
      </Tooltip>
    </>
  );
};

const StyledTooltipButton = styled.button`
  cursor: pointer;
  position: relative;
  width: 60px;
  background: var(--color-transparent);
  border: 0px;
`;

export const Tooltip = ({ children, isOpen, setIsOpen }) => {
  return (
    <StyledTooltip
      className={`border shadow ${isOpen ? "animated fadeIn faster" : ""}`}
      isOpen={isOpen}
    >
      {children}
      <button
        className="btn btn-sm border-0 w-100 text-right"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
      >
        <i className="fa fa-times" />
      </button>
    </StyledTooltip>
  );
};

const StyledTooltip = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: relative;
  border-radius: 5px;
  z-index: 99;
  height: 100%;
  top: 5%;
  bottom: 100%;
  background: white;

  right: ${({ isOpen }) => (isOpen ? "50px" : "0")};
`;
