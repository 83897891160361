import { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useCharts = (url, postData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState(false);
  const [total, setTotal] = useState(0);
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const lineOptions = {
    colors: ["#00e396", "#feb019", "#008ffb", "#b400e3"],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: months,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      postData,
      (datas) => {
        var allTotals = datas.map((o) => o.months.reduce((a, b) => a + b));
        setTotal(allTotals.reduce((a, b) => a + b));
        setDatas({
          line: {
            series: datas.map((o) => ({
              name: o.name,
              data: o.months,
            })),
            options: lineOptions,
          },
        });
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  return {
    isLoading,
    getDatas,
    datas,
    total,
  };
};

export default useCharts;
