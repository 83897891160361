import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { GRAAL_API_URL } from "../../../../../config";
import tools from "../../../../../helpers/tools";
import { graalApiAxiosInstance } from "../../../../../middlewares/useApiAuthMiddleware";
import Loader from "../../../../common/Loader";
import DropZone from "../../../ClientListPage/ClientList/ClientItem/CompanyList/CompanyItem/BottomPanel/BPDocuments/BPDocumentItem/DropZone";

const LeadAttachedDocument = ({ lead, documentType = "", canUpload }) => {
  const uploadURL = `${GRAAL_API_URL}lead-documents/${lead.uuid}/upload`;
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDropFiles = (files) => {
    updateFile(files);
  };

  function updateFile(files) {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (files.length > 0 && allowedTypes.indexOf(files[0].type) != -1) {
      let data = new FormData();
      data.append("file", files[0]);
      data.append("type", documentType);

      uploadFile(data);
    } else {
      toast.error(
        "Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf."
      );
    }
  }
  function uploadFile(data) {
    setIsUploading(true);
    graalApiAxiosInstance
      .post(uploadURL, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
        },
      })
      .then((res) => {
        onDocumentUploaded(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error("L'envoi du document a échoué. Veuillez réessayer.");
        }
      })
      .then(() => {
        setIsUploading(false);
      });
  }

  const onDocumentUploaded = (data) => {
    setIsUploaded(true);
  };

  return (
    <div>
      {isUploaded && (
        <div className="text-info">
          <i className="fa fa-check mr-2" />
          Document envoyé avec succès
        </div>
      )}
      <ExistingDocument lead={lead} documentType={documentType} />
      {canUpload && (
        <DropZone onDropFiles={onDropFiles} isUploading={isUploading} />
      )}
    </div>
  );
};

export default LeadAttachedDocument;

const ExistingDocument = ({ lead, documentType }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  if (!lead.documents || !Array.isArray(lead.documents)) {
    return <i>aucun document</i>;
  }
  const docRef = lead.documents.find((d) => d.type === documentType);
  if (!docRef) {
    return <i>aucun document</i>;
  }

  const downloadFileFromLocal = () => {
    setIsDownloading(true);

    axios({
      url: docRef.file,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        downloadBlob(new Blob([res.data]), docRef.filename);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          console.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  const downloadBlob = (blob, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory

    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

  return (
    <div className="mb-2">
      <div
        className="badge badge-success text-white font-weight-bold"
        style={{ fontSize: 12 }}
      >
        envoyé le {tools.formatDate(docRef.uploadedAt, "dd/MM/yyyy HH:mm")}
      </div>
      <button
        className="btn btn-link border-0 px-0 text-left font-weight-normal py-2"
        style={{ fontSize: 12 }}
        onClick={downloadFileFromLocal}
      >
        {isDownloading ? <Loader /> : <i className="fa fa-download mr-2"></i>}
        {docRef.name}
      </button>
    </div>
  );
};
