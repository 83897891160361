import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { API_URL } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import CharCounter from "../../common/CharCounter";
import DynamicList from "../../common/DynamicList";

const FileCategoryDetail = (props) => {
  const [state, dispatch] = useStore();

  const [category, setCategory] = useState({
    filters: [],
    entityModel: "",
    isAllEntities: true,
    name: "",
  });
  const [entities, setEntities] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    getDatas();
    if (props.match.params.id != "create") {
      setIsLoading(true);
      Axios.get(API_URL + "filecategories/" + props.match.params.id)
        .then((res) => {
          setCategory(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          toast.error(
            "Une erreur est survenue lors de la récupération de la catégorie"
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, []);

  function getDatas() {
    Axios.get(API_URL + "filecategories/filters")
      .then((res) => {
        setEntities(res.data);
        var allFilters = [];
        res.data.forEach((d) => {
          d.filters.forEach((df) => {
            if (!allFilters.find((f) => f.id == df.id)) {
              allFilters.push({
                id: df.id,
                type: df.type,
                datas: df.datas,
              });
            }
          });
        });
        setFilters(allFilters);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  function updateData(e) {
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }

    setCategory({ ...category, [name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      Axios.post(API_URL + "filecategories/", category)
        .then((res) => {
          setCategory(res.data);
          setIsSaved(true);
          toast.success("La catégorie a bien été créée");
          window.location.href = "/document-categories/" + res.data.id;
        })
        .catch((err) => {
          toast.error(
            "Une erreur est survenue lors de la création de la catégorie"
          );
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    } else {
      //update
      Axios.patch(API_URL + "filecategories/" + props.match.params.id, category)
        .then((res) => {
          setCategory(res.data);
          setIsSaved(true);
          toast.success("La catégorie a bien été modifiée");
        })
        .catch((err) => {
          toast.error(
            "Une erreur est survenue lors de la modification de la catégorie"
          );
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    }
  }

  return state.constants.items.length == 0 ||
    isLoading ||
    entities.length == 0 ? (
    <Loader withContainer={true} />
  ) : (
    <Page
      container={"container-fluid px-5"}
      title={
        (props.match.params.id == "create" ? "Création" : "Modification") +
        " d'une catégorie"
      }
      errors={errors}
      back={"/document-categories"}
    >
      <Control
        label="Nom"
        name="name"
        value={category.name}
        change={updateData}
        error={errors}
      />

      <Control
        type="select"
        label="Modèle"
        name="entityModel"
        value={category.entityModel}
        change={updateData}
        datas={entities}
        error={errors}
      />
      {category.entityModel != "" && (
        <Control
          label="Toutes les entités ?"
          type="checkbox"
          checked={category.isAllEntities}
          change={updateData}
          error={errors}
          name="isAllEntities"
        />
      )}

      {!category.isAllEntities && (
        <DynamicList
          listLabel="Entités"
          uniqueKey="1"
          element="filters"
          change={updateData}
          fields={[
            {
              label: "Entité",
              type: "select",
              name: "id",
              datas: entities.find((e) => e.id == category.entityModel).filters,
              dataIndex: "id",
              dataLabel: "name",
            },
            {
              label: "Valeur",
              type: "text",
              name: "value",
              isDynamic: true,
              datas: [],
            },
          ]}
          dynamicTypes={filters}
          items={category.filters}
          btnLabel="Ajouter une entité"
          error={errors}
        />
      )}

      <SaveBtn
        className="mt-4"
        save={save}
        isSaving={isSaving}
        isSaved={isSaved}
      />
    </Page>
  );
};

export default FileCategoryDetail;
