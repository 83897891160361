import { atom, useAtom } from "jotai";
import dataService from "../../../helpers/dataService";

const rbeListAtom = atom([]);

const rbeFiltersAtom = atom({
  page: 1,
  filters: {
    clientTerms: "",
    hasBlacklistedBeneficiary: false,
    beneficiaryTerms: "",
  },
});
const isLoadingRbeListAtom = atom(false);
const nbResultsAtom = atom(0);

const useRbe = () => {
  const [rbeList, setRbeList] = useAtom(rbeListAtom);
  const [rbeFilters, setRbeFilters] = useAtom(rbeFiltersAtom);
  const [isLoadingRbeList, setIsLoadingRbeList] = useAtom(isLoadingRbeListAtom);
  const [nbResults, setNbResults] = useAtom(nbResultsAtom);

  const getRbeList = (overrideFilters) => {
    setIsLoadingRbeList(true);

    dataService.post(
      `beneficiaries/search`,
      overrideFilters || rbeFilters,
      (data) => {
        setRbeList(data.data);
        setNbResults(data.count);
      },
      (err) => {},
      () => setIsLoadingRbeList(false)
    );
    setTimeout(() => {}, 2000);
  };

  const handleFilterChange = (e) => {
    console.log(e);
    let { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      value = checked;
    }
    setRbeFilters({
      ...rbeFilters,
      page: 1,
      filters: {
        ...rbeFilters.filters,
        [name]: value,
      },
    });
  };

  const changePage = (page) => {
    const newFilters = {
      ...rbeFilters,
      page,
    };
    getRbeList(newFilters);
    setRbeFilters(newFilters);
  };

  return {
    rbeList,
    rbeFilters,
    isLoadingRbeList,
    nbResults,
    actions: {
      handleFilterChange,
      getRbeList,
      changePage,
    },
  };
};

export default useRbe;
