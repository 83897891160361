import React, { useContext } from "react";
import useStore from "../../../../../../context/useStore";
import tools from "../../../../../../helpers/tools";
import useProducers from "../../../../../../hooks/useProducers/useProducers";
import LabelValuePair from "../../../../../common/LabelValuePair/LabelValuePair";
import { CommissionContext } from "../../context/CommissionProvider";

const PolicyDetails = ({ amendment, policy }) => {
  const [state] = useStore();
  const { PERIODICITIES } = state.constants.items;
  const { insuranceCompanies, producers } = useContext(CommissionContext);

  const findProducer = () => {
    const producer = tools.findIn(producers, policy.producer2?.id || null, "id");

    return producer?.company || "";
  };

  return (
    <div>
      <div>
        <LabelValuePair
          label="Périodicité"
          value={`${tools.findIn(PERIODICITIES, amendment.periodicity, "id").name}`}
        />
        <div>
          <LabelValuePair
            label="Compagnie d'assurance"
            value={tools.findIn(insuranceCompanies, policy.company, "id").name}
          />
        </div>
        <div>
          <LabelValuePair label="Gestionnaire" value={findProducer()} />
        </div>
        <div>
          <LabelValuePair label="Date de création" value={tools.formatDate(policy.createdAt)} />
        </div>
      </div>
    </div>
  );
};

export default PolicyDetails;
