import React from "react";
import Control from "../Controls/Control";
import useClientSelector from "./useClientSelector";
import { smallInputProps } from "../Controls/Inputs/smallInputProps";
import SaveBtn from "../SaveBtn";

const ClientSelectorFilters = () => {
  const { isLoadingClients, clientFilters, actions } = useClientSelector();
  return (
    <form onSubmit={actions.onSubmitSearch}>
      <div className="row bg-blue border mb-3 rounded p-3 shadow-sm">
        <div className="col-12 col-md-3">
          <Control
            label="Email"
            type="text"
            name="email"
            value={clientFilters.user.email}
            change={actions.handleChangeFilters}
            fullWidth
            placeholder="email de l'utilisateur"
            {...smallInputProps}
          />
        </div>
        <div className="col-12 col-md-3">
          <Control
            label="Nom, Prénom"
            type="text"
            name="nomPrenomClient"
            value={clientFilters.user.nomPrenomClient}
            change={actions.handleChangeFilters}
            fullWidth
            placeholder="Nom, prénom"
            {...smallInputProps}
          />
        </div>
        <div className="col-12 col-md-3">
          <Control
            label="Nom de société"
            type="text"
            name="nomSociete"
            value={clientFilters.user.nomSociete}
            change={actions.handleChangeFilters}
            fullWidth
            placeholder="Nom de la société"
            {...smallInputProps}
          />
        </div>
        <div className="col-12 col-md-3">
          <Control
            label="SIREN ou SIRET"
            type="text"
            name="sirenOuSiret"
            value={clientFilters.user.sirenOuSiret}
            change={(e) => {
              //remove spaces from sirenOuSiret
              e.target.value = e.target.value.replace(/\s/g, "");
              actions.handleChangeFilters(e);
            }}
            fullWidth
            placeholder="9 ou 14 chiffres"
            {...smallInputProps}
          />
        </div>

        <div className="col-12">
          <SaveBtn
            className="w-100 d-center"
            typeBtn="submit"
            save={() => {}}
            text="Rechercher"
            isSaving={isLoadingClients}
          />
        </div>
      </div>
    </form>
  );
};

export default ClientSelectorFilters;
