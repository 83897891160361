import axios from "axios";
import React from "react";
import { API_URL } from "../../../../../../../../../../config";
import useRole from "../../../../../../../../../../hooks/useRole/useRole";
import useCompanyItem from "../../../useCompanyItem";
import CompanyOtherActions from "../CompanyOtherActions/CompanyOtherActions";

const CompanyActions = () => {
  const { company, isShowingComment, actions } = useCompanyItem();
  const { myRole, setMyRole } = useRole();

  axios.get(API_URL + "roles/mine").then((res) => {
    if (res.data[0]) {
      setMyRole(res.data[0].roleKey);
    }
  });

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <button className="btn btn-default btn-sm border" onClick={actions.toggleIsShowingComment}>
          {isShowingComment ? "Annuler" : "Ecrire une observation"}
        </button>
      </div>
      <div className="col-12 col-md-4">
        <a className="btn btn-default btn-sm border" href={`forms/${company.id}`} target="_blank">
          Voir les demandes en cours
        </a>
      </div>
      <div className="col-12 col-md-4">{myRole == "ADMIN" && <CompanyOtherActions />}</div>
    </div>
  );
};

export default CompanyActions;
