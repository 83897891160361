import React, { useRef } from "react";
import ControlValidIcon from "./ControlValidIcon";
import ErrorMessage from "./ErrorMessage";

const LabelContainer = ({
  labelClassname = "",
  k = -1,
  name,
  label = false,
  required = false,
  suffix = false,
  error = {},
  labelStyle = {},
  innerLabelStyle = {},
  ...props
}) => {
  const labelRef = useRef(null);

  return (
    <label
      ref={labelRef}
      className={`position-relative ${labelClassname} ${
        !props.isValid ? " has-error " : " "
      } 
      ${error[name] ? " has-api-error " : " "}
        ${props.fullWidth ? "w-100" : ""} `}
      htmlFor={k != -1 ? name + "-" + k : name}
      style={labelStyle}
    >
      {label && (
        <div
          className="label-text"
          style={{ paddingRight: 20, ...innerLabelStyle }}
        >
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </div>
      )}
      <div
        className={`d-flex flex-row align-items-center ${
          props.noMarginInsideLabel ? "" : "mt-2"
        }`}
      >
        <div
          className={`position-relative ${props.fullWidth ? "w-100" : ""} ${
            ["btnList", "checkBtnList", "checkboxList"].includes(props.type)
              ? ""
              : suffix
              ? "input-group"
              : ""
          }`}
          style={
            props.type == "checkBtnList" || props.inputGroupStyle
              ? props.inputGroupStyle
              : {}
          }
        >
          {props.children}
          {
            <ControlValidIcon
              hasError={!props.isValid}
              icon={props.validIcon}
            />
          }
        </div>
        {suffix && (
          <div
            className="input-group-append border-0 pl-2"
            style={{
              fontWeight: "500",
            }}
          >
            {suffix}
          </div>
        )}
      </div>
      {error[name] && <ErrorMessage message={error[name]} />}
      {props.info && (
        <p
          className={`text-muted mt-2 mb-0 `}
          style={{
            fontWeight: "400",
          }}
        >
          {props.info}
        </p>
      )}
    </label>
  );
};

export default LabelContainer;
