import { useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";

const usePartners = () => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nbPages, setNbPages] = useState(1);
  const [errors, setErrors] = useState({});
  const [nbResults, setNbResults] = useState(0);
  const [search, setSearch] = useState({
    user: {
      searchString: "",
    },
    page: 1,
  });

  const searchPartners = () => {
    setIsLoading(true);
    dataService.post(
      `partners/search`,
      search,
      (datas) => {
        console.log("DATAS", datas);
        setPartners(datas.data);
        setNbResults(datas.count);
        setNbPages(Math.ceil(parseInt(datas.count) / 25));
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  const banPartner = (partnerId) => {
    alert("bannir !");
  };

  return {
    state: {
      isLoading,
      partners,
      nbPages,
      search,
      constants,
    },
    actions: {
      setIsLoading,
      setPartners,
      setNbPages,
      setSearch,
      searchPartners,
      changePage,
      banPartner,
    },
  };
};

export default usePartners;
