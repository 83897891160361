import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import useStatFilters from "./StatFilters/useStatFilters";

const useStats = () => {
  const [isLoadingLead, setIsLoadingLead] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingRenew, setIsLoadingRenew] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [leadDatas, setLeadDatas] = useState({
    PJ: "∞",
    MRP: "∞",
    AC: "∞",
  });
  const [docDatas, setDocDatas] = useState({
    CP_MISSING: "∞",
    ATTESTATION_MISSING: "∞",
    ATTESTATION_EXPIRED: "∞",
  });
  const [renewData, setRenewData] = useState({
    RENEWABLE: "∞",
    RENEWABLE_30DAYS: "∞",
  });
  const { statFilters } = useStatFilters();

  const getLeadStats = (newFilters) => {
    setIsLoadingLead(true);
    dataService.post(
      `statistics/counters/leads`,
      newFilters?.newFilters || statFilters,
      (datas) => {
        const total = datas.PJ + datas.AC + datas.MRP;

        setLeadDatas({ ...datas });
        setIsInit(true);
      },
      (err) => {},
      () => setIsLoadingLead(false)
    );
  };
  const getRenewStats = (newFilters) => {
    setIsLoadingRenew(true);
    dataService.post(
      `statistics/counters/policies/renewals`,
      newFilters?.newFilters || statFilters,
      (datas) => {
        setRenewData({
          RENEWABLE: datas.echeanceDepassee,
          RENEWABLE_30DAYS: datas.echeanceDansMoins30Jours,
        });
        setIsInit(true);
      },
      (err) => {},
      () => setIsLoadingRenew(false)
    );
  };
  const getDocStats = (newFilters) => {
    setIsLoadingDoc(true);
    dataService.post(
      `statistics/counters/files`,
      newFilters?.newFilters || statFilters,
      (datas) => {
        setDocDatas(datas);
        setIsInit(true);
      },
      (err) => {},
      () => setIsLoadingDoc(false)
    );
  };

  const refreshWithFilters = (newFilters) => {
    getLeadStats({ newFilters });
    getDocStats({ newFilters });
  };

  return {
    isLoadingLead,
    isLoadingDoc,
    isLoadingRenew,
    isLoading: isLoadingLead || isLoadingDoc || isLoadingRenew,
    leadDatas,
    docDatas,
    renewData,
    actions: { getLeadStats, getDocStats, getRenewStats, refreshWithFilters },
  };
};

export default useStats;
