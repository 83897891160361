import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import React from "react";
import Accordion from "../../../common/Accordion/Accordion";
import Control from "../../../common/Control";
import Page from "../../../common/layout/Page";
import useFAQ from "./useFAQ";
import SaveBtn from "../../../common/SaveBtn";
import AddSectionForm from "./AddSectionForm";
import AddFaqForm from "./AddFaqForm";
import OrderButtons from "./OrderButtons";

const FAQList = () => {
  const { state, actions } = useFAQ();

  console.log("STATE", state.faqs);

  const rowClassName = "row m-1  animated fadeInUp faster";

  return (
    <Page container={"container-fluid"} title="FAQs">
      {state.sections
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((section, sk) => {
          const faqs = state.faqs
            .filter((faq) => faq.faqSection == section.id)
            .sort((a, b) => (a.order > b.order ? 1 : -1));

          return (
            <div
              key={`grpsec${section.id}`}
              className={rowClassName}
              style={{ animationDelay: sk * 100 + "ms" }}
            >
              <div className="col-1 p-0" style={{ maxHeight: 107 }}>
                <OrderButtons
                  item={section}
                  items={state.sections}
                  goUp={() => actions.sectionGoUp(section)}
                  goDown={() => actions.sectionGoDown(section)}
                  k={sk}
                />
              </div>
              <div className="col-11 p-0">
                <Accordion
                  key={`faq-sec${section.id}`}
                  title={
                    <Control
                      value={section.name}
                      change={(e) =>
                        actions.updateSection(
                          { ...section, name: e.target.value },
                          section.id
                        )
                      }
                      suffix={
                        <SaveBtn
                          save={() => actions.saveSection(section)}
                          isSaving={state.isSavingSection == section.id}
                          text={<i className="fa fa-save" />}
                          type="link"
                          className="btn-sm h-100 ml-2"
                          loaderColor="#040ca5"
                        />
                      }
                    />
                  }
                  type="SECTION"
                  typeColor="primary"
                >
                  {faqs.map((faq, fk) => (
                    <div
                      className={rowClassName}
                      style={{
                        animationDelay: fk * 100 + "ms",
                      }}
                      key={`faqs${sk}${fk}`}
                    >
                      <div className="col-1 p-0" style={{ maxHeight: 107 }}>
                        <OrderButtons
                          k={fk}
                          item={faq}
                          items={faqs}
                          goUp={() => actions.faqGoUp(faq)}
                          goDown={() => actions.faqGoDown(faq)}
                        />
                      </div>
                      <div className="col-11 p-0">
                        <Accordion
                          key={`faq-sec${section.id}${faq.id}`}
                          title={
                            <div className="d-flex align-items-center justify-content-start">
                              <Control
                                value={faq.name}
                                change={(e) =>
                                  actions.updateFaq(
                                    { ...faq, name: e.target.value },
                                    faq.id
                                  )
                                }
                                suffix={
                                  <SaveBtn
                                    save={() => actions.saveFaq(faq)}
                                    isSaving={state.isSavingFaq == faq.id}
                                    text={<i className="fa fa-save" />}
                                    type="link"
                                    className="btn-sm h-100 ml-2"
                                    loaderColor="#040ca5"
                                  />
                                }
                              />
                              <button className="btn btn-sm">
                                <i className="fa fa-trash" />
                              </button>
                            </div>
                          }
                          type="FAQ"
                          typeColor="success"
                        >
                          <CKEditor
                            editor={Editor}
                            data={faq.description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              actions.updateFaq(
                                { ...faq, description: data },
                                fk
                              );
                              console.log({ event, editor, data });
                            }}
                          />
                        </Accordion>
                      </div>
                    </div>
                  ))}
                </Accordion>
              </div>
            </div>
          );
        })}
      <hr className="w-100 my-4" />
      <AddSectionForm actions={actions} state={state} />
      <AddFaqForm actions={actions} state={state} />
    </Page>
  );
};

export default FAQList;
