import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import Control from "../Control";
import getCroppedImg from "./CropImage";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../Loader";
import SaveBtn from "../SaveBtn";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";

const ImageFileCrop = ({ setModifyAvatar, userId }) => {
  const [state, dispatch] = useStore();

  const [picture, setPicture] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [hasFile, setHasFile] = useState(false);
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const allowedTypes = ["image/jpeg"];
    console.log("acceptedFiles[0].type", acceptedFiles[0].type);
    if (
      acceptedFiles.length > 0 &&
      allowedTypes.indexOf(acceptedFiles[0].type) != -1
    ) {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents

        setHasFile(true);
        setPicture(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      toast.error(
        "Ce fichier est incorrect, veuillez choisir une image au format jpg ou jpeg."
      );
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (croppedImage) {
      uploadFile();
    }
  }, [croppedImage]);

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true);
    try {
      const croppedImage = await getCroppedImg(picture, croppedAreaPixels);
      console.log(croppedImage);
      var img = new Image();

      img.onload = function () {
        // Start resizing
        var canvas = document.querySelector("#canvas-avatar");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, 300, 300);
        canvas.toBlob((img) => {
          setCroppedImage(URL.createObjectURL(img));
        }, "image/png");
      };

      img.src = croppedImage;
      //setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, [croppedAreaPixels]);
  const uploadFile = async () => {
    const data = new FormData();
    let blob = await fetch(croppedImage).then((r) => r.blob());
    data.append("file", blob, "avatar.jpg");
    console.log("croppedImage", croppedImage);
    Axios.post(API_URL + "files/avatars/" + userId, data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    })
      .then((res) => {
        toast.success("Votre photo de profil a bien été enregistrée.");
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: true },
        });
        setModifyAvatar(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          width: 300,
          height: 300,
          background: "grey",
          borderRadius: "100%",
          margin: "0 auto",
          overflow: "hidden",
          outline: "none",
        }}
      >
        {!hasFile && (
          <>
            <input
              style={{ position: "absolute" }}
              {...getInputProps()}
              accept="image/jpeg"
            />
            <div className="d-flex w-100 h-100 justify-content-center align-items-center text-center text-white p-5 m-0">
              Glissez-déposez une image ici, ou cliquez pour parcourir vos
              fichiers.
            </div>
          </>
        )}
        <div
          className="position-relative m-auto"
          style={{
            height: 300,
            width: 300,
          }}
        >
          <div className="crop-container">
            {picture && (
              <>
                (
                <Cropper
                  image={picture}
                  crop={crop}
                  cropSize={{ width: 250, height: 250 }}
                  zoom={zoom}
                  aspect={1 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                />
                )
              </>
            )}
          </div>
        </div>
      </div>

      {picture && !isLoading && (
        <>
          <div className="controls m-auto" style={{ maxWidth: 300 }}>
            <Control
              label="Zoom"
              type="range"
              inputParams={{
                min: 1,
                max: 5,
                step: 0.1,
              }}
              change={(e) => setZoom(e.target.value)}
              value={zoom}
              noInput={true}
            />
          </div>
          <div className="text-center mt-3">
            <i className="fa fa-arrows-alt mr-2" />
            Maintenez le clic enfoncé sur l'image puis faites la glisser pour
            positionner votre visage au centre du cercle.
          </div>
        </>
      )}
      <div className="text-center mt-3">
        {picture && (
          <SaveBtn
            save={showCroppedImage}
            isSaving={isLoading}
            type="primary"
            className="m-auto"
          />
        )}
        {!isLoading && (
          <button
            className="btn btn-danger btn-sm mt-3"
            onClick={() => {
              setModifyAvatar(false);
            }}
          >
            Annuler
          </button>
        )}
      </div>
      <canvas id="canvas-avatar" width="300" height="300" />
    </>
  );
};

export default ImageFileCrop;
