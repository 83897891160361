import { atom, useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../../../../../../../../context/useStore";
import tools from "../../../../../../../../../../../helpers/tools";
import { patchReceiptModulrId } from "../../../../../../../../../../../services/policyService";

const receiptAtom = atom(null);

const useReceiptItem = () => {
  const [state] = useStore();
  const { PAYMENT_STATUS } = state.constants.items;
  const [receipt, setReceipt] = useAtom(receiptAtom);
  const receiptStatusRef = tools.findIn(
    PAYMENT_STATUS,
    receipt?.payments?.status,
    "id"
  );
  const [isSavingModulrId, setIsSavingModulrId] = useState(false);

  const initReceipt = (receiptData) => {
    setReceipt(receiptData);
  };

  const onUpdateReceipt = (newReceipt) => {
    setReceipt(newReceipt);
  };

  const saveModulrId = (modulrId) => {
    setIsSavingModulrId(true);
    patchReceiptModulrId(receipt.id, modulrId)
      .then((data) => {
        setReceipt({ ...receipt, modulrId });
        toast.success("Le Modulr ID a bien été enregistré");
      })
      .finally(() => setIsSavingModulrId(false));
  };

  return {
    PAYMENT_STATUS,
    receipt,
    receiptStatusRef,
    isSavingModulrId,
    initReceipt,
    onUpdateReceipt,
    saveModulrId,
  };
};

export default useReceiptItem;
