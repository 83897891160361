import { useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../../../../../../../../context/useStore";
import { patchPolicyGroupamaStatus } from "../../../../../../../../../../../services/policyService";
import Control from "../../../../../../../../../../common/Controls/Control";
import { smallInputProps } from "../../../../../../../../../../common/Controls/Inputs/smallInputProps";
import SaveBtn from "../../../../../../../../../../common/SaveBtn";
import usePolicyItem from "../usePolicyItem";

const ModifyGroupamaStatusForm = () => {
  const { policy } = usePolicyItem();
  const [state] = useStore();
  const { POLICIES_GROUPAMA_STATUS } = state.constants.items;

  const [isSavingStatus, setIsSavingStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  const [gpstatus, setGpstatus] = useState({
    status: "",
    resiliationDate: "",
  });

  const onSaveGroupamaStatus = () => {
    const newGpStatus = {
      ...gpstatus,
      resiliationDate: !["6", "7"].includes(gpstatus.status) ? null : gpstatus.resiliationDate,
    };
    setIsSavingStatus(true);

    patchPolicyGroupamaStatus(policy.id, newGpStatus)
      .then(() => toast.success("Le statut Groupama a bien été mis à jour"))
      .finally(() => setIsSavingStatus(false));
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setGpstatus({ ...gpstatus, [name]: value });
  };

  return (
    <>
      <button
        data-priv="bo_modifygroupamastatus"
        className="btn btn-light btn-sm"
        onClick={toggleOpen}
      >
        {isOpen ? "Annuler" : "Modifier le statut Groupama"}
      </button>
      {isOpen && (
        <div className="bg-blue p-2 rounded mt-2">
          <Control
            label="Nouveau statut"
            name="status"
            value={gpstatus.status}
            change={handleChangeInput}
            type="select"
            datas={POLICIES_GROUPAMA_STATUS}
            dataIndex="id"
            dataLabel="name"
            {...smallInputProps}
          />
          {["6", "7"].includes(gpstatus.status) && (
            <Control
              label="Date d'effet"
              type="date"
              value={gpstatus.resiliationDate}
              change={handleChangeInput}
              name="resiliationDate"
              {...smallInputProps}
            />
          )}
          <SaveBtn
            className="w-100 d-center btn-sm"
            isSaving={isSavingStatus}
            save={onSaveGroupamaStatus}
          />
        </div>
      )}
    </>
  );
};

export default ModifyGroupamaStatusForm;
