import React, { useState, useEffect } from "react";
import ControlTools from "../ControlTools";
import LabelContainer from "../LabelContainer";

const RangeInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "___",
  inputParams = {
    min: 0,
    max: 100,
    step: 1,
  },
  displayInput = true,
  ...props
}) => {
  const [tempValue, setTempValue] = useState(value);
  const [tempInpValue, setTempInpValue] = useState(value);

  const getProgress = (value) => {
    let prog = ((parseInt(value) - inputParams.min) / (inputParams.max - inputParams.min)) * 100;
    prog = prog > 94 ? 94 : prog < 5 ? 5 : prog;

    return value !== "" ? Math.round(prog) : 50;
  };
  const [progress, setProgress] = useState(getProgress(value));

  const deformatValue = (value) => {
    return parseInt(value.toString().split(" ").join(""));
  };

  const valueChange = (e) => {
    //console.log("valueChange", e.target.value);
    var val = e.target.value;
    //console.log("VAL", val);
    var filteredValue = parseInt(val.toString().split(" ").join(""));
    //console.log("filteredValue", filteredValue);
    filteredValue = filteredValue > inputParams.max ? inputParams.max : filteredValue;
    filteredValue = filteredValue < inputParams.min ? inputParams.min : filteredValue;
    filteredValue = parseInt(filteredValue, 10);
    filteredValue = isNaN(filteredValue) ? "" : filteredValue;
    setTempValue(filteredValue);
    setTempInpValue(filteredValue);
    setProgress(getProgress(filteredValue));
    //console.log("filteredValue", filteredValue);
    let changeEvent = {
      target: {
        name: props.name,
        value: parseInt(filteredValue),
        k: k,
      },
    };

    change(changeEvent);
  };
  const formatValue = (value) => {
    var compareValue = value === 0 ? value : value ? value : [];
    return ControlTools.numberWithSpaces(parseInt(compareValue));
  };

  useEffect(() => {
    setTempInpValue(tempValue);
    setProgress(getProgress(tempValue));
  }, [tempValue]);

  const onFocus = (e) => {
    e.target.select();
  };

  useEffect(() => {
    forceValue();
  }, []);
  useEffect(() => {
    forceValue();
  }, [inputParams]);
  useEffect(() => {
    forceValue();
  }, [value]);

  const forceValue = () => {
    //setTempInpValue(value);
    setTempValue(deformatValue(value));
    //setProgress(getProgress(value));
  };

  return (
    <LabelContainer {...props} value={value}>
      {displayInput && (
        <>
          <div
            className="position-relative w-100 mt-2"
            style={{
              margin: "0 30px",
            }}
          >
            <div
              className="position-absolute d-flex flex-row justify-content-center bg-white rounded p-2 shadow-sm "
              style={{
                left: progress + "%",
                transform: "translate(-50%, 5px)",
                background: "rgba(255,255,255,0.001)",
                borderRadius: 3,
                zIndex: "999",
                top: -14,
              }}
            >
              <input
                style={{
                  width: 100,
                  height: 40,
                }}
                onChange={(e) => {
                  setTempInpValue(e.target.value.split(" ").join(""));
                }}
                type="text"
                pattern="[0-9]*"
                className="form-control range-inp-value text-center border-0"
                name={props.name}
                value={formatValue(tempInpValue)}
                disabled={disabled ? "disabled" : false}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={() => valueChange({ target: { value: tempInpValue } })}
              />
              {props.after && <small className="d-center text-muted ">{props.after}</small>}
            </div>
          </div>

          <div className="w-100">
            <input
              min={inputParams.min}
              max={inputParams.max}
              step={inputParams.step}
              className={" bg-transparent w-100  mt-5 custom-range " + inputClassName}
              type={type}
              disabled={disabled ? "disabled" : false}
              id={k != -1 ? props.name + "-" + k : id ? id : props.name}
              name={props.name}
              value={tempValue}
              onChange={(e) => {
                setTempValue(e.target.value);
              }}
              onMouseUp={(e) => {
                valueChange(e);
              }}
              onTouchEnd={(e) => {
                valueChange(e);
              }}
              placeholder={placeholder}
              style={{ ...inputStyle, marginTop: 40 }}
              onBlur={props.onBlur || null}
            />
            <div
              className="d-flex justify-content-between align-items-center px-2"
              style={{
                position: "relative",
                bottom: 7,
              }}
            >
              <small className="text-muted">
                {ControlTools.numberWithSpaces(inputParams.min)}
                {props.after}
              </small>
              <small className="text-muted">
                {ControlTools.numberWithSpaces(inputParams.max)}
                {props.after}
              </small>
            </div>
          </div>
        </>
      )}
    </LabelContainer>
  );
};

export default RangeInput;
