import React from "react";

const nameMatch = {
  ttc_sans_promo: "TTC ss promo",
  ttc: "TTC",
  ht: "HT",
  taxes: "Taxes",
};

const typeValMatch = {
  base: "Tarifs de base",
  option_fs: "Option FS",
  option_rc: "Option RC",
  total_selection: "Tarif global",
};

const ResultItem = ({ resk, dat, datk, name }) => {
  return (
    <>
      <h4 className="m-0 p-0 text-center">{name}</h4>
      <li
        key={resk + datk}
        className="list-group-item d-flex flex-column mb-3 border-0 "
      >
        {Object.keys(dat).map((tar, tark) => {
          const type_val = dat[tar];

          return (
            <>
              <h5 className="py-2 m-0">{typeValMatch[tar]}</h5>
              <ul
                key={resk + datk + tark}
                className="list-group d-flex flex-row w-100 mb-3"
              >
                {Object.keys(type_val).map((t, tk) => {
                  const tarif = type_val[t];
                  return (
                    <li
                      key={resk + datk + tark + tk}
                      className="list-group-item d-flex flex-column  align-items-center justify-content-between flex-fill py-0 px-2"
                      style={{
                        borderRadius: 3,
                        borderColor: "#efefef",
                      }}
                    >
                      <small className="d-block" style={{ fontSize: 9 }}>
                        {nameMatch[t]}
                      </small>

                      <label className="">{tarif}</label>
                    </li>
                  );
                })}
              </ul>
            </>
          );
        })}
      </li>
    </>
  );
};

export default ResultItem;
