import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { DateTime } from "luxon";
import ClientListFilters from "./ClientListFilters";
import Table from "../../common/Table";

const isBOA = true;

const ClientModulrList = () => {
  const [isInit, setIsInit] = useState(isBOA ? true : false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [search, setSearch] = useState({
    client: {
      searchString: "",
      hasMinOneContract: false,
      unimportableList: "email",
      beginAt: DateTime.local().startOf("month").toISO(),
      endAt: DateTime.local().toISO(),
    },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [, setIsHighlight] = useState(false);
  const [isDeletingClient] = useState(false);
  const [resetPage, setResetPage] = useState(false);

  useEffect(() => {
    setIsHighlight(false);
  }, [search.client.searchString]);

  useEffect(() => {
    if (!isInit) {
      searchModulrClients();
    }
    setIsInit(false);
  }, [search.page]);

  function searchModulrClients() {
    setErrors(false);

    if (isLoading) return false;
    setIsLoading(true);
    setUsers([]);

    Axios.post(
      API_URL + "clients/search/modulrproblem",
      resetPage ? { ...search, page: 1 } : search
    )
      .then((res) => {
        if (resetPage) setSearch({ ...search, page: 1 });
        setResetPage(false);
        setUsers(res.data);
        setNbResults(res.data.length);
        setNbPages(Math.ceil(parseInt(res.data.length) / 25));
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        // setUsers([]);
      })
      .then(() => {
        setIsLoading(false);
      });
  }
  function updateClientSearch(e) {
    var { name, value, type, checked } = e.target;
    var su = { ...search };
    if (type == "checkbox") {
      if (checked) {
        su.client[name] = true;
      } else {
        su.client[name] = false;
      }
    } else {
      su.client[name] = value;
    }
    if (name == "productTypes") {
      su.client.products = [];
    }

    setResetPage(true);

    setSearch(su);
  }
  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  useEffect(() => {
    highlightResults();
  });

  function highlightResults() {
    var strSearch = search.client.searchString.split(" ");
    var accOpened = [];

    window.$(".highlight").each((i, elem) => {
      window.$(elem).removeClass("highlight");
    });

    if (strSearch[0].length > 1) {
      for (var key in strSearch) {
        let str = strSearch[key];

        window.$(".user-name").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
          }
        });
        window.$(".user-email").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
          }
        });
        window.$(".client-name").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
            //window.$("." + window.$(elem).attr('data-ref')).click()
            if (!accOpened.includes(window.$(elem).attr("data-ref"))) {
              accOpened.push(window.$(elem).attr("data-ref"));
            }
          }
        });
        window.$(".client-siret").each((i, elem) => {
          if (window.$(elem).html().toLowerCase().includes(str.toLowerCase())) {
            window.$(elem).addClass("highlight");
            //window.$("." + window.$(elem).attr('data-ref')).click()
            if (!accOpened.includes(window.$(elem).attr("data-ref"))) {
              accOpened.push(window.$(elem).attr("data-ref"));
            }
          }
        });
      }

      accOpened.forEach((acc) => {
        window.$("#" + acc).collapse("show");
      });
    }
    window.$(".collapse").each((i, elem) => {
      if (!accOpened.includes(window.$(elem).attr("id"))) {
        window.$(elem).collapse("hide");
      }
    });
    setIsHighlight(true);
  }

  return (
    <>
      <Page
        container="container-fluid"
        title={"Liste des clients Modulr non importables"}
        errors={errors}
      >
        <ClientListFilters
          search={search}
          updateClientSearch={updateClientSearch}
          errors={errors}
          nbResults={nbResults}
          startSearch={searchModulrClients}
          isLoading={isLoading}
        />

        {isDeletingClient ? (
          <Loader text="Suppression du client en cours..." />
        ) : (
          <Table
            datas={users}
            fields={[
              {
                name: "Société",
                path: "company",
                render: (elem) => (
                  <span className={"text-" + (elem ? "success" : "danger")}>
                    {elem ? elem : "Non renseigné"}
                  </span>
                ),
              },
              {
                name: "Nom",
                path: "lastname",
                render: (elem) => (
                  <span className={"text-" + (elem ? "success" : "danger")}>
                    {elem ? elem : "Non renseigné"}
                  </span>
                ),
              },
              {
                name: "Prénom",
                path: "firstname",
                render: (elem) => (
                  <span className={"text-" + (elem ? "success" : "danger")}>
                    {elem ? elem : "Non renseigné"}
                  </span>
                ),
              },
              {
                name: "Mobile",
                path: "mobilePhone",
                render: (elem) => (
                  <span className={"text-" + (elem ? "success" : "danger")}>
                    {elem ? elem : "Non renseigné"}
                  </span>
                ),
              },
              {
                name: "Email",
                path: "email",
                render: (elem) => (
                  <span className={"text-" + (elem ? "success" : "danger")}>
                    {elem ? elem : "Non renseigné"}
                  </span>
                ),
              },
              {
                name: "Date création",
                path: "createdAt",
                render: (elem) => (
                  <span className={"text-" + (elem ? "success" : "danger")}>
                    {elem
                      ? DateTime.fromISO(elem).toFormat("dd/MM/yyyy")
                      : "Non renseigné"}
                  </span>
                ),
              },
            ]}
          />
        )}

        <Pagination
          nbPages={nbPages}
          page={search.page}
          changePage={changePage}
        />
      </Page>
    </>
  );
};

export default ClientModulrList;
