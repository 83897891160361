import dataService from "../helpers/dataService";

export const searchLeads = (searchData, productCategory) => {
  const urlByProduct = {
    PRO: "leads/search",
    DOMI: "quotes/search",
  };
  return new Promise((resolve, reject) => {
    dataService.post(urlByProduct[productCategory], searchData, resolve, reject);
  });
};
export const getLinkedLeads = (quotationId) => {
  return new Promise((resolve, reject) => {
    dataService.get(`leads/quotation/${quotationId}`, resolve, reject);
  });
};
