import React from "react";
import colors from "../../../../../../helpers/colors";
import tools from "../../../../../../helpers/tools";

const FranchiseTOCBtn = ({
  isActive,
  indiceFFB,
  FFBMultiplicatorMini,
  FFBMultiplicatorMaxi,
  label,
  icon,
}) => {
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center ${
        isActive ? "" : "text-dark"
      }`}
    >
      <img src={`/images/icons/${icon}`} />
      <div className="ml-2 text-center mt-2">
        <div>{label}</div>
        <div>
          <span style={{ color: isActive ? "white" : colors.secondary }}>
            Mini : {FFBMultiplicatorMini} x FFB soit
          </span>{" "}
          {tools.truenumberWithSpaces(tools.round(indiceFFB * FFBMultiplicatorMini))} €
        </div>
        <div>
          <span style={{ color: isActive ? "white" : colors.secondary }}>
            Maxi : {FFBMultiplicatorMaxi} x FFB soit
          </span>{" "}
          {tools.truenumberWithSpaces(tools.round(indiceFFB * FFBMultiplicatorMaxi))} €
        </div>
      </div>
    </div>
  );
};

export default FranchiseTOCBtn;
