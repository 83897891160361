import React from "react";
import { DateTime } from "luxon";

const ClientItem = ({ client, openClient, parent, removeClient }) => {
  return (
    <div className="d-flex flex-row flex-wrap align-items-center justify-content-between w-100 user-title-container">
      <div className="d-flex flex-row flex-wrap align-items-center user-title-header">
        <div
          className="d-flex flex-column mr-2 p-2 bg-success text-white user-title-icon  align-items-center"
          style={{
            fontSize: 12,
            lineHeight: "22px",
            textTransform: "capitalize",
          }}
        >
          <i className="fa fa-building mt-1" />
          Société
        </div>
        <div className="text-left  user-title-info">
          <div className="client-name" data-ref={parent}>
            <b>{client.company}</b>
          </div>
          <div className="client-siret" data-ref={parent}>
            {client.siret} - {client.ape}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center user-title-content">
        <div className="mr-2 text-muted" style={{ fontSize: 10 }}>
          <div title="Date de création">
            <i className="fa fa-calendar-plus mr-2" />
            {DateTime.fromISO(client.createdAt).toFormat("dd/MM/yyyy")}
          </div>
          {client.updatedAt && (
            <div title="Date de dernière modification">
              <i className="fa fa-edit mr-2" />
              {DateTime.fromISO(client.updatedAt).toFormat("dd/MM/yyyy")}
            </div>
          )}
        </div>
        <div className="mr-2">
          <button
            className="btn btn-success mr-3 btn-sm"
            onClick={() => openClient(client.id)}
          >
            <i className="fa fa-eye mr-2" />
            Voir
          </button>
          <button
            className="btn btn-danger mr-3 btn-sm"
            data-priv="bo_crudusertemp"
            onClick={removeClient}
          >
            <i className="fa fa-times mr-2" />
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientItem;
