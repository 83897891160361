import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import Loader from "../../../common/Loader";
import MRPForm from "./MRPForm/MRPForm";
import useFormState from "./MRPForm/useFormState";

const ModifyAnswers = (props) => {
  const { actions, apiData } = useFormState();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  var { quotationId, establishmentId, productId, clientId } =
    props.match.params;

  useEffect(() => {
    window.scrollTo(0, 0);
    actions.resetFormState();
    getQuotation();
  }, []);

  const getQuotation = () => {
    setIsLoading(true);

    if (quotationId) {
      dataService.get(
        `quotations/${quotationId}/establishments/${establishmentId}/products/${productId}`,
        (data) => {
          dataService.get(`clients/${clientId}`, (cdata) => {
            actions.initFormState({
              ...data,
              headOfficeTurnover: cdata.turnover,
              headOfficeWorkforce: cdata.workforce,
              headOfficeLegalForm: cdata.legalForm.id,
              headOfficeCsp: cdata.socioProfessionalCategory,
              establishmentId,
              quotationId,
            });
          });
        },
        (err) =>
          setErrors(
            "Erreur lors de la récupération des données. Veuillez recharger la page. Si le problème persiste, contactez Perceval"
          ),
        () => setIsLoading(false)
      );
    }
  };

  return (
    <Page
      title="Modifier les réponses de la demande"
      back={`/forms/${clientId}`}
      backText="Retour"
      containerClassname=""
    >
      {isLoading ? (
        <Loader />
      ) : errors ? (
        <div className="alert alert-danger">{errors}</div>
      ) : (
        <>
          <div className="">
            <p className="custom-p ">
              <strong>Pensez à enregistrer vos modifications</strong> et
              retrouvez le questionnaire mis à jour dans vos « demandes en
              cours ».
            </p>
          </div>
          {apiData && (
            <h4 className="pb-2 pt-3">
              <i className="fa fa-chess-rook mr-2" />
              {apiData.name}{" "}
              <small>
                (
                {tools.formatFollowingPattern(apiData.siret, [3, 3, 3, 5], " ")}
                )
              </small>
            </h4>
          )}
          <MRPForm />
        </>
      )}
    </Page>
  );
};

export default withRouter(ModifyAnswers);
