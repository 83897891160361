import React from "react";
import Pagination from "../../../common/Pagination";
import useRbe from "../useRbe";

const RBEPagination = () => {
  const { nbResults, rbeFilters, actions } = useRbe();

  return (
    <>
      <div className="w-100 d-center">{nbResults} résultat(s)</div>
      <Pagination
        page={rbeFilters.page}
        nbPages={Math.ceil(nbResults / 25)}
        changePage={actions.changePage}
      />
    </>
  );
};

export default RBEPagination;
