export const initialState = {
  formData: {
    headOfficeInfosInputs: {
      headOfficeTurnover: "",
      headOfficeWorkforce: "",
      headOfficeLegalForm: "",
      headOfficeCsp: "",
      quotationType: "",
      qualityApplicant: "",
    },
    globalInfosInputs: {
      turnover: "",
      workforce: "",
    },
    constructionInputs: {
      buildingArea: "",
      wallType: [],
      roofType: [],
      qualityOccupant: "",
      isWaiverOfRecourse: 1,
      waiverOfRecourseType: 1,
    },
    stockageInputs: {
      classicContentValue: "",
      itContentValue: "",
      electricalContentValue: "",
      amenitiesValue: "",
      isMerchandiseStorage: "",
      isMerchandiseStored10cmFromFloor: "",
      isDangerousProductsStored: "",
      dangerousProductsNature: "",
      isConditionningValueMoreThan7500: "",
      isFreezeRoom: "",
      isFreezeRoomMoreThan120: "",
      amenitiesAndEmbelishmentsValue: "",
    },
    fundsInputs: {
      isFundsMarket: "",
      qualityOccupantFundsMarket: "",
      isBuildingExclusion: "",
      isInsuranceCommonAccount: "",
      isAlignmentHit: "",
    },
    buildingInputs: {
      isInBuilding: "",
      buildingSize: "",
      buildingNumberOfFloors: "",
      officeLocationInBuilding: "",
      risksAroundInBuilding: "",
      risksAroundNextToBuilding: "",
      isBuildingGroupMore4000: "",
      buildingGroupArea: "",
      buildingGroupType: [],
      isGroupHeightMore28: "",
      isRankedBuilding: "",
      isBuildingNearWater: "",
      isBuildingInMall: "",
      isMallMore2000: "",
    },
    risksInputs: {
      isInsuredLast24Months: "",
      isContractCanceled: "",
      contractCanceledBy: "",
      contractCancelReason: "",
      isSinisterLast36Months: "",
      nbSinistersDamage: "",
      sinistersNature: "",
      nbSinistersRC: "",
      sinistersRCNature: "",
      isCompanyInLiquidation: "",
      isMortgage: "",
    },
    accident1Inputs: {
      isElectricalInstallationChecked: "",
      isFireEstinguishedChecked: "",
      isFireAlarmChecked: "",
      isFloodDamage: false,
      floodDamageValue: "",
      isEarthQuakeDamage: false,
      earthQuakeDamageValue: "",
      isDeliveringToTiers: "",
      isRainDamage: false,
      rainDamageValue: "",
      naturalDisasterValue: "",
      franchiseTOCValue: "",
      franchiseTOC: "",
      isResponsibilityAfterWork: "",
    },
    accident2Inputs: {
      isElectricalDamage: false,
      electricalDamageValue: "",
      isWaterDamage: false,
      waterDamageValue: "",
      isStealDamage: false,
      stealDamageValue: "",
      isStealInCheckoutDamage: "",
      stealInCheckoutDamageValue: "",
      isStealInChestDamage: "",
      stealInChestDamageValue: "",
      isAggressionDamage: "",
      aggressionDamageValue: "",
      protectionAgainstSteal: "",
      isIceBreakDamage: false,
      iceBreakDamageValue: "",
      isSignBreakDamage: false,
      signBreakDamageValue: "",
      isTransportedGoodsDamage: false,
      transportedGoodsDamageValue: "",
      isHardwareDamage: false,
      hardwareDamageValue: "",
      isMachineDamage: false,
      machineDamageValue: "",
      isFreezeRoomGoodsDamage: false,
      freezeRoomGoodsDamageValue: "",
      isOperatingLossDamage: false,
      operatingLossDamageValue: "",
      isGoodwillMarketvalueDamage: false,
      goodwillMarketValueDamageValue: "",
      generalFranchise: "",
    },
  },
  isSaving: false,
  isLoaded: false,
  apiData: null,
  errors: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "RESET_FORM_DATA":
      return {
        initialState,
      };
    case "UPDATE_FORM_DATA":
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
        isLoaded: true,
      };
    case "UPDATE_SAVING":
      return {
        ...state,
        isSaving: action.payload,
      };
    case "UPDATE_API_DATA":
      return {
        ...state,
        apiData: { ...state.apiData, ...action.payload },
      };
    case "UPDATE_HEADOFFICE_INFOS_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          headOfficeInfosInputs: {
            ...state.formData.headOfficeInfosInputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_GLOBAL_INFOS_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          globalInfosInputs: {
            ...state.formData.globalInfosInputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_CONSTRUCTION_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          constructionInputs: {
            ...state.formData.constructionInputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_STOCKAGE_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          stockageInputs: {
            ...state.formData.stockageInputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_FUNDS_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          fundsInputs: {
            ...state.formData.fundsInputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_BUILDING_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          buildingInputs: {
            ...state.formData.buildingInputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_RISKS_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          risksInputs: {
            ...state.formData.risksInputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_ACCIDENT1_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          accident1Inputs: {
            ...state.formData.accident1Inputs,
            ...action.payload,
          },
        },
      };
    case "UPDATE_ACCIDENT2_INPUTS":
      return {
        ...state,
        formData: {
          ...state.formData,
          accident2Inputs: {
            ...state.formData.accident2Inputs,
            ...action.payload,
          },
        },
      };

    case "UPDATE_ERRORS":
      return {
        ...state,
        errors: { ...action.payload },
      };

    default:
      return state;
  }
}
