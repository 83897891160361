import { useAtom } from "jotai";
import { atom } from "jotai";

export const chartFiltersAtom = atom({
  year: new Date().getFullYear(),
});

const useChartFilters = () => {
  const [chartFilters, setChartFilters] = useAtom(chartFiltersAtom);

  const handleChangeChartFilters = (e) => {
    const { name, value } = e.target;

    setChartFilters({ ...chartFilters, [name]: value });
  };

  return {
    chartFilters,
    actions: {
      handleChangeChartFilters,
    },
  };
};

export default useChartFilters;
