import React from "react";

const OrderButtons = ({ item, items, goUp, goDown, k }) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column   h-100">
      {k > 0 && (
        <button
          className="btn btn-default btn-sm w-100 d-center flex-fill  flex-column "
          onClick={goUp}
        >
          <i className="fa fa-arrow-up" />
        </button>
      )}
      <div className="font-weight-bold bg-white btn-sm w-100 d-center flex-fill">
        {item.order}
      </div>
      {k < items.length - 1 && (
        <button
          className="btn btn-default btn-sm w-100 d-center flex-fill  flex-column  "
          onClick={goDown}
        >
          <i className="fa fa-arrow-down" />
        </button>
      )}
    </div>
  );
};

export default OrderButtons;
