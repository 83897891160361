import React, { useEffect, useState } from "react";
import Control from "../../../common/Control";
import SaveBtn from "../../../common/SaveBtn";

const AddSectionForm = ({ actions, state }) => {
  const [newSection, setNewSection] = useState({ name: "", order: 1 });

  useEffect(() => {
    if (state.sections.length) {
      var max = getMaxOfArray(state.sections.map((f) => f.order));

      setNewSection({ ...newSection, order: max + 1 });
    }
  }, [state.sections]);

  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }

  return (
    <div className="shadow-sm p-3 my-3 bg-white">
      <div>
        <strong>Ajouter une section</strong>
        <span className="badge badge-default">order : {newSection.order}</span>

        <Control
          label="Nom de la section"
          value={newSection.name}
          change={(e) => setNewSection({ ...newSection, name: e.target.value })}
          suffix={
            <SaveBtn
              save={() => actions.createSection(newSection)}
              isSaving={state.isCreatingSection}
              text={<i className="fa fa-save" />}
              type="link"
              className="btn-sm h-100 ml-2"
              loaderColor="#040ca5"
            />
          }
        />
      </div>
    </div>
  );
};

export default AddSectionForm;
