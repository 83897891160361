import dataService from "../../helpers/dataService";

export const inputsConfigActions = (state, dispatch, props) => {
  function getInputsConfig() {
    dataService.get(`perceval/formconfig`, (datas) => {
      dispatch({
        type: "UPDATE_INPUTS_CONFIG",
        payload: datas,
      });
    });
  }

  return {
    getInputsConfig,
  };
};
