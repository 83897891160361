const colors = {
  "event-primary": "#3e75ff",
  "event-secondary": "#8ed6e0",
  "event-success": "#040ca5",
  "event-light": "#aaabb7",
  "event-tertiary": "#860f0f",
  "event-danger": "#dc5f45",
  "event-warning": "#ffcc99",
  "event-info": "#009404",
};

export default colors;
