import React, { useEffect } from "react";
import ChartFilters from "./ChartFilters/ChartFilters";
import useChartFilters from "./ChartFilters/useChartFilters";
import ChartItem from "./ChartItem/ChartItem";
import StatContainer from "./StatContainer/StatContainer";
import StatFilters from "./StatFilters/StatFilters";
import StatItem from "./StatItem/StatItem";
import useStats from "./useStats";

const Stats = () => {
  const {
    isLoadingLead,
    isLoadingDoc,
    isLoadingRenew,
    isLoading,
    leadDatas,
    docDatas,
    renewData,
    actions,
  } = useStats();
  const { chartFilters } = useChartFilters();

  useEffect(() => {
    actions.getLeadStats();
    actions.getDocStats();
    actions.getRenewStats();
  }, []);

  return (
    <>
      <StatFilters statActions={actions} isLoading={isLoading} />
      <div className="row">
        <div className="col-12 col-md-6">
          <StatContainer title="Demandes en cours">
            <StatItem
              isLoading={isLoadingLead}
              total={leadDatas.PJ}
              title="Protection Juridique"
              link="/leads?products[]=PJ&statuses[]=TO_DO&statuses[]=IN_PROGRESS"
            />
            <StatItem
              isLoading={isLoadingLead}
              total={leadDatas.MRP}
              title="Multirisque Professionnelle"
              link="/leads?products[]=MRP&statuses[]=TO_DO&statuses[]=IN_PROGRESS"
            />
            <StatItem
              isLoading={isLoadingLead}
              total={leadDatas.AC}
              title="Assurance Construction"
              link="/leads?products[]=AC&statuses[]=TO_DO&statuses[]=IN_PROGRESS"
            />
          </StatContainer>
        </div>
        <div className="col-12 col-md-6">
          <StatContainer title="Contrats aux documents manquants">
            <StatItem
              isLoading={isLoadingDoc}
              total={docDatas.CP_MISSING}
              title="Conditions Particulières"
              link="/files?entityModel=Amendment&fileTypes[]=CP&isExist=false&activeUsersOnly=true"
            />
            <StatItem
              isLoading={isLoadingDoc}
              total={docDatas.ATTESTATION_MISSING}
              title="Attestations manquantes"
              link="/files?entityModel=Amendment&fileTypes[]=ATT_ASSU_CONT&isExist=false&activeUsersOnly=true"
            />
            <StatItem
              isLoading={isLoadingDoc}
              total={docDatas.ATTESTATION_EXPIRED}
              title="Attestations périmées"
              link="/files?entityModel=Amendment&fileTypes[]=ATT_ASSU_CONT&isExist=true&activeUsersOnly=true"
            />
          </StatContainer>
        </div>
        <div className="col-12 col-md-6">
          <StatContainer title="Renouvellements PJ en attente">
            <StatItem
              isLoading={isLoadingRenew}
              total={renewData.RENEWABLE}
              title="Échéance dépassée"
              link="/pj-renew?renewableInNbDays=-1"
            />
            <StatItem
              isLoading={isLoadingRenew}
              total={renewData.RENEWABLE_30DAYS}
              title="Échéance dans moins de 30 jours"
              link="/pj-renew?renewableInNbDays=30"
            />
          </StatContainer>
        </div>
      </div>
      <ChartFilters />
      <div className="row">
        <div className="col-12">
          <StatContainer title="Flux des leads par mois">
            <ChartItem url="statistics/leads" postData={chartFilters} />
          </StatContainer>
        </div>
      </div>
    </>
  );
};

export default Stats;
