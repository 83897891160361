import React, { useEffect, useState } from "react";
import Control from "../../../common/Controls/Control";
import usePjRenewList from "../usePjRenewList";
import { filterContainerClassName } from "../../../common/Controls/filterContainer";

const PjRenewListFilters = () => {
  const {
    actions: { handleFilterChange },
    pjListFilters,
  } = usePjRenewList();

  const {
    filters: { renewableInNbDays },
  } = pjListFilters;
  const [nbDays, setNbDays] = useState(
    renewableInNbDays != "-1" ? renewableInNbDays : "30"
  );

  // useEffect(() => {
  //   if (renewableInNbDays != "-1") setNbDays(renewableInNbDays);
  // }, [renewableInNbDays]);

  const isDateInFutur = renewableInNbDays > 0;

  const handlePastDueClick = () => {
    handleFilterChange({
      target: {
        name: "renewableInNbDays",
        value: -1,
      },
    });
  };
  const handleRenewableClick = () => {
    handleFilterChange({
      target: {
        name: "renewableInNbDays",
        value: nbDays,
      },
    });
  };

  const handleChangeNbDays = (e) => {
    var { value } = e.target;
    if (!value) return;
    setNbDays(value);
    handleFilterChange({
      target: {
        name: "renewableInNbDays",
        value: value,
      },
    });
  };

  return (
    <div className={filterContainerClassName}>
      <div className="col-12 col-md-6">
        <button
          onClick={handlePastDueClick}
          className={`btn btn-${isDateInFutur ? "default" : "primary"}`}
        >
          Échéance dépassée, non renouvelées
        </button>
      </div>
      <div className="col-12 col-md-6">
        <button
          onClick={handleRenewableClick}
          className={`btn btn-${isDateInFutur ? "primary" : "default"}`}
        >
          Échéance dans moins de {nbDays} jours
        </button>
        <Control
          label="Ajuster la durée"
          type="select"
          value={nbDays}
          change={handleChangeNbDays}
          datas={Array(3)
            .fill(1)
            .map((i, ik) => ({
              id: (ik + 1) * 30,
              name: `${(ik + 1) * 30} jours`,
            }))}
          dataIndex="id"
          dataLabel="name"
          noPaddingOnFormGroup
          noMarginOnFormGroup
          inputStyle={{ borderRadius: 8 }}
          labelClassname="mt-2"
          noBg
        />
      </div>
    </div>
  );
};

export default PjRenewListFilters;
