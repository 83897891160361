import React from "react";
import Control from "../../common/Control";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";

const InputsPJ = ({ params, updateInput }) => {
  return (
    <div className="row">
      <Control
        label="Département"
        type="btnList"
        value={params.postalCode}
        name="postalCode"
        datas={[
          { id: "974", name: "Métropole / Réunion / Antilles" },
          { id: "973", name: "Guyane / Mayotte" },
        ]}
        change={updateInput}
        className="col-12"
        btnInline
      />
      <Control
        label="Date d'effet"
        name="beginAt"
        type="date"
        value={tools.getDateFrom(params.beginAt)}
        change={(e) =>
          updateInput({
            target: {
              name: "beginAt",
              value: tools.getDateFrom(e.target.value),
            },
          })
        }
        className="col-12 col-md-6"
      />
      <Control
        label="Date d'échéance principale"
        name="endAt"
        type="date"
        value={tools.getDateFrom(params.endAt)}
        change={(e) =>
          updateInput({
            target: {
              name: "endAt",
              value: tools.getDateFrom(e.target.value),
            },
          })
        }
        className="col-12 col-md-6"
      />
      <Control
        label="Option Recouvrement de Créances"
        type="checkbox"
        name="rc"
        checked={params.rc}
        change={updateInput}
        className="col-12 col-md-6"
      />
      <Control
        label="Option Fiscale et Sociale"
        type="checkbox"
        name="fs"
        checked={params.fs}
        change={updateInput}
        className="col-12 col-md-6"
      />
      <Control
        label="Chiffre d'affaires"
        name="turnover"
        value={params.turnover}
        change={updateInput}
        className="col-12 col-md-6"
      />
      <Control
        label="Code APE"
        name="ape"
        value={params.ape}
        change={updateInput}
        className="col-12 col-md-6"
      />
      <Control
        label="Commission (%)"
        type="range"
        min={0}
        max={100}
        value={params.commission}
        change={updateInput}
        name="commission"
        className="col-12"
      />
    </div>
  );
};

export default InputsPJ;
