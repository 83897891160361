import React, { useEffect, useState } from "react";
import Page from "../../common/layout/Page";
import DynamicEditor from "./DynamicEditor";
import Axios from "axios";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";
import SaveBtn from "../../common/SaveBtn";
import Control from "../../common/Control";

const DynamicPageDetail = (props) => {
  const [page, setPage] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    setIsSaved(false);
  }, [page]);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(API_URL + "pages/" + props.match.params.id)
      .then((res) => {
        setPage(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(
            "Une erreur est survenue lors de la récupération du contenu de la page dynamique"
          );
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  function inputChange(e) {
    var pg = JSON.parse(JSON.stringify(page));
    pg[e.target.name] = e.target.value;
    setPage(pg);
  }

  function save() {
    setIsSaving(true);
    Axios.patch(API_URL + "pages/" + props.match.params.id, page)
      .then((res) => {
        toast.success("La page dynamique a bien été enregistrée");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error(
            "Une erreur est survenue lors de l'enregistrement de la page dynamique"
          );
        }
      })
      .then(() => {
        setIsSaving(false);
        setIsSaved(true);
      });
  }

  return (
    <Page
      container={"container-fluid px-5"}
      title={
        (props.match.params.id == "create" ? "Création" : "Modification") +
        " d'une page dynamique"
      }
      errors={errors}
      back={"/dynamic-pages"}
    >
      <Control
        label="Nom"
        name="name"
        value={page.name}
        change={inputChange}
        error={errors}
      />
      {page && <DynamicEditor page={page} setPage={setPage} />}
      <SaveBtn
        isSaving={isSaving}
        isSaved={isSaved}
        save={save}
        className="btn btn-primary mt-3"
      />
    </Page>
  );
};

export default DynamicPageDetail;
