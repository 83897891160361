import React from "react";
import { DateTime } from "luxon";
import Colors from "../../../../../variables.scss";
import styled from "styled-components";
import tools from "../../../../../helpers/tools";
import colors from "../../../../../helpers/colors";
import Progress from "../../../../common/Progress";
import useStore from "../../../../../context/useStore";

const RBEItem = ({ rbeItemData }) => {
  const [state] = useStore();
  const { BENEFICIARY_TYPES } = state.constants.items;
  const diff = DateTime.local().diff(DateTime.fromISO(rbeItemData.birthdate), "years");
  const age = Math.round(diff.years);
  const beneficiaryTypeRef = BENEFICIARY_TYPES.find((bt) => bt.id == rbeItemData.type);

  const displayReasonBlackListed = () => {
    alert(rbeItemData.reasonBlackListed);
  };

  return (
    <>
      <StyledRBEItem className="row border-bottom">
        <div className="col-12 col-md-8 col-md-mr-2">
          <div className="d-flex">
            <span
              className={`badge beType badge-${beneficiaryTypeRef?.color}`}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {beneficiaryTypeRef?.name}
            </span>
          </div>
          <div className="mt-1">
            <strong className="text-capitalize">
              {rbeItemData.isBlackListed && (
                <i
                  onClick={displayReasonBlackListed}
                  className="fa fa-exclamation-triangle text-danger"
                  aria-hidden="true"
                  title={rbeItemData.reasonBlackListed}
                />
              )}{" "}
              {tools.getFullName(rbeItemData)}
            </strong>
            <div className="" style={{ fontSize: 12 }}>
              Depuis le {tools.formatDate(rbeItemData.beneficiaryAt)}
            </div>
            <div className="" style={{ fontSize: 12 }}>
              Intégré le {tools.formatDate(rbeItemData.integratedAt)}
            </div>
            <div className="" style={{ fontSize: 12 }}>
              Déclaré le {tools.formatDate(rbeItemData.declaredAt)}
            </div>

            {isNaN(age) ? null : (
              <div className="text-color mt-1">
                {age} ans - {tools.formatDate(rbeItemData.birthdate)}
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3 text-right share__pct">
          <strong>{rbeItemData.nbShares || "N.C"}%</strong>
          <Progress progress={rbeItemData.nbShares || 0} />
          <p>des parts et des votes</p>
        </div>
      </StyledRBEItem>
    </>
  );
};

const StyledRBEItem = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  & .text-color {
    color: var(--color-default-dark);
  }
  & .beType {
    color: ${Colors.light};
    line-height: 12px;
  }
  & .share__pct {
    strong {
      font-size: 20px;
    }
    p {
      font-size: 12px;
    }
  }
`;

export default RBEItem;
