import dataService from "../helpers/dataService";

export const searchClients = (searchData) => {
  return new Promise((resolve, reject) => {
    dataService.post(
      `customers/search`,
      { ...searchData.user, page: searchData.page },
      (data) => resolve({ ...data, users: data.data }),
      reject
    );
  });
};

export const searchClientUsers = (userId, clientId) => {
  return new Promise((resolve, reject) => {
    dataService.post(`customers/${userId}`, { clientId }, resolve, reject);
  });
};

export const getClient = (userId) => {
  return new Promise((resolve, reject) => {
    dataService.get(`customers/${userId}/clients`, resolve, reject);
  });
};
