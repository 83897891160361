import React, { useState } from "react";

const CustomTabsContext = React.createContext();

const CustomTabsProvider = ({ value, children }) => {
  return (
    <CustomTabsContext.Provider value={value}>
      {children}
    </CustomTabsContext.Provider>
  );
};

const CustomTabs = ({ items }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <ul
        className="nav nav-tabs d-flex flex-wrap justify-content-start border-bottom"
        role="tablist"
        style={{ borderRadius: window.borderRadius, maxWidth: 900 }}
      >
        {items.map((it, itk) => (
          <li
            data-priv={it.dataPriv}
            key={"li" + it.id + itk}
            className="nav-item mx-1 text-center animated fadeIn faster "
            role="presentation"
            style={{
              // width: 100 / items.length + "%",
              animationDelay: itk * 100 + "ms",
            }}
          >
            <button
              className={
                "btn btn-default btn-sm w-100 " +
                (currentTab == itk ? "active" : "")
              }
              id={"c-" + it.id + "-tab"}
              role="tab"
              aria-controls={"c-" + it.id}
              aria-selected="true"
              onClick={() => {
                setCurrentTab(itk);
              }}
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: window.borderRadius,
                borderTopRightRadius: window.borderRadius,
              }}
              disabled={it.disabled}
            >
              {it.title}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content pt-4">
        <CustomTabsProvider value={{ currentTab, setCurrentTab }}>
          {items.map((it, itk) => (
            <div
              key={`cnt${it.id}${itk}`}
              data-priv={it.dataPriv}
              className={
                "tab-pane fade " + (currentTab == itk ? "show active" : "")
              }
              id={"c-" + it.id}
              role="tabpanel"
              aria-labelledby={"c-" + it.id + "-tab"}
            >
              {it.content(setCurrentTab)}
            </div>
          ))}
        </CustomTabsProvider>
      </div>
    </>
  );
};

export default CustomTabs;
