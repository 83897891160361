import { atom, useAtom } from "jotai";
import dataService from "../../../../helpers/dataService";

const blackListItemAtom = atom(null);

const useBlackListItem = () => {
  const [blackListItem, setBlackListItem] = useAtom(blackListItemAtom);

  const initItem = (data) => {
    setBlackListItem({ ...data, files: [] });
  };

  const removeFromBlacklist = (blackListItemId) => {
    return new Promise((resolve, reject) => {
      dataService.remove(`blacklists/${blackListItemId}`, {}, resolve, reject, () => {}, true);
    });
  };

  return {
    blackListItem,
    actions: {
      initItem,
      removeFromBlacklist,
    },
  };
};

export default useBlackListItem;
