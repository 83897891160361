import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { sidenavActions } from "../../../../context/actions/sidenavActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import {
  deleteComment,
  patchComment,
  postComment,
} from "../../../../services/commentService";
import { getCompanyFiles } from "../../../../services/fileService";
import { PjItemContext } from "../pjItemContext";
import {
  bottomPanelContentAtom,
  isFilesLoadedAtom,
  isPjLoadingAtom,
  isSearchingFilesAtom,
  pjAtom,
} from "./atoms/pjItemAtoms";
import ResiliationForm from "./ResiliationForm/ResiliationForm";

const usePj = () => {
  const [state, dispatch] = useStore();
  const snActions = sidenavActions(state, dispatch);
  const [pj, setPj] = useAtom(pjAtom);
  const [isPjLoading, setIsPjLoading] = useAtom(isPjLoadingAtom);
  const { managers } = useContext(PjItemContext);
  const [bottomPanelContent, setBottomPanelContent] = useAtom(
    bottomPanelContentAtom
  );
  const [isSearchingFiles, setIsSearchingFiles] = useAtom(isSearchingFilesAtom);
  const [isFilesLoaded, setIsFilesLoaded] = useAtom(isFilesLoadedAtom);
  const [resiliationOpen, setResiliationOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const initPj = (data) => {
    setPj({
      ...data,
      files: [],
      isPastDue: checkIsPastDue(data),
      productData: {
        ...data.productData,
        realEndAt: DateTime.fromISO(data.productData.endAt)
          .minus({ days: 1 })
          .toISO(),
      },
    });
  };

  const checkIsPastDue = (pjData) => {
    return (
      DateTime.local() >
      DateTime.fromISO(pjData.productData.endAt).minus({ days: 1 })
    );
  };

  const openComments = () => {
    const isAlreadyOpen = bottomPanelContent == "COMMENTS";
    setBottomPanelContent(isAlreadyOpen ? null : "COMMENTS");
    scrollItemToTop();
  };

  const closeBottomPanel = () => {
    setBottomPanelContent(false);
  };

  const scrollItemToTop = () => {
    const pjElem = document.querySelector(`#pjitem${pj.id}`);
    pjElem.scrollIntoView({ behavior: "smooth" });
  };

  const getFormattedPjComments = (rawPj) => {
    const newPj = {
      ...rawPj,
      comments: tools.populateNamesFromId(managers, rawPj.comments),
    };
    return newPj;
  };

  const openInfos = async () => {
    const isAlreadyOpen = bottomPanelContent == "INFOS";
    setBottomPanelContent(isAlreadyOpen ? null : "INFOS");
    scrollItemToTop();

    if (isFilesLoaded) return;
    setIsSearchingFiles(true);
    let companyFiles = await getCompanyFiles(pj.client.id);
    let otherFiles = [];

    const newLead = {
      ...pj,
      files: [...pj.files, ...companyFiles, ...otherFiles],
    };
    setIsFilesLoaded(true);
    setIsSearchingFiles(false);
    setPj(newLead);
  };

  const onUpdateComment = (comment) => {
    console.log("UPDATE COMMENT");
    const oldPj = { ...pj };
    setPj({
      ...pj,
      comments: [...pj.comments.map((c) => (c.id == comment.id ? comment : c))],
    });
    setIsPjLoading(true);
    patchComment(comment.id, comment)
      .then((data) => {
        toast.success("Commentaire mis à jour");
        setPj(getFormattedPjComments({ ...pj, comments: data.comments }));
      })
      .catch((err) => setPj(oldPj))
      .finally(() => setIsPjLoading(false));
  };
  const onAddComment = (comment) => {
    console.log("ADD COMMENT", comment);

    const oldPj = { ...pj };
    setIsPjLoading(true);
    postComment({
      entity: pj.id,
      entityRef: "Policy",
      ...comment,
    })
      .then((data) => {
        toast.success("Commentaire ajouté");
        setPj(getFormattedPjComments({ ...pj, comments: data.comments }));
      })
      .catch((err) => setPj(oldPj))
      .finally(() => setIsPjLoading(false));
  };
  const onDeleteComment = (commentId) => {
    console.log("DELETE COMMENT");
    const oldPj = { ...pj };
    setPj({
      ...pj,
      comments: [...pj.comments.filter((c) => c.id != commentId)],
    });
    setIsPjLoading(true);
    deleteComment(commentId)
      .then((data) => toast.success("Commentaire supprimé"))
      .catch((err) => setPj(oldPj))
      .finally(() => setIsPjLoading(false));
  };

  const resendRenewMail = () => {
    if (
      window.confirm("Souhaitez-vous renvoyer un email de renouvellement ?")
    ) {
      setIsResending(true);

      dataService.get(
        `policies/${pj.id}/email-renewal`,
        (data) => {
          toast.success("Un email de renouvellement a été envoyé");
          setPj({
            ...pj,
            emailData: [
              { id: "send", isDone: true, doneAt: DateTime.local().toISO() },
            ],
          });
        },
        (err) => {},
        () => setIsResending(false)
      );
    }
  };

  const openResiliation = ({ cancelReasons }) => {
    setResiliationOpen(true);
    snActions.updateSidenav({
      isOpen: true,
      content: (
        <ResiliationForm cancelReasons={cancelReasons} onValidate={cancelPj} />
      ),
      onClose: () => setResiliationOpen(false),
    });
  };

  const cancelPj = (params) => {
    setIsCanceling(true);
    snActions.updateSidenav({
      isOpen: false,
      content: null,
    });

    dataService.patch(
      `policies/${pj.id}/cancel`,
      params,
      (data) => {
        setResiliationOpen(false);
        setPj({ ...pj, isCanceled: true });
      },
      (err) => {},
      () => setIsCanceling(false)
    );
    setTimeout(() => {}, 2000);
  };

  return {
    pj,
    bottomPanelContent,
    isSearchingFiles,
    isFilesLoaded,
    resiliationOpen,
    isCanceling,
    isResending,
    actions: {
      initPj,
      onAddComment,
      onUpdateComment,
      onDeleteComment,
      openComments,
      openInfos,
      closeBottomPanel,
      resendRenewMail,
      openResiliation,
    },
  };
};

export default usePj;
