import { useEffect } from "react";
import styled from "styled-components";
import AddCommentForm from "./AddCommentForm/AddCommentForm";
import { CommentContext } from "./context/CommentContext";
import CommentItem from "./CommentItem/CommentItem";
import useCommentList from "./useCommentList";

const CommentList = ({
  initComments,
  onUpdateComments,
  onUpdateComment,
  onAddComment,
  onDeleteComment,
  users,
}) => {
  const { comments, setupComments, actions } = useCommentList(
    onUpdateComments,
    onUpdateComment,
    onAddComment,
    onDeleteComment
  );

  useEffect(() => {
    setupComments(initComments, users);
  }, [initComments]);

  return (
    <StyledCommentContainer>
      <StyledCommentList>
        {comments.map((comment) => (
          <StyledCommentItem key={`compcom${comment.id}`}>
            <CommentContext.Provider value={{ comment, actions }}>
              <CommentItem users={users} />
            </CommentContext.Provider>
          </StyledCommentItem>
        ))}
      </StyledCommentList>

      <AddCommentForm addComment={actions.addComment} users={users} />
    </StyledCommentContainer>
  );
};

const StyledCommentContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const StyledCommentList = styled.ul`
  margin-bottom: 10px;
  padding: 0px;
  list-style: none;
`;
const StyledCommentItem = styled.li`
  margin: 5px 0px;
  position: relative;
`;

export default CommentList;
