exports.API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:4000/";
exports.APP_URL = process.env.REACT_APP_APP_URL;
exports.REF_BO = process.env.REACT_APP_REF_BO;
exports.REF_APP = process.env.REACT_APP_REF_APP;
exports.WEB_URL = process.env.REACT_APP_WEB_URL;
exports.recaptcha_token = process.env.REACT_APP_RECAPTCHA_TOKEN;
exports.GRAAL_API_URL = process.env.REACT_APP_GRAAL_API_URL;
exports.GRAAL_API_KEY = process.env.REACT_APP_GRAAL_API_KEY;
exports.WEB_URL = process.env.REACT_APP_WEB_URL;
