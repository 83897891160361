import React from "react";
import useProducers from "../../../../../hooks/useProducers/useProducers";
import Control from "../../../../common/Control";

const SelectProducers = (props) => {
  const { producers } = useProducers(false);

  return <Control datas={producers} {...props} />;
};

export default SelectProducers;
