import React, { useContext } from "react";
import { LeadContext } from "../../LeadContext";
import useLeadItem from "../useLeadItem";
import "./linkedLeads.scss";

const LinkedLeads = () => {
  const { getLinkedLeads } = useContext(LeadContext);
  const { lead } = useLeadItem();

  const handleLinkedLeadClick = () => {
    getLinkedLeads(lead.quotationId);
  };

  return lead.nbLinkedLeads > 0 ? (
    <div className=" linkedLeads d-center border-left border-top border-right border mr-lg-5 linkedTo">
      <button
        className="btn btn-link btn-sm mr-4 linkedToText border-0"
        onClick={handleLinkedLeadClick}
      >
        <i className="fa fa-share-alt mr-2" />
        {lead.nbLinkedLeads} demande(s) liée(s)
      </button>
    </div>
  ) : null;
};

export default LinkedLeads;
