import React from "react";
import Control from "../../../../../common/Controls/Control";
import SaveBtn from "../../../../../common/SaveBtn";
import useUserForm from "./useUserForm";

const UserForm = ({ client, updateClient }) => {
  const {
    userData,
    isEmailDisabled,
    isPhoneDisabled,
    isSavingEmail,
    isSavingPassword,
    isSavingPhone,
    isSavingUser,
    errors,
    onSavePhone,
    onSaveEmail,
    onSaveUser,
    onSavePassword,
    toggleDisabled,
    handleChangeUser,
  } = useUserForm(client, updateClient);

  const controlProps = {
    noMarginOnFormGroup: true,
    noPaddingOnFormGroup: true,
    noBg: true,
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="bg-blue p-3 rounded border mb-3">
          <Control
            label="Civilité"
            name="gender"
            type="btnList"
            btnInline
            datas={[
              { id: 1, name: "Monsieur" },
              { id: 2, name: "Madame" },
            ]}
            dataIndex="id"
            dataLabel="name"
            value={userData.gender}
            change={handleChangeUser}
            error={errors}
            {...controlProps}
          />
          <Control
            label="Prénom"
            name="firstname"
            type="text"
            value={userData.firstname}
            change={handleChangeUser}
            error={errors}
            {...controlProps}
          />
          <Control
            label="Nom"
            name="lastname"
            type="text"
            value={userData.lastname}
            change={handleChangeUser}
            error={errors}
            {...controlProps}
          />
          <SaveBtn
            dataPriv="bo_updatecustomer"
            isSaving={isSavingUser}
            save={onSaveUser}
            text="Enregistrer les informations"
          />
        </div>
        <div className="bg-blue p-3 rounded border">
          <Control
            label="Nouveau mot de passe"
            name="password"
            type="password"
            autocomplete={"off"}
            value={userData.password}
            change={handleChangeUser}
            error={errors}
            {...controlProps}
          />
          <SaveBtn
            dataPriv="bo_updatecustomer"
            isSaving={isSavingPassword}
            save={onSavePassword}
            text="Enregistrer le mot de passe"
          />
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="bg-blue p-3 border mb-3">
          <Control
            label="Email"
            name="email"
            type="email"
            value={userData.email}
            change={handleChangeUser}
            error={errors}
            disabled={isEmailDisabled}
            fullWidth
            suffix={
              <i
                data-priv="bo_modifycontactinfos"
                className={`fa fa-lock${isEmailDisabled ? "" : "-open"}`}
                onClick={() => toggleDisabled("email")}
              />
            }
            {...controlProps}
          />
          <div className="alert alert-secondary my-2">
            Attention, ceci aura pour effet de modifier l'email lié à chaque
            entreprise de l'utilisateur dans modul'r.
          </div>
          {!isEmailDisabled && (
            <SaveBtn
              dataPriv="bo_updatecustomer"
              isSaving={isSavingEmail}
              save={onSaveEmail}
              text="Enregistrer l'email"
            />
          )}
        </div>
        <div className="bg-blue p-3 border">
          <Control
            label="Téléphone"
            name="phone"
            type="tel"
            value={userData.phone}
            change={handleChangeUser}
            error={errors}
            disabled={isPhoneDisabled}
            fullWidth
            suffix={
              <i
                data-priv="bo_modifycontactinfos"
                className={`fa fa-lock${isPhoneDisabled ? "" : "-open"}`}
                onClick={() => toggleDisabled("phone")}
              />
            }
            {...controlProps}
          />
          <div className="alert alert-secondary my-2">
            Attention, ceci aura pour effet de modifier le téléphone sur lequel
            les réinitialisations de mot de passe seront envoyées.
          </div>
          <Control
            margin={"mt-2 mb-3"}
            type="checkbox"
            name="isAllClients"
            label="Mettre à jour toutes les entreprises de cet utilisateur sur Modul'r"
            checked={userData.isAllClients}
            change={handleChangeUser}
          />
          {!isPhoneDisabled && (
            <SaveBtn
              dataPriv="bo_updatecustomer"
              isSaving={isSavingPhone}
              save={onSavePhone}
              text="Enregistrer le numéro de téléphone"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
