import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import UserCard from "./UserCard/UserCard";
import {
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonType,
} from "../../common/Controls/filterContainer";
import SaveBtn from "../../common/SaveBtn";
import { atom, useAtom } from "jotai";

const searchUserAtom = atom({
  user: { role: "" },
  page: 1,
});

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setError] = useState(false);
  const [search, setSearch] = useAtom(searchUserAtom);
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [resetPage, setResetPage] = useState(false);
  const [searchText, setSearchText] = useState("Rechercher");

  useEffect(() => {
    searchUsers();
  }, []);

  useEffect(() => {
    setSearchText("Rechercher");
  }, [search]);

  useEffect(() => {
    if (nbResults > 0) setSearchText(nbResults + " résultats trouvés");
  }, [nbResults]);

  function searchUsers() {
    setError(false);
    setIsLoading(true);
    Axios.post(
      API_URL + "managers/search",
      resetPage ? { ...search, page: 1 } : search
    )
      .then((res) => {
        if (resetPage) setSearch({ ...search, page: 1 });
        setResetPage(false);
        setUsers(res.data.users);
        setNbResults(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data);
        setUsers([]);
      });
  }
  function updateUserSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (value == "" && e.target.name !== "role") {
      delete su.user[e.target.name];
    } else {
      su.user[e.target.name] = value;
    }
    setResetPage(true);

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  return (
    <Page
      container="container-fluid px-5"
      action={{
        to: "/users/create",
        text: "Créer un utilisateur",
      }}
      title={"Liste des utilisateurs"}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchUsers();
        }}
        className={filterContainerClassName}
      >
        {state.constants.items.length == 0 ? (
          <Loader withContainer={true} />
        ) : (
          <div className="col-12">
            <Control
              label="Rôle"
              btnInline
              name="role"
              type="btnList"
              datas={[
                { id: "", name: "Tous" },
                ...state.constants.items.ROLES_PER_BO,
              ]}
              value={search.user.role}
              change={updateUserSearch}
            />
          </div>
        )}
        <div className="col-12 col-md-6 col-lg-4 justify-content-center">
          <Control
            margin={"my-2"}
            label="Recherche"
            name="searchString"
            value={search.user.searchString}
            change={updateUserSearch}
            suffix={<i className="fa fa-search ml-2"></i>}
          />
        </div>
        <div className={searchButtonContainerClassName}>
          <SaveBtn
            label={searchText}
            type={searchButtonType}
            className={searchButtonClassName}
            isSaving={isLoading}
            save={searchUsers}
            typeBtn="submit"
            text="Chercher"
          />
          {/* <button
            style={{ fontWeight: "600" }}
            className={`btn mb-2 ${searchButtonClassName} btn-${searchButtonType}
             `}
            onClick={searchUsers}
          >
            {isLoading ? <Loader color="white" /> : searchText}
          </button> */}
        </div>
      </form>
      <div className="row mt-3">
        <div
          className="col-12"
          style={{
            overflow: "auto",
            maxWidth: "100%",
          }}
        >
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader withContainer={true} />
          ) : (
            <>
              <div className="row">
                {users.map((user) => (
                  <UserCard user={user} />
                ))}
              </div>
              {/* <Table
                datas={users}
                fields={[
                  {
                    name: "Avatar",
                    path: "id",
                    render: (elem) => <Avatar width={25} height={25} id={elem} />,
                  },
                  {
                    name: "Rôle",
                    path: "role",
                  },
                  {
                    name: "Utilisateur",
                    path: "*",
                    render: (elem) => (
                      <Link
                        to={"/users/" + elem.id}
                        style={{ minWidth: 0, fontWeight: "400" }}
                        className="btn  btn-link border-0 p-0 text-left "
                        data-priv="bo_modifyuser"
                      >
                        {tools.getFullName(elem)}
                      </Link>
                    ),
                  },
                  {
                    name: "Prénom",
                    path: "firstname",
                  },
                  {
                    name: "Email",
                    path: "email",
                  },
                  {
                    name: "Société",
                    path: "producer",
                    render: (el) => tools.findIn(producers, el, "id").company,
                  },
                  {
                    name: "Modifier",
                    path: "id",
                    render: (elem) => (
                      <Link
                        to={"/users/" + elem}
                        style={{ minWidth: 0 }}
                        className="btn btn-sm btn-default "
                        data-priv="bo_modifyuser"
                      >
                        <i className="fa fa-cog" />
                      </Link>
                    ),
                  },
                ]}
              /> */}
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default UserList;
