import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Avatar from "../Avatar";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import Notifications from "../Notifications/Notifications";

const TopHeader = () => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  useEffect(() => {
    if (state.auth.isAuthenticated) {
      window.$(document).ready(function () {
        window.$("#sidebarCollapse").on("click", function () {
          window.$("#sidebar").addClass("active");
        });
      });
    }
  }, [state.auth.isAuthenticated]);
  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);
      console.log("NEW IMAGE !");
      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);
  var authData = state.auth;

  const logoutUser = () => {
    localStorage.removeItem("jwtTokenBo");
    window.location.reload();
  };

  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <nav className="navbar navbar-expand-lg navbar-light p-3">
      <div className="container-fluid p-0">
        <button
          type="button"
          id="sidebarCollapse"
          className="navbar-btn active"
        >
          <i className="fa fa-bars fa-2x text-success" />
        </button>
        <div className="d-flex justify-content-end flex-fill">
          <Notifications />
          <button
            className="btn btn-toolbar btn-link justify-content-center align-items-center"
            onClick={logoutUser}
            style={{ textDecoration: "none" }}
          >
            Déconnexion
            <i className="fa fa-power-off ml-2" />
          </button>
        </div>
      </div>
    </nav>
  ) : (
    <></>
  );
};

export default TopHeader;
