import React from "react";
import styled from "styled-components";
import { PanelSectionTitle } from "../layout/StyledComponents/StyledComponents";
import Loader from "../Loader";
import FileItem from "./FileItem";
import useDocumentList from "./useDocumentList";

const DocumentList = ({
  title,
  files,
  isLoading,
  showEmpty = false,
  companyName = "",
  productName = "",
}) => {
  const { isDownloadingDocId, actions } = useDocumentList();

  let hasDocuments = false;
  files.forEach((doc) => {
    if (doc[0].fileName || showEmpty) {
      hasDocuments = true;
    }
  });

  return (
    <>
      <PanelSectionTitle>{title}</PanelSectionTitle>
      <StyledDocumentList>
        {isLoading && (
          <div className="d-center w-100 h-100">
            <Loader />
          </div>
        )}
        {!hasDocuments && !isLoading && !showEmpty && <div>aucun document</div>}
        {files.map((docCont) => {
          const doc = docCont[0];
          if (doc.fileName || showEmpty) {
            return (
              <FileItem
                key={`docitem${doc.fileName}${doc.id}`}
                doc={{ ...doc, companyName, productName }}
                onDownload={actions.downloadDocument}
                isDownloading={isDownloadingDocId == doc.id}
              />
            );
          }
          return null;
        })}
      </StyledDocumentList>
    </>
  );
};

const StyledDocumentList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  max-width: 100%;
  overflow-y: scroll;
  padding: 10px 0px;
  flex-wrap: wrap;
`;

export default DocumentList;
