import React from "react";

const ModalBtn = ({
  id,
  label,
  btnClass,
  onClick = false,
  backdrop,
  dataPriv,
}) => {
  return (
    <button
      data-priv={dataPriv}
      type="button"
      className={btnClass}
      data-toggle="modal"
      data-target={"#" + id}
      onClick={onClick}
      data-backdrop={backdrop ? "true" : "false"}
    >
      {label}
    </button>
  );
};

export default ModalBtn;
