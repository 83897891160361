import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";
import { useEffect } from "react";

const productTypesAtom = atom([]);

const useProductTypes = (fetchOnLoad) => {
  const [productTypes, setProductTypes] = useAtom(productTypesAtom);

  useEffect(() => {
    if (fetchOnLoad && productTypes.length === 0) onGetProductTypes();
  }, []);

  const onGetProductTypes = () => {
    dataService.get(`productTypes`, setProductTypes);
  };

  return {
    productTypes,
    onGetProductTypes,
  };
};

export default useProductTypes;
