import React from "react";
import { PanelSectionTitle } from "../../layout/StyledComponents/StyledComponents";
import ProductDataList from "../ProductDataList/ProductDataList";
const CYBERInfos = () => {
  return (
    <div>
      <PanelSectionTitle>Assurance Cyber</PanelSectionTitle>
      <ProductDataList />
    </div>
  );
};

export default CYBERInfos;
