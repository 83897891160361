import React from "react";
import styled from "styled-components";
import tools from "../../../../../../helpers/tools";
import UpdatedAt from "../UpdatedAt/UpdatedAt";
import useLeadItem from "../useLeadItem";
import "./createdAt.scss";

const CreatedAt = () => {
  const { lead } = useLeadItem();

  return (
    <div className="createdAt d-center d-flex flex-column text-start border-left border-top border-right border createdAt">
      {/* <i className="fa fa-calendar-alt mr-2" /> */}
      <UpdatedAt />
      Créé le {tools.formatDateHours(lead.createdAt)}
    </div>
  );
};

export default CreatedAt;
