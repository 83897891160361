import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import {
  commonControlProps,
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../common/Controls/filterContainer";
import SaveBtn from "../../common/SaveBtn";

var strTimeout = null;

const HintList = () => {
  const [hints, setHints] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [nbResults, setNbResults] = useState(0);
  const [search, setSearch] = useState({
    hint: { searchString: "" },
    page: 1,
  });
  const [searchText, setSearchText] = useState("Rechercher");

  const [nbPages, setNbPages] = useState(1);
  useEffect(() => {
    setSearchText("Rechercher");
  }, [search]);

  useEffect(() => {
    if (nbResults > 0) setSearchText(nbResults + " résultats trouvés");
  }, [nbResults]);

  function searchHints() {
    setErrors(false);
    setIsLoading(true);
    Axios.post(API_URL + "hints/search", search)
      .then((res) => {
        setHints(res.data.hints);
        setNbResults(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        setHints([]);
      })
      .then(() => {
        setIsLoading(false);
      });
  }
  function updateHintSearch(e) {
    var { name, value } = e.target;
    var su = { ...search };
    if (value == "") {
      delete su.hint[name];
    } else {
      su.hint[name] = value;
    }

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  function removeHint(id) {
    setIsLoading(true);
    Axios.delete(API_URL + "hints/" + id)
      .then((res) => {
        searchHints();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error("Une erreur est survenue lors de la suppression");
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  return (
    <Page
      container="container-fluid"
      action={{ to: "/hints/create", text: "Créer une astuce" }}
      title={"Liste des astuces"}
      errors={errors}
    >
      <div className={filterContainerClassName}>
        <div className="col-12 col-md-6 col-lg-4 justify-content-center">
          <Control
            label="Recherche"
            name="searchString"
            type="text"
            value={search.hint.searchString}
            change={updateHintSearch}
            suffix={<i className="fa fa-search ml-2"></i>}
            {...commonControlProps}
          />
        </div>

        <div className={searchButtonContainerClassName}>
          <SaveBtn
            className={searchButtonClassName}
            typeBtn="button"
            save={searchHints}
            text={searchText}
            isSaving={isLoading}
            type={searchButtonType}
            loaderColor={searchButtonLoaderColor}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-3">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader withContainer={true} />
          ) : (
            <>
              <Table
                datas={hints}
                fields={[
                  {
                    name: "Est visible ?",
                    path: "isVisible",
                    render: (el) =>
                      el ? (
                        <i className="text-success fa fa-check" />
                      ) : (
                        <i className="text-danger fa fa-times" />
                      ),
                  },
                  {
                    name: "Texte",
                    path: "text",
                  },

                  {
                    name: "Date création",
                    path: "createdAt",
                    render: (elem) =>
                      DateTime.fromISO(elem).toFormat("dd/MM/yyyy hh:mm"),
                  },
                  {
                    name: "Modifier",
                    path: "id",
                    render: (elem) => (
                      <Link
                        to={"/hints/" + elem}
                        style={{ minWidth: 0 }}
                        className="btn btn-sm  btn-default "
                      >
                        <i className="fa fa-cog" />
                      </Link>
                    ),
                  },
                  {
                    name: "Supprimer",
                    path: "id",
                    render: (elem) => (
                      <button
                        onClick={() => removeHint(elem)}
                        className="btn btn-sm btn-danger "
                      >
                        <i className="fa fa-times" />
                      </button>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default HintList;
