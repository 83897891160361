import React from "react";
import SimpleStatCounter from "../../../common/SimpleStatCounter/SimpleStatCounter";

const PartnerShortcuts = () => {
  return (
    <>
      <h1 className="text-success mb-3 pt-0 font-weight-bold">Accès rapide</h1>
      <div className="row mb-3">
        <div className="col-12 d-flex flex-wrap" style={{ gap: 16 }}>
          <SimpleStatCounter
            path="/client-list"
            text={"Clients"}
            icon="users"
          />
          <SimpleStatCounter
            path="/leads"
            text={"Leads entreprise"}
            icon="user-clock"
          />
          <SimpleStatCounter
            path="/leads-internes"
            text={"Leads internes"}
            icon="user-tie"
          />
          <SimpleStatCounter
            path="/partner-commissions"
            text={"Commissions"}
            icon="euro-sign"
            dataPriv="bo_readcommissions"
          />
          <SimpleStatCounter
            path="/timeline"
            text={"Timeline"}
            icon="heartbeat"
          />
          <SimpleStatCounter path="/rbe-list" text={"RBE"} icon="user-shield" />
        </div>
      </div>
    </>
  );
};

export default PartnerShortcuts;
