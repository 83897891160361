import React from "react";
import styled from "styled-components";

const TodoStatus = ({ todo, updateTodo }) => {
  const { isDone } = todo;
  const icon = isDone ? "fa-check animated fadeInDown faster" : "";

  const onUpdateStatus = () => {
    const newTodo = { ...todo, isDone: !todo.isDone };
    updateTodo(newTodo);
  };

  return (
    <StyledTodoStatus onClick={onUpdateStatus} className={`d-center shadow-sm`} isDone={isDone}>
      <i className={`fa ${icon}`} />
    </StyledTodoStatus>
  );
};

const StyledTodoStatus = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ isDone }) => (isDone ? "var(--success)" : "var(--color-body)")};
  color: ${({ isDone }) => (isDone ? "white" : "var(--color-default)")};
  border: 1px solid;
  cursor: pointer;
  transition: 300ms;
`;
export default TodoStatus;
