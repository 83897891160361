import React from "react";
import NotificationItem from "./NotificationItem";

const NotifContainer = ({ notifications, updateReadStatus }) => {
  return notifications.map((notif, k) => (
    <NotificationItem
      notif={notif}
      updateReadStatus={updateReadStatus}
      k={k}
      key={`notif-${notif.id}`}
    />
  ));
};

export default NotifContainer;
