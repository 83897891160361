import React from "react";
import styled from "styled-components";
import useLeadItem from "../useLeadItem";

const LeadBottomPanel = ({ children }) => {
  const { actions } = useLeadItem();

  return (
    <StyledLeadBottomPanel>
      <div
        className="fabArrow d-center shadow-sm cursor-pointer"
        onClick={actions.closeBottomPanel}
      >
        <i className="fa fa-chevron-down" />
      </div>
      {children}
    </StyledLeadBottomPanel>
  );
};

const StyledLeadBottomPanel = styled.div`
  padding: 40px;
  padding-top: 50px;
  position: relative;
  box-shadow: inset 0px 6px 8px -10px black;
  max-height: calc(100vh - 300px);
  overflow-x: scroll;

  .fabArrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    & i {
      transition: 300ms;
    }

    &:hover {
      i {
        transform: rotate(-180deg);
      }
    }
  }
`;

export default LeadBottomPanel;
