import React, { useState } from "react";
import RichInputEditor from "../../../RichInputEditor/RichInputEditor";

const CommentNameEdit = ({ users, comment, updateComment }) => {
  const [mode, setMode] = useState("VIEW");

  const onSubmit = (commentData) => {
    updateComment({ ...comment, ...commentData.comment });
    setMode("VIEW");
  };

  switch (mode) {
    case "VIEW":
      return (
        <div
          className="cursor-pointer py-2 ck-content"
          style={{ minHeight: "0" }}
          onClick={() => setMode("EDIT")}
          dangerouslySetInnerHTML={{ __html: comment.text }}
        />
      );
    case "EDIT":
      return (
        <RichInputEditor
          onSubmit={onSubmit}
          users={users}
          initialComment={comment?.text}
        />
      );

    default:
      return null;
  }
};

export default CommentNameEdit;
