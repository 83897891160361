import { atom, useAtom } from "jotai";
import { useState } from "react";
import { deleteCompany } from "../../../../../../../services/companyService";

const companyAtom = atom(null);
const bottomPanelContentAtom = atom("");
const bottomPanelParamsAtom = atom(null);
const isShowingCommentAtom = atom(false);
const isDeletedAtom = atom(false);

const useCompanyItem = () => {
  const [company, setCompany] = useAtom(companyAtom);
  const [bottomPanelContent, setBottomPanelContent] = useAtom(
    bottomPanelContentAtom
  );
  const [bottomPanelParams, setBottomPanelParams] = useAtom(
    bottomPanelParamsAtom
  );
  const [isShowingComment, setIsShowingComment] = useAtom(isShowingCommentAtom);
  const [isDeleted, setIsDeleted] = useAtom(isDeletedAtom);
  const [isDeleting, setIsDeleting] = useState(false);

  const initCompany = (data) => {
    setCompany({ ...data });
  };

  const openBottomPanel = (panelName, params = null) => {
    const isOpened = bottomPanelContent == panelName;
    document.querySelector(`#leaditem${company?.id}`)?.scrollIntoView();
    setBottomPanelContent(isOpened && !params ? null : panelName);
    setBottomPanelParams(params);
  };

  const onRemoveCompany = () => {
    if (window.confirm("Confirmez-vous la suppression de cette entreprise ?")) {
      setIsDeleting(true);
      deleteCompany(company.id)
        .then((data) => setIsDeleted(true))
        .finally(() => setIsDeleting(false));
    }
  };

  const closeBottomPanel = () => {
    setBottomPanelContent("");
    setBottomPanelParams(null);
  };

  const toggleIsShowingComment = () => {
    setIsShowingComment(!isShowingComment);
  };

  const populateCompanyUsers = (users) => {
    setCompany({ ...company, users });
  };

  return {
    company,
    bottomPanelContent,
    bottomPanelParams,
    isShowingComment,
    isDeleted,
    isDeleting,
    actions: {
      initCompany,
      openBottomPanel,
      closeBottomPanel,
      toggleIsShowingComment,
      populateCompanyUsers,
      onRemoveCompany,
      setCompany,
    },
  };
};

export default useCompanyItem;
