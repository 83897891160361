import axios from "axios";
import { authActions } from "./context/actions/authActions";
import { constantActions } from "./context/actions/constantActions";
import { inputsConfigActions } from "./context/actions/inputsConfigActions";
import useStore from "./context/useStore";
import jwt_decode from "jwt-decode";
import dataService from "./helpers/dataService";
import { API_URL, recaptcha_token, REF_BO } from "./config";
import { useEffect } from "react";
import { AUTH_TYPES } from "./context/reducers/authReducers";
import { loadReCaptcha } from "react-recaptcha-v3";
import useLegalForms from "./hooks/useExternalConstants/useLegalForms";

var firstLoad = true;

const useApp = () => {
  const [state, dispatch] = useStore();
  console.log("state", state);
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  const i_actions = inputsConfigActions(state, dispatch);
  const { legalForms, legalFormsActions } = useLegalForms();

  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== API_URL + "auth/logout") {
          actions.logoutUser();
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  if (localStorage.jwtTokenBo && firstLoad) {
    firstLoad = false;
    // Set auth token header auth
    //tools.setAuthToken(localStorage.jwtTokenBo);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtTokenBo);

    // Set user and isAuthenticated
    dispatch({
      type: AUTH_TYPES.SET_CURRENT_USER,
      payload: decoded,
    });

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      actions.logoutUser();

      // Clear current Profile
      // store.dispatch(clearCurrentProfile())
      // Redirect to login
    }
  }

  useEffect(() => {
    if (!localStorage.jwtTokenBo) {
      actions.logoutUser();
    } else {
      dataService.get(
        `auth/me/${REF_BO}`,
        (data) => {
          const decoded = jwt_decode(data);
          dispatch({
            type: AUTH_TYPES.SET_CURRENT_USER,
            payload: decoded,
          });
        },
        (err) => {
          actions.logoutUser();
        }
      );
    }
    c_actions.getConstants();
    i_actions.getInputsConfig();

    loadReCaptcha(recaptcha_token, () => {
      console.log("captcha loaded !");
    });

    legalFormsActions.getLegalForms();
  }, []);

  return { state, externalConstants: { legalForms } };
};

export default useApp;
